import { AlcoholType, LocationInput } from 'shared/types';
import { mapToId } from 'shared/utils';
import { Row } from 'pages/Location/types';
import { extractVolumeIds } from 'pages/Location/utils';

const mapRowToLocationArgs = (row: Row): LocationInput => ({
  id: row.id,
  isThirdParty: row.isThirdParty,
  nationalAssortment: row.nationalAssortment?.id,
  allocationPriority: row.allocationPriority?.id,
  locationCustomAssortments: row.locationCustomAssortments.map(mapToId),
  locationTobaccoCapabilities: row.locationTobaccoCapabilities.map(mapToId),
  locationCapabilities: row.locationCapabilities.map(mapToId),
  alcoholVolumes: extractVolumeIds(row.locationAlcoholCapabilities as AlcoholType[]),
  stopBuying: row.stopBuying,
  ageOutThreshold: row.ageOutThreshold,
});

export { mapRowToLocationArgs };
