import { FC } from 'react';

import { Spinner } from '../components/Spinner';

import { LoaderProps } from './types';
import styles from './Loader.module.css';

const Loader: FC<LoaderProps> = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <Spinner active size={50} />
    </div>
  );
};

export { Loader };
