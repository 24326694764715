import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { ContainerCapacity } from 'shared/types';

import { makeContainerUom } from './makeContainerUom';

const makeCapacity = (props: O.Partial<ContainerCapacity, 'deep'> = {}): ContainerCapacity =>
  <ContainerCapacity>merge<ContainerCapacity, O.Partial<ContainerCapacity, 'deep'>>(
    {
      id: '1',
      containerMin: 1,
      containerMax: 99995,
      containerUom: makeContainerUom(),
      __typename: 'ContainerCapacity',
    },
    props,
  );

export { makeCapacity };
