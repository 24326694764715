/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useQuery } from '@apollo/client';

import { PricingChannel, PricingChannelsQuery } from 'shared/types';

import { queryPricingChannels } from '../../operations';

import { PricingChannelMultiSelectorProps } from './types';
import styles from './PricingChannelMultiSelector.module.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PricingChannelMultiSelector: FC<PricingChannelMultiSelectorProps> = ({
  dealPricingChannels,
  onSubmitPricingChannelsClick,
}) => {
  const { data, loading: loadingPricingChannels } = useQuery<PricingChannelsQuery>(
    queryPricingChannels,
    {
      variables: {},
    },
  );

  const pricingChannelsData = data?.pricingChannels?.records || [];
  const [selectedPricingChannelNames, setSelectedPricingChannelNames] = useState<
    PricingChannel['name'][]
  >(dealPricingChannels?.map(dealPricingChannel => dealPricingChannel?.name) || []);

  const handleChange = (event: SelectChangeEvent<typeof selectedPricingChannelNames>) => {
    const {
      target: { value },
    } = event;
    setSelectedPricingChannelNames(typeof value === 'string' ? [value] : value);
  };

  const submitChange = () => {
    if (selectedPricingChannelNames.length === 0) {
      setSelectedPricingChannelNames(
        dealPricingChannels?.map(dealPricingChannel => dealPricingChannel?.name) || [],
      );
      return;
    }
    const selectedPricingChannelIDs: string[] = [];
    pricingChannelsData.map(({ id, name }) => {
      if (selectedPricingChannelNames.includes(name)) {
        selectedPricingChannelIDs.push(id);
      }
      return null;
    });
    void onSubmitPricingChannelsClick({ pricingChannelIDs: selectedPricingChannelIDs || [] });
  };

  return (
    <FormControl
      className={styles.pricingChannelsSelect}
      disabled={loadingPricingChannels}
      size="small"
      sx={{ m: 1, minWidth: 200, margin: 0 }}
    >
      <InputLabel id="pricing-channels-multi-selector-label" sx={{ fontSize: '14px' }}>
        Pricing Channels
      </InputLabel>
      <Select
        autoWidth
        input={<OutlinedInput label="Pricing Channels" />}
        labelId="pricing-channels-multi-selector-label"
        MenuProps={MenuProps}
        multiple
        onBlur={submitChange}
        onChange={handleChange}
        renderValue={selected => selected.join(', ')}
        sx={{ height: '32px' }}
        value={selectedPricingChannelNames}
      >
        {pricingChannelsData?.map(pricingChannel => (
          <MenuItem key={pricingChannel?.id} value={pricingChannel?.name}>
            <Checkbox
              checked={
                selectedPricingChannelNames?.find(
                  selectedPricingChannelName => selectedPricingChannelName === pricingChannel.name,
                ) !== undefined
              }
            />
            <ListItemText primary={pricingChannel.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export * from './types';
