import { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './InputBase.module.css';
import { InputBaseProps } from './types';

const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(
  (
    { className, disabled, noBorder = false, showErrorBorder, showSuccessBorder, value, ...props },
    ref,
  ) => (
    <input
      className={classNames(styles.input, className, {
        [styles.inputBordered]: !noBorder,
        [styles.successBorder]: !disabled && showSuccessBorder && value,
        [styles.errorBorder]: showErrorBorder,
      })}
      disabled={disabled}
      value={value}
      {...props}
      ref={ref}
    />
  ),
);

export { InputBase };
