import { ComponentProps, ElementType, ForwardedRef, ReactElement, forwardRef } from 'react';

import { ConditionalWrapperProps } from './types';

const ConditionalWrapper = forwardRef(
  <WrapperType extends ElementType>(
    { Wrapper, children, condition, ...wrapperProps }: ConditionalWrapperProps<WrapperType>,
    ref: ForwardedRef<any>,
  ): JSX.Element =>
    condition ? (
      <Wrapper {...(wrapperProps as ComponentProps<WrapperType>)} ref={ref}>
        {children}
      </Wrapper>
    ) : (
      (children as ReactElement)
    ),
);

export { ConditionalWrapper };
