import { FC } from 'react';

import { config } from 'setup';

const resources = (
  <a href={config.resourcesUrl} rel="noopener noreferrer" target="_blank">
    <h3 style={{ marginLeft: '24px' }}> Vendor Extranet Guides and Request Forms</h3>
  </a>
);

const ResourcesView: FC = () => (
  <>
    <h1 style={{ marginLeft: '24px' }}>Resources</h1>
    {resources}
  </>
);

export { ResourcesView };
