import { FC, memo, useMemo } from 'react';
import { equals } from 'ramda';

import { Select } from 'shared/components/Select';

import type { SelectBinaryProps } from './types';
import { deriveOptions } from './utils';

const SelectBinary: FC<SelectBinaryProps> = memo(({ optionsLabel, ...props }) => {
  const options = useMemo(() => deriveOptions(optionsLabel), [optionsLabel]);
  return <Select<number | ''> {...props} options={options} />;
}, equals);

export { SelectBinary };
