import {
  T,
  always,
  both,
  cond,
  contains,
  either,
  endsWith,
  hasPath,
  identity,
  ifElse,
  inc,
  is,
  mapObjIndexed,
  not,
  path,
  pipe,
  replace,
  values,
} from 'ramda';

import type {
  Replacements,
  ReplicateNArgs,
  ReplicateNArgsObject,
  ReplicateNArgsTuple,
  SkipIncrementForFields,
} from './types';

const replicateN = <D extends object = object>(...args: ReplicateNArgs<D>): D[] => {
  let data: D;
  let n;
  let replacements: Replacements<D>;
  let skipIncrementForFields: SkipIncrementForFields<D>;

  if (typeof args[1] === 'number') {
    [data, n, replacements = {}, skipIncrementForFields = []] = args as ReplicateNArgsTuple<D>;
  } else {
    // eslint-disable-next-line prefer-destructuring
    ({
      data,
      n,
      replacements = {},
      skipIncrementForFields = [],
    } = args[0] as ReplicateNArgsObject<D>);
  }

  skipIncrementForFields = [...skipIncrementForFields, '__typename' as keyof D];

  const change =
    (i: number, increment = true, isArray = false) =>
    (o: any) =>
      mapObjIndexed(
        (value, key): any =>
          cond<any, any>([
            [() => hasPath([key, String(i)], replacements), always(path([key, i], replacements))],
            [
              either(
                () => !isArray && contains(key, skipIncrementForFields as string[]),
                () => !increment,
              ),
              identity,
            ],
            [is(Array), pipe(change(i, increment, true), values, identity)],
            [is(Object), change(i, increment)],
            [
              T,
              cond([
                [is(Boolean), not],
                [both(is(String), () => /^\d+$/.test(String(value))), pipe(inc, String)],
                [is(Number), inc],
                [
                  is(String),
                  ifElse(
                    endsWith(String(i - 1)),
                    replace(String(i - 1), String(i)),
                    prop => `${prop} ${i}`,
                  ),
                ],
                [T, identity],
              ]),
            ],
          ])(value),
        o,
      );

  let previous = change(0, false)(data) as D;
  const result: D[] = [previous];

  for (let i = 1; i < n; i++) {
    previous = change(i)(previous) as D;
    result.push(previous);
  }

  return result;
};

export { replicateN };
