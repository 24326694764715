import { memo } from 'react';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';

import { CellProps } from 'shared/types';

import styles from './FactoryCellTimeConverter.module.css';

const FactoryCellTimeConverter = <DataType extends object>(formatDate = 'MMM/dd/yyyy') =>
  memo<CellProps<DataType, number>>(({ value: initialValue }) => (
    <>
      {!initialValue ? (
        <p className={styles.text}>-</p>
      ) : (
        format(fromUnixTime(initialValue), formatDate)
      )}
    </>
  ));

export { FactoryCellTimeConverter };
