import { FC } from 'react';

import { ListItemTextProps } from './types';
import styles from './ListItemText.module.css';

const ListItemText: FC<ListItemTextProps> = ({ children, ...divProps }) => (
  <div {...divProps} className={styles.listItemText}>
    {children}
  </div>
);

export { ListItemText };
