import { FC } from 'react';
import { Route } from 'react-router-dom';

import { hasPermissions } from './helpers';
import { SecureRouteProps } from './types';

const SecureRoute: FC<SecureRouteProps> = ({ userPermissions, ...props }) => {
  const pagePath = props.path;
  return hasPermissions({ pagePath, userPermissions }) ? <Route exact {...props} /> : null;
};
export { SecureRoute };
