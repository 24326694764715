import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { AssortmentOverrideState, DestinationAssortment } from 'shared/types';

import { pickOptions, replicateN } from '../utils';
import { makeDestinationAssortment } from '../factories/makeDestinationAssortment';
import { makeCost } from '../factories/makeCost';

import { currencies } from './currencies';
import { velocityCategory } from './velocityCategory';
import { packagings } from './packagings';

const currenciesOptions = currencies();
const velocityCategoryOptions = velocityCategory();
const packagingOptions = packagings();

const destinationAssortment = (
  props: (O.Partial<DestinationAssortment, 'deep'> | undefined)[] = [],
): DestinationAssortment[] =>
  mergeDeepArrays<DestinationAssortment>(
    replicateN({
      data: makeDestinationAssortment(),
      n: 5,
      replacements: {
        assortmentOverrideState: [
          ,
          ,
          ,
          AssortmentOverrideState.Active,
          AssortmentOverrideState.Active,
        ],
        nationalCost: [, , , undefined, undefined],
        packaging: [
          ,
          [packagingOptions[1]],
          [packagingOptions[2]],
          [packagingOptions[3]],
          [packagingOptions[1]],
        ],
        selectedPackagingID: [, '298706', '298707', '298708', '298706'],
        localCost: [, , , undefined, undefined],
        localCostCurrency: [
          ,
          pickOptions({ options: currenciesOptions, unpack: true, pick: 0 }),
          pickOptions({ options: currenciesOptions, unpack: true, pick: 1 }),
          pickOptions({ options: currenciesOptions, unpack: true, pick: 0 }),
          undefined,
        ],
        costs: [[makeCost()], [makeCost()], [makeCost()], [makeCost()], [makeCost()]],
        nationalCostCurrency: [
          ,
          pickOptions({ options: currenciesOptions, unpack: true, pick: 0 }),
          pickOptions({ options: currenciesOptions, unpack: true, pick: 1 }),
          undefined,
          pickOptions({ options: currenciesOptions, unpack: true, pick: 1 }),
        ],
        velocityCategory: [
          ,
          pickOptions({ options: velocityCategoryOptions, unpack: true, pick: 1 }),
          pickOptions({ options: velocityCategoryOptions, unpack: true, pick: 2 }),
          pickOptions({ options: velocityCategoryOptions, unpack: true, pick: 3 }),
          pickOptions({ options: velocityCategoryOptions, unpack: true, pick: 4 }),
        ],
      },

      skipIncrementForFields: [
        'velocityCategory',
        'nationalCostCurrency',
        'localCostCurrency',
        'packaging',
        'selectedPackagingID',
      ],
    }),
    props,
  );

export { destinationAssortment };
