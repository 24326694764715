import { useCallback, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { SelectMulti } from 'shared/components/Select';
import { TooltipHeader } from 'shared/components/TooltipHeader';

import { BaseQueryArgs, mapAutocompleteValueToFilterParams } from '../shared';
import { Label } from '../../shared';

import { FilterSearchStatusProps } from './types';

const FilterStatus = <QueryArgs extends BaseQueryArgs>({
  filterFieldName,
  filterOptions,
  label,
  onSubmitFilter,
  title,
  useUrlQueryParameter,
  ...props
}: FilterSearchStatusProps<QueryArgs>) => {
  const [value, setValue] = useState<string[]>([]);
  const location = useLocation();
  const history = useHistory();

  const handleChange = useCallback(
    ({ target: { value: selectedValue } }: SelectChangeEvent<string[]>) => {
      setValue(selectedValue as string[]);

      const canUseUrlQueryParameter =
        useUrlQueryParameter && typeof filterFieldName === 'string' && filterFieldName.length > 0;
      if (!canUseUrlQueryParameter) return;

      const newParams = new URLSearchParams(location.search);
      if (Array.isArray(selectedValue) && selectedValue.length > 0) {
        newParams.set(filterFieldName, selectedValue.join(','));
      } else {
        newParams.delete(filterFieldName);
      }

      history.replace({
        ...location,
        search: newParams.toString(),
      });
    },
    [setValue],
  );

  const options = Array.from(filterOptions.values())
    .filter(option => filterOptions.has(option))
    .map(option => ({
      value: option,
      label: option,
    }));

  const submitFilterValue = useCallback(
    (filterValue: string[]) => {
      onSubmitFilter(
        mapAutocompleteValueToFilterParams<QueryArgs, true, string[]>(filterValue, filterFieldName),
      );
    },
    [filterFieldName, onSubmitFilter],
  );

  const onClose = useCallback(() => {
    submitFilterValue(value);
  }, [submitFilterValue, value]);

  useEffect(() => {
    const canUseUrlQueryParameter =
      useUrlQueryParameter && typeof filterFieldName === 'string' && filterFieldName.length > 0;
    if (!canUseUrlQueryParameter) return;

    const queryValue = new URLSearchParams(location.search).get(filterFieldName);
    if (!queryValue) return;

    const queryOptions = queryValue.split(',');
    queryOptions.sort();
    const currentSelectedOptions = value.sort();

    if (
      queryOptions.length === currentSelectedOptions.length &&
      queryOptions.every((queryOption, index) => queryOption === currentSelectedOptions[index])
    ) {
      return;
    }

    const newSelectedOptions = queryOptions.filter(o => filterOptions.has(o));
    setValue(newSelectedOptions);
    submitFilterValue(newSelectedOptions);
  }, [
    useUrlQueryParameter,
    filterFieldName,
    filterOptions,
    location.search,
    setValue,
    submitFilterValue,
    value,
  ]);

  return (
    <>
      {title ? (
        <TooltipHeader label={label?.toString() || ''} title={title} />
      ) : (
        <Label label={label} />
      )}
      <SelectMulti
        value={value}
        {...props}
        label={label}
        onChange={handleChange}
        onClose={onClose}
        options={options}
      />
    </>
  );
};

export { FilterStatus };
