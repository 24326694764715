import { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './ListItem.module.css';
import { ListItemProps } from './types';

const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ children, disabled, highlight, ...liProps }, ref) => (
    <li
      {...liProps}
      ref={ref}
      className={classNames(styles.listItem, {
        [styles.highlighted]: highlight,
        [styles.disabled]: disabled,
      })}
    >
      {children}
    </li>
  ),
);

export { ListItem };
