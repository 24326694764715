import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { SupplierAssortment } from 'shared/types';

import { makeSupplierAssortment } from '../factories';
import { pickOptions, replicateN } from '../utils';

import { users } from './users';

const supplierAssortmentUsers = users();

const supplierAssortment = (
  props: O.Partial<SupplierAssortment, 'deep'>[] = [],
): SupplierAssortment[] =>
  mergeDeepArrays<SupplierAssortment>(
    replicateN({
      data: makeSupplierAssortment(),
      n: 5,
      replacements: {
        managedBy: [
          ,
          pickOptions({ options: supplierAssortmentUsers, unpack: true, pick: 0 }),
          pickOptions({ options: supplierAssortmentUsers, unpack: true, pick: 1 }),
          pickOptions({ options: supplierAssortmentUsers, unpack: true, pick: 0 }),
          pickOptions({ options: supplierAssortmentUsers, unpack: true, pick: 1 }),
        ],
      },
      skipIncrementForFields: ['managedBy'],
    }),
    props,
  );

export { supplierAssortment };
