import { TooltipHeader } from 'shared/components/TooltipHeader';

import { FactoryCellTooltipHeaderProps } from './types';

const FactoryCellTooltipHeader = ({
  isHeaderCell,
  label,
  title,
}: FactoryCellTooltipHeaderProps) => (
  <TooltipHeader isSubHeaderCell={!isHeaderCell} label={label} title={title} />
);

export { FactoryCellTooltipHeader };
