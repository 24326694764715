import { FC } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Modal } from 'shared/components/Modal';
import { FORM_ID } from 'pages/UserDefinedAttribute/constants';

import { WarningModalProps } from './types';
import styles from './WarningModal.module.css';

const WarningModal: FC<WarningModalProps> = ({
  expanded,
  isError,
  isOpen,
  onCancel,
  onCloseModal,
}) => (
  <Modal isOpen={isOpen} onClose={onCloseModal} size="small" title="Warning">
    <div className={styles.text}>
      {expanded ? (
        <>
          Are you sure, you want to leave <Typography variant="bold1">{`${expanded} `}</Typography>
          without <Typography variant="bold1">Saving</Typography>? If yes, your all modified data
          will be lost.
        </>
      ) : (
        <>
          Are you sure, you want to proceed without <Typography variant="bold1">Saving</Typography>?
          If yes, your all modified data will be lost.
        </>
      )}
    </div>
    <div className={styles.actions}>
      <Button onClick={onCloseModal} variant="outlined">
        Cancel
      </Button>
      <div>
        <Button disabled={isError} form={FORM_ID} type="submit" variant="outlined">
          Save and {expanded ? 'leave' : 'proceed'}
        </Button>
        <Button className={styles.redButton} onClick={onCancel} variant="contained">
          Yes, {expanded ? 'leave' : 'proceed'}
        </Button>
      </div>
    </div>
  </Modal>
);

export { WarningModal };
