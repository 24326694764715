import { useMemo } from 'react';

import {
  FactoryCellDataLink,
  FactoryCellInput,
  FactoryCellTimeConverter,
} from 'shared/components/DataGrid';
import { Row, UseColumnsArgs, UseColumnsResult } from 'pages/TransportationLane/types';
import {
  LocationAutocompleteOptionsQuery,
  LocationAutocompleteOptionsQueryVariables,
  OpcosQuery,
  OpcosQueryVariables,
  TransportationLanesQueryVariables,
} from 'shared/types';
import { isReadOnlyRole, mapToSelectOptions, mapValuesSeparatedWithComma } from 'shared/utils';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteQuery,
  FilterSearchDatePicker,
  FilterSearchInput,
} from 'shared/components/Search';
import { locationAutocompleteOptionsQuery, opcosQuery } from 'shared/operations/query';

const CellItemName = FactoryCellDataLink<Row>();
const CellTimeConverter = FactoryCellTimeConverter();

const useColumns = ({
  activePageIndex,
  role,
  systemLocked,
  updateFilter,
  userOptions,
}: UseColumnsArgs) => {
  const {
    accountNumber,
    destinationId,
    destinationName,
    opcoStateProvinceRegion,
    sourceId,
    sourceName,
    updatedAt,
    updatedBy,
  } = useMemo(
    () => ({
      sourceName: (
        <FilterSearchAutocompleteQuery<
          OpcosQuery,
          OpcosQueryVariables,
          TransportationLanesQueryVariables
        >
          filterFieldName="sourceName"
          label="OpCo/Site Name"
          mapDataToOptions={result => mapToSelectOptions(result.opcos.opcos)}
          mapUserInputToAutocompleteQueryFilerParams={value => ({
            name: value,
          })}
          onSubmitFilter={updateFilter}
          query={opcosQuery}
        />
      ),
      sourceId: (
        <FilterSearchInput<TransportationLanesQueryVariables>
          filterFieldName="sourceIds"
          label="OpCo/Site ID"
          mapUserInputToFilerParams={value => ({
            sourceIds: mapValuesSeparatedWithComma(value),
          })}
          onSubmitFilter={updateFilter}
        />
      ),
      opcoStateProvinceRegion: (
        <FilterSearchInput<TransportationLanesQueryVariables>
          filterFieldName="opcoStateProvinceRegion"
          label="Opco State/Province/Region"
          mapUserInputToFilerParams={value => ({
            opcoStateProvinceRegion: value,
          })}
          onSubmitFilter={updateFilter}
        />
      ),
      destinationName: (
        <FilterSearchAutocompleteQuery<
          LocationAutocompleteOptionsQuery,
          LocationAutocompleteOptionsQueryVariables,
          TransportationLanesQueryVariables
        >
          filterFieldName="destinationName"
          label="Destination Name"
          mapDataToOptions={result => mapToSelectOptions(result.locations.locations)}
          mapUserInputToAutocompleteQueryFilerParams={value => ({
            name: value,
          })}
          onSubmitFilter={updateFilter}
          query={locationAutocompleteOptionsQuery}
        />
      ),
      destinationId: (
        <FilterSearchInput<TransportationLanesQueryVariables>
          filterFieldName="destinationExtIds"
          label="Destination ID"
          mapUserInputToFilerParams={value => ({
            destinationExtIds: mapValuesSeparatedWithComma(value),
          })}
          onSubmitFilter={updateFilter}
        />
      ),
      accountNumber: (
        <FilterSearchInput<TransportationLanesQueryVariables>
          filterFieldName="accountNumbers"
          label="Account Number"
          mapUserInputToFilerParams={value => ({
            accountNumbers: mapValuesSeparatedWithComma(value),
          })}
          onSubmitFilter={updateFilter}
        />
      ),
      updatedAt: (
        <FilterSearchDatePicker<TransportationLanesQueryVariables>
          filterFieldName="updatedAt"
          label="Updated At"
          onSubmitFilter={updateFilter}
        />
      ),
      updatedBy: (
        <FilterSearchAutocomplete<TransportationLanesQueryVariables>
          filterFieldName="updatedBy"
          label="Updated By"
          mapUserAutocompleteValueSelectToFilterParams={option => ({
            updatedBy: option!.value,
          })}
          onSubmitFilter={updateFilter}
          options={userOptions}
        />
      ),
    }),
    [userOptions],
  );

  return useMemo(() => {
    const CellAccountNumber = FactoryCellInput<Row>({
      dependencies: ['destination', 'source'],
      isDisabled: ({ destination, source }) =>
        !source?.id || !destination?.id || systemLocked || isReadOnlyRole(role),
    });
    const columnSource = [
      {
        Header: () => sourceName,
        accessor: 'source.name',
        Cell: CellItemName,
      },
      {
        Header: () => sourceId,
        accessor: 'source.id',
      },
      {
        Header: () => opcoStateProvinceRegion,
        accessor: 'source.stateProvinceRegion',
        width: 200,
      },
      {
        Header: () => updatedBy,
        accessor: 'updatedBy',
      },
      {
        Header: () => updatedAt,
        accessor: 'updatedAt',
        Cell: CellTimeConverter,
        width: 175,
      },
      {
        Header: () => destinationName,
        accessor: 'destination.name',
      },
      {
        Header: () => destinationId,
        accessor: 'destination.extId',
      },
      {
        Header: () => accountNumber,
        accessor: 'accountNumber',
        Cell: CellAccountNumber,
      },
    ];
    const columnsDestination = [
      {
        Header: () => destinationName,
        accessor: 'destination.name',
      },
      {
        Header: () => destinationId,
        accessor: 'destination.extId',
      },
      {
        Header: () => accountNumber,
        accessor: 'accountNumber',
        Cell: CellAccountNumber,
      },
      {
        Header: () => updatedBy,
        accessor: 'updatedBy',
      },
      {
        Header: () => updatedAt,
        accessor: 'updatedAt',
        Cell: CellTimeConverter,
        width: 175,
      },
      {
        Header: () => sourceName,
        accessor: 'source.name',
      },
      {
        Header: () => sourceId,
        accessor: 'source.id',
      },
      {
        Header: () => opcoStateProvinceRegion,
        accessor: 'source.stateProvinceRegion',
        width: 200,
      },
    ];

    const columns = activePageIndex === 0 ? columnSource : columnsDestination;

    return columns as UseColumnsResult;
  }, [activePageIndex, systemLocked, userOptions]);
};

export { useColumns };
