import { FC } from 'react';

import { DataGridPage } from 'shared/components/DataGridPage';
import { DataGrid } from 'shared/components/DataGrid';
import { Header } from 'shared/components/Header';

import { SuppliersViewProps } from './types';

const header = <Header title="Suppliers" />;

const SuppliersView: FC<SuppliersViewProps> = props => (
  <DataGridPage header={header}>
    <DataGrid
      {...props}
      disableOnLoadingMoreData
      enableExpanding
      showLoaderOnLoadingMoreData={false}
    />
  </DataGridPage>
);

export { SuppliersView };
