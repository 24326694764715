import { EventHandler, FC } from 'react';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import * as React from 'react';

import { AutocompleteOption } from 'shared/components/Search';

import { ReactComponent as DeleteIcon } from './assets/close-icon.svg';

type ChipProps = {
  value: AutocompleteOption[];
  onDelete: EventHandler<any>;
  className?: string;
};

const CustomChip: FC<ChipProps> = ({ className = '', onDelete, value }) => (
  <Box>
    {value.map(({ label, value: val }) => (
      <Chip
        key={val}
        className={className}
        deleteIcon={<DeleteIcon />}
        label={label}
        // @ts-ignore
        onDelete={onDelete(label)}
      />
    ))}
  </Box>
);
export { CustomChip };
