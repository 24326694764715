import { useCallback, useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { memoCell } from 'shared/utils';
import { OptionValueType, Select } from 'shared/components/NewestSelect';
import { Option } from 'shared/types/props';
import { MockCellValue } from 'shared/components/DataGrid/components/Cells/types';

import { shouldDisable } from '../utils';

import { FactoryCellNewestSelectProps } from './types';

const FactoryCellNewestSelect = <
  DataType extends object,
  CellValue extends OptionValueType = string,
  ObjectValue extends object | CellValue = CellValue,
>(
  cellArguments: {
    allowEntryInSearch?: boolean;
    validateSearchEntry?: (entry: string) => boolean;
    options?: Option<CellValue, string>[];
    isDisabled?: (rowData: DataType) => boolean;
    pickValue?: (value: ObjectValue) => CellValue | null;
    dependencies?: (keyof DataType)[];
    setValueToRowState?: boolean;
    deriveOptionsFromRow?: (rowData: DataType) => Option<CellValue>[];
    showSearch?: boolean;
    resetValueAfterUpdate?: boolean;
    onEntryAdded?: (value?: OptionValueType | null) => void;
    searchPlaceholder?: string;
  } = { dependencies: [], resetValueAfterUpdate: false, setValueToRowState: false },
) =>
  memoCell<FactoryCellNewestSelectProps<DataType, CellValue, ObjectValue>>(cellProps => {
    const {
      column: { id: columnId },
      row,
      updateGridData,
      value: initialValue,
    } = cellProps;
    const {
      allowEntryInSearch,
      deriveOptionsFromRow,
      isDisabled,
      onEntryAdded,
      options,
      pickValue,
      resetValueAfterUpdate,
      searchPlaceholder,
      setValueToRowState,
      showSearch,
      validateSearchEntry,
    } = cellArguments;
    const { index, original, setState } = row;

    const disabled = shouldDisable(cellProps, isDisabled);
    const mappedOptions =
      options || (deriveOptionsFromRow?.(original) as Option<CellValue, string>[]);

    const { isOpen } = (initialValue ?? {}) as MockCellValue<ObjectValue>;
    const derivedInitialValue =
      ((initialValue ?? {}) as MockCellValue<ObjectValue>).value ?? initialValue;

    const mapInitialValue =
      (pickValue ? pickValue(derivedInitialValue) : (derivedInitialValue as CellValue)) ??
      ('' as CellValue);

    const [value, setValue] = useState(mapInitialValue);

    useEffect(() => {
      if (setValueToRowState) {
        setState({ [columnId]: value });
      }
    }, [value, setState, setValueToRowState]);

    useUpdateEffect(() => {
      if (resetValueAfterUpdate) {
        setValue(mapInitialValue);
      }
    }, [resetValueAfterUpdate, mapInitialValue]);

    const handleChange = useCallback(
      selectedValue => {
        setValue(selectedValue as CellValue);
        updateGridData(index, columnId, selectedValue as CellValue, original, row);
      },
      [updateGridData, index, columnId, setValue, original, row],
    );

    return (
      <Select
        allowEntryInSearch={allowEntryInSearch}
        disabled={disabled}
        initialSelectedOption={resetValueAfterUpdate ? undefined : value}
        onChange={handleChange}
        onEntryAdded={onEntryAdded}
        open={isOpen}
        options={mappedOptions}
        placeholder={value as string}
        searchPlaceholder={searchPlaceholder}
        selectedOption={resetValueAfterUpdate ? value : undefined}
        showSearch={showSearch}
        validateSearchEntry={validateSearchEntry}
      />
    );
  }, cellArguments.dependencies);

export { FactoryCellNewestSelect };
