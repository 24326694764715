import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { Supplier } from 'shared/types';

import { replicateN } from '../utils';
import { makeSupplier } from '../factories';

import { fullOpcos } from './fullOpcos';

const fullOpcosMock = fullOpcos();

const suppliers = (props: O.Partial<Supplier, 'deep'>[] = []): Supplier[] =>
  mergeDeepArrays<Supplier>(
    replicateN({
      data: makeSupplier(),
      n: 5,
      replacements: {
        opcos: [
          [fullOpcosMock[0]],
          [fullOpcosMock[1]],
          [fullOpcosMock[2]],
          [fullOpcosMock[3]],
          [fullOpcosMock[4]],
        ],
      },
      skipIncrementForFields: ['opcos'],
    }),
    props,
  );

export { suppliers };
