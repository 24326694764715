import { GraphQLError } from 'graphql';
import { NetworkError } from '@apollo/client/errors';
import { ServerError } from '@apollo/client/link/utils';

const getErrorCode = (error: GraphQLError | NetworkError): string => {
  switch ((error as ServerError).statusCode) {
    case 422:
    case 400:
      return 'Error 422 or 400 Unprocessable data - please correct your choice';
    case 504:
      return 'Error 504 Timeout - please decrease the number of selection';
    default:
      return 'Error - the data was not processed/saved!';
  }
};

export { getErrorCode };
