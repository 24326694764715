import { Fragment, memo } from 'react';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import { Tooltip } from '@mui/material';

import { CellProps } from 'shared/types';

import styles from './FactoryCellMultiDateConverter.module.css';

// Interface for the DataType object.
// It has a __typename and an accessor which are of type string,
// and can have additional properties.
interface DataType {
  label: string;
  accessor: string;
  [key: string]: any;
}

// An array of DataType objects.
type DataTypeArray = DataType[];

// Function to get the most recently updated item in an array of DataType objects.
// If the items array is empty, it returns null.
// Otherwise, it finds the item with the highest value for the specified key and returns it with the accessor key added.
function getMostRecentlyUpdated(items: DataType[], key: string): DataType | null {
  if (items.length === 0) {
    return null;
  }

  const mostRecentItem = items.reduce((maxItem, currentItem) => {
    if (currentItem[key] === null) {
      return maxItem;
    }

    return currentItem[key] > maxItem[key] ? currentItem : maxItem;
  }, items[0]);

  // If the most recent item's value is null, undefined or less than 0, return null.
  if (
    mostRecentItem[key] === null ||
    mostRecentItem[key] === undefined ||
    mostRecentItem[key] <= 0
  ) {
    return null;
  }

  // Add the accessor key to the most recent item
  return { ...mostRecentItem, accessor: key };
}

// Function to get a nested value from an object using a dot-separated path.
// If the path does not exist in the object, it returns null.
// Otherwise, it returns the value at the end of the path.
function getNestedValue<T extends object>(obj: T, path: string): DataTypeArray | number {
  return path
    .split('.')
    .reduce(
      (o: unknown, p: string) =>
        o && typeof o === 'object' && Object.prototype.hasOwnProperty.call(o, p)
          ? (o as { [key: string]: unknown })[p]
          : null,
      obj,
    ) as DataTypeArray | number;
}

// Factory function that returns a memoized React component for displaying data with multiple dates.
// It takes a date format and an array of accessor strings as parameters.
const FactoryCellMultiDateConverter = (
  formatDate = 'MMM/dd/yyyy',
  accessors: string[],
  accessorLabels: string[],
  initialLabel: string,
) =>
  // The returned component takes a CellProps object as props, and returns a Tooltip element.
  memo<CellProps<DataType, number>>(({ row, value: mainValue }) => {
    const mainValueItem: DataType | null = mainValue
      ? { time: mainValue, label: initialLabel, accessor: 'time' }
      : null;

    // For each accessor, it gets the most recently updated item from the data in the row values at the path specified by the accessor.
    // It then adds these items, along with an item created from the initialValue, to an array.
    const items: (DataType | null)[] = accessors.map((accessor, idx) => {
      const [path, key] = accessor.split('.'); // split the accessor into path and key
      const data = getNestedValue(row.original, path);

      if (typeof data === 'number') {
        return data > 0
          ? ({ time: data, label: accessorLabels[idx], accessor: 'time' } as DataType)
          : null;
      }

      if (!data || data.length === 0) {
        return null;
      }

      return getMostRecentlyUpdated(data, key);
    });

    items.push(mainValueItem); // Add the initialValueItem to the items array

    // Get the most recent time
    const mostRecent = items.reduce(
      (max, current) => (!max || (current && current.time > max.time) ? current : max),
      mainValueItem,
    );

    // Filter out any null items
    const validItems = items.filter(Boolean) as DataType[];
    // Sort the items by time
    validItems.sort((a, b) => {
      const aValue = a[a.accessor];
      const bValue = b[b.accessor];
      return aValue < bValue ? 1 : -1;
    });

    // It renders a Tooltip element with the formatted dates of the valid items as the title,
    // and the formatted date of the most recent item (or a hyphen if there is no most recent item) as the content.
    return (
      <Tooltip
        style={{ display: validItems.length > 0 ? 'block' : 'none' }}
        title={
          <div>
            {validItems.map(item => (
              <Fragment key={item[item.accessor]}>
                <p className={styles.text}>
                  {item.label}: {format(fromUnixTime(item[item.accessor]), formatDate)}
                </p>
              </Fragment>
            ))}
          </div>
        }
      >
        <div className={styles.wrapper}>
          {!mostRecent ? (
            <p className={styles.text}>-</p>
          ) : (
            format(fromUnixTime(mostRecent[mostRecent.accessor]), formatDate)
          )}
        </div>
      </Tooltip>
    );
  });

export { FactoryCellMultiDateConverter };
