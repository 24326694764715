import { FC } from 'react';

import { DataGrid } from 'shared/components/DataGrid';
import { DataGridPage } from 'shared/components/DataGridPage';
import { ACTION_MASS_EDIT_ITEM, ACTION_UPLOAD_FILE_ITEM } from 'shared/constants';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';

import { ItemViewProps, Row } from './types';

const ItemView: FC<ItemViewProps> = ({
  onAddTagsClick,
  onDataGridDownloadButtonClick,
  onEditClick,
  selectedRows,
  triggerUncheckAllCheckboxes,
  uploadFileProps,
  userPermissions,
  ...props
}) => (
  <DataGridPage<Row, true>
    header={
      <HeaderDataGridActions
        hasAccessToUploadFile={userPermissions.has(ACTION_UPLOAD_FILE_ITEM)}
        onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
        title="Item"
        uploadFileProps={uploadFileProps}
      />
    }
    isMassEditOn={userPermissions.has(ACTION_MASS_EDIT_ITEM)}
    onAddTagsClick={onAddTagsClick}
    onEditClick={onEditClick}
    selectedRows={selectedRows}
    triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
  >
    <DataGrid<Row> {...props} disableOnLoadingMoreData enableRowSelection />
  </DataGridPage>
);
export { ItemView };
