import { FC } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ActionModal } from 'shared/components/Modal';
import { FORM_ID } from 'shared/components/Edit';

import { WarningModalProps } from './types';
import styles from './WarningModal.module.css';

const WarningModal: FC<WarningModalProps> = ({
  isOpen,
  onClickLeave,
  onCloseModal,
  onSaveAndCloseModal,
}) => (
  <ActionModal
    actions={
      <>
        <Button onClick={onCloseModal} variant="outlined">
          Cancel
        </Button>
        <div>
          <Button form={FORM_ID} onClick={onSaveAndCloseModal} type="submit" variant="outlined">
            Save and leave
          </Button>
          <Button className={styles.redButton} onClick={onClickLeave} variant="contained">
            Yes, leave
          </Button>
        </div>
      </>
    }
    isOpen={isOpen}
    onClose={onCloseModal}
    size="small"
    title="Warning"
  >
    Are you sure, you want to leave <Typography variant="bold1">{'Mass Edit '}</Typography>
    without <Typography variant="bold1">Saving</Typography>? All unsaved changes will be lost.
  </ActionModal>
);

export { WarningModal };
