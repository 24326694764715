import { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as InfoIcon } from 'shared/assets/svg/info-icon.svg';

import { InfoTooltipProps } from './types';

const InfoTooltip: FC<InfoTooltipProps> = ({ title }) => (
  <Tooltip title={title}>
    <InfoIcon />
  </Tooltip>
);

export { InfoTooltip };
