const mapToLanguage = new Map<string, string>([
  ['English (United States of America) - en-US', 'en-US'],
  ['English (United Kingdom) - en-GB', 'en-GB'],
  ['English (Canada) - en-CA', 'en-CA'],
  ['German (Germany) - de-DE', 'de-DE'],
  ['Spanish (Spain) - es-ES', 'es-ES'],
  ['French (France) - fr-FR', 'fr-FR'],
  ['French (Canada) - fr-CA', 'fr-CA'],
]);

export { mapToLanguage };
