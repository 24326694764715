import { Option, SelectOption } from 'shared/types';

import { mapToSelectOption } from './mapToSelectOption';

type Mapper<T extends object[]> = (data: T[number], idx?: number) => Option<string, string>;

const mapToSelectOptions = <T extends object[] = SelectOption[]>(
  data: T,
  mapper: Mapper<T> = mapToSelectOption as Mapper<T>,
) => data.map(mapper);

export { mapToSelectOptions };
