import { FC } from 'react';

import { DataGrid } from 'shared/components/DataGrid';
import { DataGridPage } from 'shared/components/DataGridPage';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';
import { ACTION_MASS_EDIT_ITEM_SOURCE, ACTION_UPLOAD_FILE_ITEM_SOURCE } from 'shared/constants';

import { ItemSourceViewProps, MappedRow } from './types';

const ItemSourceView: FC<ItemSourceViewProps> = ({
  onDataGridDownloadButtonClick,
  onEditClick,
  selectedRows,
  triggerUncheckAllCheckboxes,
  uploadFileProps,
  userPermissions,
  ...props
}) => (
  <DataGridPage
    header={
      <HeaderDataGridActions
        hasAccessToUploadFile={userPermissions.has(ACTION_UPLOAD_FILE_ITEM_SOURCE)}
        onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
        title="Item Source"
        uploadFileProps={uploadFileProps}
      />
    }
    isMassEditOn={userPermissions.has(ACTION_MASS_EDIT_ITEM_SOURCE)}
    onEditClick={onEditClick}
    selectedRows={selectedRows}
    triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
  >
    <DataGrid<MappedRow>
      {...props}
      disableOnLoadingMoreData
      enableExpanding
      enableRowSelection
      showLoaderOnLoadingMoreData={false}
    />
  </DataGridPage>
);
export { ItemSourceView };
