import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { prop, uniqBy } from 'ramda';
import { Row as TableRow } from 'react-table';
import Typography from '@mui/material/Typography';
import { flushSync } from 'react-dom';

import { Loader } from 'shared/components/Loader';
import { EditPage, WarningExistingConnectionsModal } from 'shared/components/Edit';
import { ErrorModal } from 'shared/components/Modal';
import { FactoryCellDelete } from 'shared/components/DataGrid';
import { mapToSelectOptions } from 'shared/utils';
import { AutocompleteOption } from 'shared/components/Search';
import {
  MassEditSupplierAssortmentsMutation,
  MassEditSupplierAssortmentsMutationVariables,
  Option,
} from 'shared/types';

import { mapDataToMassEditMutationArgs } from './mappers';
import { massEditSupplierAssortmentsMutation } from './operations';
import { MassEditFormRow, MassEditFormRowAdditionalProps } from './components/MassEditFormRow';
import { Row, UseColumnsResult } from './types';
import { headersForMassEditColumns } from './constants';
import { deriveRowKey, findItem, findManagedBy, findOpco, isSupplierPage } from './utils';
import { SupplierAssortmentView } from './SupplierAssortmentView';
import { useSetup } from './hooks';

const SupplierAssortment: FC = () => {
  const { editPageProps, reset, showMainLoader, systemLocked, userOptions, ...props } = useSetup();
  const { activePageIndex } = props;
  const {
    defaultManagedBy,
    defaultOptions,
    isFirstSave,
    newRows,
    onCloseEditPage,
    onCloseWarningExistingConnectionsModal,
    onEditClick,
    onProceed,
    selectedRows,
    setDefaultManagedBy,
    setDefaultOptions,
    setIsFirstSave,
    setNewRows,
    setSelectedRows,
    showEditPage,
    showWarningExistingConnections,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
    uniqueSelectedRows,
  } = editPageProps;

  const [showErrorDifferentManagers, setShowErrorDifferentManagers] = useState(false);
  const [showErrorUnconnected, setShowErrorUnconnected] = useState(false);

  const deriveMassEditData = useCallback(() => {
    const isOnSupplierPage = isSupplierPage(activePageIndex);
    const currentManagedBy = findManagedBy(selectedRows);
    const linked = isOnSupplierPage
      ? uniqBy(prop('id'), findItem(selectedRows))
      : uniqBy(prop('id'), findOpco(selectedRows));
    const updateLocationFieldName = isOnSupplierPage ? 'itemID' : 'opcoID';
    const defaultValues = {
      managedById: currentManagedBy ? currentManagedBy.id : null,
      linked: linked ? mapToSelectOptions(linked) : null,
    };

    type Options = Option<string, string>[] | null;

    const mapRowToMutationArgs = ({
      linked: newLinked,
      managedById,
    }: {
      managedById?: string | null;
      linked: AutocompleteOption[] | null;
    }) =>
      mapDataToMassEditMutationArgs({
        data: uniqueSelectedRows,
        managedById,
        updateLocationFieldName,
        newOptions: newLinked as Options,
        defaultOptions: (defaultOptions ?? defaultValues.linked) as Options,
        defaultManagedBy: (defaultManagedBy ?? defaultValues.managedById) as string,
        isFirstSave,
        selectedRows,
        newRows,
      });

    const columns = [
      ...headersForMassEditColumns[activePageIndex],
      {
        id: 'delete',
        Cell: FactoryCellDelete<Row>({ setData: setSelectedRows }),
      },
    ] as UseColumnsResult;

    const onSubmit = (
      formData: {
        linked: AutocompleteOption[] | null;
        managedById: string | null;
      },
      { input: { removeAssortments } }: MassEditSupplierAssortmentsMutationVariables,
    ) => {
      setDefaultOptions(formData.linked);
      setIsFirstSave(false);
      setDefaultManagedBy(formData.managedById);

      flushSync(() => {
        setNewRows(previousNewRows =>
          previousNewRows.filter(
            ({ item: previousItem, opco: previousOpco }) =>
              !removeAssortments!.find(
                ({ itemID, opcoID }) => previousItem?.id === itemID && previousOpco?.id === opcoID,
              ),
          ),
        );
      });
      flushSync(() => {
        setSelectedRows(previousSelectedRows =>
          previousSelectedRows.filter(
            ({ original: { item: previousItem, opco: previousOpco } }) =>
              !removeAssortments!.find(
                ({ itemID, opcoID }) => previousItem?.id === itemID && previousOpco?.id === opcoID,
              ),
          ),
        );
      });
    };

    const onCompleted = ({
      massEditSupplierAssortments: { assortments },
    }: MassEditSupplierAssortmentsMutation) => {
      flushSync(() => {
        setNewRows(previousAssortments => [
          ...assortments.filter(
            ({ item: { id: itemId }, opco: { id: opcoId } }) =>
              !previousAssortments.find(
                ({ item: { id: previousItemId }, opco: { id: previousOpcoId } }) =>
                  itemId === previousItemId && opcoId === previousOpcoId,
              ),
          ),
          ...previousAssortments,
        ]);
      });
    };

    return {
      columns,
      data: uniqueSelectedRows,
      defaultValues,
      deriveRowKey,
      mapRowToMutationArgs,
      onSubmit,
      onCompleted,
    };
  }, [
    selectedRows,
    activePageIndex,
    defaultOptions,
    isFirstSave,
    defaultManagedBy,
    uniqueSelectedRows,
    newRows,
  ]);

  const checkIsCheckboxDisabled = useCallback(
    (row: TableRow<Row>) => {
      if (systemLocked) {
        return true;
      }

      const {
        original: { item, opco },
      } = row;

      return isSupplierPage(activePageIndex) ? !opco : !item;
    },
    [activePageIndex, systemLocked],
  );

  const checkIsCheckboxSelectable = useCallback(
    ({
      allRows,
      enabledFields,
      row,
      selectedFlatRows,
    }: {
      selectedFlatRows: TableRow<Row>[];
      row: TableRow<Row>;
      allRows: TableRow<Row>[];
      enabledFields?: number;
    }): boolean => {
      const isHeader = !row;

      if (isHeader) {
        let differentManagedById: string;
        const allCheckboxesHaveTheSameManagedById: boolean = !allRows.some(
          ({ original: { managedBy: currentManagedBy } }) => {
            if (!currentManagedBy || differentManagedById === currentManagedBy.id) {
              return false;
            }

            if (differentManagedById === undefined) {
              differentManagedById = currentManagedBy.id;
              return false;
            }

            return true;
          },
        );

        const allCheckboxesAreEnabled = !!enabledFields || !allRows.some(checkIsCheckboxDisabled);

        if (!allCheckboxesHaveTheSameManagedById) {
          setShowErrorDifferentManagers(true);
        }

        if (!allCheckboxesAreEnabled) {
          setShowErrorUnconnected(true);
        }

        return allCheckboxesHaveTheSameManagedById && allCheckboxesAreEnabled;
      }

      const {
        original: { managedBy: managedBySelected },
      } = row;

      if (!managedBySelected || !selectedFlatRows.length) {
        return true;
      }

      const alreadyExistedManagedBy = findManagedBy(selectedFlatRows);

      if (alreadyExistedManagedBy === undefined) {
        return true;
      }

      const isSelectable = alreadyExistedManagedBy.id === managedBySelected.id;

      if (!isSelectable) {
        setShowErrorDifferentManagers(true);
      }

      return isSelectable;
    },
    [checkIsCheckboxDisabled],
  );

  const toggleAllTableRows = useCallback(
    (
      rows: TableRow<Row>[],
      toggleRowSelected: (rowId: string, checked: boolean) => void,
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      const accessor = isSupplierPage(activePageIndex) ? 'opco' : 'item';

      rows.forEach(({ id, original }) => {
        if (original[accessor]) {
          toggleRowSelected(id, event.currentTarget.checked);
        }
      });
    },
    [activePageIndex],
  );

  const getNumberOfEnableFields = useCallback(
    (rows: TableRow<Row>[]): number =>
      rows.filter(({ original: { item, opco } }) => (isSupplierPage(activePageIndex) ? opco : item))
        .length,
    [activePageIndex],
  );

  const onCloseErrorDifferentManagersModal = useCallback(
    () => setShowErrorDifferentManagers(false),
    [],
  );

  const onCloseErrorUnConnectedModal = useCallback(() => setShowErrorUnconnected(false), []);

  const editFormRowProps = useMemo<MassEditFormRowAdditionalProps>(
    () => ({ isSupplierPage: isSupplierPage(activePageIndex), userOptions }),
    [activePageIndex, userOptions],
  );

  if (showMainLoader) {
    return <Loader isLoading />;
  }

  return (
    <>
      {!showEditPage && (
        <SupplierAssortmentView
          {...props}
          checkIsCheckboxDisabled={checkIsCheckboxDisabled}
          checkIsCheckboxSelectable={checkIsCheckboxSelectable}
          getNumberOfEnableFields={getNumberOfEnableFields}
          onEditClick={onEditClick}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          toggleAllTableRows={toggleAllTableRows}
          triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
          uncheckAllCheckboxes={uncheckAllCheckboxes}
        />
      )}
      {showEditPage && (
        <EditPage
          afterMutation={reset}
          deriveMassEditData={deriveMassEditData}
          editFormRowProps={editFormRowProps}
          evictCacheForQueryName="supplierAssortments"
          MassEditFormRow={MassEditFormRow}
          mutation={massEditSupplierAssortmentsMutation}
          onCloseEditPage={onCloseEditPage}
        />
      )}
      <WarningExistingConnectionsModal
        isOpen={showWarningExistingConnections}
        onClose={onCloseWarningExistingConnectionsModal}
        onProceed={onProceed}
      />
      <ErrorModal isOpen={showErrorDifferentManagers} onClose={onCloseErrorDifferentManagersModal}>
        Please correct your choice. You try to edit products assigned to different managers
        simultaneously - this is <Typography variant="bold1">not supported.</Typography>
      </ErrorModal>
      <ErrorModal isOpen={showErrorUnconnected} onClose={onCloseErrorUnConnectedModal}>
        Please correct your choice. You are trying to edit items that
        <Typography variant="bold1">{' cannot be updated at the same time. '}</Typography>
        time. Examples: Update unconnected Items and Unconnected OpCo’s simultaneously. or Connect
        new OpCo’s in Item view or connect new Items in Supplier view.
      </ErrorModal>
    </>
  );
};
export { SupplierAssortment };
