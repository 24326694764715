import { FC } from 'react';
import Alert from '@mui/material/Alert';

import { Tld } from 'shared/constants';
import { TLD } from 'shared/constants/environment';

import styles from './EnvironmentBanner.module.css';

const componentMap: Record<Tld, JSX.Element | null> = {
  [Tld.local]: null,
  [Tld.dev]: (
    <Alert className={styles.banner} icon={false} variant="filled">
      DEVELOPMENT
    </Alert>
  ),
  [Tld.e2e]: (
    <Alert className={styles.banner} icon={false} variant="filled">
      TESTING
    </Alert>
  ),
  [Tld.tech]: (
    <Alert className={styles.banner} icon={false} variant="filled">
      UAT
    </Alert>
  ),
  [Tld.com]: null,
  [Tld.corp]: null,
};

const EnvironmentBanner: FC = () => componentMap[TLD];

export { EnvironmentBanner };
