import { FC } from 'react';

import { Modal } from 'shared/components/Modal';

import styles from './ActionModal.module.css';
import { ActionModalProps } from './types';

const ActionModal: FC<ActionModalProps> = ({ actions, children, ...props }) => (
  <Modal {...props}>
    <div className={styles.text}>{children}</div>
    <div className={styles.actions}>{actions}</div>
  </Modal>
);

export { ActionModal };
