import { FC, useCallback, useState } from 'react';
import Button from '@mui/material/Button';

import { ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT } from 'shared/constants';
import { DataGridToggleButtons } from 'shared/components/DataGridToggleButtons';
import { Header as HeaderBase } from 'shared/components/Header';
import { headerButtonNames } from 'pages/DestinationAssortment/constants';
import { DataGridDownloadButton } from 'shared/components/IconButton/DataGridDownloadButton';
import { UploadFileModal } from 'shared/components/UploadFileModal';
import { UploadIconButton } from 'shared/components/IconButton';

import { ValidationModal } from '../ValidationModal';

import { HeaderProps } from './types';
import styles from './Header.module.css';

const Header: FC<HeaderProps> = ({
  activePageIndex,
  lastDestinationAssortmentTime,
  onDataGridDownloadButtonClick,
  onTogglePage,
  onUpdateAssortmentClick,
  uploadFileProps: { onOpenUploadFileModal, ...uploadFileProps },
  userPermissions,
}) => {
  const [showValidationModal, setShowValidationModal] = useState(false);

  const handleCloseValidationModal = useCallback(() => setShowValidationModal(false), []);
  const handleOpenValidationModal = useCallback(() => setShowValidationModal(true), []);
  const handleUpdateAssortmentClick = useCallback(() => {
    handleCloseValidationModal();
    onUpdateAssortmentClick();
  }, [onUpdateAssortmentClick]);

  return (
    <>
      <HeaderBase title="Destination Assortment">
        <div className={styles.container}>
          <span className={styles.text}>
            The last successful validation was {lastDestinationAssortmentTime}
          </span>
          <Button onClick={handleOpenValidationModal} variant="outlined">
            Update Assortment
          </Button>
          <DataGridDownloadButton onClick={onDataGridDownloadButtonClick} />
          {userPermissions.has(ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT) && (
            <UploadIconButton onClick={onOpenUploadFileModal} />
          )}
          <DataGridToggleButtons
            activePageIndex={activePageIndex}
            buttonNames={headerButtonNames}
            onTogglePage={onTogglePage}
          />
        </div>
      </HeaderBase>
      <ValidationModal
        isOpen={showValidationModal}
        onClose={handleCloseValidationModal}
        onUpdateAssortmentClick={handleUpdateAssortmentClick}
      />
      <UploadFileModal {...uploadFileProps} />
    </>
  );
};

export { Header };
