import { FC } from 'react';
import classNames from 'classnames';

import styles from './FieldError.module.css';
import { FieldErrorProps } from './types';

const FieldError: FC<FieldErrorProps> = ({ block = false, message }) => (
  <span className={classNames(styles.default, { [styles.block]: block })}>{message}</span>
);

export { FieldError };
