import {
  TransportationLaneUpdateInput,
  UpdateTransportationLanesAccountIdMutationVariables,
} from 'shared/types';
import { Row } from 'pages/TransportationLane/types';

const mapRowToMutationArgs = (
  row: Row,
  updatedChunk: Partial<TransportationLaneUpdateInput>,
): UpdateTransportationLanesAccountIdMutationVariables => ({
  input: [
    {
      id: row.id,
      ...updatedChunk,
    },
  ],
});

export { mapRowToMutationArgs };
