import { FC, useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CA, DE, ES, FR, GB, US } from 'country-flag-icons/react/3x2';

import { mapToLanguage } from 'shared/utils';
import { SessionStorageContext } from 'setup/providers/Context/Context';

import styles from './LanguageDropdown.module.css';

const Language: FC<{ title: string }> = ({ title }) => {
  let language = <US className={styles.flag} title={title} />;

  switch (title) {
    case 'English (United Kingdom) - en-GB':
      language = <GB className={styles.flag} title={title} />;
      break;
    case 'English (Canada) - en-CA':
      language = <CA className={styles.flag} title={title} />;
      break;
    case 'German (Germany) - de-DE':
      language = <DE className={styles.flag} title={title} />;
      break;
    case 'Spanish (Spain) - es-ES':
      language = <ES className={styles.flag} title={title} />;
      break;
    case 'French (France) - fr-FR':
      language = <FR className={styles.flag} title={title} />;
      break;
    case 'French (Canada) - fr-CA':
      language = <CA className={styles.flag} title={title} />;
      break;
    default:
      break;
  }

  return language;
};

const LanguageDropdown: FC = () => {
  const { language, setLanguage } = useContext(SessionStorageContext);

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
  };

  return (
    <div>
      <FormControl className={styles.dropdown} fullWidth>
        <Select
          classes={{ icon: styles.icon, select: styles.select }}
          className={styles.dropdown}
          displayEmpty
          onChange={handleChange}
          renderValue={selectedLanguage => (
            <MenuItem className={styles.item} value={selectedLanguage}>
              <Language title={selectedLanguage} />
              {mapToLanguage.get(selectedLanguage)}
            </MenuItem>
          )}
          value={language}
        >
          {Array.from(mapToLanguage.keys()).map((key: string) => (
            <MenuItem key={key} value={key}>
              <Language title={key} />
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export { LanguageDropdown };
