import { FC, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';

import { MAX_ALCOHOL_VOLUME } from 'shared/constants';
import { renderEmptyPlaceholder } from 'shared/components/Select';
import { UpdateValue } from 'pages/Location/components/FactoryCellSelectAlcoholType/types';

import { AlcoholType, AlcoholTypesProps, SelectedAlcoholTypes } from './types';
import styles from './SelectAlcoholType.module.css';

const getAlcoholTypeWithSelectVolumesToClickedValue = (
  checkedAlcoholTypeOption: AlcoholType,
  checkedAlcoholVolumeId: string,
) => {
  const newSelectedVolumes = [];
  const value = [];

  for (let i = 0; i < checkedAlcoholTypeOption.volumes.length; i++) {
    const newSelectedVolume = checkedAlcoholTypeOption.volumes[i];
    newSelectedVolumes.push(newSelectedVolume);
    value.push(newSelectedVolume.id);
    if (newSelectedVolume.id === checkedAlcoholVolumeId) {
      break;
    }
  }
  return {
    alcoholTypeWithSelectVolumesToClickedValue: {
      ...checkedAlcoholTypeOption,
      volumes: newSelectedVolumes,
    },
    value,
  };
};

const SelectAlcoholType: FC<AlcoholTypesProps> = ({
  onChange,
  onClose,
  onOpen,
  open,
  options,
  selectedAlcoholTypes = [],
  value,
  isDisabled = false,
}) => {
  const [checkedAlcoholTypes, setCheckedAlcoholTypes] = useState(selectedAlcoholTypes);
  const selectedAlcoholTypesMap: SelectedAlcoholTypes = useMemo(
    () =>
      checkedAlcoholTypes?.reduce(
        (checkedAlcoholTypesAcc, { id, volumes }) => ({
          ...checkedAlcoholTypesAcc,
          [id]: {
            selectedVolumes: volumes.reduce(
              (selectedVolumesAcc, { id: selectedVolumeId, volume: selectedVolume }) => ({
                ...selectedVolumesAcc,
                [selectedVolumeId]: true,
                vol100: selectedVolume === MAX_ALCOHOL_VOLUME ? selectedVolumeId : 0,
              }),
              {},
            ),
          },
        }),
        {},
      ),
    [checkedAlcoholTypes],
  );
  const volume100Options = useMemo(
    () =>
      options.map(({ volumes }) => volumes.find(({ volume }) => volume === MAX_ALCOHOL_VOLUME)?.id),
    [options],
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<UpdateValue>, child: ReactNode) => {
      const {
        target: { value: selectedValues },
      } = event;
      const {
        props: { value: clickedValue },
      } = child as ReactElement<{ value: string }>;
      const unchecked = !(selectedValues as UpdateValue).find(id => id === clickedValue);
      const uncheckedAll = unchecked && volume100Options.includes(clickedValue);

      const newCheckedAlcoholTypes: AlcoholType[] = [];
      let newValue: string[] = [];
      let found = false;

      const checkedAlcoholTypeOption = options.find(
        type => !!type.volumes.find(({ id }) => id === clickedValue),
      ) as AlcoholType;

      for (let i = 0; i < checkedAlcoholTypes.length; i++) {
        const checkedAlcoholType = checkedAlcoholTypes[i];

        if (checkedAlcoholTypeOption.id === checkedAlcoholType.id) {
          found = true;

          if ((unchecked && checkedAlcoholType.volumes.length === 1) || uncheckedAll) {
            continue;
          }

          const { alcoholTypeWithSelectVolumesToClickedValue, value: values } =
            getAlcoholTypeWithSelectVolumesToClickedValue(checkedAlcoholTypeOption, clickedValue);
          newCheckedAlcoholTypes.push(alcoholTypeWithSelectVolumesToClickedValue);
          newValue = [...newValue, ...values];
        } else {
          newCheckedAlcoholTypes.push(checkedAlcoholType);
          newValue = [...newValue, ...checkedAlcoholType.volumes.map(({ id }) => id)];
        }
      }

      if (!found) {
        const { alcoholTypeWithSelectVolumesToClickedValue, value: values } =
          getAlcoholTypeWithSelectVolumesToClickedValue(checkedAlcoholTypeOption, clickedValue);

        newCheckedAlcoholTypes.push(alcoholTypeWithSelectVolumesToClickedValue);
        newValue = [...newValue, ...values];
      }

      // eslint-disable-next-line no-param-reassign
      event.target.value = newValue;
      onChange?.(event, child);

      setCheckedAlcoholTypes(newCheckedAlcoholTypes);
    },
    [checkedAlcoholTypes],
  );

  const alcoholList = options.map(item => {
    const isAllAlcoholVolumesSelected =
      !!selectedAlcoholTypesMap[+item.id]?.selectedVolumes?.vol100;
    const allAlcoholVolumeId = item.volumes.find(({ volume }) => volume === MAX_ALCOHOL_VOLUME)?.id;

    return [
      <MenuItem key={allAlcoholVolumeId} className={styles.item} value={allAlcoholVolumeId}>
        <div key={item.id} className={styles.title}>
          <Radio checked={isAllAlcoholVolumesSelected} />
          {item.name}
        </div>
      </MenuItem>,
      item.volumes.map(option => {
        if (option.volume === MAX_ALCOHOL_VOLUME) {
          return null;
        }

        return (
          <MenuItem key={option.id} className={styles.item} value={option.id}>
            <ListItemIcon>
              <Radio checked={!!selectedAlcoholTypesMap[+item.id]?.selectedVolumes?.[+option.id]} />
            </ListItemIcon>
            <ListItemText className={styles.title} primary={`${option.volume}%`} />
          </MenuItem>
        );
      }),
    ];
  });

  const renderValue = (selected: string[]): ReactNode => {
    if (!selected.length) {
      return renderEmptyPlaceholder();
    }

    let label;
    let count = 0;

    checkedAlcoholTypes?.forEach(({ name, volumes }, index) => {
      const volume100 = volumes?.find(
        ({ volume: volumeSelected }) => volumeSelected === MAX_ALCOHOL_VOLUME,
      )?.id;

      if (index === 0) {
        if (volume100) {
          label = name;
        } else {
          const { volume } = volumes[volumes.length - 1];
          label = `${name} ${volume}%`;
        }
      }

      count += volume100 ? 1 : volumes.length;
    });

    return (
      <div className={styles.renderValueContainer}>
        <Chip className={styles.chip} label={label} />
        {count > 1 && <Chip className={styles.chip} label={`+${count - 1}`} />}
      </div>
    );
  };

  return (
    <FormControl fullWidth size="small">
      <Select<string[]>
        disabled={isDisabled}
        displayEmpty
        multiple
        onChange={handleChange}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        renderValue={renderValue}
        value={value}
      >
        {alcoholList}
      </Select>
    </FormControl>
  );
};

export { SelectAlcoholType };
