import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { prop, uniqBy } from 'ramda';
import Typography from '@mui/material/Typography';
import { Row as TableRow } from 'react-table';

import { Loader } from 'shared/components/Loader';
import { EditPage, WarningExistingConnectionsModal } from 'shared/components/Edit';
import { ErrorModal } from 'shared/components/Modal';
import { FactoryCellDelete } from 'shared/components/DataGrid/components/Cells';
import { mapToSelectOptions } from 'shared/utils';
import { AutocompleteOption } from 'shared/components/Search';
import {
  MassEditTransportationLanesMutation,
  MassEditTransportationLanesMutationVariables,
} from 'shared/types';
import {
  deriveRowKey,
  findDestination,
  findSource,
  isDestinationPage,
} from 'pages/TransportationLane/utils';

import { MassEditFormRow, MassEditFormRowAdditionalProps } from './components/MassEditFormRow';
import { mapDataToMassEditMutationArgs } from './mappers';
import { Row, UseColumnsResult } from './types';
import { TransportationLaneView } from './TransportationLaneView';
import { useSetup } from './hooks';
import { headersForMassEditColumns } from './constants';
import { massEditTransportationLanesMutation } from './operations';

const TransportationLane: FC = () => {
  const {
    activePageIndex,
    editPageProps: {
      defaultOptions,
      isFirstSave,
      newRows,
      onCloseEditPage,
      onCloseWarningExistingConnectionsModal,
      onEditClick,
      onProceed,
      selectedRows,
      setDefaultOptions,
      setIsFirstSave,
      setNewRows,
      setSelectedRows,
      showEditPage,
      showWarningExistingConnections,
      triggerUncheckAllCheckboxes,
      uncheckAllCheckboxes,
      uniqueSelectedRows,
    },
    reset,
    showMainLoader,
    systemLocked,
    ...props
  } = useSetup();

  const [isDifferentAccountNumbers, setIsDifferentAccountNumbers] = useState(false);
  const [showErrorUnconnected, setShowErrorUnconnected] = useState(false);

  const deriveMassEditData = useCallback(() => {
    const isDestination = isDestinationPage(activePageIndex);
    const locations = isDestination
      ? uniqBy(prop('id'), findSource(selectedRows))
      : uniqBy(prop('id'), findDestination(selectedRows));
    const updateLocationFieldName = isDestination ? 'sourceOpco' : 'destination';
    const defaultValues = {
      locations: locations ? mapToSelectOptions(locations) : null,
    };

    const mapRowToMutationArgs = ({
      locations: newLocations,
    }: {
      locations: AutocompleteOption[] | null;
    }) =>
      mapDataToMassEditMutationArgs({
        data: uniqueSelectedRows,
        updateLocationFieldName,
        newOptions: newLocations,
        defaultOptions: defaultOptions ?? defaultValues.locations,
        isFirstSave,
        selectedRows,
        newRows,
      });

    const columns = [
      ...headersForMassEditColumns[activePageIndex],
      {
        id: 'delete',
        Cell: FactoryCellDelete<Row>({ setData: setSelectedRows }),
      },
    ] as UseColumnsResult;

    const onSubmit = (
      formData: { locations: AutocompleteOption[] | null },
      { input: { removeTLanes } }: MassEditTransportationLanesMutationVariables,
    ) => {
      setIsFirstSave(false);
      setDefaultOptions(formData.locations);
      flushSync(() => {
        setNewRows(previousNewRows =>
          previousNewRows.filter(
            ({ destination: previousDestination, id: rowId, source: previousSource }) =>
              !removeTLanes!.find(
                ({ destination, id, sourceOpco }) =>
                  id === rowId &&
                  destination === previousDestination?.id &&
                  sourceOpco === previousSource?.id,
              ),
          ),
        );
      });
      flushSync(() => {
        setSelectedRows(previousSelectedRows =>
          previousSelectedRows.filter(
            ({
              original: { destination: previousDestination, id: rowId, source: previousSource },
            }) =>
              !removeTLanes!.find(
                ({ destination, id, sourceOpco }) =>
                  id === rowId &&
                  destination === previousDestination?.id &&
                  sourceOpco === previousSource?.id,
              ),
          ),
        );
      });
    };

    const onCompleted = ({
      massEditTransportationLanes: { transportationLanes },
    }: MassEditTransportationLanesMutation) => {
      flushSync(() => {
        setNewRows(previousTransportationLanes => [
          ...transportationLanes,
          ...previousTransportationLanes,
        ]);
      });
    };

    return useMemo(
      () => ({
        columns,
        data: uniqueSelectedRows,
        defaultValues,
        deriveRowKey,
        mapRowToMutationArgs,
        onSubmit,
        onCompleted,
      }),
      [selectedRows, defaultOptions, uniqueSelectedRows, newRows],
    );
  }, [
    selectedRows,
    activePageIndex,
    defaultOptions,
    isDifferentAccountNumbers,
    isFirstSave,
    newRows,
    uniqueSelectedRows,
  ]);

  const checkIsCheckboxDisabled = useCallback(
    (row: TableRow<Row>) => {
      if (systemLocked) {
        return true;
      }

      const {
        original: { destination, source },
      } = row;

      return isDestinationPage(activePageIndex) ? !destination : !source;
    },
    [activePageIndex, systemLocked],
  );

  const checkIsCheckboxSelectable = useCallback(
    ({
      allRows,
      enabledFields,
      row,
    }: {
      selectedFlatRows: TableRow<Row>[];
      row: TableRow<Row>;
      allRows: TableRow<Row>[];
      enabledFields?: number;
    }): boolean => {
      const isHeader = !row;

      if (isHeader) {
        const allCheckboxesAreEnabled = !!enabledFields || !allRows.some(checkIsCheckboxDisabled);

        if (!allCheckboxesAreEnabled) {
          setShowErrorUnconnected(true);
        }

        return allCheckboxesAreEnabled;
      }

      return true;
    },
    [checkIsCheckboxDisabled],
  );

  const toggleAllTableRows = useCallback(
    (
      rows: TableRow<Row>[],
      toggleRowSelected: (rowId: string, checked: boolean) => void,
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      const accessor = isDestinationPage(activePageIndex) ? 'destination' : 'source';

      rows.forEach(({ id, original }) => {
        if (original[accessor]) {
          toggleRowSelected(id, event.currentTarget.checked);
        }
      });
    },
    [activePageIndex],
  );

  const getNumberOfEnableFields = useCallback(
    (rows: TableRow<Row>[]): number =>
      rows.filter(({ original: { destination, source } }) =>
        isDestinationPage(activePageIndex) ? destination : source,
      ).length,
    [activePageIndex],
  );

  const onCloseErrorUnConnectedModal = useCallback(() => setShowErrorUnconnected(false), []);

  const editFormRowProps = useMemo<MassEditFormRowAdditionalProps>(
    () => ({ isDestinationPage: isDestinationPage(activePageIndex) }),
    [activePageIndex],
  );

  const closeEditPageHandler = useCallback(() => {
    onCloseEditPage();
    setIsDifferentAccountNumbers(false);
  }, []);

  if (showMainLoader) {
    return <Loader isLoading />;
  }

  return (
    <>
      {!showEditPage && (
        <TransportationLaneView
          {...props}
          activePageIndex={activePageIndex}
          checkIsCheckboxDisabled={checkIsCheckboxDisabled}
          checkIsCheckboxSelectable={checkIsCheckboxSelectable}
          getNumberOfEnableFields={getNumberOfEnableFields}
          onEditClick={onEditClick}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          toggleAllTableRows={toggleAllTableRows}
          triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
          uncheckAllCheckboxes={uncheckAllCheckboxes}
        />
      )}
      {showEditPage && (
        <EditPage
          afterMutation={reset}
          deriveMassEditData={deriveMassEditData}
          editFormRowProps={editFormRowProps}
          evictCacheForQueryName="transportationLanes"
          MassEditFormRow={MassEditFormRow}
          mutation={massEditTransportationLanesMutation}
          onCloseEditPage={closeEditPageHandler}
        />
      )}
      <WarningExistingConnectionsModal
        isOpen={showWarningExistingConnections}
        onClose={onCloseWarningExistingConnectionsModal}
        onProceed={onProceed}
      />
      <ErrorModal isOpen={showErrorUnconnected} onClose={onCloseErrorUnConnectedModal}>
        Please correct your choice. You are trying to edit items that
        <Typography variant="bold1">{' cannot be updated at the same time. '}</Typography>Examples:
        Update unconnected Sources and Unconnected Destinations simultaneously. or Connect new
        Sources in Destination view or connect new Destinations in Source view.
      </ErrorModal>
    </>
  );
};
export { TransportationLane };
