import { DefaultValues } from 'react-hook-form/dist/types/form';

import { AddModalFormValues } from 'pages/CostManagement/types';

const defaultAddModalFormValues: DefaultValues<AddModalFormValues> = {
  item: null,
  supplier: null,
  opco: null,
  location: null,
};

export { defaultAddModalFormValues };
