import { FC, useCallback, useMemo } from 'react';
import { update } from 'ramda';

import { Loader } from 'shared/components/Loader';
import { EditPage } from 'shared/components/Edit';
import { FactoryCellDelete } from 'shared/components/DataGrid';
import { UpdateGridData, UpdateItemSourceMutationVariables } from 'shared/types';
import { setDefaultValueForNumericApiInputField } from 'shared/utils';
import { PackageTypeName } from 'shared/constants/package';

import {
  AdditionalMassEditFormRowProps,
  FormValues,
  MassEditFormRow,
} from './components/MassEditFormRow';
import { updateItemSourceMutation } from './operations';
import {
  PackageTypeNameLabels,
  SupplierItemNumberColumnName,
  headersForMassEditColumns,
} from './constants';
import { MappedRow, UseColumnsResult } from './types';
import { useSetup } from './hooks';
import { ItemSourceView } from './ItemSourceView';

const ItemSource: FC = () => {
  const {
    onCloseEditPage,
    onDataGridDownloadButtonClick,
    onEditClick,
    packageTypes,
    reset,
    selectedRows,
    setSelectedRows,
    showEditPage,
    showMainLoader,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
    ...props
  } = useSetup();

  const deriveMassEditData = useCallback(() => {
    const data = selectedRows.map(({ original }) => original);
    const defaultValues = {
      packaging: [
        {
          declaredHeight: undefined,
          declaredLength: undefined,
          declaredWidth: undefined,
          declaredWeight: undefined,
          declaredTi: undefined,
          declaredHi: undefined,
          declaredWeightUnits: undefined,
          declaredLengthUnits: undefined,
          packageTypeName: PackageTypeName.Each,
        },
      ],
    };

    const mapRowToMutationArgs = (formData: FormValues): UpdateItemSourceMutationVariables => ({
      input: {
        items: selectedRows.map(
          ({
            original: {
              id,
              rows,
              supplierItemNumber,
              itemId,
              opcoId,
              isBreakable,
              breakingFromPackageTypeID,
              breakingToPackageTypeID,
              breakingThreshold,
            },
          }) => ({
            id,
            itemID: itemId!,
            opcoID: opcoId!,
            packaging: formData.packaging.map(packagingItem => {
              let newUpcCode;

              if (packagingItem.packageTypeName !== PackageTypeName.Each) {
                const row = rows!.find(
                  ({ packageTypeName }) => packageTypeName === packagingItem.packageTypeName,
                )!;
                [newUpcCode] = row.upc;
              }

              return {
                declaredHeight: setDefaultValueForNumericApiInputField(
                  packagingItem.declaredHeight,
                ),
                declaredHi: setDefaultValueForNumericApiInputField(packagingItem.declaredHi),
                declaredLength: setDefaultValueForNumericApiInputField(
                  packagingItem.declaredLength,
                ),
                declaredLengthUnits: packagingItem.declaredLengthUnits,
                declaredTi: setDefaultValueForNumericApiInputField(packagingItem.declaredTi),
                declaredWeight: setDefaultValueForNumericApiInputField(
                  packagingItem.declaredWeight,
                ),
                declaredWeightUnits: packagingItem.declaredWeightUnits,
                declaredWidth: setDefaultValueForNumericApiInputField(packagingItem.declaredWidth),
                packageType: packageTypes.find(
                  packageType => packagingItem.packageTypeName === packageType.name,
                )!.id,
                perChildPackageQty: Number(
                  formData[packagingItem.packageTypeName as keyof FormValues],
                ),
                upcCode: newUpcCode,
                supplierNumber:
                  packagingItem.packageTypeName !== PackageTypeName.Each
                    ? rows!.find(
                        ({ packageTypeName }) => packageTypeName === packagingItem.packageTypeName,
                      )![SupplierItemNumberColumnName]
                    : supplierItemNumber,
              };
            }),
            isBreakable: isBreakable ?? false,
            breakingFromPackageTypeID,
            breakingToPackageTypeID,
            breakingThreshold,
          }),
        ),
      },
    });

    const columns = [
      ...headersForMassEditColumns,
      {
        id: 'delete',
        Cell: FactoryCellDelete<MappedRow>({ setData: setSelectedRows }),
      },
    ] as UseColumnsResult;

    const onChange = useCallback<UpdateGridData<MappedRow>>(
      (index, columnId, nullOrValue, original) =>
        setSelectedRows(previousSelectedRows => {
          const updatedRow = {
            ...previousSelectedRows[index],
            original: {
              ...previousSelectedRows[index].original,
            },
          };

          if (original.packageTypeName === PackageTypeName.Each) {
            updatedRow.original = {
              ...updatedRow.original,
              [columnId]: nullOrValue,
            };
          } else {
            for (let j = 0; j < updatedRow.original.rows!.length; j++) {
              const updatedSubRow: MappedRow = updatedRow.original.rows![j];

              if (updatedSubRow.packageTypeName === original.packageTypeName) {
                if (columnId === 'upc') {
                  updatedRow.original.rows![j].upc = [nullOrValue as string];
                } else {
                  updatedRow.original.rows![j] = {
                    ...updatedSubRow,
                    [columnId]: nullOrValue,
                  };
                }
                break;
              }
            }
          }

          return update(index, updatedRow, previousSelectedRows);
        }),
      [],
    );

    return {
      defaultValues,
      columns,
      data,
      mapRowToMutationArgs,
      onChange,
    };
  }, [selectedRows]);

  const updateSubRows = useCallback(
    (packageTypeName: PackageTypeName, newValue: string, shouldExist: boolean) => {
      setSelectedRows(previousSelectedRows =>
        previousSelectedRows.map(previousSelectedRow => ({
          ...previousSelectedRow,
          original: {
            ...previousSelectedRow.original,
            rows: shouldExist
              ? [
                  ...previousSelectedRow.original.rows!,
                  {
                    id: `${previousSelectedRow.original.id}${packageTypeName}`,
                    name: PackageTypeNameLabels[packageTypeName],
                    packageTypeName,
                    perChildPackageQty: Number(newValue),
                    rows: [],
                    upc: [],
                    [SupplierItemNumberColumnName]: '',
                    opco: '',
                    opcoStateProvinceRegion: '',
                  },
                ]
              : previousSelectedRow.original.rows!.filter(
                  ({ packageTypeName: previousSelectedRowPackageTypeName }) =>
                    packageTypeName !== previousSelectedRowPackageTypeName,
                ),
          },
        })),
      );
    },
    [],
  );

  const editFormRowProps = useMemo<AdditionalMassEditFormRowProps>(
    () => ({ updateSubRows }),
    [updateSubRows],
  );

  if (showMainLoader) {
    return <Loader isLoading />;
  }

  return (
    <>
      {!showEditPage && (
        <ItemSourceView
          {...props}
          onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
          onEditClick={onEditClick}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
          uncheckAllCheckboxes={uncheckAllCheckboxes}
        />
      )}
      {showEditPage && (
        <EditPage
          afterMutation={reset}
          deriveMassEditData={deriveMassEditData}
          editFormRowProps={editFormRowProps}
          enableExpanding
          MassEditFormRow={MassEditFormRow}
          mutation={updateItemSourceMutation}
          onCloseEditPage={onCloseEditPage}
        />
      )}
    </>
  );
};
export { ItemSource };
