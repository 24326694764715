import { FC } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { CheckboxIconProps } from './types';

const CheckboxIcon: FC<CheckboxIconProps> = ({ checked }) =>
  checked ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="action" />;

export { CheckboxIcon };
