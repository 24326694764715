import { downloadColumnNames } from 'pages/CostManagementV2/mappers/mapRowsForDownload';
import { exportCostManagementXlsxFile } from 'pages/CostManagementV2/utils/exportCostManagementXlsxFile';

const downloadCostManagementRegularCostTemplateExcel = () =>
  exportCostManagementXlsxFile(
    [
      Object.fromEntries(
        [
          downloadColumnNames.itemId,
          downloadColumnNames.effectiveDate,
          downloadColumnNames.eachCost,
          downloadColumnNames.caseCost,
          downloadColumnNames.currency,
          downloadColumnNames.supplierId,
          downloadColumnNames.opcoName,
          downloadColumnNames.locationId,
        ].map(columnName => [columnName, '']),
      ),
    ],
    `Vendor Cost Management (${new Date().toLocaleString()})`,
    'cost',
  );

const downloadCostManagementPromotionalCostTemplateExcel = () =>
  exportCostManagementXlsxFile(
    [
      Object.fromEntries(
        [
          downloadColumnNames.itemId,
          downloadColumnNames.type,
          downloadColumnNames.effectiveDate,
          downloadColumnNames.endDate,
          downloadColumnNames.eachCost,
          downloadColumnNames.caseCost,
          downloadColumnNames.currency,
          downloadColumnNames.supplierId,
          downloadColumnNames.opcoName,
          downloadColumnNames.locationId,
        ].map(columnName => [columnName, columnName === downloadColumnNames.type ? 'PROMO' : '']),
      ),
    ],
    `Vendor Cost Management (${new Date().toLocaleString()})`,
    'cost',
  );

export {
  downloadCostManagementRegularCostTemplateExcel,
  downloadCostManagementPromotionalCostTemplateExcel,
};
