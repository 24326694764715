import { useCallback, useState } from 'react';
import * as React from 'react';

import { SearchAutocompleteMultiple } from 'shared/components/Search/SearchAutocompleteMultiple';

import { AutocompleteOption, BaseQueryArgs, mapAutocompleteValueToFilterParams } from '../shared';

import type { FilterSearchAutocompleteMultipleProps } from './types';

const defaultMapper = <QueryArgs extends BaseQueryArgs>(
  v: AutocompleteOption[],
  filterFieldName: keyof QueryArgs['filter'],
) => ({
  [filterFieldName]: v.map(({ value: val }) => val),
});

const FilterSearchAutocompleteMultiple = <QueryArgs extends BaseQueryArgs>({
  filterFieldName,
  mapUserAutocompleteValueSelectToFilterParams,
  onSubmitFilter,
  ...props
}: FilterSearchAutocompleteMultipleProps<QueryArgs>) => {
  const [value, setValue] = useState<AutocompleteOption[]>([]);

  const onChange = useCallback(
    (e, newValue, reason, { isOpen }) => {
      setValue(newValue);

      if ((reason === 'removeOption' || reason === 'clear') && !isOpen) {
        onSubmitFilter(
          mapAutocompleteValueToFilterParams(
            newValue,
            filterFieldName,
            mapUserAutocompleteValueSelectToFilterParams,
            defaultMapper,
          ),
        );
      }
    },
    [onSubmitFilter],
  );

  const onBlur = useCallback(() => {
    onSubmitFilter(
      mapAutocompleteValueToFilterParams(
        value,
        filterFieldName,
        mapUserAutocompleteValueSelectToFilterParams,
        defaultMapper,
      ),
    );
  }, [value, mapUserAutocompleteValueSelectToFilterParams, filterFieldName]);

  return (
    <>
      <SearchAutocompleteMultiple
        {...props}
        onBlur={onBlur}
        onChange={onChange}
        renderOptionWithCheckbox
        value={value}
      />
    </>
  );
};

export { FilterSearchAutocompleteMultiple };
