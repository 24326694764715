import { FC } from 'react';

import { DataGrid } from 'shared/components/DataGrid';
import { DataGridPage } from 'shared/components/DataGridPage';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';
import {
  ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT,
  ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT,
} from 'shared/constants';

import { Row, SupplierAssortmentViewProps } from './types';
import { headerButtonNames, rowsPerPageExtraOptions } from './constants';
import { deriveRowKey, highlightRow } from './utils';

const SupplierAssortmentView: FC<SupplierAssortmentViewProps> = ({
  activePageIndex,
  onDataGridDownloadButtonClick,
  onEditClick,
  onTogglePage,
  selectedRows,
  triggerUncheckAllCheckboxes,
  uploadFileProps,
  userPermissions,
  ...props
}) => (
  <DataGridPage<Row, true>
    header={
      <HeaderDataGridActions
        activePageIndex={activePageIndex}
        hasAccessToUploadFile={userPermissions.has(ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT)}
        headerButtonNames={headerButtonNames}
        onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
        onTogglePage={onTogglePage}
        title="Supplier Assortment"
        uploadFileProps={uploadFileProps}
      />
    }
    isMassEditOn={userPermissions.has(ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT)}
    onEditClick={onEditClick}
    selectedRows={selectedRows}
    triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
  >
    <DataGrid
      {...props}
      deriveRowKey={deriveRowKey}
      enableRowSelection
      highlightRow={highlightRow}
      rowsPerPageOptions={rowsPerPageExtraOptions}
    />
  </DataGridPage>
);

export { SupplierAssortmentView };
