import { useMutation } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { FORM_ID } from 'shared/components/Edit';
import { Loader } from 'shared/components/Loader';
import { preventPressEnter } from 'shared/utils';

import styles from './MassEditForm.module.css';
import { MassEditFormProps } from './types';

const MassEditForm = <
  FormFieldValues extends Record<string, any>,
  RowProps extends object,
  MutationVariables,
  Mutation extends TypedDocumentNode<any, MutationVariables>,
>({
  isEditPage,
  MassEditFormRow,
  defaultValues,
  mapRowToMutationArgs,
  mutation,
  rowProps,
  setIsFormChanged,
  evictCacheForQueryName,
  onSubmit,
  onCompleted,
  refetchQueries = [],
}: MassEditFormProps<FormFieldValues, RowProps, MutationVariables, Mutation>) => {
  const {
    control,
    formState: { isDirty, isValid },
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<FormFieldValues>({ defaultValues, mode: 'onChange' });

  const [saveFormChanges, { loading: mutationLoading }] = useMutation(mutation, {
    awaitRefetchQueries: true,
    onCompleted(data) {
      reset(getValues() as DefaultValues<FormFieldValues>);
      onCompleted?.(data);
    },
    update(cache) {
      if (evictCacheForQueryName) {
        cache.evict({
          fieldName: evictCacheForQueryName,
          broadcast: false,
        });
        cache.gc();
      }
    },
    refetchQueries,
  });

  useEffect(() => {
    setIsFormChanged(isDirty && isValid);
  }, [isDirty, isValid]);

  const onSubmitHandler = useMemo(
    () =>
      handleSubmit(formData => {
        const payload = mapRowToMutationArgs(formData);

        onSubmit?.(formData, payload);

        return saveFormChanges({
          variables: payload,
        });
      }),
    [saveFormChanges, mapRowToMutationArgs],
  );

  return (
    <>
      <Loader isLoading={mutationLoading} />
      <form
        className={styles.wrapper}
        id={FORM_ID}
        onKeyDown={preventPressEnter}
        onSubmit={onSubmitHandler}
        role="presentation"
      >
        <MassEditFormRow
          control={control}
          isEditPage={isEditPage}
          setValue={setValue}
          watch={watch}
          {...rowProps}
        />
      </form>
    </>
  );
};

export { MassEditForm };
