import { ChangeEvent, FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { UseRowStateLocalState } from 'react-table';
import { useUpdateEffect } from 'react-use';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';

import { Popper } from 'shared/components/Popper';
import { memoCell } from 'shared/utils';
import { CellProps } from 'shared/types/props';
import { Input } from 'shared/components/Input';
import { InputType } from 'shared/components/Input/types';
import { inputTypes } from 'shared/components/Input/constants';

import { shouldDisable, shouldShowBorder } from '../utils';

export type CellValue = string | number;

const FactoryCellInput = <
  DataType extends object,
  ObjectValue extends object | CellValue = CellValue,
>(
  cellArguments: {
    dependencies?: (keyof DataType)[];
    pickValue?: (value: ObjectValue) => CellValue;
    pickValueFromRowState?: (
      state: UseRowStateLocalState<DataType>,
      value: ObjectValue,
    ) => CellValue;
    isDisabled?: (rowData: DataType) => boolean;
    isHidden?: (rowData: DataType) => boolean;
    type?: InputType;
    min?: number;
    max?: number;
    showSuccessBorder?: boolean;
    showErrorBorder?: (rowData: DataType) => boolean;
    resetStateOnNewValue?: boolean;
    isPopperActive?: boolean;
    popperText?: string;
    validate?: (value: string | number, rowData: DataType) => string;
  } = { dependencies: [], resetStateOnNewValue: false },
) =>
  memoCell<CellProps<DataType, CellValue, ObjectValue>>(
    cellProps => {
      const {
        column: { id: columnId },
        disableCells,
        row,
        updateGridData,
        value: initialValue,
      } = cellProps;
      const {
        isDisabled,
        isHidden,
        max,
        min,
        pickValue,
        pickValueFromRowState,
        resetStateOnNewValue,
        showErrorBorder,
        showSuccessBorder,
        type,
        isPopperActive,
        popperText,
        validate,
      } = cellArguments;
      const { index, original, state } = row;
      const { enqueueSnackbar } = useSnackbar();
      if (isHidden && isHidden(original)) return null;

      const disabled = shouldDisable(cellProps, isDisabled);
      const showError = shouldShowBorder(cellProps, showErrorBorder);

      const mapInitialValue = pickValue ? pickValue(initialValue) : (initialValue as CellValue);
      const [value, setValue] = useState(mapInitialValue ?? '');
      const [valueBeforeChange, setValueBeforeChange] = useState(value);
      const [hasFocus, setHasFocus] = useState(false);
      const [popperOpen, setPopperOpen] = useState(false);
      const inputRef = useRef<HTMLInputElement | null>(null);

      useUpdateEffect(() => {
        if (pickValueFromRowState) {
          setValue(pickValueFromRowState(state, initialValue));
        }
      }, [state, initialValue]);

      useUpdateEffect(() => {
        if (resetStateOnNewValue) {
          const newValue = mapInitialValue ?? '';
          setValue(newValue);
          setValueBeforeChange(newValue);
        }
      }, [resetStateOnNewValue, mapInitialValue]);

      useEffect(() => {
        if (!disableCells && hasFocus && document.activeElement !== inputRef.current) {
          inputRef.current!.focus();
        }
      }, [disableCells, hasFocus]);

      const handleFocus = useCallback(() => {
        setHasFocus(true);
        setPopperOpen(true);
      }, []);

      const handleChange = useCallback(
        ({ currentTarget: { value: inputValue } }: ChangeEvent<HTMLInputElement>) => {
          setValue(inputValue);
        },
        [setValue],
      );

      const handlePopperBlur = useCallback(({ target }: MouseEvent | TouchEvent) => {
        if (!inputRef.current!.contains(target as Node)) {
          setPopperOpen(false);
        }
      }, []);

      const handleBlur = useCallback<FocusEventHandler>(() => {
        setHasFocus(false);
        const nullOrValue = (
          (type === inputTypes.Float || type === inputTypes.Number) && value === '' ? null : value
        ) as CellValue;

        if (validate) {
          const validationResult = validate(value, original);
          if (validationResult !== '') {
            enqueueSnackbar(validationResult, { variant: 'error' });
            setValue(valueBeforeChange);
            return;
          }
        }

        if (valueBeforeChange !== value) {
          updateGridData(index, columnId, nullOrValue, original, row, state);
        }

        setValueBeforeChange(value);
      }, [
        updateGridData,
        index,
        columnId,
        value,
        original,
        row,
        state,
        setValueBeforeChange,
        valueBeforeChange,
      ]);

      return (
        <div>
          <Input
            ref={inputRef}
            disabled={disabled}
            max={max}
            min={min}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            showErrorBorder={showError}
            showSuccessBorder={!disableCells && showSuccessBorder}
            type={type}
            value={value}
          />
          {isPopperActive && (
            <Popper anchorEl={inputRef.current} onBlur={handlePopperBlur} open={popperOpen}>
              <Box sx={{ border: 0.5, p: 1, bgcolor: '#ffde21' }}>{popperText}</Box>
            </Popper>
          )}
        </div>
      );
    },
    cellArguments.dependencies,
    !!cellArguments.pickValueFromRowState,
  );

export { FactoryCellInput };
