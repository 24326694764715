import { FC } from 'react';
import Button from '@mui/material/Button';

import { Header as HeaderBase } from 'shared/components/Header';
import { FORM_ID } from 'shared/components/Edit';

import { HeaderProps } from './types';
import styles from './Header.module.css';

const Header: FC<HeaderProps> = ({ isFormChanged, onCancel, onSaveAndClose }) => (
  <HeaderBase title="Edit">
    <div className={styles.buttonsContainer}>
      <Button onClick={onCancel} variant="outlined">
        Cancel
      </Button>
      <Button disabled={!isFormChanged} form={FORM_ID} type="submit" variant="contained">
        Save
      </Button>
      <Button
        disabled={!isFormChanged}
        form={FORM_ID}
        onClick={onSaveAndClose}
        type="submit"
        variant="contained"
      >
        Save & Close
      </Button>
    </div>
  </HeaderBase>
);

export { Header };
