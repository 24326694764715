import { FC, useCallback, useMemo } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

import { inputTypes } from 'shared/components/Input/constants';
import {
  SelectField as NewestSelectField,
  SelectMultiField,
} from 'shared/components/NewestSelectField';
import { SelectBinaryField, SelectField } from 'shared/components/SelectField';
import { TextField } from 'shared/components/TextField';
import { CaptureExpiryMethod, PackingConstraintType, TemperatureType } from 'shared/types';
import {
  captureExpiryMethodOptions,
  packingConstraintOptions,
  temperatureOptions,
} from 'pages/Item/constants';

import { FormValues, MassEditFormRowProps } from './types';

const MassEditFormRow: FC<MassEditFormRowProps> = ({
  alcoholTypesOptions,
  capabilitiesOptions,
  control,
  customAssortmentsOptions,
  isAlcoholDisabled,
  isEditPage,
  isManufacturingTypeDisabled,
  isTobaccoDisabled,
  manufacturingTypesOptions,
  nationalAssortmentsOptions,
  tobaccoTypesOptions,
}) => {
  const {
    renderAcceptableShelfLife,
    renderAlcoholType,
    renderAlcoholVolume,
    renderBaggableAtPack,
    renderBinnableAtPack,
    renderCaptureExpiryMethod,
    renderGuaranteedShelfLife,
    renderIgnoreBlockOverReceipt,
    renderItemCapability,
    renderManufacturingType,
    renderMarkdownItem,
    renderNationalAssortment,
    renderPackingConstraint,
    renderPercentageOverageAllowed,
    renderPullByDate,
    renderMarkdownDays,
    renderRequireProduceBag,
    renderSellableTemperature,
    renderStopBuying,
    renderStorageTemperature,
    renderThawExpiryDays,
    renderThawReplenishmentMaxQty,
    renderThawReplenishmentMinQty,
    renderThawTimeHours,
    renderThawToSellEnabled,
    renderTobaccoType,
  } = useMemo<{
    renderNationalAssortment: ControllerProps<
      FormValues,
      FormValues['nationalAssortment'],
      'nationalAssortment'
    >['render'];
    renderAlcoholType: ControllerProps<
      FormValues,
      FormValues['alcoholType'],
      'alcoholType'
    >['render'];
    renderAlcoholVolume: ControllerProps<
      FormValues,
      FormValues['alcoholByVolume'],
      'alcoholByVolume'
    >['render'];
    renderTobaccoType: ControllerProps<
      FormValues,
      FormValues['tobaccoType'],
      'tobaccoType'
    >['render'];
    renderStopBuying: ControllerProps<
      FormValues,
      FormValues['stopBuyingFlag'],
      'stopBuyingFlag'
    >['render'];
    renderManufacturingType: ControllerProps<
      FormValues,
      FormValues['manufacturingType'],
      'manufacturingType'
    >['render'];
    renderItemCapability: ControllerProps<
      FormValues,
      FormValues['capabilities'],
      'capabilities'
    >['render'];
    renderPullByDate: ControllerProps<FormValues, FormValues['pullByDate'], 'pullByDate'>['render'];
    renderMarkdownDays: ControllerProps<
      FormValues,
      FormValues['markdownDays'],
      'markdownDays'
    >['render'];
    renderGuaranteedShelfLife: ControllerProps<
      FormValues,
      FormValues['guaranteedShelfLife'],
      'guaranteedShelfLife'
    >['render'];
    renderCaptureExpiryMethod: ControllerProps<
      FormValues,
      FormValues['captureExpiryMethod'],
      'captureExpiryMethod'
    >['render'];
    renderAcceptableShelfLife: ControllerProps<
      FormValues,
      FormValues['acceptableShelfLife'],
      'acceptableShelfLife'
    >['render'];
    renderMarkdownItem: ControllerProps<
      FormValues,
      FormValues['markdownItem'],
      'markdownItem'
    >['render'];
    renderBinnableAtPack: ControllerProps<
      FormValues,
      FormValues['binnableAtPack'],
      'binnableAtPack'
    >['render'];
    renderBaggableAtPack: ControllerProps<
      FormValues,
      FormValues['baggableAtPack'],
      'baggableAtPack'
    >['render'];
    renderPackingConstraint: ControllerProps<
      FormValues,
      FormValues['packingConstraint'],
      'packingConstraint'
    >['render'];
    renderIgnoreBlockOverReceipt: ControllerProps<
      FormValues,
      FormValues['ignoreBlockOverReceipt'],
      'ignoreBlockOverReceipt'
    >['render'];
    renderPercentageOverageAllowed: ControllerProps<
      FormValues,
      FormValues['percentageOverageAllowed'],
      'percentageOverageAllowed'
    >['render'];
    renderRequireProduceBag: ControllerProps<
      FormValues,
      FormValues['requireProduceBag'],
      'requireProduceBag'
    >['render'];
    renderSellableTemperature: ControllerProps<
      FormValues,
      FormValues['sellableTemperature'],
      'sellableTemperature'
    >['render'];
    renderStorageTemperature: ControllerProps<
      FormValues,
      FormValues['storageTemperature'],
      'storageTemperature'
    >['render'];
    renderThawToSellEnabled: ControllerProps<
      FormValues,
      FormValues['thawToSellEnabled'],
      'thawToSellEnabled'
    >['render'];
    renderThawReplenishmentMinQty: ControllerProps<
      FormValues,
      FormValues['thawReplenishmentMinQty'],
      'thawReplenishmentMinQty'
    >['render'];
    renderThawReplenishmentMaxQty: ControllerProps<
      FormValues,
      FormValues['thawReplenishmentMaxQty'],
      'thawReplenishmentMaxQty'
    >['render'];
    renderThawTimeHours: ControllerProps<
      FormValues,
      FormValues['thawTimeHours'],
      'thawTimeHours'
    >['render'];
    renderThawExpiryDays: ControllerProps<
      FormValues,
      FormValues['thawExpiryDays'],
      'thawExpiryDays'
    >['render'];
  }>(
    () => ({
      renderNationalAssortment: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          label="National Assortment"
          name="nationalAssortment"
          onBlur={onBlur}
          onChange={handleChange}
          options={nationalAssortmentsOptions}
          value={value}
        />
      ),
      renderAlcoholType: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          disabled={isAlcoholDisabled}
          label="Alcohol Type"
          name="alcoholType"
          onBlur={onBlur}
          onChange={handleChange}
          options={alcoholTypesOptions}
          value={value}
        />
      ),
      renderAlcoholVolume: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          disabled={isAlcoholDisabled}
          label="Alcohol ABV %"
          name="alcoholByVolume"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderTobaccoType: ({ field: { onChange: handleChange, value = '' } }) => (
        <NewestSelectField
          disabled={isTobaccoDisabled}
          label="Tobacco Type"
          name="tobaccoType"
          onChange={handleChange}
          options={tobaccoTypesOptions}
          value={value}
        />
      ),
      renderStopBuying: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <SelectBinaryField
          label="Stop Buying"
          name="stopBuyingFlag"
          onBlur={onBlur}
          onChange={handleChange}
          value={Number(value)}
        />
      ),
      renderManufacturingType: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          disabled={isManufacturingTypeDisabled}
          label="Manufacturing Type"
          name="manufacturingType"
          onBlur={onBlur}
          onChange={handleChange}
          options={manufacturingTypesOptions}
          value={value}
        />
      ),
      renderGuaranteedShelfLife: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Guar. Shelf Life"
          name="guaranteedShelfLife"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderPullByDate: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Pull By Date (days)"
          name="pullByDate"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMarkdownDays: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Bevmo Markdown (days)"
          name="markdownDays"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderItemCapability: ({ field: { onChange: handleChange, value = [] } }) => (
        <SelectMultiField
          label="Item Capability"
          name="capabilities"
          onChange={handleChange}
          options={capabilitiesOptions}
          value={value}
        />
      ),
      renderCaptureExpiryMethod: ({
        field: { onBlur, onChange: handleChange, value = CaptureExpiryMethod.No },
      }) => (
        <SelectField
          label="Capture Expiry Method"
          name="captureExpiryMethod"
          onBlur={onBlur}
          onChange={handleChange}
          options={captureExpiryMethodOptions}
          value={value}
        />
      ),
      renderAcceptableShelfLife: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Acceptable Shelf Life"
          name="acceptableShelfLife"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMarkdownItem: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <SelectBinaryField
          label="UK Markdown Item"
          name="markdownItem"
          onBlur={onBlur}
          onChange={handleChange}
          value={Number(value)}
        />
      ),
      renderBinnableAtPack: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <SelectBinaryField
          label="Binnable at Pack"
          name="binnableAtPack"
          onBlur={onBlur}
          onChange={handleChange}
          value={Number(value)}
        />
      ),
      renderBaggableAtPack: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <SelectBinaryField
          label="Baggable at Pack"
          name="baggableAtPack"
          onBlur={onBlur}
          onChange={handleChange}
          value={Number(value)}
        />
      ),
      renderPackingConstraint: ({
        field: { onBlur, onChange: handleChange, value = PackingConstraintType.None },
      }) => (
        <SelectField
          label="Packing Constraint"
          name="packingConstraint"
          onBlur={onBlur}
          onChange={handleChange}
          options={packingConstraintOptions}
          value={value}
        />
      ),
      renderRequireProduceBag: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <SelectBinaryField
          label="Require Produce Bag"
          name="requireProduceBag"
          onBlur={onBlur}
          onChange={handleChange}
          value={Number(value)}
        />
      ),
      renderIgnoreBlockOverReceipt: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <SelectBinaryField
          label="Ignore Block Over Receipt"
          name="ignoreBlockOverReceipt"
          onBlur={onBlur}
          onChange={handleChange}
          value={Number(value)}
        />
      ),
      renderPercentageOverageAllowed: ({
        field: { onBlur, onChange: handleChange, value = '' },
      }) => (
        <TextField
          label="Percentage Overage Allowed"
          name="percentageOverageAllowed"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderSellableTemperature: ({
        field: { onBlur, onChange: handleChange, value = TemperatureType.Ambient },
      }) => (
        <SelectField
          label="Sellable Temperature"
          name="sellableTemperature"
          onBlur={onBlur}
          onChange={handleChange}
          options={temperatureOptions}
          value={value}
        />
      ),
      renderStorageTemperature: ({
        field: { onBlur, onChange: handleChange, value = TemperatureType.Ambient },
      }) => (
        <SelectField
          label="Storage Temperature"
          name="storageTemperature"
          onBlur={onBlur}
          onChange={handleChange}
          options={temperatureOptions}
          value={value}
        />
      ),
      renderThawToSellEnabled: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <SelectBinaryField
          label="Thaw To Sell Enabled"
          name="thawToSellEnabled"
          onBlur={onBlur}
          onChange={handleChange}
          value={Number(value)}
        />
      ),
      renderThawReplenishmentMinQty: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <TextField
          label="Thaw Replenishment Min Qty"
          name="thawReplenishmentMinQty"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderThawReplenishmentMaxQty: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <TextField
          label="Thaw Replenishment Max Qty"
          name="thawReplenishmentMaxQty"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderThawTimeHours: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <TextField
          label="Thaw Time Hours"
          name="thawTimeHours"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderThawExpiryDays: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <TextField
          label="Thaw Expiry Days"
          name="thawExpiryDays"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
    }),
    [control],
  );
  const renderCustomAssortments = useCallback<
    ControllerProps<FormValues, FormValues['customAssortments'], 'customAssortments'>['render']
  >(
    ({ field: { onChange: handleChange, value = [] } }) => (
      <SelectMultiField
        label="Custom Assortment"
        name="customAssortments"
        onChange={handleChange}
        options={customAssortmentsOptions}
        value={value}
      />
    ),
    [control],
  );

  return (
    <>
      {isEditPage && (
        <Controller control={control} name="nationalAssortment" render={renderNationalAssortment} />
      )}
      <Controller control={control} name="customAssortments" render={renderCustomAssortments} />
      {isEditPage && <Controller control={control} name="alcoholType" render={renderAlcoholType} />}
      {isEditPage && (
        <Controller control={control} name="alcoholByVolume" render={renderAlcoholVolume} />
      )}
      {isEditPage && <Controller control={control} name="tobaccoType" render={renderTobaccoType} />}
      {isEditPage && (
        <Controller control={control} name="stopBuyingFlag" render={renderStopBuying} />
      )}
      {isEditPage && (
        <Controller control={control} name="manufacturingType" render={renderManufacturingType} />
      )}
      {isEditPage && (
        <Controller
          control={control}
          name="guaranteedShelfLife"
          render={renderGuaranteedShelfLife}
        />
      )}
      <Controller control={control} name="capabilities" render={renderItemCapability} />
      {isEditPage && (
        <>
          <Controller control={control} name="pullByDate" render={renderPullByDate} />
          <Controller control={control} name="markdownDays" render={renderMarkdownDays} />
          <Controller
            control={control}
            name="captureExpiryMethod"
            render={renderCaptureExpiryMethod}
          />
          <Controller
            control={control}
            name="acceptableShelfLife"
            render={renderAcceptableShelfLife}
          />
          <Controller control={control} name="markdownItem" render={renderMarkdownItem} />
          <Controller control={control} name="binnableAtPack" render={renderBinnableAtPack} />
          <Controller control={control} name="baggableAtPack" render={renderBaggableAtPack} />
          <Controller control={control} name="packingConstraint" render={renderPackingConstraint} />
          <Controller control={control} name="requireProduceBag" render={renderRequireProduceBag} />
          <Controller control={control} name="thawToSellEnabled" render={renderThawToSellEnabled} />
          <Controller
            control={control}
            name="thawReplenishmentMinQty"
            render={renderThawReplenishmentMinQty}
          />
          <Controller
            control={control}
            name="thawReplenishmentMaxQty"
            render={renderThawReplenishmentMaxQty}
          />
          <Controller control={control} name="thawTimeHours" render={renderThawTimeHours} />
          <Controller control={control} name="thawExpiryDays" render={renderThawExpiryDays} />
          <Controller
            control={control}
            name="sellableTemperature"
            render={renderSellableTemperature}
          />
          <Controller
            control={control}
            name="storageTemperature"
            render={renderStorageTemperature}
          />
        </>
      )}
      {isEditPage && (
        <>
          <Controller
            control={control}
            name="ignoreBlockOverReceipt"
            render={renderIgnoreBlockOverReceipt}
          />
          <Controller
            control={control}
            name="percentageOverageAllowed"
            render={renderPercentageOverageAllowed}
          />
        </>
      )}
    </>
  );
};

export { MassEditFormRow };
