import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { memoCell } from 'shared/utils';
import { MappedRow, UnsubmittedRows } from 'pages/SourceDestination/types';

import { FactoryCellAddLeadTimeOverrideProps } from './types';

const FactoryCellAddLeadTimeOverride = (
  setUnsubmittedRows: Dispatch<SetStateAction<UnsubmittedRows>>,
  dependencies?: (keyof MappedRow)[],
  deriveHasDependenciesChanged?: (previousRowData: MappedRow, nextRowData: MappedRow) => boolean,
) =>
  memoCell<FactoryCellAddLeadTimeOverrideProps>(
    ({
      row: {
        original: { parent },
      },
    }) => {
      const { id } = parent!;

      const onAddLeadTimeOverrideClick = useCallback(() => {
        setUnsubmittedRows(oldValue => ({
          ...oldValue,
          [id]: [
            {
              id: `${id}-unsubmitted`,
              isUnsubmitted: true,
              isLeadTimeOverrideRow: true,
              transportationLaneId: id,
              parent,
            },
          ],
        }));
      }, [setUnsubmittedRows, parent]);
      return (
        <Button onClick={onAddLeadTimeOverrideClick} startIcon={<AddIcon />} variant="outlined">
          Add Override
        </Button>
      );
    },
    dependencies,
    false,
    deriveHasDependenciesChanged,
  );

export { FactoryCellAddLeadTimeOverride };
