import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { FullOpco } from 'shared/types';

import { replicateN } from '../utils';
import { makeFullOpco } from '../factories';

const fullOpcos = (props: O.Partial<FullOpco, 'deep'>[] = []): FullOpco[] =>
  mergeDeepArrays<FullOpco>(
    replicateN(makeFullOpco(), 5, {
      name: [
        'Main.31st and Wharton',
        'Main.Andrew s Distributing Company',
        'Burke Beverage',
        'Chesterman Company',
        "Columbia/Young's Market",
      ],
    }),
    props,
  );

export { fullOpcos };
