import { FC, createContext, useMemo } from 'react';

import { useSessionStorage } from 'shared/hooks/useSessionStorage';

const SessionStorageContext = createContext({
  language: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLanguage: (s: string) => {},
});

const Context: FC = ({ children }) => {
  const [language, setLanguage] = useSessionStorage(
    'language',
    'English (United States of America) - en-US',
  );

  const value = useMemo(() => ({ language, setLanguage }), [language]);

  return <SessionStorageContext.Provider value={value}>{children}</SessionStorageContext.Provider>;
};

export { Context, SessionStorageContext };
