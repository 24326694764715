import { format, fromUnixTime } from 'date-fns';

import { MappedRow } from '../types';
import { PackageTypeName } from '../../../shared/constants/package';
import { CostDateStatus, Currency } from '../../../shared/types';

const downloadColumnNames = {
  itemDepartment: 'GP Item Department',
  itemClass: 'GP Item Class',
  itemId: 'GP Item ID (*required)',
  itemName: 'GP Item Name',
  parentCompany: 'Parent Company',
  caseCount: 'Case Quantity',
  eachUPC: 'Each UPC',
  caseUPC: 'Case UPC',
  eachVIN: 'Each VIN',
  caseVIN: 'Case VIN',
  status: 'Status',
  type: 'Type',
  eachCostValidation: 'Each Cost % Change',
  caseCostValidation: 'Case Cost % Change',
  canAutoApprove: 'Can Auto Approve',
  note: 'Note',
  isDisabled: 'Is Disabled',
  effectiveDate: 'Effective Date (*required)',
  endDate: 'End Date',
  eachCost: 'Each Cost',
  caseCost: 'Case Cost',
  currency: 'Currency (*required)',
  supplierName: 'GP Vendor Name',
  supplierId: 'GP Vendor Number (*required)',
  opcoName: 'GP Opco Name',
  opcoId: 'GP Opco ID',
  opcoStateProvinceRegion: 'OpCo State/Province/Region',
  locationName: 'GP Location Name',
  locationId: 'GP Location ID',
  locationAddress: 'GP Location Address',
  locationCity: 'GP Location City',
  locationStateProvinceRegion: 'GP Location State/Province/Region',
  locationPostalCode: 'GP Location Zip',
  error: 'Error',
};

const mapRowsForDownload = (
  parentRows: MappedRow[],
  currencies: Currency[],
  hideIsDisabledColumn: Boolean,
  hideCanAutoApproveColumn: Boolean,
  shouldShowParentCompanyColumn: Boolean,
): Record<string, string>[] => {
  const currenciesMap = new Map<string, string>();
  currencies.forEach(currency => {
    currenciesMap.set(currency.id, currency.code);
  });

  const result = parentRows.flatMap(parentRow => {
    const parentAndChildRows = [parentRow, ...(parentRow.rows ?? [])];
    parentAndChildRows.sort((a, b) => (a.effectiveDate ?? 0) - (b.effectiveDate ?? 0));
    const rowsToDownload = parentAndChildRows.map(row => {
      const rowToDownload: Record<string, string> = {};
      rowToDownload[downloadColumnNames.itemDepartment] = parentRow.itemDepartment ?? '';
      rowToDownload[downloadColumnNames.itemClass] = parentRow.itemClass ?? '';
      rowToDownload[downloadColumnNames.itemId] = parentRow.itemId ?? '';
      rowToDownload[downloadColumnNames.itemName] = parentRow.itemName ?? '';
      if (shouldShowParentCompanyColumn) {
        rowToDownload[downloadColumnNames.parentCompany] = parentRow.parentCompany ?? '';
      }
      rowToDownload[downloadColumnNames.caseCount] = parentRow.caseCount?.toString() ?? '';
      rowToDownload[downloadColumnNames.eachUPC] = parentRow.eachUPC ?? '';
      rowToDownload[downloadColumnNames.caseUPC] = parentRow.caseUPC ?? '';
      rowToDownload[downloadColumnNames.eachVIN] = parentRow.eachVIN ?? '';
      rowToDownload[downloadColumnNames.caseVIN] = parentRow.caseVIN ?? '';
      rowToDownload[downloadColumnNames.status] =
        row.status === CostDateStatus.Undefined ? '' : row.status;
      rowToDownload[downloadColumnNames.type] = row.type ?? '';
      const autoValidationEachCost = row.autoValidationEachCost?.toString() ?? '';
      const autoValidationEachCostValue = autoValidationEachCost.match(/(-)?\d+(\.\d+)?/g);
      rowToDownload[downloadColumnNames.eachCostValidation] =
        autoValidationEachCostValue?.[0] ?? '';
      const autoValidationCaseCost = row.autoValidationCaseCost?.toString() ?? '';
      const autoValidationCaseCostValue = autoValidationCaseCost.match(/(-)?\d+(\.\d+)?/g);
      rowToDownload[downloadColumnNames.caseCostValidation] =
        autoValidationCaseCostValue?.[0] ?? '';
      if (!hideCanAutoApproveColumn) {
        rowToDownload[downloadColumnNames.canAutoApprove] = row.canAutoApprove ?? '';
      }
      rowToDownload[downloadColumnNames.note] = row.comments ?? '';
      if (!hideIsDisabledColumn) {
        rowToDownload[downloadColumnNames.isDisabled] = row.isDisabled ? 'Yes' : 'No';
      }
      rowToDownload[downloadColumnNames.effectiveDate] = row.effectiveDate
        ? format(fromUnixTime(row.effectiveDate), 'yyyy-MM-dd')
        : '';
      rowToDownload[downloadColumnNames.endDate] = row.endDate
        ? format(fromUnixTime(row.endDate), 'yyyy-MM-dd')
        : '';
      rowToDownload[downloadColumnNames.eachCost] = row[PackageTypeName.Each]?.toString() ?? '';
      rowToDownload[downloadColumnNames.caseCost] = row[PackageTypeName.Case]?.toString() ?? '';
      rowToDownload[downloadColumnNames.currency] =
        (row.currency && currenciesMap.get(row.currency)) ?? '';
      rowToDownload[downloadColumnNames.supplierName] = parentRow.supplierName ?? '';
      rowToDownload[downloadColumnNames.supplierId] = parentRow.supplierId ?? '';
      rowToDownload[downloadColumnNames.opcoName] = parentRow.opcoName ?? '';
      rowToDownload[downloadColumnNames.opcoId] = parentRow.opcoId ?? '';
      rowToDownload[downloadColumnNames.opcoStateProvinceRegion] =
        parentRow.opcoStateProvinceRegion ?? '';
      rowToDownload[downloadColumnNames.locationName] = parentRow.locationName ?? '';
      rowToDownload[downloadColumnNames.locationId] = parentRow.locationId ?? '';
      rowToDownload[downloadColumnNames.locationAddress] = parentRow.locationAddress ?? '';
      rowToDownload[downloadColumnNames.locationCity] = parentRow.locationCity ?? '';
      rowToDownload[downloadColumnNames.locationStateProvinceRegion] =
        parentRow.locationStateProvinceRegion ?? '';
      rowToDownload[downloadColumnNames.locationPostalCode] = parentRow.locationPostalCode ?? '';

      return rowToDownload;
    });
    return rowsToDownload;
  });
  return result;
};

export { mapRowsForDownload, downloadColumnNames };
