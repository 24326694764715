import { FC, useCallback, useMemo } from 'react';

import { Loader } from 'shared/components/Loader';
import { EditPage } from 'shared/components/Edit';
import { FactoryCellDelete } from 'shared/components/DataGrid';
import { AssortmentOverrideState, MutationUpdateDestinationAssortmentsArgs } from 'shared/types';

import { Row, UseColumnsResult } from './types';
import { FormValues, MassEditFormRow } from './components/MassEditFormRow';
import { updateDestinationAssortmentsMutation } from './operations';
import { headersForMassEditColumns } from './constants';
import { useSetup } from './hooks';
import { DestinationAssortmentView } from './DestinationAssortmentView';

const DestinationAssortment: FC = () => {
  const {
    currenciesOptions,
    isCostingOn,
    onCloseEditPage,
    packageTypes,
    packagingIdOptions,
    reset,
    selectedRows,
    setSelectedRows,
    showEditPage,
    showMainLoader,
    velocityCategoryOptions,
    ...props
  } = useSetup();

  const deriveMassEditData = useCallback(() => {
    const data = selectedRows.map(({ original }) => original);
    const defaultValues = {
      assortmentOverrideState: AssortmentOverrideState.Active,
      localCost: undefined,
      localCostCurrency: undefined,
      nationalCost: undefined,
      nationalCostCurrency: undefined,
      packagingID: undefined,
      velocityCategory: undefined,
    };

    const mapRowToMutationArgs = ({
      assortmentOverrideState: override,
      packagingID = '1',
      ...formData
    }: FormValues): MutationUpdateDestinationAssortmentsArgs => ({
      input: {
        assortments: selectedRows.map(
          ({
            original: {
              id,
              localCost,
              localCostCurrency,
              nationalCost,
              nationalCostCurrency,
              packaging,
              selectedPackagingID,
              velocityCategory,
            },
          }) => {
            const newPackagingId = () => {
              const newPackaging = packagingIdOptions.find(item => item.value === packagingID);
              const setPackagingId = packaging.find(
                item => item.packageType.name === newPackaging?.label,
              );
              return setPackagingId?.id || selectedPackagingID;
            };

            const newNationalCostCurrency =
              formData.nationalCostCurrency ?? nationalCostCurrency?.id;
            const newLocalCostCurrency = formData.localCostCurrency ?? localCostCurrency?.id;

            return {
              id,
              assortmentOverrideState: override,
              localCost: newLocalCostCurrency ? Number(formData.localCost ?? localCost) : undefined,
              localCostCurrency: newLocalCostCurrency,
              nationalCost: newNationalCostCurrency
                ? Number(formData.nationalCost ?? nationalCost)
                : undefined,
              nationalCostCurrency: newNationalCostCurrency,
              packagingID: newPackagingId(),
              velocityCategory: formData.velocityCategory ?? velocityCategory?.id,
            };
          },
        ),
      },
    });

    const columns = [
      ...headersForMassEditColumns,
      {
        id: 'delete',
        Cell: FactoryCellDelete<Row>({ setData: setSelectedRows }),
      },
    ] as UseColumnsResult;

    return useMemo(
      () => ({
        defaultValues,
        columns,
        data,
        mapRowToMutationArgs,
      }),
      [selectedRows],
    );
  }, [selectedRows]);

  const editFormRowProps = useMemo(() => {
    const availablePackagingType = () => {
      const data = selectedRows.map(({ original }) => original);
      const packagingTypes: { [key: string]: true } = {};
      data[0]?.packaging?.forEach(item => {
        packagingTypes[item.packageType.name] = true;
      });

      data.forEach(({ packaging }) =>
        packaging.forEach(({ packageType: { name } }) => {
          if (!packagingTypes[name]) {
            delete packagingTypes[name];
          }
        }),
      );

      return Object.keys(packagingTypes);
    };
    return {
      currenciesOptions,
      velocityCategoryOptions,
      packagingIdOptions,
      availablePackagingType: availablePackagingType(),
      selectedRows,
      isCostingOn,
    };
  }, [selectedRows, isCostingOn]);

  if (showMainLoader) {
    return <Loader isLoading />;
  }
  return (
    <>
      {!showEditPage && (
        <DestinationAssortmentView
          {...props}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}

      {showEditPage && (
        <EditPage
          afterMutation={reset}
          deriveMassEditData={deriveMassEditData}
          editFormRowProps={editFormRowProps}
          MassEditFormRow={MassEditFormRow}
          mutation={updateDestinationAssortmentsMutation}
          onCloseEditPage={onCloseEditPage}
        />
      )}
    </>
  );
};

export { DestinationAssortment };
