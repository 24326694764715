import { FactoryCellMutedHeaderProps } from './types';
import styles from './FactoryCellMutedHeader.module.css';

const FactoryCellMutedHeader =
  ({ mainText, mutedText }: FactoryCellMutedHeaderProps) =>
  () =>
    (
      <>
        {mainText} <span className={styles.muted}>{mutedText}</span>
      </>
    );

export { FactoryCellMutedHeader };
