// Item ID,National Assortment,Custom Assortment
const itemAssortmentTemplate = 'SXRlbSBJRCxOYXRpb25hbCBBc3NvcnRtZW50LEN1c3RvbSBBc3NvcnRtZW50';

// Item ID,Stop Buying
const itemStopBuyingTemplate = 'SXRlbSBJRCxTdG9wIEJ1eWluZw==';

// Item ID,Supplier ext. ID,OpCo Name,Package Name,Supplier Item Number
const itemSourceSupplierItemNumberTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxQYWNrYWdlIE5hbWUsU3VwcGxpZXIgSXRlbSBOdW1iZXI=';

// Item ID,Supplier ext. ID,OpCo Name,Package Name,UPC
const itemSourceUPCTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxQYWNrYWdlIE5hbWUsVVBD=';

// Item ID,Supplier ext. ID,OpCo Name,Package Name,Declared Length,Declared Width,Declared Height,Declared UOM,Declared Weight,Declared SOM,Measured Length,Measured Width,Measured Height,Measured UOM,Measured Weight,Measured SOM
const itemSourceDimsTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxQYWNrYWdlIE5hbWUsRGVjbGFyZWQgTGVuZ3RoLERlY2xhcmVkIFdpZHRoLERlY2xhcmVkIEhlaWdodCxEZWNsYXJlZCBVT00sRGVjbGFyZWQgV2VpZ2h0LERlY2xhcmVkIFNPTSxNZWFzdXJlZCBMZW5ndGgsTWVhc3VyZWQgV2lkdGgsTWVhc3VyZWQgSGVpZ2h0LE1lYXN1cmVkIFVPTSxNZWFzdXJlZCBXZWlnaHQsTWVhc3VyZWQgU09N';

// Item ID,Supplier ext. ID,OpCo Name,Package Name,QTY
const itemSourceQtyTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxQYWNrYWdlIE5hbWUsUVRZ';

// Item ID,Supplier ext. ID,OpCo Name,Is Breakable,Breaking From,Breaking To,Breaking Threshold
const itemSourceBreakableTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxJcyBCcmVha2FibGUsQnJlYWtpbmcgRnJvbSxCcmVha2luZyBUbyxCcmVha2luZyBUaHJlc2hvbGQ=';

// Item ID,Supplier ext. ID,OpCo Name,Package Name,Rounding Threshold,Max Allocation Quantity
const itemSourceOrderingTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxQYWNrYWdlIE5hbWUsUm91bmRpbmcgVGhyZXNob2xkLE1heCBBbGxvY2F0aW9uIFF1YW50aXR5 ';

// Item ID,Supplier ext. ID,OpCo Name,Package Name,Supplier Item Number,UPC,QTY,Declared Length,Declared Width,Declared Height,Declared UOM,Declared Weight,Declared SOM,Measured Length,Measured Width,Measured Height,Measured UOM,Measured Weight,Measured SOM
const itemSourceCreateCasepackTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxQYWNrYWdlIE5hbWUsU3VwcGxpZXIgSXRlbSBOdW1iZXIsVVBDLFFUWSxEZWNsYXJlZCBMZW5ndGgsRGVjbGFyZWQgV2lkdGgsRGVjbGFyZWQgSGVpZ2h0LERlY2xhcmVkIFVPTSxEZWNsYXJlZCBXZWlnaHQsRGVjbGFyZWQgU09NLE1lYXN1cmVkIExlbmd0aCxNZWFzdXJlZCBXaWR0aCxNZWFzdXJlZCBIZWlnaHQsTWVhc3VyZWQgVU9NLE1lYXN1cmVkIFdlaWdodCxNZWFzdXJlZCBTT00=';

// Item ID,Supplier ext. ID,OpCo Name,Package Name,Delete Record
const itemSourceDeleteCasepackTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxQYWNrYWdlIE5hbWUsRGVsZXRlIFJlY29yZA==';

//  Item ID,Supplier ext. ID,OpCo Name,Lot Size Enabled,Lot Size Min,Lot Size Increment
const itemSourceLotSizeTemplate =
  'SXRlbSBJRCxTdXBwbGllciBleHQuIElELE9wQ28gTmFtZSxMb3QgU2l6ZSBFbmFibGVkLExvdCBTaXplIE1pbixMb3QgU2l6ZSBJbmNyZW1lbnQK';

export {
  itemAssortmentTemplate,
  itemStopBuyingTemplate,
  itemSourceSupplierItemNumberTemplate,
  itemSourceUPCTemplate,
  itemSourceDimsTemplate,
  itemSourceQtyTemplate,
  itemSourceBreakableTemplate,
  itemSourceCreateCasepackTemplate,
  itemSourceDeleteCasepackTemplate,
  itemSourceOrderingTemplate,
  itemSourceLotSizeTemplate,
};
