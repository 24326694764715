import { memo } from 'react';

import { DefaultForm } from '../DefaultForm';

import { FormItem, TobaccoFormProps } from './types';
import { filterMapDataToMutationItems } from './constants';

const TobaccoForm = memo((props: TobaccoFormProps) => (
  <DefaultForm<FormItem> filterMapDataToMutationItems={filterMapDataToMutationItems} {...props} />
));

export { TobaccoForm };
