import { FC } from 'react';

import { DataGrid } from 'shared/components/DataGrid';
import { DataGridPage } from 'shared/components/DataGridPage';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';
import { ACTION_UPLOAD_FILE_LOCATION } from 'shared/constants';

import { LocationViewProps, Row } from './types';

const LocationView: FC<LocationViewProps> = ({
  onAddTagsClick,
  onDataGridDownloadButtonClick,
  onEditClick,
  selectedRows,
  triggerUncheckAllCheckboxes,
  uploadFileProps,
  userPermissions,
  ...props
}) => (
  <DataGridPage<Row, true>
    header={
      <HeaderDataGridActions
        hasAccessToUploadFile={userPermissions.has(ACTION_UPLOAD_FILE_LOCATION)}
        onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
        title="Location"
        uploadFileProps={uploadFileProps}
      />
    }
    onAddTagsClick={onAddTagsClick}
    onEditClick={onEditClick}
    selectedRows={selectedRows}
    triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
  >
    <DataGrid<Row> {...props} disableOnLoadingMoreData enableRowSelection />
  </DataGridPage>
);

export { LocationView };
