// Too many rows will cause the file to be too large
export const EXCEL_DATE_FORMAT = 'yyyy-mm-dd';

export const EXCEL_FILE_TYPES = [
  '.xls',
  '.xlsx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export enum ColumnType {
  Text = 'Text',
  Date = 'Date',
  Number = 'Number',
  Percent = 'Percent',
}
