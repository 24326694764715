import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { Packaging } from 'shared/types';

import { replicateN } from '../utils';
import { makePackaging } from '../factories';
import { Length, Weight } from '../../constants/units';

import { packageTypes } from './packageTypes';

const packagings = (props: O.Partial<Packaging, 'deep'>[] = []): Packaging[] =>
  mergeDeepArrays<Packaging>(
    replicateN({
      data: makePackaging(),
      n: 4,
      replacements: {
        declaredLengthUnits: [, Length.M, Length.Cm, Length.In],
        declaredWeightUnits: [, Weight.G, Weight.Lb, Weight.Kg],
        measuredLengthUnits: [, Length.Ft, Length.Cm, Length.In],
        measuredWeightUnits: [, Weight.Mg, Weight.Lb, Weight.Kg],
        packageType: packageTypes(),
      },
      skipIncrementForFields: [
        'declaredLengthUnits',
        'declaredWeightUnits',
        'measuredLengthUnits',
        'measuredWeightUnits',
        'packageType',
      ],
    }),
    props,
  );

export { packagings };
