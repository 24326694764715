import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import type { UsePaginationResult } from './types';

const usePagination = (defaultPageSize = 25): UsePaginationResult => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pageIndex, setPageIndex] = useState(0);

  const resetPageIndex = useCallback(() => {
    setPageIndex(0);
  }, []);

  const onPageChange = useCallback<UsePaginationResult['onPageChange']>(
    (_, page) => {
      setPageIndex(page);
    },
    [setPageIndex],
  );
  const onRowsPerPageChange = useCallback<UsePaginationResult['onRowsPerPageChange']>(
    (e: ChangeEvent<HTMLInputElement>) => {
      resetPageIndex();
      setPageSize(Number(e.target.value));
    },
    [setPageSize, setPageIndex],
  );

  return useMemo(
    () => ({
      onPageChange,
      onRowsPerPageChange,
      pagination: {
        limit: pageSize,
        offset: pageIndex * pageSize,
      },
      pageSize,
      pageIndex,
      resetPageIndex,
    }),
    [pageIndex, pageSize, onPageChange, onRowsPerPageChange],
  );
};

export { usePagination };
