import { PackageType } from 'shared/types';
import { PackageTypeName } from 'shared/constants/package';

import { MappedRow } from '../types';

export const mapRowsToPackageTypeOptions = (
  rows: MappedRow[] | undefined,
  packageTypes: PackageType[],
  includeEach: boolean,
) => {
  const options =
    rows?.map(({ packageTypeName }) => {
      const value = packageTypes.find(({ name }) => name === packageTypeName)?.id;
      if (!value) {
        return {
          label: '-',
          value: '',
        };
      }
      return {
        label: packageTypeName,
        value,
      };
    }) ?? [];
  if (includeEach) {
    options.unshift({
      label: PackageTypeName.Each,
      value: packageTypes.find(({ name }) => name === PackageTypeName.Each)?.id || '',
    });
  }
  return options;
};

export * from './mapRowToMutationArgs';
export * from './mapDataToRows';
