import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Chip from '@mui/material/Chip';

import { pagePermissions } from 'shared/constants/access';
import { ActionsType } from 'shared/constants';

import { NestedListProps } from './types';
import styles from './NestedList.module.css';
import { ReactComponent as Caret } from './caret.svg';

const NestedList: FC<NestedListProps> = ({ items, title, userPermissions }) => {
  const [open, setOpen] = useState(true);
  let itemCount = 0;
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (itemCount === 0) {
      setHidden(true);
    }
  }, [userPermissions]);

  const handleClick = () => setOpen(!open);

  return (
    <>
      {!hidden && (
        <ListItemButton className={styles.itemButton} disableGutters onClick={handleClick}>
          <ListItemText disableTypography>
            <Typography className={styles.title} variant="overline">
              {title}
            </Typography>
          </ListItemText>
          <Caret className={classNames({ [styles.caretDown]: !open })} />
        </ListItemButton>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding id="nested-list">
          {items.map(item => {
            let count: string | number;

            const requiredPermission = pagePermissions.get(item.to) as ActionsType;
            if (!userPermissions || !userPermissions.has(requiredPermission)) {
              // eslint-disable-next-line no-console
              console.warn(`user missing required permissions.
userPermissions=${JSON.stringify(userPermissions)}
requiredPermission=${requiredPermission}
pagePath=${item.to}`);
              return null;
            }
            itemCount += 1;
            if (item.count) {
              if (Number.isInteger(item.count)) {
                count = item.count as number;
              } else if ((item.count as [number, number])[0]) {
                count = `${(item.count as [number, number])[0]}/${
                  (item.count as [number, number])[1]
                }`;
              }
            }

            return (
              <ListItemButton
                key={item.title}
                activeClassName={styles.active}
                className={styles.itemButton}
                component={NavLink}
                disableGutters
                to={item.to}
              >
                <ListItemText className={styles.listItemText} disableTypography>
                  <Typography className={styles.itemText} variant="h5">
                    {item.title}
                  </Typography>
                  {!!count! && <Chip className={styles.chip} label={count} size="small" />}
                </ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export { NestedList };
