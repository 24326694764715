import { CaptureExpiryMethod, Option, PackingConstraintType, TemperatureType } from 'shared/types';

const temperatureOptions: Option<string, string>[] = [
  {
    label: 'Ambient',
    value: TemperatureType.Ambient,
  },
  {
    label: 'Cool',
    value: TemperatureType.Cool,
  },
  {
    label: 'Frozen',
    value: TemperatureType.Frozen,
  },
];

const packingConstraintOptions: Option<string, string>[] = [
  {
    label: 'Alone',
    value: PackingConstraintType.Alone,
  },
  {
    label: 'Same Department',
    value: PackingConstraintType.SameDepartment,
  },
  {
    label: 'Same Class',
    value: PackingConstraintType.SameClass,
  },
  {
    label: 'Same Subclass',
    value: PackingConstraintType.SameSubclass,
  },
  {
    label: 'None',
    value: PackingConstraintType.None,
  },
];

const captureExpiryMethodOptions: Option<string, string>[] = [
  {
    label: 'No',
    value: CaptureExpiryMethod.No,
  },
  {
    label: 'Manual',
    value: CaptureExpiryMethod.Manual,
  },
  {
    label: 'Auto Assign (GSL)',
    value: CaptureExpiryMethod.AutoAssignGsl,
  },
];

export { packingConstraintOptions, temperatureOptions, captureExpiryMethodOptions };
