import { useHistory } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { usePermissions } from 'shared/hooks';

import { UserDefinedAttributeView } from './components/UserDefinedAttributeView';
import { HandleChangeType } from './types';

const UserDefinedAttribute: FC = () => {
  const userPermissions = usePermissions();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [blockedLocation, setBlockedLocation] = useState<string | null>(null);
  const [accordionClick, setAccordionClick] = useState<string | false>('Custom Assortment');
  const [expanded, setExpanded] = useState<string | false>('Custom Assortment');
  const [isReset, setIsReset] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (blockedLocation && !isFormChanged) {
      history.push(blockedLocation);
    }
  }, [blockedLocation, isFormChanged]);

  useEffect(() => {
    const canChangeAccordion = accordionClick !== expanded && !isFormChanged;

    if (canChangeAccordion) {
      setExpanded(accordionClick);
    }
  }, [accordionClick, isFormChanged]);

  const handleChangeAccordion: HandleChangeType = useCallback(
    panel => (_, isExpanded) => {
      setAccordionClick(isExpanded ? panel : false);
    },
    [],
  );

  const afterSave = useCallback(() => {
    setIsFormChanged(false);
    enqueueSnackbar('All changes were saved successfully.', { variant: 'success' });
  }, []);

  const onCancel = useCallback(() => setIsReset(true), []);

  const onCloseModal = useCallback(() => {
    setAccordionClick(expanded);

    if (blockedLocation) {
      setBlockedLocation(null);
    }
  }, [expanded, blockedLocation]);

  const onChangeUnsavedPage = useCallback(({ pathname }) => {
    setBlockedLocation(pathname);

    return false;
  }, []);

  return (
    <UserDefinedAttributeView
      accordionClick={accordionClick}
      afterSave={afterSave}
      blockedLocation={blockedLocation}
      expanded={expanded}
      handleChangeAccordion={handleChangeAccordion}
      isError={isError}
      isFormChanged={isFormChanged}
      isReset={isReset}
      onCancel={onCancel}
      onChangeUnsavedPage={onChangeUnsavedPage}
      onCloseModal={onCloseModal}
      setIsError={setIsError}
      setIsFormChanged={setIsFormChanged}
      setIsReset={setIsReset}
      userPermissions={userPermissions}
    />
  );
};

export { UserDefinedAttribute };
