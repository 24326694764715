import { OptionValueType, SelectOption } from 'shared/components/NewestSelect/shared/types';

const valueToLabel = (value: OptionValueType, options: SelectOption[]): string => {
  if (
    typeof (options.find(({ value: optionValue }) => optionValue === value) as SelectOption) !==
    'undefined'
  ) {
    return (options.find(({ value: optionValue }) => optionValue === value) as SelectOption).label;
  }
  return '';
};

export { valueToLabel };
