import { CellProps } from 'react-table';
import React from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DataRow } from 'shared/components/DataGrid/types';

import styles from './FactoryCellExpander.module.css';

const classes = { root: styles.container };

const FactoryCellExpander =
  <DataType extends DataRow>() =>
  ({ row }: CellProps<DataType>) => {
    if (!row.original.rows?.length) {
      return null;
    }

    return (
      <div className={styles.container} {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <ExpandLessIcon classes={classes} color="disabled" />
        ) : (
          <ExpandMoreIcon classes={classes} color="disabled" />
        )}
      </div>
    );
  };

export { FactoryCellExpander };
