import { RowsPerPageOptions } from 'shared/components/DataGrid/types';

export enum SupplierAssortmentPages {
  Item = 'Item',
  Supplier = 'Supplier',
}

export const headerButtonNames = Object.values(SupplierAssortmentPages);

export const rowsPerPageExtraOptions: RowsPerPageOptions = [5, 10, 25, 50, 100, 300];
