import { FC, Fragment, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { MAX_ALCOHOL_VOLUME } from 'shared/constants';
import { ReactComponent as AddIcon } from 'pages/UserDefinedAttribute/assets/plus-icon.svg';
import { DeleteIconButton } from 'shared/components/IconButton';

import { InputField } from './components/InputField';
import { AlcoholVolumeProps } from './types';
import styles from './AlcoholVolume.module.css';

const AlcoholVolume: FC<AlcoholVolumeProps> = ({
  getValues,
  hasPermissions,
  initialLength,
  onDelete,
  typeIndex,
}) => {
  const { control } = useFormContext();
  const { append, fields, remove } = useFieldArray({
    control,
    name: `alcoholTypes.${typeIndex}.volumes`,
    keyName: 'fieldId',
  });

  const onAddIconClick = useCallback(() => {
    append({ volume: '' });
  }, []);

  return (
    <div className={styles.container}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={styles.label} htmlFor={`alcoholTypes.${typeIndex}.volumes.0.volume`}>
        Alcohol ABV %
      </label>
      <div className={styles.alcoholVolume}>
        {fields.map((item, volumeIndex) => {
          const fieldVolume = getValues(`alcoholTypes.${typeIndex}.volumes.${volumeIndex}.volume`);
          const isDeleteButtonEnabled = fieldVolume !== MAX_ALCOHOL_VOLUME;

          return (
            <Fragment key={item.fieldId}>
              <InputField
                deleteField={() => {
                  remove(volumeIndex);
                }}
                isDisabled={initialLength > volumeIndex || !hasPermissions}
                name={`alcoholTypes.${typeIndex}.volumes.${volumeIndex}.volume`}
              />
              {isDeleteButtonEnabled && (
                <DeleteIconButton
                  className={styles.deleteButton}
                  disableRipple
                  onClick={() => {
                    const fieldId = getValues(
                      `alcoholTypes.${typeIndex}.volumes.${volumeIndex}.id`,
                    );

                    if (fieldId) {
                      onDelete({ fieldId, fieldIndex: volumeIndex, isAlcoholVolume: true });
                    } else {
                      remove(volumeIndex);
                    }
                  }}
                />
              )}
            </Fragment>
          );
        })}
        <IconButton
          className={styles.plusButton}
          disabled={!hasPermissions}
          onClick={onAddIconClick}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

export { AlcoholVolume };
