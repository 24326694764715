import { Chip } from '@mui/material';
import { FC } from 'react';

import { DealStatus } from 'shared/types';

import { DealStatusChipProps } from './types';

export const DealStatusChip: FC<DealStatusChipProps> = ({ status }) => {
  switch (status) {
    case DealStatus.Current:
      return <Chip color="success" label="Current" size="small" />;
    case DealStatus.Past:
      return <Chip color="info" label="Past" size="small" />;
    case DealStatus.Future:
      return <Chip color="secondary" label="Future" size="small" />;
    case DealStatus.Pending:
      return <Chip color="warning" label="Pending" size="small" />;
    case DealStatus.RescindPending:
      return <Chip color="warning" label="Rescind Pending" size="small" />;
    case DealStatus.Rejected:
      return <Chip color="error" label="Rejected" size="small" />;
    case DealStatus.Rescinded:
      return <Chip color="error" label="Rescinded" size="small" />;
    default:
      return <></>;
  }
};
