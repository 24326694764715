import { useMemo } from 'react';
import { formatISO, getUnixTime, isMonday, parseISO } from 'date-fns';

import {
  FactoryCellDatePicker,
  FactoryCellEmptyOn,
  FactoryCellInput,
  FactoryCellMutedHeader,
  FactoryCellSelect,
  FactoryCellSelectBinary,
  FactoryCellTimeConverter,
} from 'shared/components/DataGrid';
import { MappedRow, UseColumnsArgs, UseColumnsResult } from 'pages/SourceDestination/types';
import {
  isReadOnlyRole,
  mapToIdDefaultNull,
  mapToSelectOptions,
  mapValuesSeparatedWithComma,
} from 'shared/utils';
import { inputTypes } from 'shared/components/Input/constants';
import {
  ContainerCapacity,
  ContainerUom,
  LocationAutocompleteOptionsQuery,
  LocationAutocompleteOptionsQueryVariables,
  Opco,
  OpcosQuery,
  OpcosQueryVariables,
  SourceDestinationQueryVariables,
} from 'shared/types';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteMultiple,
  FilterSearchAutocompleteQuery,
  FilterSearchDatePicker,
  FilterSearchInput,
  FilterSearchSelectBinary,
} from 'shared/components/Search';
import { locationAutocompleteOptionsQuery, opcosQuery } from 'shared/operations/query';
import {
  availableHoursOptions,
  orderFrequencyOptions,
  transitionTypeOptions,
} from 'pages/SourceDestination/constants';
import { activeInactiveBinaryOptions } from 'shared/constants/options';
import { TooltipHeader } from 'shared/components/TooltipHeader';
import { config } from 'setup';
import { FEATURE_FLAG_SHOW_LEAD_TIME_OVERRIDE } from 'shared/constants';
import { getCurrentMonday } from 'shared/tools/date/getCurrentMonday';

import { FactoryCellAddLeadTimeOverride } from '../components/FactoryCellAddLeadTimeOverride';
import { hasSiblingRowWeekStartDateChanged } from '../utils/hasSiblingRowWeekStartDateChanged';
import { FactoryCellActions } from '../components/FactoryCellActions';

const CellTimeConverter = FactoryCellTimeConverter();

const useColumns = ({
  containerUomOptions,
  currenciesOptions,
  packageTypesOptions,
  role,
  systemOfMeasuresOptions,
  updateFilter,
  userOptions,
  setUnsubmittedRows,
  onOpenDeleteModalClick,
}: UseColumnsArgs): UseColumnsResult => {
  const isReadOnly = isReadOnlyRole(role);
  const isReadOnlyFunc = () => isReadOnly;

  const sourceNameFilter = (
    <FilterSearchAutocompleteQuery<OpcosQuery, OpcosQueryVariables, SourceDestinationQueryVariables>
      filterFieldName="sourceName"
      label="OpCo/Site Name"
      mapDataToOptions={result => mapToSelectOptions(result.opcos.opcos)}
      mapUserInputToAutocompleteQueryFilerParams={value => ({
        name: value,
      })}
      onSubmitFilter={updateFilter}
      query={opcosQuery}
    />
  );

  const sourceIdFilter = (
    <FilterSearchInput<SourceDestinationQueryVariables>
      filterFieldName="sourceIds"
      label="OpCo/Site ID"
      mapUserInputToFilerParams={value => ({
        sourceIds: mapValuesSeparatedWithComma(value),
      })}
      onSubmitFilter={updateFilter}
    />
  );

  const opcoStateProvinceRegion = (
    <FilterSearchInput<SourceDestinationQueryVariables>
      filterFieldName="opcoStateProvinceRegion"
      label="Opco State/Province/Region"
      mapUserInputToFilerParams={value => ({
        opcoStateProvinceRegion: value,
      })}
      onSubmitFilter={updateFilter}
    />
  );

  const supplierExtIdFilter = (
    <FilterSearchInput<SourceDestinationQueryVariables>
      filterFieldName="supplierExtIds"
      label="Supplier Number"
      mapUserInputToFilerParams={value => ({
        supplierExtIds: mapValuesSeparatedWithComma(value),
      })}
      onSubmitFilter={updateFilter}
      title="DC sources will use the SCDC Supplier Id"
    />
  );

  const locationNameFilter = (
    <FilterSearchAutocompleteQuery<
      LocationAutocompleteOptionsQuery,
      LocationAutocompleteOptionsQueryVariables,
      SourceDestinationQueryVariables
    >
      filterFieldName="destinationName"
      label="Location Name"
      mapDataToOptions={result => mapToSelectOptions(result.locations.locations)}
      mapUserInputToAutocompleteQueryFilerParams={value => ({
        name: value,
      })}
      onSubmitFilter={updateFilter}
      query={locationAutocompleteOptionsQuery}
    />
  );

  const locationIdFilter = (
    <FilterSearchInput<SourceDestinationQueryVariables>
      filterFieldName="destinationExtIds"
      label="Location ID"
      mapUserInputToFilerParams={value => ({
        destinationExtIds: mapValuesSeparatedWithComma(value),
      })}
      onSubmitFilter={updateFilter}
    />
  );

  const isActiveFilter = (
    <FilterSearchSelectBinary<SourceDestinationQueryVariables>
      filterFieldName="isActive"
      label="Is Active"
      onSubmitFilter={updateFilter}
    />
  );

  const containerMinFilter = (
    <FilterSearchInput<SourceDestinationQueryVariables>
      filterFieldName="containerMin"
      label="Container Min"
      onSubmitFilter={updateFilter}
    />
  );

  const containerMaxFilter = (
    <FilterSearchInput<SourceDestinationQueryVariables>
      filterFieldName="containerMax"
      label="Container Max"
      onSubmitFilter={updateFilter}
    />
  );

  const goPuffTranspFilter = (
    <FilterSearchSelectBinary<SourceDestinationQueryVariables>
      filterFieldName="gopuffTransport"
      label="Go Puff Transp."
      onSubmitFilter={updateFilter}
    />
  );

  const transitTypeFilter = (
    <FilterSearchAutocomplete<SourceDestinationQueryVariables>
      filterFieldName="transitType"
      label="Transit Type"
      onSubmitFilter={updateFilter}
      options={transitionTypeOptions}
    />
  );

  const delivWinFromFilter = (
    <FilterSearchAutocomplete<SourceDestinationQueryVariables>
      filterFieldName="deliveryWindowFrom"
      label="Deliv.Win.From"
      onSubmitFilter={updateFilter}
      options={availableHoursOptions}
    />
  );

  const delivWinToFilter = (
    <FilterSearchAutocomplete<SourceDestinationQueryVariables>
      filterFieldName="deliveryWindowTo"
      label="Deliv.Win.To"
      onSubmitFilter={updateFilter}
      options={availableHoursOptions}
    />
  );

  const creationDateFilter = (
    <FilterSearchDatePicker<SourceDestinationQueryVariables>
      filterFieldName="createdAt"
      label="Creation Date"
      onSubmitFilter={updateFilter}
    />
  );

  const inactiveSinceFilter = (
    <FilterSearchDatePicker<SourceDestinationQueryVariables>
      filterFieldName="notActiveSince"
      label="Inactive Since"
      onSubmitFilter={updateFilter}
    />
  );

  const fillKillThresholdFilter = (
    <FilterSearchInput<SourceDestinationQueryVariables>
      filterFieldName="fillKillThreshold"
      label={
        <TooltipHeader
          label="Fill/Kill Threshold"
          title="Days past due when orders get automatically closed"
        />
      }
      onSubmitFilter={updateFilter}
    />
  );

  const updatedAtFilter = (
    <FilterSearchDatePicker<SourceDestinationQueryVariables>
      filterFieldName="updatedAt"
      label="Updated At"
      onSubmitFilter={updateFilter}
    />
  );
  const updatedByFilter = (
    <FilterSearchAutocomplete<SourceDestinationQueryVariables>
      filterFieldName="updatedBy"
      label="Updated By"
      mapUserAutocompleteValueSelectToFilterParams={option => ({
        updatedBy: option!.value,
      })}
      onSubmitFilter={updateFilter}
      options={userOptions}
    />
  );

  const CellInputDay = FactoryCellInput({
    type: inputTypes.Number,
    isDisabled: ({ isActive, isLeadTimeOverrideRow, weekStartDate }: MappedRow) => {
      if (isLeadTimeOverrideRow) {
        return !weekStartDate || isReadOnly;
      }
      return !isActive || isReadOnly;
    },
    isHidden: ({ isAddLeadTimeOverrideRow }: MappedRow) => !!isAddLeadTimeOverrideRow,
    dependencies: ['isActive', 'weekStartDate', 'isLeadTimeOverrideRow'],
    showSuccessBorder: true,
  });

  const CellDeliveryHours = FactoryCellSelect<MappedRow, string, string[]>({
    options: availableHoursOptions,
    isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
    dependencies: ['isActive'],
    isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
  });

  const CellWeekStartDate = FactoryCellDatePicker<MappedRow, string | null>({
    disableKeyboardInput: true,
    shouldDisableDate: (day: Date) => !isMonday(day),
    deriveDisabledDates: ({ id, parent }) => {
      const existingWeekStartDates = parent?.rows
        ?.filter(row => row.id !== id && row.weekStartDate)
        .map(row => getUnixTime(parseISO(row.weekStartDate!)));

      return existingWeekStartDates ?? [];
    },
    deriveHasDependenciesChanged: hasSiblingRowWeekStartDateChanged,
    deriveDateRange: () => ({ minDate: getCurrentMonday(), maxDate: undefined }),
    formatDateToCellValue: value => (value ? formatISO(value, { representation: 'date' }) : value),
    formatCellValueToDate: weekStartDate =>
      weekStartDate ? parseISO(weekStartDate.toString()) : null,
    isDisabled: ({ isCurrentWeek }: MappedRow) => !!isCurrentWeek,
  });

  const CellAddLeadTimeOverride = FactoryCellAddLeadTimeOverride(setUnsubmittedRows, []);

  const CellWeekStartDateOrAddLeadTimeOverride = FactoryCellEmptyOn<MappedRow>(
    CellAddLeadTimeOverride,
    (_, { isAddLeadTimeOverrideRow }) => !!isAddLeadTimeOverrideRow,
    CellWeekStartDate,
    true,
    [],
    hasSiblingRowWeekStartDateChanged,
  );

  const CellActions = FactoryCellActions({
    onOpenDeleteModalClick,
  });

  const CellAutoOrderPercent = FactoryCellInput({
    type: inputTypes.Float,
    isDisabled: isReadOnlyFunc,
    min: 0,
    max: 1,
  });

  const CellAutoOrderValue = FactoryCellInput({
    type: inputTypes.Float,
    isDisabled: isReadOnlyFunc,
    min: 0,
    max: 100_000,
  });

  const CellAutoOrderQuantity = FactoryCellInput({
    type: inputTypes.Number,
    isDisabled: isReadOnlyFunc,
    min: 0,
    max: 10_000,
  });

  return useMemo(() => {
    if (!containerUomOptions.length) {
      return [];
    }

    const containerUom = (
      <FilterSearchAutocomplete<SourceDestinationQueryVariables>
        filterFieldName="containerUom"
        label="Container UOM"
        onSubmitFilter={updateFilter}
        options={containerUomOptions}
      />
    );

    const orderFrequencyFilter = (
      <FilterSearchAutocompleteMultiple<SourceDestinationQueryVariables>
        filterFieldName="orderFrequency"
        label="Order Frequency"
        onSubmitFilter={updateFilter}
        options={orderFrequencyOptions}
      />
    );

    const minimumOrderValueFilter = (
      <FilterSearchInput<SourceDestinationQueryVariables>
        filterFieldName="minimumOrderValue"
        label={<TooltipHeader label="Minimum Order Value" title="MOV" />}
        onSubmitFilter={updateFilter}
      />
    );

    const movCurrencyCodesFilter = (
      <FilterSearchAutocompleteMultiple<SourceDestinationQueryVariables>
        filterFieldName="movCurrencyIds"
        label="MOV Currency"
        onSubmitFilter={updateFilter}
        options={currenciesOptions}
      />
    );

    const minimumOrderQuantityFilter = (
      <FilterSearchInput<SourceDestinationQueryVariables>
        filterFieldName="minimumOrderQuantity"
        label={<TooltipHeader label="Minimum Order Quantity" title="MOQ" />}
        onSubmitFilter={updateFilter}
      />
    );

    const moqOrderingUomNamesFilter = (
      <FilterSearchAutocompleteMultiple<SourceDestinationQueryVariables>
        filterFieldName="moqOrderingUomIds"
        label="MOQ Ordering UOM"
        onSubmitFilter={updateFilter}
        options={packageTypesOptions}
      />
    );

    const minimumOrderWeightFilter = (
      <FilterSearchInput<SourceDestinationQueryVariables>
        filterFieldName="minimumOrderWeight"
        label={<TooltipHeader label="Minimum Order Weight" title="MOW" />}
        onSubmitFilter={updateFilter}
      />
    );

    const mowSystemOfMeasureNamesFilter = (
      <FilterSearchAutocompleteMultiple<SourceDestinationQueryVariables>
        filterFieldName="mowSystemOfMeasureIds"
        label="MOW System of Measure"
        onSubmitFilter={updateFilter}
        options={systemOfMeasuresOptions}
      />
    );

    const result = [
      {
        Header: () => sourceNameFilter,
        accessor: 'source.name',
        width: 224,
      },
      {
        Header: () => sourceIdFilter,
        accessor: 'source.id',
        width: 110,
      },
      {
        id: 'opcoStateProvinceRegion',
        Header: () => opcoStateProvinceRegion,
        accessor: ({ source }) => (source as Opco)?.stateProvinceRegion,
        width: 200,
      },
      {
        id: 'supplierExtId',
        Header: () => supplierExtIdFilter,
        accessor: ({ source }) => (source as Opco)?.supplier?.extID,
        width: 140,
      },
      {
        Header: () => locationNameFilter,
        accessor: 'location.name',
      },
      {
        Header: () => locationIdFilter,
        accessor: 'location.extId',
      },
      {
        Header: () => isActiveFilter,
        accessor: 'isActive',
        Cell: FactoryCellSelectBinary({
          optionsLabel: activeInactiveBinaryOptions,
          isDisabled: isReadOnlyFunc,
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => fillKillThresholdFilter,
        accessor: 'fillKillThreshold',
        Cell: FactoryCellInput({
          type: inputTypes.Number,
          isDisabled: isReadOnlyFunc,
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
        width: 135,
      },
      {
        id: 'days',
        Header: FactoryCellMutedHeader({
          mainText: 'Lead Time',
          mutedText: '(*Green - ordering day, count - in calendar days)',
        }),
        columns: [
          {
            Header: 'Override Week Start',
            accessor: 'weekStartDate',
            SubCell: CellWeekStartDateOrAddLeadTimeOverride,
            width: 200,
          },
          {
            Header: 'Monday',
            accessor: 'monLeadTime',
            Cell: CellInputDay,
            width: 90,
          },
          {
            Header: 'Tuesday',
            accessor: 'tueLeadTime',
            Cell: CellInputDay,
            width: 90,
          },
          {
            Header: 'Wednesday',
            accessor: 'wedLeadTime',
            Cell: CellInputDay,
            width: 90,
          },
          {
            Header: 'Thursday',
            accessor: 'thuLeadTime',
            Cell: CellInputDay,
            width: 90,
          },
          {
            Header: 'Friday',
            accessor: 'friLeadTime',
            Cell: CellInputDay,
            width: 90,
          },
          {
            Header: 'Saturday',
            accessor: 'satLeadTime',
            Cell: CellInputDay,
            width: 90,
          },
          {
            Header: 'Sunday',
            accessor: 'sunLeadTime',
            Cell: CellInputDay,
            width: 90,
          },
        ],
      },
      { id: 'actions', Header: 'Actions', accessor: 'id', Cell: CellActions },
      {
        Header: 'Auto Release',
        accessor: 'autoRelease',
        Cell: FactoryCellSelectBinary({
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        id: 'autoOrderColumns',
        Header: FactoryCellMutedHeader({
          mainText: 'Auto Ordering',
          mutedText: '',
        }),
        columns: [
          {
            Header: 'Auto Order',
            accessor: 'autoOrder',
            Cell: FactoryCellSelectBinary(),
          },
          {
            Header: 'Value Overage %',
            accessor: 'valueOveragePercent',
            Cell: CellAutoOrderPercent,
          },
          {
            Header: 'Value Overage $',
            accessor: 'valueOverageValue',
            Cell: CellAutoOrderValue,
          },
          {
            Header: 'Value Underage %',
            accessor: 'valueUnderagePercent',
            Cell: CellAutoOrderPercent,
          },
          {
            Header: 'Value Underage $',
            accessor: 'valueUnderageValue',
            Cell: CellAutoOrderValue,
          },
          {
            Header: 'Qty Overage %',
            accessor: 'quantityOveragePercent',
            Cell: CellAutoOrderPercent,
          },
          {
            Header: 'Qty Overage Count',
            accessor: 'quantityOverageCount',
            Cell: CellAutoOrderQuantity,
          },
          {
            Header: 'Qty Underage %',
            accessor: 'quantityUnderagePercent',
            Cell: CellAutoOrderPercent,
          },
          {
            Header: 'Qty Underage Count',
            accessor: 'quantityUnderageCount',
            Cell: CellAutoOrderQuantity,
          },
        ],
      },
      {
        Header: () => orderFrequencyFilter,
        accessor: 'orderFrequency',
        Cell: FactoryCellSelect<MappedRow, string>({
          options: orderFrequencyOptions,
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => containerMinFilter,
        id: 'containerMin',
        accessor: (row: MappedRow) => row.capacities,
        Cell: FactoryCellInput<MappedRow, ContainerCapacity[]>({
          type: inputTypes.Number,
          pickValue: capacities => capacities[0]?.containerMin,
          pickValueFromRowState: (state, capacities) =>
            capacities.find(({ containerUom: { id } }) => id === state.containerUom)
              ?.containerMin as number,
          isDisabled: ({ isActive: isActiveColumn }: MappedRow) => !isActiveColumn || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => containerMaxFilter,
        id: 'containerMax',
        accessor: (row: MappedRow) => row.capacities,
        Cell: FactoryCellInput<MappedRow, ContainerCapacity[]>({
          type: inputTypes.Number,
          pickValue: capacities => capacities[0]?.containerMax,
          pickValueFromRowState: (state, capacities) =>
            capacities.find(({ containerUom: { id } }) => id === state.containerUom)
              ?.containerMax as number,
          isDisabled: ({ isActive: isActiveColumn }: MappedRow) => !isActiveColumn || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => containerUom,
        accessor: (row: MappedRow) => row.capacities?.[0]?.containerUom || '1',
        id: 'containerUom',
        Cell: FactoryCellSelect<MappedRow, string, ContainerUom>({
          options: containerUomOptions,
          pickValue: mapToIdDefaultNull,
          isDisabled: ({ isActive: isActiveColumn }: MappedRow) => !isActiveColumn || isReadOnly,
          dependencies: ['isActive'],
          setValueToRowState: true,
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => goPuffTranspFilter,
        accessor: 'gopuffTransport',
        Cell: FactoryCellSelectBinary({
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => transitTypeFilter,
        accessor: 'transitType',
        Cell: FactoryCellSelect<MappedRow, string, string[]>({
          options: transitionTypeOptions,
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => delivWinFromFilter,
        accessor: 'deliveryWindowFrom',
        Cell: CellDeliveryHours,
      },
      {
        Header: () => delivWinToFilter,
        accessor: 'deliveryWindowTo',
        Cell: CellDeliveryHours,
      },
      {
        Header: () => minimumOrderValueFilter,
        accessor: 'minimumOrderValue',
        Cell: FactoryCellInput({
          type: inputTypes.Money,
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => movCurrencyCodesFilter,
        id: 'movCurrencyId',
        accessor: (row: MappedRow) =>
          row.movCurrencyId ? row.movCurrencyId.toString() : undefined,
        Cell: FactoryCellSelect<MappedRow, string, string[]>({
          options: currenciesOptions,
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => minimumOrderQuantityFilter,
        accessor: 'minimumOrderQuantity',
        Cell: FactoryCellInput({
          type: inputTypes.Number,
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => moqOrderingUomNamesFilter,
        id: 'moqOrderingUomId',
        accessor: (row: MappedRow) =>
          row.moqOrderingUomId ? row.moqOrderingUomId.toString() : undefined,
        Cell: FactoryCellSelect<MappedRow, string, string[]>({
          options: packageTypesOptions,
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => minimumOrderWeightFilter,
        accessor: 'minimumOrderWeight',
        Cell: FactoryCellInput({
          type: inputTypes.Float,
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => mowSystemOfMeasureNamesFilter,
        id: 'mowSystemOfMeasureId',
        accessor: (row: MappedRow) =>
          row.mowSystemOfMeasureId ? row.mowSystemOfMeasureId.toString() : undefined,
        Cell: FactoryCellSelect<MappedRow, string, string[]>({
          options: systemOfMeasuresOptions,
          isDisabled: ({ isActive }: MappedRow) => !isActive || isReadOnly,
          dependencies: ['isActive'],
          isHidden: ({ isLeadTimeOverrideRow }: MappedRow) => !!isLeadTimeOverrideRow,
        }),
      },
      {
        Header: () => creationDateFilter,
        accessor: 'createdAt',
        Cell: CellTimeConverter,
        width: 165,
      },
      {
        Header: () => inactiveSinceFilter,
        accessor: 'notActiveSince',
        Cell: CellTimeConverter,
        width: 165,
      },
      {
        Header: () => updatedByFilter,
        id: 'updatedBy',
        accessor: (row: MappedRow) => {
          if (row.isLeadTimeOverrideRow) return '';
          const user = userOptions ? userOptions.filter(u => u.value.includes(row.updatedBy!)) : [];
          return row.updatedBy && user.length ? user[0].label : row.updatedBy || '-';
        },
      },
      {
        Header: () => updatedAtFilter,
        accessor: 'updatedAt',
        Cell: CellTimeConverter,
        width: 170,
      },
    ] as UseColumnsResult;

    if (!config.featureFlags[FEATURE_FLAG_SHOW_LEAD_TIME_OVERRIDE]) {
      result.forEach(column => {
        if (column.id === 'days') {
          column?.columns?.shift();
        }
      });
    }
    return result;
  }, [
    containerUomOptions,
    userOptions,
    currenciesOptions,
    packageTypesOptions,
    systemOfMeasuresOptions,
  ]);
};

export { useColumns };
