import { useMemo } from 'react';

import {
  MIXED_IS_ACTIVE_OPTION_VALUE,
  MIXED_SEND_TO_TRAVERSE_OPTION_VALUE,
} from 'pages/Suppliers/constants';
import { MappedRow, UseColumnsArgs, UseColumnsResult } from 'pages/Suppliers/types';
import {
  CellIdentity,
  EmptyCell,
  FactoryCellNewestSelect,
  FactoryCellSelectBinary,
} from 'shared/components/DataGrid';
import {
  FilterSearchAutocomplete,
  FilterSearchInput,
  FilterSearchSelectBinary,
} from 'shared/components/Search';
import { deriveOptions } from 'shared/components/Select/SelectBinary';
import { activeInactiveBinaryOptions } from 'shared/constants/options';
import { SuppliersQueryVariables } from 'shared/types';
import { isReadOnlyRole, mapValuesSeparatedWithComma } from 'shared/utils';

const useColumns = ({
  onBuyersEntryAdded,
  opcoBuyerOptions,
  opcoPackageTypeOptions,
  role,
  supplierPackageTypeOptions,
  updateFilter,
}: UseColumnsArgs): UseColumnsResult =>
  useMemo(() => {
    const isReadOnly = isReadOnlyRole(role);
    const isReadOnlyFunc = () => isReadOnly;

    const supplierIdsFilter = (
      <FilterSearchInput<SuppliersQueryVariables>
        filterFieldName="extIds"
        label="Supplier Number"
        mapUserInputToFilerParams={value => ({
          extIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
        title="DC sources will use the SCDC Supplier Id"
      />
    );
    const opcoIdsFilter = (
      <FilterSearchInput<SuppliersQueryVariables>
        filterFieldName="opcoIds"
        label="OpCo ID"
        mapUserInputToFilerParams={value => ({
          opcoIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );
    const defaultUomFilter = (
      <FilterSearchAutocomplete<SuppliersQueryVariables>
        filterFieldName="opcoPkgTypes"
        label="Default UOM"
        mapUserAutocompleteValueSelectToFilterParams={value => ({
          opcoPkgTypes: [value!.value],
        })}
        onSubmitFilter={updateFilter}
        options={opcoPackageTypeOptions}
      />
    );
    const isActiveFilter = (
      <FilterSearchSelectBinary<SuppliersQueryVariables>
        filterFieldName="isActive"
        label="Is Active"
        onSubmitFilter={updateFilter}
        optionsLabel={activeInactiveBinaryOptions}
      />
    );
    const stateFilter = (
      <FilterSearchInput<SuppliersQueryVariables>
        filterFieldName="opcoState"
        label="State"
        onSubmitFilter={updateFilter}
      />
    );
    const cityFilter = (
      <FilterSearchInput<SuppliersQueryVariables>
        filterFieldName="opcoCity"
        label="City"
        onSubmitFilter={updateFilter}
      />
    );
    const zipFilter = (
      <FilterSearchInput<SuppliersQueryVariables>
        filterFieldName="opcoPostalCode"
        label="ZIP"
        onSubmitFilter={updateFilter}
      />
    );
    const countryFilter = (
      <FilterSearchInput<SuppliersQueryVariables>
        filterFieldName="opcoCountry"
        label="Country"
        onSubmitFilter={updateFilter}
      />
    );
    const addressFilter = (
      <FilterSearchInput<SuppliersQueryVariables>
        filterFieldName="opcoAddress"
        label="Address"
        onSubmitFilter={updateFilter}
      />
    );
    const nameFilter = (
      <FilterSearchInput<SuppliersQueryVariables>
        filterFieldName="name"
        label="Name (Supplier/OpCo)"
        onSubmitFilter={updateFilter}
      />
    );
    const sendToTraverseFilter = (
      <FilterSearchSelectBinary<SuppliersQueryVariables>
        filterFieldName="opcoSendToTraverse"
        label="Send to Traverse"
        onSubmitFilter={updateFilter}
      />
    );
    const buyerFilter = (
      <FilterSearchAutocomplete<SuppliersQueryVariables>
        filterFieldName="opcoBuyer"
        label="Buyer"
        onSubmitFilter={updateFilter}
        options={opcoBuyerOptions}
      />
    );

    return [
      {
        Header: nameFilter,
        accessor: 'name',
      },
      {
        Header: defaultUomFilter,
        accessor: 'packageTypeId',
        Cell: FactoryCellNewestSelect<MappedRow>({
          options: supplierPackageTypeOptions,
          isDisabled: isReadOnlyFunc,
          showSearch: false,
          resetValueAfterUpdate: true,
        }),
        SubCell: FactoryCellNewestSelect<MappedRow>({
          options: opcoPackageTypeOptions,
          isDisabled: isReadOnlyFunc,
          showSearch: false,
          resetValueAfterUpdate: true,
        }),
      },
      {
        Header: isActiveFilter,
        accessor: 'isActive',
        Cell: FactoryCellNewestSelect<MappedRow, number>({
          options: [
            ...deriveOptions(activeInactiveBinaryOptions),
            { label: 'Mixed', value: MIXED_IS_ACTIVE_OPTION_VALUE, disabled: true },
          ],
          isDisabled: isReadOnlyFunc,
          showSearch: false,
          resetValueAfterUpdate: true,
        }),
        SubCell: FactoryCellSelectBinary({
          optionsLabel: activeInactiveBinaryOptions,
          isDisabled: isReadOnlyFunc,
          resetValueAfterUpdate: true,
        }),
      },
      {
        Header: sendToTraverseFilter,
        accessor: 'sendToTraverse',
        Cell: FactoryCellNewestSelect<MappedRow, number>({
          options: [
            ...deriveOptions(),
            { label: 'Mixed', value: MIXED_SEND_TO_TRAVERSE_OPTION_VALUE, disabled: true },
          ],
          isDisabled: isReadOnlyFunc,
          showSearch: false,
          resetValueAfterUpdate: true,
        }),
        SubCell: FactoryCellSelectBinary({
          isDisabled: isReadOnlyFunc,
          resetValueAfterUpdate: true,
        }),
      },
      {
        Header: supplierIdsFilter,
        accessor: 'supplierId',
        SubCell: EmptyCell,
      },
      {
        Header: opcoIdsFilter,
        accessor: 'opcoId',
        Cell: EmptyCell,
        SubCell: CellIdentity,
      },
      {
        Header: addressFilter,
        accessor: 'address',
        Cell: EmptyCell,
        SubCell: CellIdentity,
      },
      {
        Header: cityFilter,
        accessor: 'city',
        Cell: EmptyCell,
        SubCell: CellIdentity,
      },
      {
        Header: stateFilter,
        accessor: 'state',
        Cell: EmptyCell,
        SubCell: CellIdentity,
      },
      {
        Header: zipFilter,
        accessor: 'zip',
        Cell: EmptyCell,
        SubCell: CellIdentity,
      },
      {
        Header: countryFilter,
        accessor: 'country',
        Cell: EmptyCell,
        SubCell: CellIdentity,
      },
      {
        Header: () => buyerFilter,
        accessor: 'buyer',
        Cell: EmptyCell,
        SubCell: FactoryCellNewestSelect<MappedRow, string>({
          options: opcoBuyerOptions,
          pickValue: value => value,
          isDisabled: isReadOnlyFunc,
          showSearch: true,
          allowEntryInSearch: true,
          resetValueAfterUpdate: true,
          onEntryAdded: onBuyersEntryAdded,
          searchPlaceholder: 'Enter Email',
        }),
      },
    ];
  }, [opcoPackageTypeOptions, supplierPackageTypeOptions, opcoBuyerOptions]);

export { useColumns };
