import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';

import {
  FormValues,
  filterMapDataToMutationItems as baseFilterMapDataToMutationItems,
} from 'pages/UserDefinedAttribute/components/DefaultForm';

import { FormItem } from '../types';

const filterMapDataToMutationItems = (
  formData: UnpackNestedValue<FormValues<FormItem>>,
  dirtyFields: FieldNamesMarkedBoolean<FormValues<FormItem>>,
  fieldName: string,
): FormItem[] =>
  // again, seems to be the same issue with react-hook-form types
  // @ts-ignore
  baseFilterMapDataToMutationItems(formData, dirtyFields, fieldName).map(
    ({ description, name, ...props }) => ({
      ...props,
      restrictionCode: name,
      name: description,
    }),
  );

export { filterMapDataToMutationItems };
