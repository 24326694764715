import { FC, useEffect, useMemo, useState } from 'react';
import { isNil } from 'ramda';
import { Controller, ControllerProps } from 'react-hook-form';

import { TextField } from 'shared/components/TextField';
import { inputTypes } from 'shared/components/Input/constants';
import { SelectField } from 'shared/components/SelectField';
import { PackagingTypeError } from 'pages/DestinationAssortment/components/PackagingTypeError';
import { AssortmentOverrideState } from 'shared/types';
import { assortmentOverrideStateOptions } from 'shared/utils/assortmentOverrideStateOptions';

import { FormValues, MassEditFormRowProps } from './types';

const MassEditFormRow: FC<MassEditFormRowProps> = ({
  availablePackagingType,
  control,
  currenciesOptions,
  isCostingOn,
  packagingIdOptions,
  velocityCategoryOptions,
  watch,
}) => {
  const packagingTypeValue = watch('packagingID');
  const nationalCostCurrencyValue = watch('nationalCostCurrency');
  const localCostCurrencyValue = watch('localCostCurrency');

  const [showError, setShowError] = useState<string | boolean>(false);

  useEffect(() => {
    const isShowError = packagingIdOptions.find(
      item => item.value === packagingTypeValue && availablePackagingType.includes(item.label),
    );
    const error = !isShowError && packagingTypeValue;
    setShowError(error);
  }, [packagingTypeValue]);

  const renderNationalCost = useMemo<
    ControllerProps<FormValues, FormValues['nationalCost']>['render']
  >(
    () =>
      ({ field: { onBlur, onChange: handleChange, value = '' } }) =>
        (
          <TextField
            disabled={isNil(nationalCostCurrencyValue)}
            label="National Cost"
            name="nationalCost"
            onBlur={onBlur}
            onChange={handleChange}
            size="full"
            type={inputTypes.Float}
            value={value}
          />
        ),
    [nationalCostCurrencyValue],
  );

  const renderLocalCost = useMemo<ControllerProps<FormValues, FormValues['localCost']>['render']>(
    () =>
      ({ field: { onBlur, onChange: handleChange, value = '' } }) =>
        (
          <TextField
            disabled={isNil(localCostCurrencyValue)}
            label="Local Cost"
            name="localCost"
            onBlur={onBlur}
            onChange={handleChange}
            size="full"
            type={inputTypes.Float}
            value={value}
          />
        ),
    [localCostCurrencyValue],
  );

  const {
    renderAssortmentOverrideState,
    renderLocalCostCurrency,
    renderNationalCostCurrency,
    renderPackaging,
    renderVelocityCategory,
  } = useMemo<{
    renderAssortmentOverrideState: ControllerProps<
      FormValues,
      FormValues['assortmentOverrideState']
    >['render'];
    renderPackaging: ControllerProps<FormValues, FormValues['packagingID']>['render'];
    renderNationalCostCurrency: ControllerProps<
      FormValues,
      FormValues['nationalCostCurrency']
    >['render'];
    renderLocalCostCurrency: ControllerProps<FormValues, FormValues['localCostCurrency']>['render'];
    renderVelocityCategory: ControllerProps<FormValues, FormValues['velocityCategory']>['render'];
  }>(
    () => ({
      renderAssortmentOverrideState: ({
        field: { onBlur, onChange: handleChange, value = AssortmentOverrideState.Active },
      }) => (
        <SelectField
          label="Assor. Override"
          name="assortmentOverrideState"
          onBlur={onBlur}
          onChange={handleChange}
          options={assortmentOverrideStateOptions}
          placeholder="Select"
          value={value}
        />
      ),
      renderPackaging: ({ field: { onBlur, onChange: handleChange, value = '1' } }) => (
        <SelectField
          label="Packaging Type"
          name="packagingID"
          onBlur={onBlur}
          onChange={handleChange}
          options={packagingIdOptions}
          placeholder="Select"
          value={value}
        />
      ),
      renderNationalCostCurrency: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          label="Currency"
          name="nationalCostCurrency"
          onBlur={onBlur}
          onChange={handleChange}
          options={currenciesOptions}
          placeholder="Select"
          value={value}
        />
      ),
      renderLocalCostCurrency: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          label="Currency"
          name="localCostCurrency"
          onBlur={onBlur}
          onChange={handleChange}
          options={currenciesOptions}
          placeholder="Select"
          value={value}
        />
      ),
      renderVelocityCategory: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          label="Velocity Cat."
          name="velocityCategory"
          onBlur={onBlur}
          onChange={handleChange}
          options={velocityCategoryOptions}
          placeholder="Select"
          value={value}
        />
      ),
    }),
    [control],
  );

  return (
    <>
      <Controller
        control={control}
        name="assortmentOverrideState"
        render={renderAssortmentOverrideState}
      />
      <Controller control={control} name="packagingID" render={renderPackaging} />
      {showError && <PackagingTypeError />}
      {!isCostingOn && (
        <>
          <Controller control={control} name="nationalCost" render={renderNationalCost} />
          <Controller
            control={control}
            name="nationalCostCurrency"
            render={renderNationalCostCurrency}
          />
          <Controller control={control} name="localCost" render={renderLocalCost} />
          <Controller control={control} name="localCostCurrency" render={renderLocalCostCurrency} />
        </>
      )}
      <Controller control={control} name="velocityCategory" render={renderVelocityCategory} />
    </>
  );
};

export { MassEditFormRow };
