import { FC } from 'react';

import { DataGrid } from 'shared/components/DataGrid';
import {
  ACTION_MASS_EDIT_TRANSPORTATION_LANE,
  ACTION_UPLOAD_FILE_TRANSPORTATION_LANE,
} from 'shared/constants';
import { DataGridPage } from 'shared/components/DataGridPage';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';

import { Row, TransportationLaneViewProps } from './types';
import { headerButtonNames, rowsPerPageExtraOptions } from './constants';
import { deriveRowKey, highlightRow } from './utils';

const TransportationLaneView: FC<TransportationLaneViewProps> = ({
  activePageIndex,
  onDataGridDownloadButtonClick,
  onEditClick,
  onTogglePage,
  selectedRowIds,
  selectedRows,
  triggerUncheckAllCheckboxes,
  uploadFileProps,
  userPermissions,
  ...props
}) => (
  <DataGridPage<Row, true>
    header={
      <HeaderDataGridActions
        activePageIndex={activePageIndex}
        hasAccessToUploadFile={userPermissions.has(ACTION_UPLOAD_FILE_TRANSPORTATION_LANE)}
        headerButtonNames={headerButtonNames}
        onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
        onTogglePage={onTogglePage}
        title="Transportation Lane"
        uploadFileProps={uploadFileProps}
      />
    }
    isMassEditOn={userPermissions.has(ACTION_MASS_EDIT_TRANSPORTATION_LANE)}
    onEditClick={onEditClick}
    selectedRows={selectedRows}
    triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
  >
    <DataGrid
      {...props}
      deriveRowKey={deriveRowKey}
      enableRowSelection
      highlightRow={highlightRow}
      rowsPerPageOptions={rowsPerPageExtraOptions}
      selectedRowIds={selectedRowIds}
    />
  </DataGridPage>
);
export { TransportationLaneView };
