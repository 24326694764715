import { Row } from 'pages/DealManagementDetail/types';
import { DealLineScanDealInput, UpdateDealLineScanDealMutationVariables } from 'shared/types';

const mapRowToMutationArgs = (
  row: Row,
  updatedChunk: Partial<DealLineScanDealInput>,
): UpdateDealLineScanDealMutationVariables => {
  const { gopuffDollarFundingPerUnit, gopuffPercentageFundingPerUnit, ...chunk } = updatedChunk;
  return {
    input: {
      id: row.id,
      gopuffDollarFundingPerUnit:
        (gopuffDollarFundingPerUnit as any) === ''
          ? undefined
          : gopuffDollarFundingPerUnit || row.gopuffDollarFundingPerUnit || undefined,
      gopuffPercentageFundingPerUnit:
        (gopuffPercentageFundingPerUnit as any) === ''
          ? undefined
          : gopuffPercentageFundingPerUnit || row.gopuffPercentageFundingPerUnit || undefined,
      ...chunk,
    },
  };
};

export { mapRowToMutationArgs };
