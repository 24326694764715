import _ from 'lodash';

import { config as appConfig } from 'setup/config';

const { auth0 } = appConfig;

const config = _.merge(auth0, {
  authorizationParams: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    redirect_uri: window.location.origin,
    scopes: ['openid', 'profile', 'email', 'roles', 'permissions'],
  },
});

export { config };
