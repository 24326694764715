import { memo, useCallback, useMemo, useRef } from 'react';
import Paper, { PaperClasses } from '@mui/material/Paper';

import { ConditionalWrapper } from 'shared/components/ConditionalWrapper';
import { TOGGLE_ICON_DATA_ATTRIBUTE } from 'shared/components/NewestSelect/shared/constants';
import { List } from 'shared/components/List';

import { SelectButton } from './components/SelectButton';
import { Popper } from './components/Popper';
import { Search } from './components/Search';
import { ComboboxContainer } from './components/ComboboxContainer';
import { SelectViewProps } from './types';
import styles from './SelectView.module.css';

const paperClasses: Partial<PaperClasses> = { root: styles.paper };

const SelectView = memo(
  <Item,>({
    buttonContent,
    children,
    closeMenu,
    disabled,
    getComboboxProps,
    getDropdownProps,
    getInputProps,
    getMenuProps,
    getToggleButtonProps,
    open = false,
    searchPlaceholder,
  }: SelectViewProps<Item>) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const { id: menuId, ...menuProps } = useMemo(() => {
      const { onBlur, onMouseLeave, ...props } = getMenuProps(undefined, {
        suppressRefError: true,
      }) as {
        id: string;
        ref: any;
        onBlur: Function;
        onMouseLeave: Function;
      };
      return props;
    }, [getMenuProps]);

    const toggleIconDataAttribute = `${TOGGLE_ICON_DATA_ATTRIBUTE}-${menuId}`;

    const onBlur = useCallback(
      ({ target }: MouseEvent | TouchEvent) => {
        if (
          !buttonRef.current!.contains(target as Node) &&
          // parentNode is null for icons
          !(
            (target as SVGSVGElement).hasAttribute(toggleIconDataAttribute) ||
            (target as SVGSVGElement)?.parentElement?.hasAttribute(toggleIconDataAttribute)
          )
        ) {
          closeMenu();
        }
      },
      [toggleIconDataAttribute],
    );

    return (
      <ConditionalWrapper
        condition={!!getComboboxProps}
        Wrapper={ComboboxContainer}
        {...getComboboxProps?.()}
      >
        <SelectButton
          ariaDescribedby={menuId}
          disabled={disabled}
          open={open}
          toggleIconDataAttribute={toggleIconDataAttribute}
          {...getToggleButtonProps({ disabled, ref: buttonRef })}
        >
          {buttonContent}
        </SelectButton>
        <Popper anchorEl={buttonRef.current} onBlur={open ? onBlur : undefined} open={open}>
          <Paper classes={paperClasses} elevation={3} square={false}>
            {getInputProps && (
              <Search
                placeholder={searchPlaceholder}
                {...getInputProps(getDropdownProps?.({ preventKeyAction: open }), {
                  suppressRefError: true,
                })}
              />
            )}
            <List {...menuProps} id={menuId}>
              {children}
            </List>
          </Paper>
        </Popper>
      </ConditionalWrapper>
    );
  },
);

export { SelectView };
