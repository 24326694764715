import { FC } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PopperBase from '@mui/material/Popper';

import { noop } from 'shared/utils';

import { PopperProps } from './types';
import { modifiers, styles } from './constants';

const Popper: FC<PopperProps> = ({ anchorEl, onBlur = noop, open, children, id }) => (
  <ClickAwayListener onClickAway={onBlur}>
    <PopperBase
      anchorEl={anchorEl}
      disablePortal
      id={id}
      modifiers={modifiers}
      open={open}
      placement="bottom-start"
      style={styles}
    >
      {children}
    </PopperBase>
  </ClickAwayListener>
);
export { Popper };
