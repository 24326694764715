import classNames from 'classnames';

import { SelectBinary } from 'shared/components/Select';
import { FieldError } from 'shared/components/FieldError';
import { FieldLabel } from 'shared/components/FieldLabel';

import styles from './SelectField.module.css';
import { SelectBinaryFieldProps } from './types';

const SelectBinaryField = ({
  className,
  displayBlockError = true,
  error,
  label,
  name,
  ...props
}: SelectBinaryFieldProps) => (
  <div className={classNames(styles.container, className)}>
    <FieldLabel name={name}>{label}</FieldLabel>
    <SelectBinary {...props} aria-label={label as string} name={name} />
    {error && <FieldError block={displayBlockError} message={error} />}
  </div>
);

export { SelectBinaryField };
