import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { ContainerUom } from 'shared/types';

const makeContainerUom = (props: O.Partial<ContainerUom, 'deep'> = {}): ContainerUom =>
  merge<ContainerUom, O.Partial<ContainerUom, 'deep'>>(
    {
      id: '1',
      name: 'Cube',
      __typename: 'ContainerUom',
    },
    props,
  );

export { makeContainerUom };
