import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { User } from 'shared/types';

import { replicateN } from '../utils';
import { makeUsers } from '../factories/makeUsers';

const users = (props: O.Partial<User, 'deep'>[] = []): User[] =>
  mergeDeepArrays<User>(
    replicateN(makeUsers(), 5, {
      displayName: [, 'John Smit', 'John Do', 'Tom Smit', 'Some One'],
    }),
    props,
  );

export { users };
