import { memo } from 'react';
import classNames from 'classnames';

import { Input } from 'shared/components/Input';
import { FieldError } from 'shared/components/FieldError';
import { FieldLabel } from 'shared/components/FieldLabel';

import { TextFieldProps } from './types';
import styles from './TextField.module.css';

const TextField = memo(
  ({
    className,
    disabled = false,
    displayBlockError,
    error,
    label,
    name,
    placeholder,
    showSuccessBorder,
    size = 'standard',
    ...props
  }: TextFieldProps): JSX.Element => (
    <div className={classNames(styles.inputWrapper, styles[size], className)}>
      {label && <FieldLabel name={name!}>{label}</FieldLabel>}
      <Input
        className={classNames({
          [styles.inputError]: error,
        })}
        disabled={disabled}
        id={name}
        placeholder={placeholder}
        showSuccessBorder={showSuccessBorder}
        {...props}
      />
      {error && <FieldError block={displayBlockError} message={error} />}
    </div>
  ),
);

export { TextField };
