import { Column } from 'react-table';

import { MappedRow } from 'pages/ItemSource/types';
import { FactoryCellEachUpc } from 'pages/ItemSource/components/FactoryCellEachUpc';
import { FactoryCellInput } from 'shared/components/DataGrid';
import { pickUpcValue } from 'pages/ItemSource/utils';

import { SupplierItemNumberColumnName } from './supplierItemNumberColumnName';

const CellEachUpc = FactoryCellEachUpc();

const CellPackagingUpc = FactoryCellInput<MappedRow, string[]>({
  type: 'number',
  pickValue: pickUpcValue,
});

const CellSupplierItemNumber = FactoryCellInput<MappedRow>();

export const headersForMassEditColumns: Column<MappedRow>[] = [
  {
    Header: 'Item Name',
    accessor: 'name',
  },
  {
    Header: 'Item ID',
    accessor: 'itemId',
  },
  {
    Header: 'UPC',
    accessor: 'upc',
    Cell: CellEachUpc,
    SubCell: CellPackagingUpc,
  },
  {
    Header: 'Supplier Item Number',
    accessor: SupplierItemNumberColumnName,
    Cell: CellSupplierItemNumber,
  },
];
