import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { Currency } from 'shared/types';

const makeCurrencies = (props: O.Partial<Currency, 'deep'> = {}): Currency =>
  merge<Currency, O.Partial<Currency, 'deep'>>(
    {
      id: '1',
      code: 'USD',
      __typename: 'Currency',
    },
    props,
  );

export { makeCurrencies };
