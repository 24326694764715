import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { AlcoholVolume } from 'shared/types';

import { replicateN } from '../utils';
import { makeAlcoholVolume } from '../factories';

const volume = [, 5, 5.5, 6, 6.5, 7, 7.5, 8, 10, 16, 17, 18, 38, 39, 40, 100, 100, 100, 100];

const alcoholVolumes = (props: O.Partial<AlcoholVolume, 'deep'>[] = []): AlcoholVolume[] =>
  mergeDeepArrays<AlcoholVolume>(
    replicateN(makeAlcoholVolume(), volume.length, {
      volume,
    }),
    props,
  );

export { alcoholVolumes };
