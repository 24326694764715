import { useMemo } from 'react';
import { Row as TableRow } from 'react-table';
import { Tooltip, Typography } from '@mui/material';

import {
  EmptyCell,
  FactoryCellDataLink,
  FactoryCellInput,
  FactoryCellSelect,
  FactoryCellTimeConverter,
} from 'shared/components/DataGrid';
import type { Row, UseColumnsArgs, UseColumnsResult } from 'pages/DestinationAssortment/types';
import {
  isReadOnlyRole,
  mapToIdDefaultNull,
  mapToPackagingTypeOptions,
  mapToSelectOptions,
  mapValuesSeparatedWithComma,
} from 'shared/utils';
import {
  AssortmentOverrideState,
  Currency,
  DestinationAssortmentsItemOptionsQuery,
  DestinationAssortmentsItemOptionsQueryVariables,
  DestinationAssortmentsLocationOptionsQuery,
  DestinationAssortmentsLocationOptionsQueryVariables,
  DestinationAssortmentsOpcoOptionsQuery,
  DestinationAssortmentsOpcoOptionsQueryVariables,
  DestinationAssortmentsSourceOptionsQuery,
  DestinationAssortmentsSourceOptionsQueryVariables,
  VelocityCategory,
} from 'shared/types';
import { inputTypes } from 'shared/components/Input/constants';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteMultiple,
  FilterSearchAutocompleteQuery,
  FilterSearchDatePicker,
  FilterSearchInput,
} from 'shared/components/Search';
import { TooltipHeader } from 'shared/components/TooltipHeader';
import {
  ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST,
  ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST_CURRENCY,
  ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST,
  ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST_CURRENCY,
} from 'shared/constants';
import { DestinationAssortmentsViewProps } from 'pages/DestinationAssortment/types';
import { assortmentOverrideStateOptions } from 'shared/utils/assortmentOverrideStateOptions';

import {
  destinationAssortmentsItemOptionsQuery,
  destinationAssortmentsLocationOptionsQuery,
  destinationAssortmentsOpcoOptionsQuery,
  destinationAssortmentsSourceOptionsQuery,
} from '../operations';

const CellWithTooltip = FactoryCellDataLink();
const CellTimeConverter = FactoryCellTimeConverter();

const useColumns = ({
  activePageIndex,
  currenciesOptions,
  eachPackageTypeId,
  isCostingOn,
  isFirstLoad,
  packagingIdOptions,
  role,
  updateFilter,
  userOptions,
  userPermissions,
  velocityCategoryOptions,
}: UseColumnsArgs) =>
  useMemo(() => {
    const isReadOnly = isReadOnlyRole(role);
    const isReadOnlyFunc = () => isReadOnly;

    const CellNationalConst = FactoryCellInput({
      type: inputTypes.Float,
      isDisabled: ({ assortmentOverrideState, nationalCostCurrency }: Row) =>
        assortmentOverrideState === AssortmentOverrideState.Inactive ||
        !nationalCostCurrency?.id ||
        !userPermissions.has(ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST),
      dependencies: ['assortmentOverrideState', 'nationalCostCurrency'],
    });

    const CellLocalConst = FactoryCellInput({
      type: inputTypes.Float,
      isDisabled: ({ assortmentOverrideState, localCostCurrency }: Row) =>
        assortmentOverrideState === AssortmentOverrideState.Inactive ||
        !localCostCurrency?.id ||
        !userPermissions.has(ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST),
      dependencies: ['assortmentOverrideState', 'localCostCurrency'],
    });

    const itemNameFilter = (
      <FilterSearchAutocompleteQuery<
        DestinationAssortmentsItemOptionsQuery,
        DestinationAssortmentsItemOptionsQueryVariables
      >
        filterFieldName="itemName"
        label="Item Name"
        mapDataToOptions={a =>
          mapToSelectOptions(a.destinationAssortments.assortments.map(({ item }) => item))
        }
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          itemName: value,
        })}
        onSubmitFilter={updateFilter}
        query={destinationAssortmentsItemOptionsQuery}
      />
    );
    const itemIdFilter = (
      <FilterSearchInput<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="itemExtIds"
        label="Item ID"
        mapUserInputToFilerParams={value => ({
          itemExtIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const locationNameFilter = (
      <FilterSearchAutocompleteQuery<
        DestinationAssortmentsLocationOptionsQuery,
        DestinationAssortmentsLocationOptionsQueryVariables
      >
        filterFieldName="locationName"
        label="Location Name"
        mapDataToOptions={a =>
          mapToSelectOptions(a.destinationAssortments.assortments.map(({ location }) => location))
        }
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          locationName: value,
        })}
        onSubmitFilter={updateFilter}
        query={destinationAssortmentsLocationOptionsQuery}
      />
    );

    const locationIdsFilter = (
      <FilterSearchInput<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="locationExtIds"
        label="Location ID"
        mapUserInputToFilerParams={value => ({
          locationExtIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const sourceNameFilter = (
      <FilterSearchAutocompleteQuery<
        DestinationAssortmentsSourceOptionsQuery,
        DestinationAssortmentsSourceOptionsQueryVariables
      >
        filterFieldName="sourceName"
        label="Source Name"
        mapDataToOptions={a =>
          mapToSelectOptions(a.destinationAssortments.assortments.map(({ source }) => source))
        }
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          sourceName: value,
        })}
        onSubmitFilter={updateFilter}
        query={destinationAssortmentsSourceOptionsQuery}
      />
    );

    const sourceIdsFilter = (
      <FilterSearchInput<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="supplierExtIds"
        label="Supplier Number"
        mapUserInputToFilerParams={value => ({
          supplierExtIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
        title="DC sources will use the SCDC Supplier Id"
      />
    );

    const opcoStateProvinceRegionFilter = (
      <FilterSearchInput<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="opcoStateProvinceRegion"
        label="Opco State/Province/Region"
        mapUserInputToFilerParams={value => ({
          opcoStateProvinceRegion: value,
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const opcoNameFilter = (
      <FilterSearchAutocompleteQuery<
        DestinationAssortmentsOpcoOptionsQuery,
        DestinationAssortmentsOpcoOptionsQueryVariables
      >
        filterFieldName="opcoName"
        label="Opco/Site Name"
        mapDataToOptions={a =>
          mapToSelectOptions(a.destinationAssortments.assortments.map(({ opco }) => opco))
        }
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          opcoName: value,
        })}
        onSubmitFilter={updateFilter}
        query={destinationAssortmentsOpcoOptionsQuery}
      />
    );

    const opcoIdsFilter = (
      <FilterSearchInput<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="opcoIds"
        label="Opco/Site ID"
        mapUserInputToFilerParams={value => ({
          opcoIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const assorOverrideStateFilter = (
      <FilterSearchAutocompleteMultiple<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="assorOverrideState"
        label={<TooltipHeader label="Assor. Override" title="Include in Assortment" />}
        onSubmitFilter={updateFilter}
        options={assortmentOverrideStateOptions}
      />
    );

    const pkgTypeIdsFilter = (
      <FilterSearchAutocompleteMultiple<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="pkgTypeIds"
        label="Packaging Type"
        onSubmitFilter={updateFilter}
        options={packagingIdOptions}
      />
    );

    const nationalCostFilter = (
      <FilterSearchInput<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="nationalCost"
        label="National Cost"
        onSubmitFilter={updateFilter}
      />
    );

    const natCostCurrencyIdsFilter = (
      <FilterSearchAutocompleteMultiple<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="natCostCurrencyIds"
        label="Currency"
        onSubmitFilter={updateFilter}
        options={currenciesOptions}
      />
    );

    const localCostFilter = (
      <FilterSearchInput<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="localCost"
        label="Local Cost"
        onSubmitFilter={updateFilter}
      />
    );
    const locCostCurrencyIdsFilter = (
      <FilterSearchAutocompleteMultiple<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="locCostCurrencyIds"
        label="Currency"
        onSubmitFilter={updateFilter}
        options={currenciesOptions}
      />
    );
    const velocityCategoryIdsFilter = (
      <FilterSearchAutocompleteMultiple<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="velocityCategoryIds"
        label="Velocity Cat."
        onSubmitFilter={updateFilter}
        options={velocityCategoryOptions}
      />
    );
    const managedByFilter = (
      <FilterSearchAutocompleteMultiple<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="managedByIds"
        label="Managed by"
        onSubmitFilter={updateFilter}
        options={userOptions}
      />
    );
    const updatedAtFilter = (
      <FilterSearchDatePicker<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="updatedAt"
        label="Updated At"
        onSubmitFilter={updateFilter}
      />
    );
    const updatedByFilter = (
      <FilterSearchAutocomplete<DestinationAssortmentsItemOptionsQueryVariables>
        filterFieldName="updatedBy"
        label="Updated By"
        mapUserAutocompleteValueSelectToFilterParams={option => ({
          updatedBy: option!.value,
        })}
        onSubmitFilter={updateFilter}
        options={userOptions}
      />
    );

    const headersForItemAndLocation = [
      [
        {
          Header: itemNameFilter,
          accessor: 'item.name',
          width: 240,
          Cell: CellWithTooltip,
        },
        {
          Header: itemIdFilter,
          accessor: 'item.extId',
          width: 104,
        },
      ],
      [
        {
          Header: locationNameFilter,
          accessor: 'location.name',
          Cell: ({ row }: { row: TableRow<Row> }) => {
            const locationName = row.original.location?.name ?? '';

            return (
              <Tooltip title={locationName}>
                <Typography
                  style={{
                    maxWidth: '224px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontSize: '0.875rem',
                  }}
                >
                  {locationName}
                </Typography>
              </Tooltip>
            );
          },
          width: 240,
        },
        {
          Header: locationIdsFilter,
          accessor: 'location.extId',
          width: 104,
        },
      ],
    ];

    const validateMinMax = (
      minAllocationQuantity: string | number | null,
      maxAllocationQuantity: string | number | null,
    ) =>
      !minAllocationQuantity ||
      !maxAllocationQuantity ||
      Number(minAllocationQuantity) <= Number(maxAllocationQuantity)
        ? ''
        : 'Min. Allocation Quantity must be less than or equal to Max. Allocation Quantity';

    const CellMinAllocationQuantity = FactoryCellInput<Row>({
      type: 'number',
      max: 100000,
      isDisabled: isReadOnlyFunc,
      validate: (value: string | number, row: Row) =>
        validateMinMax(value, row.maxAllocationQuantity),
    });

    const CellMaxAllocationQuantity = FactoryCellInput<Row>({
      type: 'number',
      max: 100000,
      isDisabled: isReadOnlyFunc,
      validate: (value: string | number, row: Row) =>
        validateMinMax(row.minAllocationQuantity, value),
    });
    return isFirstLoad
      ? []
      : ([
          ...[
            { ...headersForItemAndLocation[activePageIndex][0], sticky: 'left' },
            headersForItemAndLocation[activePageIndex][1],
          ],
          ...headersForItemAndLocation[+!activePageIndex],
          {
            Header: sourceNameFilter,
            accessor: 'source.name',
          },
          {
            Header: sourceIdsFilter,
            accessor: 'source.extID',
            width: 150,
          },
          {
            Header: opcoNameFilter,
            accessor: 'opco.name',
          },
          {
            Header: opcoIdsFilter,
            accessor: 'opco.id',
          },
          {
            Header: opcoStateProvinceRegionFilter,
            accessor: 'opco.stateProvinceRegion',
            width: 300,
          },
          {
            Header: assorOverrideStateFilter,
            accessor: 'assortmentOverrideState',
            Cell: FactoryCellSelect<Row, string, AssortmentOverrideState>({
              options: assortmentOverrideStateOptions,
              isDisabled: isReadOnlyFunc,
            }),
          },
          {
            Header: pkgTypeIdsFilter,
            accessor: 'selectedPackagingID',
            Cell: FactoryCellSelect<Row, string>({
              deriveOptionsFromRow: mapToPackagingTypeOptions,
              isDisabled: ({ assortmentOverrideState }: Row) =>
                assortmentOverrideState === AssortmentOverrideState.Inactive || isReadOnly,
              dependencies: ['assortmentOverrideState'],
            }),
          },
          ...(isCostingOn
            ? ([
                {
                  Header: 'Each Cost',
                  id: 'cost',
                  accessor: ({ costs }) =>
                    costs?.find(({ packageTypeID }) => packageTypeID === eachPackageTypeId)
                      ?.value ?? '',
                  width: 104,
                },
                {
                  Header: 'Currency',
                  id: 'currency',
                  accessor: ({ costs }) => {
                    const currencyId = costs?.find(
                      ({ packageTypeID }) => packageTypeID === eachPackageTypeId,
                    )?.currencyId;
                    return currencyId
                      ? currenciesOptions.find(({ value }) => value === currencyId)?.label
                      : '';
                  },
                },
              ] as DestinationAssortmentsViewProps['columns'])
            : [
                {
                  Header: nationalCostFilter,
                  accessor: 'nationalCost',
                  Cell: CellNationalConst,
                  width: 104,
                },
                {
                  Header: natCostCurrencyIdsFilter,
                  accessor: 'nationalCostCurrency',
                  Cell: FactoryCellSelect<Row, string, Currency>({
                    options: currenciesOptions,
                    isDisabled: ({ assortmentOverrideState }: Row) =>
                      assortmentOverrideState === AssortmentOverrideState.Inactive ||
                      !userPermissions.has(
                        ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST_CURRENCY,
                      ),
                    pickValue: mapToIdDefaultNull,
                    dependencies: ['assortmentOverrideState'],
                  }),
                },
                {
                  Header: localCostFilter,
                  accessor: 'localCost',
                  Cell: CellLocalConst,
                  width: 104,
                },
                {
                  Header: locCostCurrencyIdsFilter,
                  accessor: 'localCostCurrency',
                  Cell: FactoryCellSelect<Row, string, Currency>({
                    options: currenciesOptions,
                    isDisabled: ({ assortmentOverrideState }: Row) =>
                      assortmentOverrideState === AssortmentOverrideState.Inactive ||
                      !userPermissions.has(ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST_CURRENCY),
                    pickValue: mapToIdDefaultNull,
                    dependencies: ['assortmentOverrideState'],
                  }),
                },
              ]),
          {
            Header: velocityCategoryIdsFilter,
            accessor: 'velocityCategory',
            Cell: FactoryCellSelect<Row, string, VelocityCategory>({
              options: velocityCategoryOptions,
              isDisabled: ({ assortmentOverrideState }: Row) =>
                assortmentOverrideState === AssortmentOverrideState.Inactive || isReadOnly,
              pickValue: mapToIdDefaultNull,
              dependencies: ['assortmentOverrideState'],
            }),
          },
          {
            Header: <TooltipHeader label="Min Allocation" title="Represented in terms of EACHES" />,
            Cell: CellMinAllocationQuantity,
            accessor: 'minAllocationQuantity',
            SubCell: EmptyCell,
          },
          {
            Header: <TooltipHeader label="Max Allocation" title="Represented in terms of EACHES" />,
            Cell: CellMaxAllocationQuantity,
            accessor: 'maxAllocationQuantity',
            SubCell: EmptyCell,
          },
          {
            Header: managedByFilter,
            accessor: 'managedBy.displayName',
          },
          {
            Header: () => updatedByFilter,
            id: 'updatedBy',
            accessor: (row: Row) => {
              const user = userOptions
                ? userOptions.filter(u => u.value.includes(row.updatedBy))
                : [];
              return row.updatedBy && user.length ? user[0].label : row.updatedBy || '-';
            },
          },
          {
            Header: () => updatedAtFilter,
            accessor: 'updatedAt',
            Cell: CellTimeConverter,
            width: 170,
          },
        ].filter(Boolean) as UseColumnsResult);
  }, [
    activePageIndex,
    currenciesOptions,
    isCostingOn,
    isFirstLoad,
    packagingIdOptions,
    role,
    updateFilter,
    userOptions,
    userPermissions,
    velocityCategoryOptions,
    eachPackageTypeId,
  ]);

export { useColumns };
