import { MappedRow } from '../types';

export const hasSiblingRowWeekStartDateChanged = (
  previous: MappedRow,
  next: MappedRow,
): boolean => {
  const previousRows = (previous.parent ? previous.parent.rows : previous.rows) ?? [];
  const nextRows = (next.parent ? next.parent.rows : next.rows) ?? [];

  return (
    nextRows.length !== previousRows.length ||
    previousRows.some(
      (row, index) => !!nextRows[index] && nextRows[index].weekStartDate !== row.weekStartDate,
    )
  );
};
