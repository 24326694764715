import { mergeDeepRight } from 'ramda';
import { O } from 'ts-toolbelt';

import { AlcoholType } from 'shared/types';

import { makeAlcoholVolume } from './makeAlcoholVolume';

const volumes = [makeAlcoholVolume()];

const makeAlcoholType = (props: O.Partial<AlcoholType, 'deep'> = {}): AlcoholType =>
  mergeDeepRight<AlcoholType, O.Partial<AlcoholType, 'deep'>>(
    {
      id: '1',
      name: 'Liquor',
      description: 'Description...',
      volumes,
      updatedAt: 0,
      updatedBy: 'test',
      __typename: 'AlcoholType',
    },
    props,
  ) as AlcoholType;

export { makeAlcoholType };
