import { FC } from 'react';
import Chip from '@mui/material/Chip';

import { valueToLabel } from 'shared/components/NewestSelect/shared/utils';
import { Placeholder } from 'shared/components/NewestSelect/shared/components/Placeholder';

import { ButtonContentProps } from './types';
import styles from './ButtonContent.module.css';

const ButtonContent: FC<ButtonContentProps> = ({
  disabled,
  options,
  placeholder,
  selectedOptions,
}) => (
  <>
    {selectedOptions.length ? (
      <>
        <Chip
          className={styles.chip}
          label={valueToLabel(selectedOptions[0], options)}
          size="small"
        />
        {selectedOptions.length > 1 && (
          <Chip className={styles.chip} label={`+${selectedOptions.length - 1}`} size="small" />
        )}
      </>
    ) : (
      <Placeholder disabled={disabled} placeholder={placeholder} />
    )}
  </>
);

export { ButtonContent };
