import { forwardRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';

import { InputStyled } from 'shared/components/InputStyled';

import styles from './Search.module.css';
import { SearchProps } from './types';

const endAdornment = <SearchIcon />;

const Search = forwardRef<HTMLInputElement, SearchProps>((props, ref) => (
  <div className={styles.container}>
    <InputStyled
      {...props}
      ref={ref}
      endAdornment={endAdornment}
      placeholder={props.placeholder || 'Search'}
    />
  </div>
));

export { Search };
