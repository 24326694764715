import { mergeDeepRight } from 'ramda';
import { O } from 'ts-toolbelt';

const mergeDeepArrays = <
  Result extends object,
  O1 extends Result[] = Result[],
  O2 extends (O.Partial<Result, 'deep'> | undefined)[] = (O.Partial<Result, 'deep'> | undefined)[],
>(
  o1: O1,
  o2: O2,
): Result[] => Object.values(mergeDeepRight(o1, o2)) as Result[];

export { mergeDeepArrays };
