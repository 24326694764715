import { ColumnType } from 'shared/tools/excel/constants';

export const constants = {
  download: {
    paginationLimit: 1000,
    totalLimit: 10000,
    columns: {
      header: {
        offerNumber: {
          displayName: 'Deal #',
          type: ColumnType.Text,
          width: 10,
        },
        status: {
          displayName: 'Status',
          type: ColumnType.Text,
          width: 10,
        },
        parentCompany: {
          displayName: 'Parent Company',
          type: ColumnType.Text,
          width: 20,
        },
        submitterName: {
          displayName: 'Submitter Name',
          type: ColumnType.Text,
          width: 20,
        },
        submitterEmail: {
          displayName: 'Submitter Email',
          type: ColumnType.Text,
          width: 20,
        },
        description: {
          displayName: 'Deal Description',
          type: ColumnType.Text,
        },
        type: {
          displayName: 'Deal Type',
          type: ColumnType.Text,
          width: 16,
        },
        startDate: {
          displayName: 'Deal Start Date',
          type: ColumnType.Date,
        },
        startTimeZone: {
          displayName: 'Deal Start Time Zone',
          type: ColumnType.Text,
        },
        endDate: {
          displayName: 'Deal End Date',
          type: ColumnType.Date,
        },
        endTimeZone: {
          displayName: 'Deal End Time Zone',
          type: ColumnType.Text,
        },
        locations: {
          displayName: 'Locations',
          type: ColumnType.Text,
        },
        currency: {
          displayName: 'Currency',
        },
        supplierNumber: {
          displayName: 'Supplier #',
          type: ColumnType.Text,
        },
        approvedBy: {
          displayName: 'Approved by',
          type: ColumnType.Text,
        },
        approvedDate: {
          displayName: 'Approval Date',
          type: ColumnType.Date,
        },
        updatedDate: {
          displayName: 'Last Updated Date',
          type: ColumnType.Date,
        },
        updatedBy: {
          displayName: 'Last Updated By',
          type: ColumnType.Text,
          width: 20,
        },
        note: {
          displayName: 'Note',
          type: ColumnType.Text,
          width: 20,
        },
        error: {
          displayName: 'Error',
          type: ColumnType.Text,
        },
      },
      lines: {
        eachUPC: {
          displayName: 'Each UPC',
          type: ColumnType.Text,
          width: 20,
        },
        itemGopuffId: {
          displayName: 'Item Gopuff ID',
          type: ColumnType.Text,
        },
        itemName: {
          displayName: 'Item Name',
          type: ColumnType.Text,
          width: 40,
        },
        error: {
          displayName: 'Error',
          type: ColumnType.Text,
        },
        scanDeal: {
          vendorFundingPerUnit: {
            displayName: 'Vendor Funding Per Unit',
            type: ColumnType.Number,
          },
          dollarOffStrikethrough: {
            displayName: 'Dollar Off Strikethrough',
            type: ColumnType.Number,
          },
          percentageOffStrikethrough: {
            displayName: 'Percentage Off Strikethrough',
            type: ColumnType.Percent,
          },
        },
      },
      locations: {
        name: {
          displayName: 'Location Name',
          type: ColumnType.Text,
          width: 20,
        },
        id: {
          displayName: 'Location ID',
          type: ColumnType.Text,
        },
      },
      locationGroups: {
        name: {
          displayName: 'Location Group Name',
          type: ColumnType.Text,
        },
        locationIds: {
          displayName: 'Location IDs',
          type: ColumnType.Text,
        },
      },
    },
  },
};
