import { FC } from 'react';

import { Header as HeaderBase } from 'shared/components/Header';

import { DataGridToggleButtons } from '../DataGridToggleButtons';
import { UploadIconButton } from '../IconButton';
import { DataGridDownloadButton } from '../IconButton/DataGridDownloadButton';
import { UploadFileModal } from '../UploadFileModal';

import styles from './HeaderDataGridActions.module.css';
import { HeaderDataGridActionsProps } from './types';

const HeaderDataGridActions: FC<HeaderDataGridActionsProps> = ({
  activePageIndex,
  children,
  downloadButtonDisabled,
  hasAccessToUploadFile,
  headerButtonNames,
  onDataGridDownloadButtonClick,
  onTogglePage,
  title,
  uploadFileProps,
}) => (
  <HeaderBase title={title}>
    <div className={styles.container}>
      {children}
      {onDataGridDownloadButtonClick && (
        <DataGridDownloadButton
          disabled={downloadButtonDisabled}
          onClick={onDataGridDownloadButtonClick}
        />
      )}
      {uploadFileProps && hasAccessToUploadFile && (
        <>
          <UploadIconButton onClick={uploadFileProps.onOpenUploadFileModal} />
          <UploadFileModal {...uploadFileProps} />
        </>
      )}
      {headerButtonNames && (
        <DataGridToggleButtons
          activePageIndex={activePageIndex}
          buttonNames={headerButtonNames}
          onTogglePage={onTogglePage!}
        />
      )}
    </div>
  </HeaderBase>
);

export { HeaderDataGridActions };
