import { gql } from '@apollo/client';

export const NATIONAL_ASSORTMENT_QUERY = gql`
  query NationalAssortmentQuery {
    nationalAssortments {
      id
      name
      description
      __typename @skip(if: true)
    }
  }
`;

export const ALLOCATION_PRIORITY_QUERY = gql`
  query AllocationPriorityQuery {
    allocationPriorities {
      id
      name
      description
      __typename @skip(if: true)
    }
  }
`;

export const CUSTOM_ASSORTMENT_QUERY = gql`
  query CustomAssortmentQuery {
    customAssortments {
      id
      name
      description
      __typename @skip(if: true)
    }
  }
`;

export const ALCOHOL_TYPES_QUERY = gql`
  query AlcoholTypesQuery {
    alcoholTypes {
      id
      name
      description
      __typename @skip(if: true)
      volumes {
        id
        volume
        __typename @skip(if: true)
      }
    }
  }
`;

export const TOBACCO_TYPES_QUERY = gql`
  query TobaccoTypesQuery {
    tobaccoTypes {
      id
      name: restrictionCode
      description: name
      __typename @skip(if: true)
    }
  }
`;

export const CAPABILITIES_QUERY = gql`
  query CapabilitiesQuery {
    capabilities {
      id
      name
      description
      __typename @skip(if: true)
    }
  }
`;
