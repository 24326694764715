import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { BomItem } from 'shared/types';

import { makeBOM } from '../factories';
import { replicateN } from '../utils';

import { items } from './items';

const bomItems = items();

const boms = (props: O.Partial<BomItem, 'deep'>[] = []): BomItem[] =>
  mergeDeepArrays<BomItem>(
    replicateN({
      data: makeBOM(),
      n: 5,
      replacements: {
        item: bomItems,
      },
      skipIncrementForFields: ['item'],
    }),
    props,
  );

export { boms };
