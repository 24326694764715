import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SearchAutocomplete } from 'shared/components/Search/SearchAutocomplete';

import { AutocompleteOption, BaseQueryArgs, mapAutocompleteValueToFilterParams } from '../shared';

import { FilterSearchAutocompleteProps } from './types';

const FilterSearchAutocomplete = <QueryArgs extends BaseQueryArgs>({
  filterFieldName,
  mapUserAutocompleteValueSelectToFilterParams,
  onSubmitFilter,
  useUrlQueryParameter,
  ...props
}: FilterSearchAutocompleteProps<QueryArgs>) => {
  const [value, setValue] = useState<AutocompleteOption | null>(null);
  const location = useLocation();
  const history = useHistory();

  const handleChange = useCallback(
    (e, newValue: AutocompleteOption | null) => {
      setValue(newValue);

      onSubmitFilter(
        mapAutocompleteValueToFilterParams<QueryArgs, false>(
          newValue,
          filterFieldName,
          mapUserAutocompleteValueSelectToFilterParams,
          currentValue => ({
            [filterFieldName]: currentValue.value,
          }),
        ),
      );

      const canUseUrlQueryParameter =
        useUrlQueryParameter && typeof filterFieldName === 'string' && filterFieldName.length > 0;
      if (!canUseUrlQueryParameter) return;

      const newParams = new URLSearchParams(location.search);
      if (newValue) {
        newParams.set(filterFieldName, newValue.value);
      } else {
        newParams.delete(filterFieldName);
      }
      history.replace({
        ...location,
        search: newParams.toString(),
      });
    },
    [onSubmitFilter, useUrlQueryParameter, filterFieldName, location, history],
  );

  useEffect(() => {
    const canUseUrlQueryParameter =
      useUrlQueryParameter && typeof filterFieldName === 'string' && filterFieldName.length > 0;
    if (!canUseUrlQueryParameter) return;

    const queryValue = new URLSearchParams(location.search).get(filterFieldName);
    if (!queryValue) return;

    const optionToUse = props.options.find(option => option.value === queryValue);
    if (optionToUse && value?.value !== optionToUse.value) {
      handleChange(null, optionToUse);
    }
  }, [useUrlQueryParameter, filterFieldName, location.search, props.options, value]);

  return <SearchAutocomplete<false> {...props} onChange={handleChange} value={value} />;
};

export { FilterSearchAutocomplete };
