import { DealUploadLine, DealUploadResult } from 'shared/types';
import { exportXlsxFile } from 'shared/tools/excel/exportXlsxFile';
import { constants } from 'pages/DealManagement/constants';

export const downloadDealUploadResult = (dealUploadResult: DealUploadResult) => {
  const { header, lines } = dealUploadResult;

  const lineRows: Array<Record<string, unknown>> = [];
  lines.forEach(line => {
    const typedRow = line as unknown as DealUploadLine;
    lineRows.push({
      [constants.download.columns.lines.eachUPC.displayName]: typedRow.eachUpc ?? '',
      [constants.download.columns.lines.scanDeal.vendorFundingPerUnit.displayName]:
        (Math.round(Number(typedRow.scanDeal.vendorFundingPerUnit) * 100) / 100).toString() ?? '',
      // second table in sheet, reusing startTimeZone column for error
      [constants.download.columns.lines.error.displayName]: typedRow.error ?? '',
    });
  });

  const input = {
    fileName: `Deal Management Upload Result (${new Date().toLocaleString()})`,
    sheets: [
      {
        sheetName: 'deal',
        tables: [
          {
            headers: [
              constants.download.columns.header.description,
              constants.download.columns.header.type,
              constants.download.columns.header.startDate,
              constants.download.columns.header.startTimeZone,
              constants.download.columns.header.endDate,
              constants.download.columns.header.endTimeZone,
              constants.download.columns.header.locations,
              constants.download.columns.header.currency,
              constants.download.columns.header.supplierNumber,
              constants.download.columns.header.error,
            ],
            rows: [
              {
                [constants.download.columns.header.description.displayName]:
                  header.description ?? '',
                [constants.download.columns.header.type.displayName]: header.type ?? '',
                [constants.download.columns.header.startDate.displayName]: header.startDate ?? '',
                [constants.download.columns.header.startTimeZone.displayName]:
                  header.startTimeZone ?? '',
                [constants.download.columns.header.endDate.displayName]: header.endDate ?? '',
                [constants.download.columns.header.endTimeZone.displayName]:
                  header.endTimeZone ?? '',
                [constants.download.columns.header.locations.displayName]: header.locations ?? '',
                [constants.download.columns.header.supplierNumber.displayName]:
                  header.supplierNumber ?? '',
                [constants.download.columns.header.currency.displayName]: header.currency ?? '',
                [constants.download.columns.header.error.displayName]: header.error ?? '',
              },
            ],
          },
          {
            headers: [
              constants.download.columns.lines.eachUPC,
              constants.download.columns.lines.scanDeal.vendorFundingPerUnit,
              constants.download.columns.lines.error,
            ],
            rows: lineRows,
          },
        ],
      },
    ],
  };
  exportXlsxFile(input);
};
