import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { Location } from 'shared/types';

import { makeLocation } from '../factories';
import { pickOptions, replicateN } from '../utils';

import { nationalAssortments } from './nationalAssortments';
import { tobaccoTypes } from './tobaccoTypes';
import { capabilities } from './capabilities';
import { customAssortments } from './customAssortments';
import { alcoholTypes } from './alcoholTypes';

const nationalAssortmentsOptions = nationalAssortments();
const tobaccoTypesOptions = tobaccoTypes();
const capabilitiesOptions = capabilities();
const customAssortmentsOptions = customAssortments();
const alcoholTypesOptions = alcoholTypes();

const locations = (props: O.Partial<Location, 'deep'>[] = []): Location[] =>
  mergeDeepArrays<Location>(
    replicateN({
      data: makeLocation(),
      n: 5,
      replacements: {
        nationalAssortment: [
          ,
          ...pickOptions({ options: nationalAssortmentsOptions, pick: [1, 2, 3, 0] }),
        ],
        locationTobaccoCapabilities: [
          ,
          pickOptions({ options: tobaccoTypesOptions, pick: 1 }),
          pickOptions({ options: tobaccoTypesOptions, pick: [2, 3] }),
          pickOptions({ options: tobaccoTypesOptions, pick: [3, 4] }),
          pickOptions({ options: tobaccoTypesOptions, pick: 4 }),
        ],
        locationCapabilities: [
          ,
          pickOptions({ options: capabilitiesOptions, pick: [0, 1, 2, 3] }),
          pickOptions({ options: capabilitiesOptions, pick: 2 }),
          pickOptions({ options: capabilitiesOptions, pick: [1, 2, 3] }),
          pickOptions({ options: capabilitiesOptions, pick: 0 }),
        ],
        locationCustomAssortments: [
          ,
          pickOptions({ options: customAssortmentsOptions, pick: 1 }),
          pickOptions({ options: customAssortmentsOptions, pick: [3, 2] }),
          pickOptions({ options: customAssortmentsOptions, pick: [2] }),
          pickOptions({ options: customAssortmentsOptions, pick: [0, 1, 2, 3] }),
        ],
        locationAlcoholCapabilities: [
          ,
          pickOptions({ options: alcoholTypesOptions, pick: 1 }),
          pickOptions({ options: alcoholTypesOptions, pick: 2 }),
          pickOptions({ options: alcoholTypesOptions, pick: 3 }),
          pickOptions({ options: alcoholTypesOptions, pick: [1, 4] }),
        ],
      },
      skipIncrementForFields: [
        'locationTobaccoCapabilities',
        'nationalAssortment',
        'locationCustomAssortments',
        'locationCapabilities',
        'locationAlcoholCapabilities',
      ],
    }),
    props,
  );

export { locations };
