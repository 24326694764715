import { FC } from 'react';

import { Loader } from 'shared/components/Loader';

import { BOMView } from './BOMView';
import { useSetup } from './hooks';

const BOM: FC = () => {
  const { isLoading, name, ...props } = useSetup();

  if (isLoading) {
    return <Loader isLoading />;
  }

  return <BOMView {...props} name={name!} showPagination={false} />;
};

export { BOM };
