import { forwardRef } from 'react';
import classNames from 'classnames';

import { InputBase } from '../InputBase';

import { AdornmentContainer } from './components/AdornmentContainer';
import styles from './InputStyled.module.css';
import { InputStyledProps } from './types';

const InputStyled = forwardRef<HTMLInputElement, InputStyledProps>(
  ({ disabled, endAdornment, ...inputProps }, ref) => (
    <div className={classNames(styles.inputStyled, { [styles.disabled]: disabled })}>
      <InputBase
        noBorder
        {...inputProps}
        ref={ref}
        className={styles.inputBase}
        disabled={disabled}
      />
      {endAdornment && <AdornmentContainer>{endAdornment}</AdornmentContainer>}
    </div>
  ),
);

export { InputStyled };
