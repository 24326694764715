import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';

import { FormItem, FormValues } from '../types';

const makeEmptyFormItem = <FormItemType extends FormItem>() =>
  ({
    name: '',
    description: '',
  } as Partial<FormItemType>);

const filterMapDataToMutationItems = <FormItemType extends FormItem>(
  formData: UnpackNestedValue<FormValues<FormItemType>>,
  dirtyFields: FieldNamesMarkedBoolean<FormValues<FormItemType>>,
  fieldName: string,
): FormItemType[] =>
  (dirtyFields[fieldName] as FormItem[])
    .map((item, index) => (item.name || item.description ? formData[fieldName][index] : null))
    .filter(existedField => existedField !== null) as FormItemType[];

export { makeEmptyFormItem, filterMapDataToMutationItems };
