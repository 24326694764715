import { memo, useCallback } from 'react';
import { equals } from 'ramda';
import FormControl from '@mui/material/FormControl';
import BaseSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { renderEmptyPlaceholder } from 'shared/components/Select/constants';

import { OptionValueType, SelectProps } from './types';
import styles from './Select.module.css';

const Select = memo(
  <
    OptionValue extends OptionValueType,
    SelectValue extends OptionValue | OptionValue[] = OptionValue,
    AdditionalOptionProps extends object = {},
  >({
    size = 'small',
    options,
    value,
    optionProps = {} as AdditionalOptionProps,
    Option,
    error,
    ...props
  }: SelectProps<OptionValue, SelectValue, AdditionalOptionProps>): JSX.Element => {
    const renderValue = useCallback(() => {
      if (value === '') {
        return renderEmptyPlaceholder();
      }

      return options.find(({ value: optionValue }) => optionValue === value)?.label;
    }, [options, value]);

    return (
      <FormControl error={error} fullWidth size={size}>
        <BaseSelect
          classes={{ root: styles.select, disabled: styles.disabled }}
          displayEmpty
          renderValue={renderValue}
          value={value}
          {...props}
        >
          {options.map(({ label, value: mappedValue }) => (
            <MenuItem key={mappedValue} className={styles.label} value={mappedValue}>
              {Option ? (
                <Option key={mappedValue} label={label} value={mappedValue} {...optionProps} />
              ) : (
                label
              )}
            </MenuItem>
          ))}
        </BaseSelect>
      </FormControl>
    );
  },
  equals,
);

export { Select };
