const mergeFilter = (
  previousFilter: { [key: string]: any },
  newFilter: { [key: string]: any },
  defaultFilter?: { [key: string]: any },
): { [key: string]: any } => {
  const newMergedFilter = { ...previousFilter, ...newFilter };
  if (defaultFilter) {
    Object.keys(defaultFilter).forEach(key => {
      if (newMergedFilter[key] === null || newMergedFilter[key] === undefined) {
        newMergedFilter[key] = defaultFilter[key];
      }
    });
  }
  return newMergedFilter;
};

export { mergeFilter };
