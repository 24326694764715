import { defaultTo, nth, pipe, prop } from 'ramda';
import { FC, ReactElement } from 'react';

import { memoCell } from 'shared/utils';
import { CellProps } from 'shared/types';
import { MappedRow } from 'pages/ItemSource/types';

const FactoryCellEachUpc = () =>
  memoCell<CellProps<MappedRow, MappedRow['upc']>>(
    pipe<CellProps<MappedRow, MappedRow['upc']>, MappedRow['upc'], string | ReactElement>(
      prop('value'),
      pipe<
        MappedRow['upc'] | undefined,
        MappedRow['upc'],
        MappedRow['upc'][number] | undefined,
        string
      >(defaultTo([]), nth(0) as () => string, defaultTo('')),
    ) as FC,
  );

export { FactoryCellEachUpc };
