import { FC } from 'react';

import { useRole } from 'shared/hooks';
import { externalRoles } from 'shared/constants/access';

const RootView: FC = () => {
  const role = useRole();

  const internalHeader = 'Welcome to SC/DC';
  const vendorHeader = 'Welcome to Vendor Extranet';

  const header = externalRoles.has(role) ? vendorHeader : internalHeader;

  return (
    <>
      <h1 style={{ marginLeft: '24px' }}>{header}</h1>
    </>
  );
};

export { RootView };
