import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { ManufacturingType } from 'shared/types';

const makeManufacturingType = (
  props: O.Partial<ManufacturingType, 'deep'> = {},
): ManufacturingType =>
  merge<ManufacturingType, O.Partial<ManufacturingType, 'deep'>>(
    {
      id: '1',
      name: 'Make-To-Stock',
      __typename: 'ManufacturingType',
    },
    props,
  );

export { makeManufacturingType };
