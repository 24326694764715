import { constants } from 'pages/DealManagement/constants';
import { exportXlsxFile } from 'shared/tools/excel/exportXlsxFile';

const downloadStrikethroughTemplateExcel = () => {
  const input = {
    fileName: `Deal Management (${new Date().toLocaleString()})`,
    sheets: [
      {
        sheetName: 'deal',
        tables: [
          {
            headers: [
              constants.download.columns.header.description,
              constants.download.columns.header.type,
              constants.download.columns.header.startDate,
              constants.download.columns.header.startTimeZone,
              constants.download.columns.header.endDate,
              constants.download.columns.header.endTimeZone,
              constants.download.columns.header.locations,
              constants.download.columns.header.currency,
              constants.download.columns.header.supplierNumber,
            ],
            rows: [
              {
                [constants.download.columns.header.locations.displayName]: 'US',
                [constants.download.columns.header.type.displayName]: 'Strikethrough',
                [constants.download.columns.header.startTimeZone.displayName]: 'America/New_York',
                [constants.download.columns.header.endTimeZone.displayName]: 'America/Los_Angeles',
                [constants.download.columns.header.currency.displayName]: 'USD',
              },
            ],
          },
          {
            headers: [
              constants.download.columns.lines.eachUPC,
              constants.download.columns.lines.scanDeal.vendorFundingPerUnit,
            ],
            rows: [],
          },
        ],
      },
    ],
  };

  return exportXlsxFile(input);
};

export { downloadStrikethroughTemplateExcel };
