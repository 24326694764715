// TODO better typing job here, support any nesting depth
const uniqueUnpack = <
  K extends keyof T,
  G extends keyof T[K],
  T extends {
    [P in K]: { [F in G]: { id: string } };
  },
>(
  data: T[],
  compareField: G,
  unpackField: K,
) =>
  data.reduce<T[K][]>(
    (acc, item) =>
      acc.find(searchItem => searchItem[compareField].id === item[unpackField][compareField].id)
        ? acc
        : [...acc, item[unpackField]],
    [],
  );

export { uniqueUnpack };
