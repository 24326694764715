import { CostDateStatus } from 'shared/types';

export const ExternalUserVendorCostDateStatusFilter = [
  CostDateStatus.Current,
  CostDateStatus.Future,
  CostDateStatus.Draft,
  CostDateStatus.Pending,
  CostDateStatus.Rejected,
];

export const InternalUserVendorCostDateStatusFilter = [
  CostDateStatus.Past,
  CostDateStatus.Current,
  CostDateStatus.Future,
  CostDateStatus.Draft,
  CostDateStatus.Pending,
  CostDateStatus.Rejected,
];
