import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { usePagination, useRole } from 'shared/hooks';
import {
  GraphJinItemExtraSubClassDefaultInsertInput,
  GraphJinItemExtraSubClassDefaultQuery,
  GraphJinItemExtraSubClassDefaultUpdateMutation,
  GraphJinItemExtraSubClassDefaultUpdateMutationVariables,
  GraphJinItemSubClassQuery,
  QueryGraphJinItemExtraSubClassDefaultArgs,
  QueryGraphJinItemSubClassArgs,
} from 'shared/types';
import { UseOnGridChangeResult } from 'shared/hooks/useOnGridChange/types';

import { ItemExtraSubClassDefaultRow, ItemSubClassRow, MappedRow, SetupResult } from '../types';
import {
  graphJinItemExtraSubClassDefaultQuery,
  graphJinItemExtraSubClassDefaultUpdateMutation,
  graphJinItemSubClassQuery,
} from '../operations';
import { mapDataToRows } from '../mappers/mapDataToRows';
import { constants } from '../constants';

import { useColumns } from './useColumns';

const defaultItemExtraSubClassDefaults: ItemExtraSubClassDefaultRow[] = [];
const defaultItemSubClasses: ItemSubClassRow[] = [];

export const useSetup = (): SetupResult => {
  // Initial setup & states
  const role = useRole();
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination } = usePagination(
    constants.rowsPerPage,
  );
  const [
    isItemExtraSubClassDefaultFirstLoadingFinish,
    setIsItemExtraSubClassDefaultFirstLoadingFinish,
  ] = useState(false);
  const [isItemSubClassFirstLoadingFinish, setIsItemSubClassFirstLoadingFinish] = useState(false);

  // Queries
  const { data: itemExtraSubClassDefaultData, loading: itemExtraSubClassDefaultLoading } = useQuery<
    GraphJinItemExtraSubClassDefaultQuery,
    QueryGraphJinItemExtraSubClassDefaultArgs
  >(graphJinItemExtraSubClassDefaultQuery, {
    variables: {
      filter: {},
      pagination,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setIsItemExtraSubClassDefaultFirstLoadingFinish(true);
    },
  });

  const { data: itemSubClassData, loading: itemSubClassLoading } = useQuery<
    GraphJinItemSubClassQuery,
    QueryGraphJinItemSubClassArgs
  >(graphJinItemSubClassQuery, {
    variables: {
      filter: {},
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setIsItemSubClassFirstLoadingFinish(true);
    },
  });

  // Process query results
  const itemExtraSubClassDefaults =
    itemExtraSubClassDefaultData?.graphJinItemExtraSubClassDefault ??
    defaultItemExtraSubClassDefaults;

  const totalCount = itemExtraSubClassDefaults.length;

  const itemSubClasses = itemSubClassData?.graphJinItemSubClass ?? defaultItemSubClasses;

  const mappedData: MappedRow[] = useMemo(
    () => mapDataToRows(itemExtraSubClassDefaults, itemSubClasses),
    [itemExtraSubClassDefaults, itemSubClasses],
  );

  // Mutations
  const [updateItemExtraSubClassDefault, { loading: updateItemExtraSubClassDefaultLoading }] =
    useMutation<
      GraphJinItemExtraSubClassDefaultUpdateMutation,
      GraphJinItemExtraSubClassDefaultUpdateMutationVariables
    >(graphJinItemExtraSubClassDefaultUpdateMutation, {
      notifyOnNetworkStatusChange: true,
    });

  // Callbacks
  const onChange = useCallback<UseOnGridChangeResult<MappedRow>[0]>(
    async (...updateArgs) => {
      const [, columnName, newValue, row, ,] = updateArgs;

      const updateRow = {
        pullByDate: row.pullByDate,
        markdownDays: row.markdownDays,
        captureExpiry: row.captureExpiry,
        [columnName]: newValue,
      };

      await updateItemExtraSubClassDefault({
        variables: {
          id: row.id,
          update: updateRow as GraphJinItemExtraSubClassDefaultInsertInput,
        },
      });
    },
    [updateItemExtraSubClassDefault],
  );

  // Bootstrap columns
  const columns = useColumns({ role });

  return {
    canUpload: false,
    columns,
    data: mappedData,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    loadingMoreData:
      itemExtraSubClassDefaultLoading ||
      updateItemExtraSubClassDefaultLoading ||
      itemSubClassLoading,
    isFirstLoadingFinish:
      isItemExtraSubClassDefaultFirstLoadingFinish || isItemSubClassFirstLoadingFinish,
  };
};
