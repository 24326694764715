import { useCallback } from 'react';

import { AllocationPriorityInput } from 'shared/types';

import { DefaultFormProps } from '../DefaultForm';
import { GeneralForm } from '../GeneralForm';
import { filterMapDataToMutationItems, makeEmptyFormItem } from '../DefaultForm/constants';
import { Row } from '../DefaultForm/components/Row';
import { AdditionalRowProps } from '../DefaultForm/types';

import { validationSchema } from './validationSchema';

export const AllocationPriorityForm = ({ inputSize, ...props }: DefaultFormProps) => {
  const deriveAdditionalRowProps = useCallback(() => ({ inputSize }), [inputSize]);

  return (
    <GeneralForm<AllocationPriorityInput, AdditionalRowProps>
      {...props}
      deriveAdditionalRowProps={deriveAdditionalRowProps}
      filterMapDataToMutationItems={filterMapDataToMutationItems}
      makeEmptyFormItem={makeEmptyFormItem}
      Row={Row}
      validationSchema={validationSchema}
    />
  );
};
