import { isNil } from 'ramda';

import { MappedRow, Row } from 'pages/Suppliers/types';
import { UpdateOpcoMutationVariables } from 'shared/types';

const mapRowToMutationArgs = (
  row: Row,
  updatedChuck: Record<string, any>,
  mappedRow: MappedRow,
): UpdateOpcoMutationVariables => ({
  input: {
    opcos: mappedRow?.rows
      ? mappedRow.rows.map(({ isActive, opcoId, packageTypeId, sendToTraverse }) => ({
          id: opcoId as string,
          packageTypeId: packageTypeId as string,
          isActive: Boolean(isActive),
          sendToTraverse: Boolean(sendToTraverse),
          ...(!isNil(updatedChuck.isActive)
            ? { isActive: Boolean(updatedChuck.isActive) }
            : updatedChuck),
          ...(!isNil(updatedChuck.sendToTraverse)
            ? { sendToTraverse: Boolean(updatedChuck.sendToTraverse) }
            : updatedChuck),
        }))
      : [
          {
            id: mappedRow.opcoId as string,
            packageTypeId: mappedRow.packageTypeId as string,
            isActive: Boolean(mappedRow.isActive),
            sendToTraverse: Boolean(mappedRow.sendToTraverse),
            ...updatedChuck,
          },
        ],
  },
});

export { mapRowToMutationArgs };
