import { FC } from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { Security } from 'setup/providers/Security';
import { Router } from 'setup/providers/Router';
import { ThemeProvider } from 'setup/providers/ThemeProvider';
import { ApolloClient } from 'setup/providers/ApolloClient';
import { Intl } from 'setup/providers/Intl';
import { Toasts } from 'setup/providers/Toasts';
import { Context } from 'setup/providers/Context';
import { App } from 'pages/App';

const Providers: FC = () => (
  <ThemeProvider>
    <Context>
      <Router>
        <Toasts>
          <Security>
            <ApolloClient>
              <Intl>
                <Route component={withAuthenticationRequired(App)} path="/" />
              </Intl>
            </ApolloClient>
          </Security>
        </Toasts>
      </Router>
    </Context>
  </ThemeProvider>
);

export { Providers };
