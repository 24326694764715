import {
  ROLE_ADMIN,
  ROLE_ANALYST,
  ROLE_BUYER,
  ROLE_MANAGER,
  ROLE_MANUFACTURER,
  ROLE_PRICING,
  ROLE_READ_ONLY,
  ROLE_VENDOR,
} from './roles';

export const ROLE_NAMES = {
  [ROLE_ADMIN]: 'Superuser',
  [ROLE_MANAGER]: 'Supply Chain / Merchandising Manager',
  [ROLE_PRICING]: 'Pricing Team',
  [ROLE_ANALYST]: 'Supply Chain / Merchandising Analyst',
  [ROLE_BUYER]: 'Buyer',
  [ROLE_VENDOR]: 'Vendor',
  [ROLE_READ_ONLY]: 'Read-Only',
  [ROLE_MANUFACTURER]: 'Manufacturer',
};
