import { FC, useMemo, useRef } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionBase from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

import { AccordionProps } from './types';
import styles from './Accordion.module.css';

const TransitionProps = { unmountOnExit: true, exit: false };
const classes = { root: styles.accordion, expanded: styles.expanded };

const Accordion: FC<AccordionProps> = ({ children, expanded, handleChange, name }) => {
  const onChange = useMemo(() => handleChange(name), [name]);
  const { current: expandIcon } = useRef(<ExpandMoreIcon />);

  return (
    <AccordionBase
      classes={classes}
      expanded={expanded === name}
      onChange={onChange}
      TransitionProps={TransitionProps}
    >
      <AccordionSummary className={styles.accordionSummary} expandIcon={expandIcon}>
        <Typography className={styles.title} variant="h5">
          {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionBase>
  );
};

export { Accordion };
