import { UseExpandedState, UseRowSelectState } from 'react-table';

import { RowsPerPageOptions } from '../types';

export const SelectProps = {
  inputProps: { 'aria-label': 'results per page' },
  native: true,
};

export const defaultRowsPerPageOptions: RowsPerPageOptions = [5, 10, 25, 50, 100, 300];

export const renderEmptyPlaceholder = () => 'Select';

export const defaultSelectedRows: UseRowSelectState<any>['selectedRowIds'] = {};

export const defaultExpandedRows: UseExpandedState<any>['expanded'] = {};
