import { FC } from 'react';

import { Loader } from 'shared/components/Loader';
import { DataGridPage } from 'shared/components/DataGridPage';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';
import { DataGrid } from 'shared/components/DataGrid';

import { DealManagementViewProps } from './types';

const DealManagementView: FC<DealManagementViewProps> = ({
  canUpload,
  isFirstLoadingFinish,
  uploadFileProps,
  onDataGridDownloadButtonClick,
  isLoading,
  selectedRows,
  ...props
}) => (
  <>
    <DataGridPage
      header={
        <HeaderDataGridActions
          hasAccessToUploadFile={canUpload}
          onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
          title="Deal Management"
          uploadFileProps={uploadFileProps}
        />
      }
      isLoading={isLoading}
      selectedRows={selectedRows}
    >
      {isFirstLoadingFinish ? (
        <DataGrid
          {...props}
          disableOnLoadingMoreData
          enableRowSelection
          testId="deal-management-data-grid"
        />
      ) : (
        <Loader isLoading />
      )}
    </DataGridPage>
  </>
);

export { DealManagementView };
