import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { VelocityCategory } from 'shared/types';

const makeVelocityCategory = (props: O.Partial<VelocityCategory, 'deep'> = {}): VelocityCategory =>
  merge<VelocityCategory, O.Partial<VelocityCategory, 'deep'>>(
    {
      id: '1',
      name: '1',
      serviceLevel: 1,
      __typename: 'VelocityCategory',
    },
    props,
  );

export { makeVelocityCategory };
