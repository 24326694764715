import { FC } from 'react';
import Button from '@mui/material/Button';

import styles from './AddButton.module.css';
import { AddButtonProps } from './types';

const AddButton: FC<AddButtonProps> = ({ onClick }) => (
  <Button className={styles.addButton} onClick={onClick} variant="contained">
    Add
  </Button>
);

export { AddButton };
