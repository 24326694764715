import { PackageTypeName } from 'shared/constants/package';

const PackageTypeNameLabels: { [key in PackageTypeName]: string } = {
  [PackageTypeName.Each]: 'Each',
  [PackageTypeName.Inner]: 'Inner',
  [PackageTypeName.Case]: 'Case',
  [PackageTypeName.MasterCase]: 'Master Case',
  [PackageTypeName.Pallet]: 'Pallet',
};

export { PackageTypeNameLabels };
