import { forwardRef } from 'react';

import { ListProps } from './types';
import styles from './List.module.css';

const List = forwardRef<HTMLUListElement, ListProps>(({ children, ...ulProps }, ref) => (
  <ul {...ulProps} ref={ref} className={styles.list}>
    {children}
  </ul>
));

export { List };
