import { difference } from 'ramda';
import { Row as TableRow } from 'react-table';

import { Option, TransportationLaneInput } from 'shared/types';
import { Row } from 'pages/TransportationLane/types';

type Options = Option<string, string>[] | null;

const mapRowAndOptionToMutationInput = (
  row: Row,
  option: Option<string, string>,
  updateLocationFieldName: 'destination' | 'sourceOpco',
  includeId: boolean,
): TransportationLaneInput => ({
  id: includeId ? row.id : '-1',
  destination: row.destination?.id,
  sourceOpco: row.source?.id,
  ...{
    [updateLocationFieldName]: option.value,
  },
});

const mapDataToMassEditMutationArgs = ({
  data,
  defaultOptions,
  isFirstSave,
  newOptions,
  newRows,
  selectedRows,
  updateLocationFieldName,
}: {
  newOptions: Options;
  defaultOptions: Options;
  data: Row[];
  newRows: Row[];
  selectedRows: TableRow<Row>[];
  updateLocationFieldName: 'destination' | 'sourceOpco';
  isFirstSave: boolean;
}) => {
  const upsertTLanes: TransportationLaneInput[] = [];
  const removeTLanes: TransportationLaneInput[] = [];
  const deletedOptions = difference(defaultOptions || [], newOptions || []);
  const addedOptions = difference(newOptions || [], defaultOptions || []);
  const [field, oppositeField]: [keyof Row, keyof Row] =
    updateLocationFieldName === 'destination'
      ? ['destination', 'source']
      : ['source', 'destination'];

  [...selectedRows, ...newRows].forEach(row => {
    const originalRow: Row = (row as TableRow<Row>).original || row;
    deletedOptions.forEach(deletedOption => {
      if (originalRow[field].id === deletedOption.value) {
        removeTLanes.push(
          mapRowAndOptionToMutationInput(originalRow, deletedOption, updateLocationFieldName, true),
        );
      }
    });
  });

  data.forEach(row => {
    if (isFirstSave) {
      (newOptions || []).forEach(addedOption => {
        if (
          !selectedRows.find(
            selectedRow =>
              selectedRow.original[oppositeField]?.id === row[oppositeField]?.id &&
              selectedRow.original[field]?.id === addedOption.value,
          )
        ) {
          upsertTLanes.push(
            mapRowAndOptionToMutationInput(row, addedOption, updateLocationFieldName, false),
          );
        }
      });
    } else {
      addedOptions.forEach(addedOption => {
        upsertTLanes.push(
          mapRowAndOptionToMutationInput(row, addedOption, updateLocationFieldName, false),
        );
      });
    }
  });

  return {
    input: {
      upsertTLanes,
      removeTLanes,
    },
  };
};

export { mapDataToMassEditMutationArgs };
