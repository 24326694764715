import { FC } from 'react';

import { Loader } from 'shared/components/Loader';

import { useSetup } from './hooks';
import { SuppliersView } from './SuppliersView';

const Suppliers: FC = () => {
  const { showMainLoader, ...props } = useSetup();

  if (showMainLoader) {
    return <Loader isLoading />;
  }

  return <SuppliersView {...props} />;
};

export { Suppliers };
