import { FC } from 'react';
import classNames from 'classnames';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

import { ModalProps } from './types';
import styles from './Modal.module.css';

const Modal: FC<ModalProps> = ({
  children,
  hideCloseIcon = false,
  isOpen,
  onClose,
  size = 'standard',
  title = '',
}) => (
  <Dialog aria-labelledby="modal" classes={{ paper: styles[size] }} onClose={onClose} open={isOpen}>
    <div className={classNames(styles.header, { [styles.noTitle]: !title })}>
      {title && <Typography variant="h3">{title}</Typography>}
      {!hideCloseIcon && (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
    <DialogContent className={styles.content}>{children}</DialogContent>
  </Dialog>
);

export { Modal };
