import { FC, KeyboardEventHandler, useCallback, useState } from 'react';
import DatePickerBase, { DatePickerProps as DatePickerPropsBase } from '@mui/lab/DatePicker';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import startOfDay from 'date-fns/startOfDay';

import { InputStyled } from 'shared/components/InputStyled';

import { DatePickerProps } from './types';

const DatePicker: FC<DatePickerProps> = ({
  disabledDates = [],
  disableKeyboardInput = false,
  disabled,
  label = 'Select',
  onSubmit,
  value: initialValue = null,
  minDate,
  maxDate,
  shouldDisableDate,
}) => {
  const [value, setValue] = useState<Date | null>(initialValue);

  const handleChange = useCallback((newDate: Date | null) => {
    setValue(newDate);
  }, []);

  const handleAccept = useCallback(
    (newDate: Date | null) => {
      setValue(newDate);
      onSubmit(newDate);
    },
    [onSubmit],
  );

  const handleBlur = useCallback(() => {
    if (value === null || value.toString() !== 'Invalid Date') {
      onSubmit(value);
    }
  }, [value]);

  const handleKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  const renderInput = useCallback<DatePickerPropsBase['renderInput']>(
    ({ InputProps, inputProps, inputRef }) => (
      <InputStyled
        ref={inputRef}
        endAdornment={InputProps?.endAdornment}
        {...{ ...inputProps, onKeyPress: handleKeyPress, onBlur: handleBlur }}
        readOnly={disableKeyboardInput}
      />
    ),
    [disableKeyboardInput, handleKeyPress, handleBlur],
  );

  const shouldDisableDateFinal = useCallback(
    (day: Date) => {
      if (shouldDisableDate && shouldDisableDate(day)) return true;
      const unixTimeDay = getUnixTime(day);
      return (
        !(value && unixTimeDay === getUnixTime(value)) &&
        !!disabledDates?.length &&
        disabledDates.some(date => getUnixTime(startOfDay(fromUnixTime(date))) === unixTimeDay)
      );
    },
    [disabledDates, value, shouldDisableDate],
  );

  return (
    <DatePickerBase
      disabled={disabled}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      onAccept={handleAccept}
      onChange={handleChange}
      renderInput={renderInput}
      shouldDisableDate={shouldDisableDateFinal}
      value={value}
    />
  );
};

export { DatePicker };
