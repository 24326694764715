import Box from '@mui/material/Box';
import classNames from 'classnames';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

import styles from './Spinner.module.css';

const Spinner = ({ active, size }: { size: number; active: boolean }) => (
  <Box className={styles.wrapper}>
    <CircularProgress
      className={classNames({
        [styles.grey]: active,
        [styles.lightGrey]: !active,
      })}
      size={size}
      thickness={2}
      value={100}
      variant="determinate"
    />
    <CircularProgress
      className={classNames(styles.spinner, {
        [styles.blue]: active,
        [styles.red]: !active,
      })}
      disableShrink
      size={size}
      sx={{
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      thickness={4}
      variant="indeterminate"
    />
  </Box>
);

export { Spinner };
