import { FC } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ActionModal } from 'shared/components/Modal';

import { WarningDeleteTagModalProps } from './types';

const WarningDeleteTagModal: FC<WarningDeleteTagModalProps> = ({ isOpen, onClose, onProceed }) => (
  <ActionModal
    actions={
      <>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onProceed} variant="contained">
          Proceed
        </Button>
      </>
    }
    isOpen={isOpen}
    onClose={onClose}
    size="small"
    title="Warning"
  >
    Please be aware, you are trying to
    <Typography variant="bold1">{' remove value'}</Typography> which is currently used.
  </ActionModal>
);

export { WarningDeleteTagModal };
