import { Loader } from 'shared/components/Loader';

import { useSetup } from './hooks';
import { CostManagementView } from './CostManagementView';

const CostManagement = () => {
  const { showMainLoader, ...props } = useSetup();

  if (showMainLoader) {
    return <Loader isLoading />;
  }

  return <CostManagementView {...props} />;
};

export { CostManagement };
