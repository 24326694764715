import { FC } from 'react';
import {
  ApolloClient as ApolloClientBase,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { SnackbarAction, useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

import { deriveRowKey as deriveCostManagementRowKey } from 'pages/CostManagementV2';
import { config } from 'setup/config';
import {
  deriveRowKey as transportationLaneDeriveRowKey,
  Row as transportationLaneRow,
} from 'pages/TransportationLane';
import { deriveRowKey as supplierAssortmentDeriveRowKey } from 'pages/SupplierAssortment/utils';
import { Row as SupplierAssortmentRow } from 'pages/SupplierAssortment/types';
import { Row as BOMRow } from 'pages/BOM/types';
import { deriveRowKey as deriveBOMRowKey } from 'pages/BOM/utils';

import { defaultOnError } from './utils';
import { ApolloErrorHandler } from './types';

const apolloCache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'TransportationLane':
        return `TransportationLane:${transportationLaneDeriveRowKey(
          responseObject as transportationLaneRow,
        )}`;
      case 'SupplierAssortment':
        return `SupplierAssortment:${supplierAssortmentDeriveRowKey(
          responseObject as SupplierAssortmentRow,
        )}`;
      case 'BomItem':
        return `BomItem:${deriveBOMRowKey(responseObject as BOMRow)}`;
      case 'CostManagement':
        return deriveCostManagementRowKey(responseObject);
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

const ApolloClient: FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const closeSnackbarAction: SnackbarAction = snackbarId => (
    <Button
      onClick={() => closeSnackbar(snackbarId)}
      sx={{ color: 'error.contrastText' }}
      type="button"
    >
      Dismiss
    </Button>
  );

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // issue with as unknown https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369#issue-696555001
  const link = createUploadLink({
    uri: config.apiUrl,
  }) as unknown as ApolloLink;

  const authLink = setContext(
    async (_, { headers, ...context }: { headers: Record<string, any> }) => {
      // async () => {
      const token =
        (isAuthenticated &&
          (await getAccessTokenSilently({
            authorizationParams: {
              audience: config.auth0.authorizationParams.audience,
              scopes: ['openid', 'profile', 'email', 'username', 'roles'],
            },
          }))) ||
        '';
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
        ...context,
      };
    },
  );

  const errorLink = onError(e => {
    const {
      operation: { getContext },
    } = e;
    const { onError: customOnError } = (getContext() || {}) as {
      onError?: ApolloErrorHandler;
    };
    return customOnError
      ? customOnError(enqueueSnackbar, e)
      : defaultOnError(enqueueSnackbar, e, closeSnackbarAction);
  });

  const client = new ApolloClientBase({
    cache: apolloCache,
    link: errorLink.concat(authLink.concat(link)),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { ApolloClient, apolloCache };
