import Button from '@mui/material/Button';
import { format, fromUnixTime } from 'date-fns';

import { memoCell } from 'shared/utils';
import { CostDateApprovalStatus, CostDateStatus } from 'shared/types';

import { UseColumnsArgs } from '../../types';

import { FactoryCellActionsProps } from './types';

const FactoryCellActions = ({
  canApproveAndReject,
  canSubmit,
  deletableCostDateStatuses,
  onOpenCommentsModalClick,
  onOpenDeleteModalClick,
  updateCostDatesApprovalStatusCallback,
}: {
  canSubmit?: boolean;
  canApproveAndReject: boolean;
  deletableCostDateStatuses: UseColumnsArgs['deletableCostDateStatuses'];
  onOpenCommentsModalClick: UseColumnsArgs['onOpenCommentsModalClick'];
  updateCostDatesApprovalStatusCallback: UseColumnsArgs['updateCostDatesApprovalStatusCallback'];
  onOpenDeleteModalClick: UseColumnsArgs['onOpenDeleteModalClick'];
}) =>
  memoCell<FactoryCellActionsProps>(({ disableCells, row, value }) => {
    const deleteButton = (
      <Button
        color="error"
        disabled={disableCells}
        onClick={() => {
          if (row.original.costId && row.original.effectiveDate) {
            const input: Record<string, string[]> = {};
            input[row.original.costManagementId] = [row.original.costId];

            const supplierName =
              row.original.supplierName ?? row.original.parent?.supplierName ?? '';
            const supplierId = row.original.supplierId ?? row.original.parent?.supplierId ?? '';
            const supplierDisplay = supplierName
              ? `${supplierName}${supplierId ? ` (${supplierId})` : ''}`
              : '';

            const itemName = row.original.itemName ?? row.original.parent?.itemName ?? '';
            const itemId = row.original.itemId ?? row.original.parent?.itemId ?? '';
            const itemDisplay = itemName ? `${itemName}${itemId ? ` (${itemId})` : ''}` : '';

            const opcoName = row.original.opcoName ?? row.original.parent?.opcoName ?? '';
            const opcoId = row.original.opcoId ?? row.original.parent?.opcoId ?? '';
            const opcoDisplay = opcoName ? `${opcoName}${opcoId ? ` (${opcoId})` : ''}` : '';

            const locationName =
              row.original.locationName ?? row.original.parent?.locationName ?? '';

            onOpenDeleteModalClick(
              input,
              <div>
                <div>Are you sure you want to delete this cost?</div>
                <p>
                  <div>
                    Effective date: {format(fromUnixTime(row.original.effectiveDate), 'MM/dd/yyyy')}
                  </div>
                  <div>Supplier: {supplierDisplay}</div>
                  <div>Item: {itemDisplay}</div>
                  <div>Opco: {opcoDisplay}</div>
                  <div>Location: {locationName}</div>
                </p>
              </div>,
            );
          }
        }}
        size="small"
      >
        Delete
      </Button>
    );

    const sharedActions = deletableCostDateStatuses.includes(value) ? deleteButton : <></>;

    switch (value) {
      case CostDateStatus.Current:
        return sharedActions;
      case CostDateStatus.Past:
        return sharedActions;
      case CostDateStatus.Future:
        return sharedActions;
      case CostDateStatus.Pending:
        return (
          <>
            {canApproveAndReject ? (
              <>
                <Button
                  disabled={disableCells}
                  onClick={() => {
                    if (row.original.costId) {
                      onOpenCommentsModalClick(
                        [row.original.costId],
                        CostDateApprovalStatus.Approved,
                      );
                    }
                  }}
                  size="small"
                >
                  Approve
                </Button>
                <Button
                  color="error"
                  disabled={disableCells}
                  onClick={() => {
                    if (row.original.costId) {
                      onOpenCommentsModalClick(
                        [row.original.costId],
                        CostDateApprovalStatus.Rejected,
                      );
                    }
                  }}
                  size="small"
                >
                  Reject
                </Button>
              </>
            ) : (
              <></>
            )}

            {sharedActions}
          </>
        );
      case CostDateStatus.Rejected:
        return sharedActions;
      case CostDateStatus.Draft:
        return (
          <>
            {canSubmit ? (
              <>
                <Button
                  color="warning"
                  disabled={disableCells}
                  onClick={() => {
                    if (row.original.costId) {
                      updateCostDatesApprovalStatusCallback(
                        [row.original.costId],
                        CostDateApprovalStatus.Pending,
                      );
                    }
                  }}
                  size="small"
                >
                  Submit
                </Button>
              </>
            ) : (
              <></>
            )}
            {sharedActions}
          </>
        );
      default:
        return sharedActions;
    }
  });

export { FactoryCellActions };
