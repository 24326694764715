import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { SourceDestination } from 'shared/types';
import { transitionType } from 'shared/constants';

import { pickOptions, replicateN } from '../utils';
import { makeSourceDestination } from '../factories';

import { capacities } from './capacities';

const transitTypes = transitionType.map(({ name }) => name);
const capacitiesOptions = capacities();

const sourceDestinations = (
  props: O.Partial<SourceDestination, 'deep'>[] = [],
): SourceDestination[] =>
  mergeDeepArrays<SourceDestination>(
    replicateN({
      data: makeSourceDestination(),
      n: 5,
      replacements: {
        capacities: [
          ,
          pickOptions({ options: capacitiesOptions, pick: 1 }),
          pickOptions({ options: capacitiesOptions, pick: 2 }),
          pickOptions({ options: capacitiesOptions, pick: 3 }),
          pickOptions({ options: capacitiesOptions, pick: 4 }),
        ],
        transitType: [, ...transitTypes],
        notActiveSince: [, 1639915463, 0, 1639915463, 0],
        updatedAt: [, 1639915463, 1636141377, 1639915463, 1636141377],
      },
      skipIncrementForFields: [
        'capacities',
        'transitType',
        'deliveryWindowFrom',
        'deliveryWindowTo',
        'notActiveSince',
        'updatedAt',
      ],
    }),
    props,
  );

export { sourceDestinations };
