import { useCallback, useMemo } from 'react';

import { defaultOptionsLabel } from 'shared/components/Select';

import { BaseQueryArgs, mapAutocompleteValueToFilterParams } from '../shared';
import { FilterSearchAutocomplete } from '../FilterSearchAutocomplete';

import { FilterSearchSelectBinaryProps } from './types';

const FilterSearchSelectBinary = <QueryArgs extends BaseQueryArgs>({
  filterFieldName,
  mapUserAutocompleteValueSelectToFilterParams,
  optionsLabel: { noText, yesText } = defaultOptionsLabel,
  ...props
}: FilterSearchSelectBinaryProps<QueryArgs>) => {
  const options = useMemo(
    () => [
      { value: '1', label: yesText },
      { value: '0', label: noText },
    ],
    [yesText, noText],
  );
  const mapper = useCallback<
    NonNullable<
      FilterSearchSelectBinaryProps<QueryArgs>['mapUserAutocompleteValueSelectToFilterParams']
    >
  >(
    newValue =>
      mapAutocompleteValueToFilterParams(
        newValue,
        filterFieldName,
        mapUserAutocompleteValueSelectToFilterParams,
        v => ({ [filterFieldName]: Boolean(Number(v.value)) }),
      ),
    [filterFieldName, mapUserAutocompleteValueSelectToFilterParams],
  );

  return (
    <>
      <FilterSearchAutocomplete
        filterFieldName={filterFieldName}
        mapUserAutocompleteValueSelectToFilterParams={mapper}
        options={options}
        {...props}
      />
    </>
  );
};

export { FilterSearchSelectBinary };
