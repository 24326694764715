import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Column, Row as TableRow, UseExpandedState, UseRowSelectState } from 'react-table';
import { TablePaginationProps } from '@mui/material/TablePagination';

import { UpdateGridData } from 'shared/types/props';

type RowsPerPageOptions = TablePaginationProps['rowsPerPageOptions'];

export enum RowHighlightColor {
  Green = 'green',
  Yellow = 'yellow',
}

interface DataRow {
  id: string;
  rows?: DataRow[];
  highlightColor?: RowHighlightColor;
}

interface DataGridProps<DataType extends DataRow> {
  data: DataType[];
  columns: Column<DataType>[];
  borderAfterIndex?: number;
  pageSize?: number;
  pageIndex?: number;
  onPageChange?: TablePaginationProps['onPageChange'];
  onRowsPerPageChange?: TablePaginationProps['onRowsPerPageChange'];
  onChange?: UpdateGridData<DataType>;
  totalCount?: number;
  showPagination?: boolean;
  highlightRow?: (row: DataType) => boolean;
  highlightRowWithColor?: (row: DataType) => RowHighlightColor | undefined;
  deriveRowKey?: (row: DataType) => string;
  selectedRowIds?: UseRowSelectState<DataType>['selectedRowIds'];
  expandedRowIds?: UseExpandedState<DataType>['expanded'];
  enableRowSelection?: boolean;
  enableRowState?: boolean;
  checkIsCheckboxDisabled?: (row: TableRow<DataType>) => boolean;
  toggleAllTableRows?: (
    rows: TableRow<DataType>[],
    toggleRowSelected: (rowId: string, checked: boolean) => void,
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  getNumberOfEnableFields?: (rows: TableRow<DataType>[]) => number;
  checkIsCheckboxSelectable?: (arg: {
    selectedFlatRows: TableRow<DataType>[];
    row: TableRow<DataType>;
    allRows: TableRow<DataType>[];
    enabledFields?: number;
  }) => boolean;
  setSelectedRows?: Dispatch<SetStateAction<TableRow<DataType>[]>>;
  uncheckAllCheckboxes?: boolean;
  enableExpanding?: boolean;
  loadingMoreData?: boolean;
  rowsPerPageOptions?: RowsPerPageOptions;
  disableOnLoadingMoreData?: boolean;
  showLoaderOnLoadingMoreData?: boolean;
  usePreviousDataOnLoadingMore?: boolean;
  expandAllRows?: boolean;
  testId?: string;
  shouldExpandByDefault?: (row: DataType) => boolean;
  currentPageLoaded?: boolean;
}

export type { DataRow, DataGridProps, RowsPerPageOptions };
