import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { PackageType } from 'shared/types';
import { PackageTypeName } from 'shared/constants/package';

import { replicateN } from '../utils';
import { makePackageType } from '../factories';

const packageTypes = (props: O.Partial<PackageType, 'deep'>[] = []): PackageType[] =>
  mergeDeepArrays<PackageType>(
    replicateN({
      data: makePackageType(),
      n: 5,
      replacements: {
        name: [
          PackageTypeName.Each,
          PackageTypeName.Pallet,
          PackageTypeName.Inner,
          PackageTypeName.Case,
          PackageTypeName.MasterCase,
        ],
      },
      skipIncrementForFields: ['name'],
    }),
    props,
  );

export { packageTypes };
