import { mergeDeepRight } from 'ramda';
import { O } from 'ts-toolbelt';

import { AlcoholVolume } from 'shared/types';

const makeAlcoholVolume = (props: O.Partial<AlcoholVolume, 'deep'> = {}): AlcoholVolume =>
  mergeDeepRight<AlcoholVolume, O.Partial<AlcoholVolume, 'deep'>>(
    { id: '1', volume: 15, description: 'description' },
    props,
  );

export { makeAlcoholVolume };
