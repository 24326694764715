import { DealManagementDetailView } from './DealManagementDetailView';
import { useSetup } from './hooks';

const DealManagementDetail = () => {
  const props = useSetup();

  return <DealManagementDetailView {...props} />;
};

export { DealManagementDetail };
