import { useMemo } from 'react';

import { FactoryCellDataLink } from 'shared/components/DataGrid';
import { PAGE_DEAL_MANAGEMENT } from 'shared/constants';

import { MappedRow, UseColumnsResult } from '../types';
import { FactoryCellStatus } from '../components/FactoryCellStatus';
import { constants } from '../constants';

const CellStatus = FactoryCellStatus();

const useColumns = (): UseColumnsResult =>
  useMemo(
    () =>
      [
        {
          Header: constants.download.columns.header.offerNumber.displayName,
          accessor: 'offerNumber',
          Cell: FactoryCellDataLink<MappedRow>({
            isLink: () => true,
            linkProps: row => `${PAGE_DEAL_MANAGEMENT}/${row.id}`,
            useI18n: false,
          }),
        },
        {
          Header: constants.download.columns.header.status.displayName,
          accessor: 'status',
          Cell: CellStatus,
        },
        {
          Header: constants.download.columns.header.parentCompany.displayName,
          accessor: 'parentCompanyName',
        },
        {
          Header: constants.download.columns.header.submitterName.displayName,
          accessor: 'submitterName',
        },
        {
          Header: constants.download.columns.header.submitterEmail.displayName,
          accessor: 'submitterEmail',
        },
        {
          Header: constants.download.columns.header.description.displayName,
          accessor: 'description',
          Cell: FactoryCellDataLink<MappedRow>({
            maxWidth: '300px',
            useI18n: false,
          }),
        },
        {
          Header: constants.download.columns.header.type.displayName,
          accessor: 'type',
        },
        {
          Header: constants.download.columns.header.startDate.displayName,
          accessor: 'startDate',
        },
        {
          Header: constants.download.columns.header.endDate.displayName,
          accessor: 'endDate',
        },
        {
          Header: constants.download.columns.header.approvedBy.displayName,
          accessor: 'approvedBy',
        },
        {
          Header: constants.download.columns.header.approvedDate.displayName,
          accessor: 'approvedDate',
        },
        {
          Header: constants.download.columns.header.updatedDate.displayName,
          accessor: 'updatedDate',
        },
        {
          Header: constants.download.columns.header.updatedBy.displayName,
          accessor: 'updatedBy',
        },
        {
          Header: constants.download.columns.header.note.displayName,
          accessor: 'note',
          Cell: FactoryCellDataLink<MappedRow>({
            maxWidth: '300px',
            useI18n: false,
          }),
        },
      ].filter(Boolean) as UseColumnsResult,
    [],
  );

export { useColumns };
