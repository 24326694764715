import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { VelocityCategory } from 'shared/types';

import { replicateN } from '../utils';
import { makeVelocityCategory } from '../factories';

const velocityCategory = (props: O.Partial<VelocityCategory, 'deep'>[] = []): VelocityCategory[] =>
  mergeDeepArrays<VelocityCategory>(
    replicateN(makeVelocityCategory(), 5, {
      name: [, '2', '3', '4'],
    }),
    props,
  );

export { velocityCategory };
