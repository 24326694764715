import { ForwardedRef, forwardRef } from 'react';

import styles from './ComboboxContainer.module.css';
import { ComboboxContainerProps } from './types';

const ComboboxContainer = forwardRef(
  ({ children, ...props }: ComboboxContainerProps, ref: ForwardedRef<HTMLDivElement>) => (
    <div {...props} ref={ref} className={styles.comboboxContainer}>
      {children}
    </div>
  ),
);

export { ComboboxContainer };
