import { Row } from 'pages/Item/types';
import { ItemInput, UpdateItemMutationVariables } from 'shared/types';
import { mapRowToItemArgs } from 'pages/Item/mappers/mapRowToItemArgs';

const mapRowToMutationArgs = (
  row: Row,
  updatedChunk: Partial<ItemInput>,
): UpdateItemMutationVariables => ({
  input: {
    items: [
      {
        ...mapRowToItemArgs(row),
        ...updatedChunk,
      },
    ],
  },
});

export { mapRowToMutationArgs };
