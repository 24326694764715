import { constants } from '../constants';
import { MappedRow } from '../types';

export const mapRowsForDownload = (rows: MappedRow[]): Record<string, string>[] => {
  const rowsToDownload = rows.map(row => {
    const rowToDownload: Record<string, string> = {};
    rowToDownload[constants.download.columns.header.offerNumber.displayName] = row.offerNumber;
    rowToDownload[constants.download.columns.header.status.displayName] = row.status;
    rowToDownload[constants.download.columns.header.parentCompany.displayName] =
      row.parentCompanyName;
    rowToDownload[constants.download.columns.header.submitterName.displayName] = row.submitterName;
    rowToDownload[constants.download.columns.header.submitterEmail.displayName] =
      row.submitterEmail;
    rowToDownload[constants.download.columns.header.description.displayName] = row.description;
    rowToDownload[constants.download.columns.header.type.displayName] = row.type;
    rowToDownload[constants.download.columns.header.startDate.displayName] = row.startDate;
    rowToDownload[constants.download.columns.header.endDate.displayName] = row.endDate;
    rowToDownload[constants.download.columns.header.approvedBy.displayName] = row.approvedBy;
    rowToDownload[constants.download.columns.header.approvedDate.displayName] = row.approvedDate;
    rowToDownload[constants.download.columns.header.updatedDate.displayName] = row.updatedDate;
    rowToDownload[constants.download.columns.header.updatedBy.displayName] = row.updatedBy;
    rowToDownload[constants.download.columns.header.note.displayName] = row.note;

    // For detail page download only
    rowToDownload[constants.download.columns.header.locations.displayName] =
      row.country || 'See locations sheet for details';
    rowToDownload[constants.download.columns.header.startTimeZone.displayName] = row.startTimeZone;
    rowToDownload[constants.download.columns.header.endTimeZone.displayName] = row.endTimeZone;
    rowToDownload[constants.download.columns.header.currency.displayName] = row.currency;
    return rowToDownload;
  });

  return rowsToDownload;
};
