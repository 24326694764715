import { UseComboboxProps, useCombobox } from 'downshift';

import { SelectOption } from '../types';

const stateReducer: UseComboboxProps<SelectOption>['stateReducer'] = (state, actionAndChanges) => {
  const { changes, type } = actionAndChanges;
  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.FunctionSelectItem:
    case useCombobox.stateChangeTypes.ItemClick:
    case useCombobox.stateChangeTypes.InputBlur:
      return {
        ...changes,
        isOpen: true,
      };
    default:
      return changes;
  }
};

export { stateReducer };
