import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { TobaccoType } from 'shared/types';

const makeTobaccoType = (props: O.Partial<TobaccoType, 'deep'> = {}): TobaccoType =>
  merge<TobaccoType, O.Partial<TobaccoType, 'deep'>>(
    {
      id: '1',
      name: 'VP',
      description: 'Non-Nicotine Vaping Devices',
      restrictionCode: '123',
      updatedAt: 0,
      updatedBy: 'test',
      __typename: 'TobaccoType',
    },
    props,
  );

export { makeTobaccoType };
