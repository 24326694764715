import Chip from '@mui/material/Chip';

import { memoCell } from 'shared/utils';
import { CostDateStatus } from 'shared/types';

import { FactoryCellStatusProps } from './types';

const FactoryCellStatus = () =>
  memoCell<FactoryCellStatusProps>(({ value }) => {
    switch (value) {
      case CostDateStatus.Current:
        return <Chip color="success" label="Current" size="small" />;
      case CostDateStatus.Past:
        return <Chip color="info" label="Past" size="small" />;
      case CostDateStatus.Future:
        return <Chip color="secondary" label="Future" size="small" />;
      case CostDateStatus.Pending:
        return <Chip color="warning" label="Pending" size="small" />;
      case CostDateStatus.Rejected:
        return <Chip color="error" label="Rejected" size="small" />;
      case CostDateStatus.Draft:
        return <Chip label="Draft" size="small" />;
      default:
        return <></>;
    }
  });

export { FactoryCellStatus };
