import { FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import styles from './Input.module.css';

const Input: FC<TextFieldProps> = ({ InputProps, placeholder = 'Search', ...restProps }) => (
  <>
    {placeholder && (
      <div aria-hidden="true" className={styles.inputHiddenLabel}>
        {placeholder}
      </div>
    )}
    <TextField
      {...restProps}
      className={styles.input}
      InputProps={{
        ...InputProps,
        size: 'small',
      }}
      placeholder={placeholder}
    />
  </>
);

export { Input };
