import { FC, MouseEvent, memo } from 'react';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import styles from './PaginationActions.module.css';

const PaginationActions: FC<TablePaginationActionsProps> = memo<TablePaginationActionsProps>(
  ({ count, onPageChange, page, rowsPerPage }) => {
    const pageCount = Math.ceil(count / rowsPerPage) - 1;

    const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, pageCount));
    };

    return (
      <div className={styles.root}>
        <IconButton
          aria-label="first page"
          disabled={page === 0}
          onClick={handleFirstPageButtonClick}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          aria-label="previous page"
          disabled={page === 0}
          onClick={handleBackButtonClick}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          aria-label="next page"
          disabled={page >= pageCount}
          onClick={handleNextButtonClick}
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          aria-label="last page"
          disabled={page >= pageCount}
          onClick={handleLastPageButtonClick}
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  },
);

export { PaginationActions };
