import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { NationalAssortment } from 'shared/types';

import { replicateN } from '../utils';
import { makeNationalAssortment } from '../factories';

const nationalAssortments = (
  props: O.Partial<NationalAssortment, 'deep'>[] = [],
): NationalAssortment[] =>
  mergeDeepArrays<NationalAssortment>(
    replicateN(makeNationalAssortment(), 4, {
      name: [, 'C', 'E', 'G'],
    }),
    props,
  );

export { nationalAssortments };
