import { mergeDeepRight } from 'ramda';
import { O } from 'ts-toolbelt';

import { Location } from 'shared/types';

import { makeAlcoholType } from './makeAlcoholType';
import { makeTobaccoType } from './makeTobaccoType';
import { makeCustomAssortment } from './makeCustomAssortment';
import { makeCapability } from './makeCapability';
import { makeNationalAssortment } from './makeNationalAssortment';
import { makeAllocationPriority } from './makeAllocationPriority';

const makeLocation = (props: O.Partial<Location, 'deep'> = {}): Location =>
  mergeDeepRight<Location, O.Partial<Location, 'deep'>>(
    {
      districtId: 0,
      metroRegion: '',
      regionId: 0,
      city: 'Commonwealth',
      country: 'USA',
      extId: '12345',
      latitude: '50.450001',
      longitude: '30.523333',
      postalCode: '04214',
      mappedOpcoId: 1,
      stateProvinceRegion: 'Philadelphia',
      timezone: '+02:00',
      id: '20687',
      address: '20 something st',
      name: 'Philadelphia_6',
      openFrom: '07:00',
      openTo: '04:00',
      type: 'mfc',
      stopBuying: false,
      isServicePaused: false,
      isThirdParty: false,
      ageOutThreshold: 1,
      locationCustomAssortments: [makeCustomAssortment()],
      locationCapabilities: [makeCapability()],
      nationalAssortment: makeNationalAssortment(),
      allocationPriority: makeAllocationPriority(),
      locationAlcoholCapabilities: [makeAlcoholType()],
      locationTobaccoCapabilities: [makeTobaccoType()],
      orgDetails: {
        BillToLocation: 'New O',
        BillToBU: 'Some BU',
        ShipToOrganizationCode: '7494',
        ShipToOrganizationName: 'OrgName',
      },
      updatedAt: 0,
      updatedBy: 'test',
      locationExtraUpdatedAt: 0,
      locationExtraUpdatedBy: 'test',
      locationCapabilitiesUpdatedBy: 'test',
      locationCapabilitiesUpdatedAt: 0,
      locationCustomAssortmentsUpdatedAt: 0,
      locationCustomAssortmentsUpdatedBy: 'test',
      locationAlcoholCapabilitiesUpdatedAt: 0,
      locationAlcoholCapabilitiesUpdatedBy: 'test',
      locationTobaccoCapabilitiesUpdatedAt: 0,
      locationTobaccoCapabilitiesUpdatedBy: 'test',
      __typename: 'Location',
      legalEntities: [],
    },
    props,
  ) as Location;

export { makeLocation };
