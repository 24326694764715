const bytesToReadableSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`;
  }

  if (size > 1024 && size < 1048576) {
    return `${Math.round(size / 1024)} KB`;
  }

  return `${Math.round(size / 1048576)} MB`;
};

export { bytesToReadableSize };
