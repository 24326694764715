import { FC } from 'react';
import { IntlProvider } from 'react-intl';

const Intl: FC = ({ children }) => (
  <IntlProvider defaultLocale="en" locale="en">
    {children}
  </IntlProvider>
);

export { Intl };
