import { FC, useCallback, useState } from 'react';

import { DataGridToggleButtonsProps } from './types';
import { ToggleButton } from './components/ToggleButton';

const DataGridToggleButtons: FC<DataGridToggleButtonsProps> = ({
  onTogglePage,
  buttonNames: [firstButtonName, secondButtonName],
  activePageIndex = 0,
}) => {
  const [activeIndex, setActiveIndex] = useState(activePageIndex);

  const handleFirstButtonClick = useCallback(() => {
    if (activeIndex !== 0) {
      onTogglePage(0);
      setActiveIndex(0);
    }
  }, [activeIndex]);

  const handleSecondButtonClick = useCallback(() => {
    if (activeIndex !== 1) {
      onTogglePage(1);
      setActiveIndex(1);
    }
  }, [activeIndex]);

  return (
    <div>
      <ToggleButton
        active={activeIndex === 0}
        name={firstButtonName}
        onClick={handleFirstButtonClick}
      />
      <ToggleButton
        active={activeIndex === 1}
        name={secondButtonName}
        onClick={handleSecondButtonClick}
      />
    </div>
  );
};

export { DataGridToggleButtons };
