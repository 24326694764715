import { Row as TableRow } from 'react-table';

import { Row } from 'pages/Item/types';
import { FormValues } from 'pages/Item/components/MassEditFormRow';
import { mapRowToItemArgs } from 'pages/Item/mappers/mapRowToItemArgs';
import { makeArrayWithUniqElements } from 'shared/utils';

const mapRowToAddTagsMutationArgs = ({
  formData,
  selectedRows,
}: {
  selectedRows: TableRow<Row>[];
  formData: FormValues;
}) => ({
  input: {
    items: selectedRows.map(({ original }) => {
      const { capabilities, customAssortments, ...itemArgs } = mapRowToItemArgs(original);

      return {
        customAssortments: makeArrayWithUniqElements(customAssortments, formData.customAssortments),
        capabilities: makeArrayWithUniqElements(capabilities, formData.capabilities),
        ...itemArgs,
      };
    }),
  },
});

export { mapRowToAddTagsMutationArgs };
