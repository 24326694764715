import { Option } from 'shared/types/props';

enum Weight {
  Lb = '1',
  Oz = '2',
  G = '3',
  Mg = '4',
  Kg = '5',
}

const weightOptions: Option<Weight>[] = [
  {
    label: 'lb',
    value: Weight.Lb,
  },
  {
    label: 'oz',
    value: Weight.Oz,
  },
  {
    label: 'g',
    value: Weight.G,
  },
  {
    label: 'mg',
    value: Weight.Mg,
  },
  {
    label: 'kg',
    value: Weight.Kg,
  },
];

export { weightOptions, Weight };
