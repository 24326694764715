import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { clone, equals, identity } from 'ramda';
import { UseRowStateLocalState } from 'react-table';

import type { UseOnGridChangeArguments, UseOnGridChangeResult } from './types';

const useOnGridChange = <
  Row extends object,
  TData extends object,
  TVariables,
  MappedRow extends object = Row,
>({
  data,
  mapRowToMutationArgs,
  mutation,
  deriveColumnName = identity as UseOnGridChangeArguments<
    Row,
    TData,
    TVariables,
    MappedRow
  >['deriveColumnName'],
  refetchQueries = [],
  update,
  skipColumns = [],
  arbitraryMapperData,
  after,
  checkEqualityBeforeSendRequest = true,
  onError,
}: UseOnGridChangeArguments<
  Row,
  TData,
  TVariables,
  MappedRow
>): UseOnGridChangeResult<MappedRow> => {
  const [updateEntity, { loading }] = useMutation<TData, TVariables>(mutation, {
    refetchQueries,
    update,
    notifyOnNetworkStatusChange: true,
  });

  const [scheduledUpdate, setScheduledUpdate] = useState<Parameters<
    NonNullable<UseOnGridChangeResult<MappedRow>[0]>
  > | null>(null);

  const onChange = useCallback<UseOnGridChangeResult<MappedRow>[0]>(
    (...updateArgs) => {
      const mappedUpdateArgs = updateArgs;

      if (skipColumns.includes(updateArgs[1])) {
        return;
      }

      if (updateArgs.length >= 4) {
        mappedUpdateArgs[3] = clone(mappedUpdateArgs[3]);
      }

      setScheduledUpdate(mappedUpdateArgs);
    },
    [setScheduledUpdate, skipColumns],
  );

  useEffect(() => {
    if (scheduledUpdate) {
      const [
        rowIndex,
        columnId,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value,
        originalRow,
        tableRow,
        state,
      ] = scheduledUpdate;
      const row = data[rowIndex];

      // @ts-ignore
      if (checkEqualityBeforeSendRequest && equals(row[columnId], value)) {
        return;
      }

      void updateEntity({
        variables: {
          ...mapRowToMutationArgs(
            row,
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            { [deriveColumnName(columnId)]: value },
            originalRow,
            arbitraryMapperData,
            state as UseRowStateLocalState<Row>,
          ),
        },
        onCompleted: responseData => {
          after?.(responseData, tableRow);
        },
        context: {
          onError,
        },
      });

      setScheduledUpdate(null);
    }
  }, [
    arbitraryMapperData,
    scheduledUpdate,
    setScheduledUpdate,
    data,
    updateEntity,
    checkEqualityBeforeSendRequest,
  ]);

  return useMemo(() => [onChange, loading], [onChange, loading]);
};

export { useOnGridChange };
