import { useCallback, useState } from 'react';

import { AlcoholType } from 'shared/types';
import { CellProps } from 'shared/types/props';
import { SelectAlcoholType } from 'pages/Location/components/SelectAlcoholType';
import { memoCell } from 'shared/utils';
import { extractVolumeIds } from 'pages/Location/utils';
import { shouldDisable } from 'shared/components/DataGrid/components/Cells/utils';

import { CellValue, UpdateValue } from './types';

const FactoryCellSelectAlcoholType = <DataType extends object>(
  options: AlcoholType[],
  isDisabled?: (rowData: DataType) => boolean,
  dependencies?: (keyof DataType)[],
) =>
  memoCell<CellProps<DataType, CellValue, CellValue, UpdateValue>>(cellProps => {
    const {
      column: { id: columnId },
      row,
      updateGridData,
      value: selectedAlcoholTypes,
    } = cellProps;
    const disabled = shouldDisable(cellProps, isDisabled);
    const { index, original } = row;
    const [value, setValue] = useState<UpdateValue>(() => extractVolumeIds(selectedAlcoholTypes));
    const [valueBeforeOpen, setValueBeforeOpen] = useState<UpdateValue>([]);

    const handleOpen = useCallback(() => {
      setValueBeforeOpen(value);
    }, [setValueBeforeOpen, value]);

    const handleClose = useCallback(() => {
      if (
        !valueBeforeOpen.every(
          beforeVolumeId => !!value.find(afterVolumeId => afterVolumeId === beforeVolumeId),
        ) ||
        valueBeforeOpen.length !== value.length
      ) {
        updateGridData(index, columnId, value, original, row);
      }
    }, [updateGridData, index, columnId, value, valueBeforeOpen, original, row]);

    const onChange = useCallback(({ target: { value: newValue } }) => setValue(newValue), []);

    return (
      <SelectAlcoholType
        isDisabled={disabled}
        onChange={onChange}
        onClose={handleClose}
        onOpen={handleOpen}
        options={options}
        selectedAlcoholTypes={selectedAlcoholTypes}
        value={value}
      />
    );
  }, dependencies);

export { FactoryCellSelectAlcoholType };
