import { Fragment } from 'react';
import Tooltip from '@mui/material/Tooltip';

import { memoCell } from 'shared/utils';
import { CellProps } from 'shared/types';

import styles from './FactoryCellUPC.module.css';

const FactoryCellUPC = <DataType extends object>() =>
  memoCell<CellProps<DataType, string[]>>(({ value: initialValue }) => {
    if (!initialValue) {
      return null;
    }

    return (
      <>
        {initialValue.length <= 1 ? (
          <div>{initialValue[0]}</div>
        ) : (
          <Tooltip
            title={
              <div>
                {initialValue.map((item, index) => (
                  <Fragment key={item}>{index > 0 && <p>{item}</p>}</Fragment>
                ))}
              </div>
            }
          >
            <div className={styles.wrapper}>
              <div className={styles.text}>{initialValue[0]}</div>
              <div className={styles.amount}> +{initialValue.length - 1}</div>
            </div>
          </Tooltip>
        )}
      </>
    );
  });

export { FactoryCellUPC };
