import IconButton from '@mui/material/IconButton';
import { FC } from 'react';
import DownloadIcon from '@mui/icons-material/Download';

import styles from './DataGridDownloadButton.module.css';
import { DataGridDownloadButtonProps } from './types';

const DataGridDownloadButton: FC<DataGridDownloadButtonProps> = ({ disabled, onClick }) => (
  <>
    <IconButton className={styles.downloadButton} disabled={disabled} onClick={onClick}>
      <DownloadIcon className={styles.icon} data-testid="download-icon" />
    </IconButton>
  </>
);

export { DataGridDownloadButton };
