import { Row } from 'pages/Location/types';
import { LocationInput, UpdateLocationMutationVariables } from 'shared/types';
import { mapRowToLocationArgs } from 'pages/Location/mappers/mapRowToLocationArgs';

const mapRowToMutationArgs = (
  row: Row,
  updatedChunk: Partial<LocationInput>,
): UpdateLocationMutationVariables => ({
  input: {
    locations: [
      {
        ...mapRowToLocationArgs(row),
        ...updatedChunk,
      },
    ],
  },
});

export { mapRowToMutationArgs };
