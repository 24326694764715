import { FC } from 'react';
import Button from '@mui/material/Button';

import { ActionModal, ModalProps } from 'shared/components/Modal';

const ErrorModal: FC<ModalProps> = ({ children, onClose, size = 'small', ...props }) => (
  <ActionModal
    actions={
      <Button onClick={onClose} variant="outlined">
        Cancel
      </Button>
    }
    onClose={onClose}
    size={size}
    title="Error"
    {...props}
  >
    {children}
  </ActionModal>
);

export { ErrorModal };
