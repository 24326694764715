import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { mapDataToRows, mapRowToMutationArgs } from 'pages/Suppliers/mappers';
import { suppliersQuery, updateOpcoMutation } from 'pages/Suppliers/operations';
import { MappedRow, Row, SetupResult } from 'pages/Suppliers/types';
import { makePackageTypeOptions } from 'pages/Suppliers/utils';
import { useOnGridChange, usePagination, useRole } from 'shared/hooks';
import { buyersQuery, packageTypesQuery } from 'shared/operations/query';
import {
  BuyersQuery,
  BuyersQueryVariables,
  Option,
  PackageTypesQuery,
  PackageTypesQueryVariables,
  QuerySuppliersArgs,
  SuppliersQuery,
  SuppliersQueryVariables,
  UpdateOpcoMutation,
  UpdateOpcoMutationVariables,
} from 'shared/types';
import { isFirstLoad, mapToSelectOptions } from 'shared/utils';

import { onUpdateError } from '../handlers';

import { useColumns } from './useColumns';

const defaultItems: Row[] = [];

const useSetup = (): SetupResult => {
  const [mappedData, setMappedData] = useState<MappedRow[]>([]);
  const [filter, setFilter] = useState<SuppliersQueryVariables['filter']>({});
  const role = useRole();
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const {
    data: { suppliers: { suppliers: data, totalCount } } = {
      suppliers: {
        totalCount: 0,
        suppliers: defaultItems,
      },
    },
    loading,
    networkStatus,
  } = useQuery<SuppliersQuery, SuppliersQueryVariables>(suppliersQuery, {
    variables: {
      pagination,
      filter,
    },
  });
  const {
    data: { packageTypes } = {
      packageTypes: [],
    },
    networkStatus: packageTypesNetworkStatus,
  } = useQuery<PackageTypesQuery, PackageTypesQueryVariables>(packageTypesQuery);

  const {
    data: { buyers } = {
      buyers: [],
    },
    networkStatus: buyersNetworkStatus,
  } = useQuery<BuyersQuery, BuyersQueryVariables>(buyersQuery);

  const updateFilter = useCallback((newFilter: QuerySuppliersArgs['filter']) => {
    setFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
    resetPageIndex();
  }, []);

  const supplierPackageTypeOptions: Option<string, string>[] = useMemo(
    () => makePackageTypeOptions(packageTypes),
    [packageTypes],
  );

  const opcoPackageTypeOptions: Option<string, string>[] = useMemo(
    () => mapToSelectOptions(packageTypes),
    [packageTypes],
  );

  const [opcoBuyerOptions, setOpcoBuyerOptions] = useState<Option<string, string>[]>([]);
  useEffect(() => {
    if (buyers?.length) {
      setOpcoBuyerOptions(
        mapToSelectOptions(buyers, ({ email }) => ({ label: email, value: email })),
      );
    }
  }, [buyers]);

  useEffect(() => {
    if (data !== defaultItems) {
      setMappedData(mapDataToRows(data));
    }
  }, [data]);

  const columns = useColumns({
    supplierPackageTypeOptions,
    opcoPackageTypeOptions,
    updateFilter,
    role,
    opcoBuyerOptions,
    onBuyersEntryAdded: v => {
      const value = v as string;
      setOpcoBuyerOptions(opcoBuyerOptions.concat([{ label: value, value }]));
    },
  });

  const arbitraryMapperData = useMemo(
    () => ({ packageTypeOptions: supplierPackageTypeOptions }),
    [supplierPackageTypeOptions],
  );

  const [onChange, mutationLoading] = useOnGridChange<
    Row,
    UpdateOpcoMutation,
    UpdateOpcoMutationVariables,
    MappedRow
  >({
    checkEqualityBeforeSendRequest: false,
    data,
    mutation: updateOpcoMutation,
    mapRowToMutationArgs,
    arbitraryMapperData,
    onError: onUpdateError,
  });

  return {
    columns,
    data: mappedData,
    loadingMoreData: loading || mutationLoading,
    showMainLoader:
      isFirstLoad(networkStatus) ||
      isFirstLoad(packageTypesNetworkStatus) ||
      isFirstLoad(buyersNetworkStatus) ||
      (totalCount !== 0 && !mappedData.length),
    totalCount,
    onChange,
    onPageChange,
    onRowsPerPageChange,
    pageIndex,
    pageSize,
  };
};

export { useSetup };
