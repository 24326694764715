import { FC, useState } from 'react';
import { Box, Tabs as MuiTabs, Tab } from '@mui/material';

import { TabsProps } from './types';

export const Tabs: FC<TabsProps> = ({ tabs }) => {
  const [currentTabKey, setCurrentTabKey] = useState(tabs[0].key);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabKey(newValue);
  };

  return (
    <Box>
      <MuiTabs onChange={handleChange} value={currentTabKey}>
        {tabs.map(tab => (
          <Tab key={tab.key} label={tab.label} />
        ))}
      </MuiTabs>
      <Box paddingTop="20px">
        {tabs.map(tab => (
          <Box key={tab.key} hidden={tab.key !== currentTabKey}>
            {tab.children}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
