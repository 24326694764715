import { FC } from 'react';

import styles from './FieldLabel.module.css';
import { FieldLabelProps } from './types';

const FieldLabel: FC<FieldLabelProps> = ({ children, name }) => (
  <label className={styles.label} htmlFor={name}>
    {children}
  </label>
);

export { FieldLabel };
