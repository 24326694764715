import { FunctionComponent, memo } from 'react';
import { equals, pick } from 'ramda';

import { CellProps } from 'shared/types/props';

const memoCell = <P extends CellProps<any, any>>(
  Component: FunctionComponent<P>,
  dependencies: (keyof P['row']['original'])[] = [],
  hasStateDependencies = false,
  deriveHasDependenciesChanged?: (
    previousRow: P['row']['original'],
    nextRow: P['row']['original'],
  ) => boolean,
) =>
  memo(
    Component,
    (
      {
        disableCells: previousDisableCells,
        row: { index: previousIndex, isExpanded: previousIsExpanded, original: previousRow },
        value: previousValue,
      },
      {
        disableCells: nextDisableCells,
        row: { index: nextIndex, isExpanded: nextIsExpanded, original: nextRow },
        value: nextValue,
      },
    ) =>
      hasStateDependencies
        ? false
        : equals(previousValue, nextValue) &&
          previousDisableCells === nextDisableCells &&
          previousIndex === nextIndex &&
          previousIsExpanded === nextIsExpanded &&
          (!dependencies.length ||
            equals(pick(dependencies, previousRow), pick(dependencies, nextRow))) &&
          !deriveHasDependenciesChanged?.(previousRow, nextRow),
  );

export { memoCell };
