import { FC } from 'react';
import { Button } from '@mui/material';

import { DataGridPage } from 'shared/components/DataGridPage';
import { DataGrid } from 'shared/components/DataGrid';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';
import { ConfirmModal } from 'shared/components/Modal/ConfirmModal';
import { CommentsModal } from 'shared/components/Modal/CommentsModal';
import { FilterSearchSelect } from 'shared/components/Search/Filter/FilterSearchSelect';
import { CostDateApprovalStatus, SupplierAssortmentCostFilter } from 'shared/types';
import { Loader } from 'shared/components/Loader';

import { ActionButton } from './components/ActionButton';
import { AddModal } from './components/AddModal';
import { CostManagementViewProps } from './types';
// import { CommentsModal } from './components/CommentsModal';
import { isMvp, warningMessage } from './constants';
import styles from './CostManagementView.module.css';

const CostManagementView: FC<CostManagementViewProps> = ({
  approveOrRejectWarningMessage,
  areSomeRowsSelected,
  canApproveAndReject,
  canBulkDelete,
  canSubmit,
  canUpload,
  commentsModalContext,
  commentsRequired,
  defaultFilter,
  deleteCostDatesLoading,
  deleteMessage,
  downloadButtonDisabled,
  isLoading,
  onAddClick,
  onBulkApproveOrRejectClick,
  onBulkDeleteClick,
  onCloseAddModalClick,
  onCloseApproveOrRejectWarningModalClick,
  onCloseCommentsModalClick,
  onCloseDeleteModalClick,
  onCloseDownloadModalClick,
  onConfirmApproveOrRejectWarningModalClick,
  onConfirmDeleteModalClick,
  onConfirmDownloadModalClick,
  onDataGridDownloadButtonClick,
  onSaveAndCloseAddModalClick,
  onSubmitCommentsModalClick,
  selectedRows,
  shouldShowAddModal,
  shouldShowApproveOrRejectWarningModal,
  shouldShowCommentsModal,
  shouldShowDeleteModal,
  shouldShowDownloadModal,
  showMainLoader,
  updateCostDatesApprovalStatusLoading,
  uploadFileProps,
  shouldExpandByDefault,
  ...props
}) => (
  <>
    <DataGridPage
      header={
        <HeaderDataGridActions
          downloadButtonDisabled={downloadButtonDisabled}
          hasAccessToUploadFile={canUpload}
          onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
          title="Cost Management"
          uploadFileProps={uploadFileProps}
        >
          {canSubmit && !isMvp && <ActionButton onClick={onAddClick} title="Submit" />}
          {!isMvp && <ActionButton onClick={onAddClick} title="Add" />}
          <FilterSearchSelect
            defaultOption={defaultFilter.supplierAssortment}
            filterFieldName="supplierAssortment"
            label="Views"
            onSubmitFilter={props.updateFilter}
            options={[
              { label: 'All costs', value: SupplierAssortmentCostFilter.All.toString() },
              {
                label: 'Supplier assortment costs only',
                value: SupplierAssortmentCostFilter.Existing.toString(),
              },
              { label: 'Missing costs', value: SupplierAssortmentCostFilter.Missing.toString() },
            ]}
          />
          {canApproveAndReject && (
            <>
              <Button
                className={styles.headerGridAction}
                disabled={!areSomeRowsSelected || updateCostDatesApprovalStatusLoading}
                onClick={() => onBulkApproveOrRejectClick(CostDateApprovalStatus.Approved)}
                variant="outlined"
              >
                Approve
              </Button>
              <Button
                className={styles.headerGridAction}
                color="error"
                disabled={!areSomeRowsSelected || updateCostDatesApprovalStatusLoading}
                onClick={() => onBulkApproveOrRejectClick(CostDateApprovalStatus.Rejected)}
                sx={{ color: 'error.main' }}
                variant="outlined"
              >
                Reject
              </Button>
            </>
          )}
          {canBulkDelete && (
            <Button
              className={styles.headerGridAction}
              color="error"
              disabled={!areSomeRowsSelected || deleteCostDatesLoading}
              onClick={onBulkDeleteClick}
              sx={{ color: 'error.main' }}
              variant="outlined"
            >
              Delete
            </Button>
          )}
        </HeaderDataGridActions>
      }
      isLoading={isLoading}
      selectedRows={selectedRows}
    >
      {showMainLoader ? (
        <Loader isLoading />
      ) : (
        <DataGrid
          {...props}
          disableOnLoadingMoreData
          enableExpanding
          enableRowSelection
          expandAllRows
          shouldExpandByDefault={shouldExpandByDefault}
          testId="vendor-cost-management-data-grid"
        />
      )}
    </DataGridPage>
    <AddModal
      isOpen={shouldShowAddModal}
      onClose={onCloseAddModalClick}
      onSaveAndClose={onSaveAndCloseAddModalClick}
    />
    <CommentsModal
      commentsRequired={commentsRequired}
      info={
        commentsModalContext?.costDateIds?.length && commentsModalContext.costDateIds.length > 1 ? (
          <div style={{ marginBottom: '10px' }}>
            Note will be applied to all {commentsModalContext.costDateIds.length} pending costs.
          </div>
        ) : null
      }
      isOpen={shouldShowCommentsModal}
      onClose={onCloseCommentsModalClick}
      onSubmit={onSubmitCommentsModalClick}
    />
    <ConfirmModal
      confirmMessage={deleteMessage}
      isOpen={shouldShowDeleteModal}
      onClose={onCloseDeleteModalClick}
      onConfirm={onConfirmDeleteModalClick}
    />
    <ConfirmModal
      confirmMessage={warningMessage(props.rowsToDownloadCount)}
      isOpen={shouldShowDownloadModal}
      onClose={onCloseDownloadModalClick}
      onConfirm={onConfirmDownloadModalClick}
    />
    <ConfirmModal
      confirmMessage={approveOrRejectWarningMessage}
      isOpen={shouldShowApproveOrRejectWarningModal}
      onClose={onCloseApproveOrRejectWarningModalClick}
      onConfirm={onConfirmApproveOrRejectWarningModalClick}
    />
  </>
);

export { CostManagementView };
