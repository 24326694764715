import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';

import {
  CostDateFragmentFragment,
  CostDateType,
  CostManagementDownloadQuery,
  CostManagementInput,
  CostManagementsQuery,
  CostManagementsQueryVariables,
  CurrenciesQuery,
  MassEditCostManagementMutation,
  MassEditCostManagementMutationVariables,
  PackageTypesQuery,
  PackageTypesQueryVariables,
  UpdateCostDatesMutation,
  UpdateCostDatesMutationVariables,
  UpdateGridData,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import {
  AddModalFormValues,
  MappedRow,
  PackageTypeIdNameMap,
  Row,
  SetupResult,
  UnsubmittedRows,
} from 'pages/CostManagement/types';
import { useEditPage, useOnGridChange, usePagination, useRole, useUploadFile } from 'shared/hooks';
import { ROLE_ADMIN, ROLE_PRICING } from 'shared/constants/access';
import {
  costManagementDownloadQuery,
  costManagementMassEditMutation,
  costManagementUploadMutation,
  costManagementsQuery,
  fragmentCostDates,
  updateCostDatesMutation,
} from 'pages/CostManagement/operations';
import { mapDataToRows, mapRowToMutationArgs } from 'pages/CostManagement/mappers';
import {
  displayNameOptionMapper,
  downloadBase64StringAsFile,
  isFirstLoad,
  mapToCurrenciesOptions,
  mapToLanguage,
  mapToSelectOptions,
  mergeFilter,
} from 'shared/utils';
import { currenciesQuery, packageTypesQuery, usersQuery } from 'shared/operations/query';
import { PackageTypeName } from 'shared/constants/package';
import { onUpdateError } from 'pages/CostManagement/handlers';
import { UseOnGridChangeResult } from 'shared/hooks/useOnGridChange/types';
import { SessionStorageContext } from 'setup/providers/Context/Context';

import { CostDateDefaultStatusFilter } from '../constants';

import { useColumns } from './useColumns';

const defaultItems: Row[] = [];

const defaultFilter: CostManagementsQueryVariables['filter'] = {
  costDateStatuses: CostDateDefaultStatusFilter,
  costDateTypes: [CostDateType.Regular, CostDateType.Empty],
};

const useSetup = (): SetupResult => {
  const [unsubmittedRows, setUnsubmittedRows] = useState<UnsubmittedRows>({});
  const [shouldShowAddModal, setShouldShowAddModal] = useState<boolean>(false);
  const [mappedData, setMappedData] = useState<MappedRow[]>([]);
  const [isFirstLoadingFinish, setIsFirstLoadingFinish] = useState(false);
  const [filter, setFilter] = useState<CostManagementsQueryVariables['filter']>(defaultFilter);
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const { language } = useContext(SessionStorageContext);

  const {
    data: { costManagementRecords: { records: data, totalCount } } = {
      costManagementRecords: {
        totalCount: 0,
        records: defaultItems,
      },
    },
    loading,
    networkStatus,
    client,
  } = useQuery<CostManagementsQuery, CostManagementsQueryVariables>(costManagementsQuery, {
    variables: {
      pagination,
      filter,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const {
    data: { packageTypes } = {
      packageTypes: [],
    },
    networkStatus: packageTypesNetworkStatus,
    loading: packageTypesLoading,
  } = useQuery<PackageTypesQuery, PackageTypesQueryVariables>(packageTypesQuery);

  const { data: { currencies } = { currencies: [] }, networkStatus: currenciesNetworkStatus } =
    useQuery<CurrenciesQuery>(currenciesQuery);

  const {
    data: { users } = { users: [] },
    networkStatus: usersNetworkStatus,
    loading: usersLoading,
  } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery);

  const [saveFormChanges, { loading: massEditMutationLoading }] = useMutation<
    MassEditCostManagementMutation,
    MassEditCostManagementMutationVariables
  >(costManagementMassEditMutation, {
    update(cache) {
      cache.evict({
        fieldName: 'costManagementRecords',
        broadcast: true,
      });
    },
  });

  const packageTypeIdNameMap = useMemo<PackageTypeIdNameMap>(() => {
    const result: PackageTypeIdNameMap = {} as PackageTypeIdNameMap;
    packageTypes.forEach(({ id, name }) => {
      result[name as PackageTypeName] = id;
    });
    return result;
  }, [packageTypes]);

  const arbitraryMapperData = useMemo(() => ({ packageTypeIdNameMap }), [packageTypeIdNameMap]);

  const role = useRole();

  const canEdit = role === ROLE_ADMIN || role === ROLE_PRICING;

  useEffect(() => {
    if (!packageTypesLoading && !usersLoading && data !== defaultItems) {
      setMappedData(mapDataToRows(data, packageTypeIdNameMap, users, canEdit, unsubmittedRows));
      setIsFirstLoadingFinish(true);
    }
  }, [
    packageTypesLoading,
    data,
    packageTypeIdNameMap,
    users,
    usersLoading,
    canEdit,
    unsubmittedRows,
  ]);

  useEffect(() => {
    if (isFirstLoad(networkStatus)) {
      setIsFirstLoadingFinish(false);
    }
  }, [networkStatus]);

  const currenciesOptions = useMemo(() => mapToCurrenciesOptions(currencies), [currencies]);

  const updateFilter = useCallback((newFilter: CostManagementsQueryVariables['filter']) => {
    setFilter(prevFilter => mergeFilter(prevFilter, newFilter, defaultFilter));

    resetPageIndex();
  }, []);

  const onAddClick = useCallback(() => {
    setShouldShowAddModal(true);
  }, []);

  const onCloseAddModalClick = useCallback(() => {
    setShouldShowAddModal(false);
  }, []);

  const onSaveAndCloseAddModalClick = useCallback(
    ({ item, location, opco, supplier }: UnpackNestedValue<AddModalFormValues>) => {
      const input: CostManagementInput[] = [];

      item!.forEach(i => {
        supplier!.forEach(s => {
          if (!location || !location.length) {
            if (!opco || !opco.length) {
              input.push({
                itemId: i.value,
                supplierId: s.value,
                isDisabled: false,
              });
            } else {
              opco.forEach(o => {
                input.push({
                  itemId: i.value,
                  supplierId: s.value,
                  opcoId: o.value,
                  isDisabled: false,
                });
              });
            }
          } else {
            location.forEach(l => {
              if (!opco || !opco.length) {
                input.push({
                  itemId: i.value,
                  supplierId: s.value,
                  locationId: l.value,
                  isDisabled: false,
                });
              } else {
                opco.forEach(o => {
                  input.push({
                    itemId: i.value,
                    supplierId: s.value,
                    locationId: l.value,
                    opcoId: o.value,
                    isDisabled: false,
                  });
                });
              }
            });
          }
        });
      });

      setShouldShowAddModal(false);

      return saveFormChanges({
        variables: {
          input: {
            upsert: input,
          },
        },
      });
    },
    [],
  );

  const updateIsDisabled = useCallback<UpdateGridData<MappedRow, boolean>>(
    (index, columnId, value, original) =>
      saveFormChanges({
        variables: {
          input: {
            upsert: [
              {
                itemId: original.internalItemId!,
                supplierId: original.internalSupplierId!,
                locationId: original.internalLocationId,
                opcoId: original.opcoId,
                isDisabled: value,
              },
            ],
          },
        },
      }),
    [],
  );

  const userOptions = useMemo(() => mapToSelectOptions(users, displayNameOptionMapper), [users]);

  const columns = useColumns({
    canEdit,
    currenciesOptions,
    updateFilter,
    userOptions,
    updateIsDisabled,
    setUnsubmittedRows,
    languageCode: mapToLanguage.get(language) || 'en-US',
  });

  const { selectedRows, setSelectedRows, triggerUncheckAllCheckboxes, uncheckAllCheckboxes } =
    useEditPage<MappedRow>();

  const uploadFileProps = useUploadFile({
    downloadTemplateQuery: costManagementDownloadQuery,
    customFilter: { id: [] },
    mutation: costManagementUploadMutation,
    client,
    evictCacheForQueryName: 'costManagementRecords',
  });

  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setDownloadButtonDisabled(true);
    } else {
      setDownloadButtonDisabled(false);
    }
  }, [selectedRows]);

  const [downloadCostManagement, { loading: downloadCostManagementLoading }] = useLazyQuery<
    CostManagementDownloadQuery,
    CostManagementsQueryVariables
  >(costManagementDownloadQuery, {
    // no-cache required to prevent download missfires
    fetchPolicy: 'no-cache',
    onCompleted(dataForDownload) {
      triggerUncheckAllCheckboxes();
      setDownloadButtonDisabled(false);
      downloadBase64StringAsFile(
        dataForDownload.costManagementDownload.file.content,
        dataForDownload.costManagementDownload.file.name,
      );
    },
    onError() {
      setDownloadButtonDisabled(false);
    },
  });

  const onDataGridDownloadButtonClick = useCallback(() => {
    if (downloadButtonDisabled) return;
    setDownloadButtonDisabled(true);
    void downloadCostManagement({
      variables: {
        filter: {
          ...filter,
          id: selectedRows.map(row => row.original.costManagementId),
        },
        pagination: {
          limit: selectedRows.length,
          offset: 0,
        },
      },
    });
  }, [selectedRows, triggerUncheckAllCheckboxes, downloadButtonDisabled]);

  const [onChangeDefault, mutationLoading] = useOnGridChange<
    Row,
    UpdateCostDatesMutation,
    UpdateCostDatesMutationVariables,
    MappedRow
  >({
    checkEqualityBeforeSendRequest: false,
    data,
    mutation: updateCostDatesMutation,
    mapRowToMutationArgs,
    arbitraryMapperData,
    update(cache, updateData) {
      const [updateCostDate] = updateData.data!.updateCostDates;

      const id = `CostManagement:${updateCostDate.costManagementId}`;
      const { costDates } = cache.readFragment<CostDateFragmentFragment>({
        id,
        fragment: fragmentCostDates,
      })!;

      if (!costDates.some(costDate => costDate.id === updateCostDate.id)) {
        cache.writeFragment<CostDateFragmentFragment>({
          id,
          data: { costDates: [...costDates, updateCostDate] },
          fragment: fragmentCostDates,
        });
      }
    },
    onError: onUpdateError,
  });

  const onChange = useCallback<UseOnGridChangeResult<MappedRow>[0]>(
    (...updateArgs) => {
      const [
        ,
        columnName,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        newValue,
        row,
      ] = updateArgs;
      if (
        !row.isUnsubmitted ||
        (columnName !== 'currency' && columnName !== 'effectiveDate') ||
        row[PackageTypeName.Each]! > 0 ||
        row[PackageTypeName.Inner]! > 0 ||
        row[PackageTypeName.Case]! > 0 ||
        row[PackageTypeName.MasterCase]! > 0 ||
        row[PackageTypeName.Pallet]! > 0
      ) {
        if (row.isUnsubmitted) {
          setUnsubmittedRows(oldValue => {
            const newUnsubmittedRowsValue: UnsubmittedRows = { ...oldValue };
            delete newUnsubmittedRowsValue[row.parent!.id];
            return newUnsubmittedRowsValue;
          });
        }

        onChangeDefault(...updateArgs);
      } else {
        setUnsubmittedRows(oldValue => ({
          ...oldValue,
          [row.parent!.id]: oldValue[row.parent!.id].map(unsubmittedRow =>
            unsubmittedRow.id === row.id
              ? {
                  ...unsubmittedRow,
                  // @ts-ignore
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  [columnName]: newValue,
                }
              : unsubmittedRow,
          ),
        }));
      }
    },
    [onChangeDefault],
  );

  return {
    canEdit,
    data: mappedData,
    columns,
    onAddClick,
    onCloseAddModalClick,
    onChange,
    onDataGridDownloadButtonClick,
    downloadButtonDisabled,
    onSaveAndCloseAddModalClick,
    loadingMoreData:
      loading || mutationLoading || massEditMutationLoading || downloadCostManagementLoading,
    showMainLoader:
      isFirstLoad(networkStatus) ||
      isFirstLoad(packageTypesNetworkStatus) ||
      isFirstLoad(currenciesNetworkStatus) ||
      isFirstLoad(usersNetworkStatus) ||
      (totalCount !== 0 && !mappedData.length) ||
      !isFirstLoadingFinish,
    shouldShowAddModal,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageIndex,
    pageSize,
    selectedRows,
    setSelectedRows,
    uncheckAllCheckboxes,
    uploadFileProps,
  };
};

export { useSetup };
