import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { OptionValueType } from 'shared/components/Select';

import styles from './Option.module.css';
import type { OptionProps } from './types';

const Option = <Value extends OptionValueType>({ label, selected, value }: OptionProps<Value>) => (
  <>
    <Checkbox checked={selected.indexOf(value) > -1} className={styles.labelCheck} />
    <ListItemText className={styles.label} primary={label} />
  </>
);

export { Option };
