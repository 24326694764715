import {
  ExternalUserVendorCostDateDefaultStatusFilter,
  InternalUserVendorCostDateDefaultStatusFilter,
} from 'pages/CostManagementV2/constants/vendorCostDateDefaultStatusFilter';
import {
  ExternalUserVendorCostDateStatusFilter,
  InternalUserVendorCostDateStatusFilter,
} from 'pages/CostManagementV2/constants/vendorCostDateStatusFilter';
import { CostDateStatus } from 'shared/types';

import * as ROLES from '../../../shared/constants/access/roles';
import { RolesType } from '../../../shared/constants/access/permissions';

const {
  ROLE_ADMIN,
  ROLE_ANALYST,
  ROLE_BUYER,
  ROLE_MANAGER,
  ROLE_PRICING,
  ROLE_READ_ONLY,
  ROLE_VENDOR,
} = ROLES;

const filterPermissions: {
  [key in RolesType]?: {
    vendorCostManagement: { options: CostDateStatus[]; default: CostDateStatus[] };
  };
} = {
  [ROLE_ADMIN]: {
    vendorCostManagement: {
      options: InternalUserVendorCostDateStatusFilter,
      default: InternalUserVendorCostDateDefaultStatusFilter,
    },
  },
  [ROLE_MANAGER]: {
    vendorCostManagement: {
      options: InternalUserVendorCostDateStatusFilter,
      default: InternalUserVendorCostDateDefaultStatusFilter,
    },
  },
  [ROLE_PRICING]: {
    vendorCostManagement: {
      options: InternalUserVendorCostDateStatusFilter,
      default: InternalUserVendorCostDateDefaultStatusFilter,
    },
  },
  [ROLE_ANALYST]: {
    vendorCostManagement: {
      options: InternalUserVendorCostDateStatusFilter,
      default: InternalUserVendorCostDateDefaultStatusFilter,
    },
  },
  [ROLE_BUYER]: {
    vendorCostManagement: {
      options: InternalUserVendorCostDateStatusFilter,
      default: InternalUserVendorCostDateDefaultStatusFilter,
    },
  },
  [ROLE_READ_ONLY]: {
    vendorCostManagement: {
      options: InternalUserVendorCostDateStatusFilter,
      default: InternalUserVendorCostDateDefaultStatusFilter,
    },
  },
  [ROLE_VENDOR]: {
    vendorCostManagement: {
      options: ExternalUserVendorCostDateStatusFilter,
      default: ExternalUserVendorCostDateDefaultStatusFilter,
    },
  },
};

export { filterPermissions };
