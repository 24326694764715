import { CellObject } from 'xlsx';
import * as XLSX from 'xlsx';

import { ColumnType } from './constants';

export interface XlsxFileTableHeader {
  displayName: string;
  type?: ColumnType;
  width?: number;
}

export interface XlsxFileTable {
  headers: XlsxFileTableHeader[];
  rows: Array<Record<string, unknown>>;
  emptyRowsToAddIfDataIsEmpty?: number; // default 1
}

export interface XlsxFileSheet {
  sheetName?: string;
  tables: XlsxFileTable[];
  arrangeTablesHorizontally?: boolean;
}

export interface ExportXlsxFileInput {
  fileName: string;
  sheets: XlsxFileSheet[];
}

export const exportXlsxFile = (input: ExportXlsxFileInput) => {
  const book = XLSX.utils.book_new();

  for (let i = 0; i < input.sheets.length; i++) {
    const inputSheet = input.sheets[i];
    const tables: Array<Array<Array<unknown>>> = [];
    const columnWidths: number[] = [];

    const inputTables = [];
    if (inputSheet.arrangeTablesHorizontally) {
      const newTable: XlsxFileTable = {
        headers: [],
        rows: [],
      };

      inputSheet.tables.forEach(table => {
        if (newTable.headers.length) newTable.headers.push({ displayName: '', width: 10 });
        newTable.headers.push(...table.headers);

        table.rows.forEach((newColumns, rowIndex) => {
          const existingColumns = newTable.rows[rowIndex] ?? {};
          newTable.rows[rowIndex] = { ...existingColumns, ...newColumns };
        });
      });
      inputTables.push(newTable);
    } else {
      inputTables.push(...inputSheet.tables);
    }

    inputTables.forEach(table => {
      const headers = [];
      for (let headerIndex = 0; headerIndex < table.headers.length; headerIndex++) {
        const header = table.headers[headerIndex];
        headers.push(header.displayName);
        columnWidths[headerIndex] = Math.max(
          columnWidths[headerIndex] ?? 0,
          header.width ?? header.displayName.length,
        );
      }

      const rows = table.rows.map(row =>
        table.headers.map(header => row[header.displayName] ?? ''),
      );

      const emptyRowsToAddIfDataIsEmpty = table.emptyRowsToAddIfDataIsEmpty ?? 1;
      if (!rows.length && emptyRowsToAddIfDataIsEmpty) {
        for (let rowsToAdd = 0; rowsToAdd < emptyRowsToAddIfDataIsEmpty; rowsToAdd++) {
          rows.push(Array.from({ length: table.headers.length }, () => ''));
        }
      }

      tables.push([headers, ...rows]);
    });

    let sheet: XLSX.WorkSheet | undefined;
    let currentSheetRow = 0;

    for (let tableIndex = 0; tableIndex < tables.length; tableIndex++) {
      const table = tables[tableIndex];

      if (!sheet) {
        sheet = XLSX.utils.aoa_to_sheet(table, {
          cellStyles: true,
          cellDates: true,
          sheetStubs: true,
        });
      } else {
        XLSX.utils.sheet_add_aoa(sheet, table, {
          origin: { r: currentSheetRow, c: 0 },
          sheetStubs: true,
          cellDates: true,
          cellStyles: true,
        });
      }

      const rowIndexStart = currentSheetRow + 1; // skip header row
      const rowIndexEnd = currentSheetRow + table.length;
      for (let rowIndex = rowIndexStart; rowIndex < rowIndexEnd; rowIndex++) {
        for (let columnIndex = 0; columnIndex < table[0].length; columnIndex++) {
          const cell: CellObject = sheet[XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex })];
          const columnType = inputTables[tableIndex].headers[columnIndex].type;

          switch (columnType) {
            case ColumnType.Text:
              cell.t = 's';
              cell.z = '@';
              break;
            case ColumnType.Date:
              XLSX.utils.cell_set_number_format(cell, 'mm/dd/yyyy');
              break;
            case ColumnType.Number:
              cell.t = 'n';
              break;
            case ColumnType.Percent:
              XLSX.utils.cell_set_number_format(cell, '0.00%');
              break;
            default:
              break;
          }
        }
      }

      currentSheetRow += table.length + 1;
    }

    if (!sheet) {
      continue;
    }

    sheet['!cols'] = columnWidths.map(width => ({ wch: width }));

    const sheetName = inputSheet.sheetName ?? `sheet${i + 1}`;
    XLSX.utils.book_append_sheet(book, sheet, sheetName);
  }

  /* generate XLSX file and send to client */
  XLSX.writeFileXLSX(book, `${input.fileName}.xlsx`, { cellStyles: true });
};
