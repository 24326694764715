import { ChangeEvent } from 'react';
import { Row as TableRow } from 'react-table';

import { FactoryCellRowSelectCheckboxProps } from './types';
import styles from './FactoryCellRowSelectCheckbox.module.css';

const FactoryCellRowSelectCheckbox =
  <DataType extends object>({
    checkIsCheckboxDisabled,
    checkIsCheckboxSelectable,
  }: {
    checkIsCheckboxDisabled?: (row: TableRow<DataType>) => boolean;
    checkIsCheckboxSelectable?: (arg: {
      selectedFlatRows: TableRow<DataType>[];
      row: TableRow<DataType>;
      allRows: TableRow<DataType>[];
      enabledFields?: number;
    }) => boolean;
  }) =>
  ({ flatRows, row, selectedFlatRows }: FactoryCellRowSelectCheckboxProps<DataType>) => {
    const { indeterminate, onChange, ref, ...rest } = row.getToggleRowSelectedProps();

    const isDisabled = !checkIsCheckboxDisabled ? false : checkIsCheckboxDisabled(row);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      let isSelectable = !event.target.checked;

      if (!isSelectable) {
        isSelectable =
          checkIsCheckboxSelectable?.({ selectedFlatRows, row, allRows: flatRows }) ?? true;
      }

      if (isSelectable) {
        onChange!(event);
      }
    };

    return (
      <div
        className={styles.checkboxWrapper}
        style={
          // @ts-ignore
          row?.isSubRow ? { display: 'none' } : { display: 'block' }
        }
      >
        <input disabled={isDisabled} onChange={handleChange} type="checkbox" {...rest} />
      </div>
    );
  };

export { FactoryCellRowSelectCheckbox };
