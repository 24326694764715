import { useMemo } from 'react';

import { FactoryCellInput, FactoryCellSelectBinary } from 'shared/components/DataGrid';
import { inputTypes } from 'shared/components/Input/constants';
import { isReadOnlyRole } from 'shared/utils';

import { constants } from '../constants';
import { UseColumnsArgs, UseColumnsResult } from '../types';

export const useColumns = ({ role }: UseColumnsArgs): UseColumnsResult =>
  useMemo(() => {
    const isReadOnly = isReadOnlyRole(role);
    const isReadOnlyFunc = () => isReadOnly;

    return [
      {
        Header: constants.download.columns.headers.department.displayName,
        accessor: 'department',
      },
      {
        Header: constants.download.columns.headers.class.displayName,
        accessor: 'class',
      },
      {
        Header: constants.download.columns.headers.subClass.displayName,
        accessor: 'subClass',
      },
      {
        Header: constants.download.columns.headers.pullByDate.displayName,
        accessor: 'pullByDate',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: constants.download.columns.headers.markdownDays.displayName,
        accessor: 'markdownDays',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: constants.download.columns.headers.captureExpiry.displayName,
        accessor: 'captureExpiry',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
    ];
  }, [role]);
