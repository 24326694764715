import { FC, useMemo } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

import { SelectField } from 'shared/components/SelectField';
import {
  AutocompleteOption,
  SearchAutocompleteQuery,
  SearchAutocompleteQueryProps,
} from 'shared/components/Search';
import {
  ItemsOptionsQuery,
  ItemsOptionsQueryVariables,
  OpcosQuery,
  OpcosQueryVariables,
} from 'shared/types';
import { itemsOptionsQuery, opcosQuery } from 'shared/operations/query';
import { isNonZeroNumberOnlyString, mapToSelectOptions } from 'shared/utils';

import { FormValues, MassEditFormRowProps } from './types';

const MassEditFormRow: FC<MassEditFormRowProps> = ({ control, isSupplierPage, userOptions }) => {
  const [label, placeholder] = useMemo(
    () =>
      isSupplierPage
        ? ['Item Name or ID', 'Search by Name or ID']
        : ['OpCo/Site Name or OpCo ID', 'Search by Name or ID'],
    [isSupplierPage],
  );

  const { renderLinked, renderManagedBy } = useMemo<{
    renderLinked: ControllerProps<FormValues, FormValues['linked']>['render'];
    renderManagedBy: ControllerProps<FormValues, FormValues['managedById']>['render'];
  }>(() => {
    let linkedAutocompleteProps: Pick<
      SearchAutocompleteQueryProps<
        ItemsOptionsQuery | OpcosQuery,
        ItemsOptionsQueryVariables | OpcosQueryVariables,
        true
      >,
      'query' | 'mapDataToOptions' | 'mapUserInputToAutocompleteQueryFilerParams'
    >;

    if (isSupplierPage) {
      linkedAutocompleteProps = {
        query: itemsOptionsQuery,
        mapDataToOptions: data => mapToSelectOptions((data as ItemsOptionsQuery)?.items.items),
        mapUserInputToAutocompleteQueryFilerParams: value => {
          if (isNonZeroNumberOnlyString(value)) {
            return { extIds: [value] };
          }

          return { name: value };
        },
      };
    } else {
      linkedAutocompleteProps = {
        query: opcosQuery,
        mapDataToOptions: data => mapToSelectOptions((data as OpcosQuery)?.opcos.opcos),
        mapUserInputToAutocompleteQueryFilerParams: value => {
          if (isNonZeroNumberOnlyString(value)) {
            return { id: [value] };
          }

          return { name: value };
        },
      };
    }
    return {
      renderLinked: ({
        field: { onBlur, onChange: handleChange, value: val },
        fieldState: { error },
      }) => (
        <SearchAutocompleteQuery<
          ItemsOptionsQuery | OpcosQuery,
          ItemsOptionsQueryVariables | OpcosQueryVariables,
          true
        >
          clearInputValueOnblur
          defaultValue={val as AutocompleteOption[]}
          error={error?.message}
          label={`Assign ${label}`}
          multiple
          onBlur={onBlur}
          onChange={(_, data) => handleChange(data)}
          placeholder={placeholder}
          showAutocompleteAfter={1}
          {...linkedAutocompleteProps}
        />
      ),
      renderManagedBy: ({
        field: { onChange: handleChange, value: val },
        fieldState: { error },
      }) => (
        <SelectField
          error={error?.message}
          label="Managed by"
          name="managedById"
          onChange={handleChange}
          options={userOptions}
          placeholder="Select"
          value={val ?? ''}
        />
      ),
    };
  }, [control, label, placeholder, isSupplierPage, userOptions]);

  return (
    <>
      <Controller control={control} name="managedById" render={renderManagedBy} />
      <Controller control={control} name="linked" render={renderLinked} />
    </>
  );
};

export { MassEditFormRow };
