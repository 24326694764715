import { FC } from 'react';
import { Prompt } from 'react-router-dom';

import {
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALCOHOL_TYPE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALLOCATION_PRIORITY,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_CUSTOM_ASSORTMENT_CODE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_LOCATION_ITEM_CAPABILITIES,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_NATIONAL_ASSORTMENT_CODE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_TOBACCO_TYPE,
} from 'shared/constants';
import {
  ALCOHOL_TYPES_MUTATION,
  ALCOHOL_TYPES_QUERY,
  ALLOCATION_PRIORITY_QUERY,
  CAPABILITIES_MUTATION,
  CAPABILITIES_QUERY,
  CUSTOM_ASSORTMENTS_MUTATION,
  CUSTOM_ASSORTMENT_QUERY,
  DELETE_ALCOHOL_TYPES_MUTATION,
  DELETE_ALLOCATION_PRIORITIES_MUTATION,
  DELETE_CAPABILITIES_MUTATION,
  DELETE_CUSTOM_ASSORTMENTS_MUTATION,
  DELETE_NATIONAL_ASSORTMENTS_MUTATION,
  DELETE_TOBACCO_TYPES_MUTATION,
  NATIONAL_ASSORTMENTS_MUTATION,
  NATIONAL_ASSORTMENT_QUERY,
  TOBACCO_TYPES_MUTATION,
  TOBACCO_TYPES_QUERY,
  UPSERT_ALLOCATION_PRIORITIES_MUTATION,
} from 'pages/UserDefinedAttribute/operations';
import { Accordion } from 'pages/UserDefinedAttribute/components/Accordion';
import { AlcoholForm } from 'pages/UserDefinedAttribute/components/AlcoholForm';
import { DefaultForm } from 'pages/UserDefinedAttribute/components/DefaultForm';
import { Header } from 'pages/UserDefinedAttribute/components/Header';
import { NationalAssortmentForm } from 'pages/UserDefinedAttribute/components/NationalAssortmentForm';
import { AllocationPriorityForm } from 'pages/UserDefinedAttribute/components/AllocationPriorityForm';
import { TobaccoForm } from 'pages/UserDefinedAttribute/components/TobaccoForm';
import { WarningModal } from 'pages/UserDefinedAttribute/components/WarningModal';

import { UserDefinedAttributeViewProps } from './types';
import styles from './UserDefinedAttributeView.module.css';

const UserDefinedAttributeView: FC<UserDefinedAttributeViewProps> = ({
  accordionClick,
  afterSave,
  blockedLocation,
  expanded,
  handleChangeAccordion,
  isError,
  isFormChanged,
  isReset,
  onCancel,
  onChangeUnsavedPage,
  onCloseModal,
  setIsError,
  setIsFormChanged,
  setIsReset,
  userPermissions,
}) => (
  <div className={styles.container}>
    <Prompt message={onChangeUnsavedPage} when={isFormChanged} />
    <Header isFormChanged={isFormChanged} onCancel={onCancel} />
    <Accordion expanded={expanded} handleChange={handleChangeAccordion} name="National Assortment">
      <NationalAssortmentForm
        afterSave={afterSave}
        blockedLocation={blockedLocation}
        containerColumn
        deleteMutation={DELETE_NATIONAL_ASSORTMENTS_MUTATION}
        hasPermissions={userPermissions.has(
          ACTION_EDIT_USER_DEFINED_ATTRIBUTES_NATIONAL_ASSORTMENT_CODE,
        )}
        inputSize="standard"
        isReset={isReset}
        label="National Assortment Type"
        mutation={NATIONAL_ASSORTMENTS_MUTATION}
        mutationVariableName="assortments"
        name="nationalAssortments"
        query={NATIONAL_ASSORTMENT_QUERY}
        setIsError={setIsError}
        setIsFormChanged={setIsFormChanged}
        setIsReset={setIsReset}
      />
    </Accordion>
    <Accordion expanded={expanded} handleChange={handleChangeAccordion} name="Custom Assortment">
      <DefaultForm
        afterSave={afterSave}
        blockedLocation={blockedLocation}
        canFilter
        deleteMutation={DELETE_CUSTOM_ASSORTMENTS_MUTATION}
        hasPermissions={userPermissions.has(
          ACTION_EDIT_USER_DEFINED_ATTRIBUTES_CUSTOM_ASSORTMENT_CODE,
        )}
        isReset={isReset}
        label="Custom Assortment"
        mutation={CUSTOM_ASSORTMENTS_MUTATION}
        mutationVariableName="assortments"
        name="customAssortments"
        query={CUSTOM_ASSORTMENT_QUERY}
        setIsError={setIsError}
        setIsFormChanged={setIsFormChanged}
        setIsReset={setIsReset}
      />
    </Accordion>
    <Accordion expanded={expanded} handleChange={handleChangeAccordion} name="Alcohol Type">
      <AlcoholForm
        afterSave={afterSave}
        blockedLocation={blockedLocation}
        deleteMutation={DELETE_ALCOHOL_TYPES_MUTATION}
        hasPermissions={userPermissions.has(ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALCOHOL_TYPE)}
        isReset={isReset}
        label="Alcohol Type"
        mutation={ALCOHOL_TYPES_MUTATION}
        mutationVariableName="types"
        name="alcoholTypes"
        query={ALCOHOL_TYPES_QUERY}
        setIsError={setIsError}
        setIsFormChanged={setIsFormChanged}
        setIsReset={setIsReset}
      />
    </Accordion>
    <Accordion expanded={expanded} handleChange={handleChangeAccordion} name="Tobacco Type">
      <TobaccoForm
        afterSave={afterSave}
        blockedLocation={blockedLocation}
        deleteMutation={DELETE_TOBACCO_TYPES_MUTATION}
        hasPermissions={userPermissions.has(ACTION_EDIT_USER_DEFINED_ATTRIBUTES_TOBACCO_TYPE)}
        isReset={isReset}
        label="Tobacco Type"
        mutation={TOBACCO_TYPES_MUTATION}
        mutationVariableName="types"
        name="tobaccoTypes"
        query={TOBACCO_TYPES_QUERY}
        setIsError={setIsError}
        setIsFormChanged={setIsFormChanged}
        setIsReset={setIsReset}
      />
    </Accordion>
    <Accordion
      expanded={expanded}
      handleChange={handleChangeAccordion}
      name="Location/item Capabilities"
    >
      <DefaultForm
        afterSave={afterSave}
        blockedLocation={blockedLocation}
        deleteMutation={DELETE_CAPABILITIES_MUTATION}
        hasPermissions={userPermissions.has(
          ACTION_EDIT_USER_DEFINED_ATTRIBUTES_LOCATION_ITEM_CAPABILITIES,
        )}
        inputSize="standard"
        isReset={isReset}
        label="Location/item Capabilities"
        mutation={CAPABILITIES_MUTATION}
        mutationVariableName="capabilities"
        name="capabilities"
        query={CAPABILITIES_QUERY}
        setIsError={setIsError}
        setIsFormChanged={setIsFormChanged}
        setIsReset={setIsReset}
      />
    </Accordion>
    <Accordion expanded={expanded} handleChange={handleChangeAccordion} name="Allocation Priority">
      <AllocationPriorityForm
        afterSave={afterSave}
        blockedLocation={blockedLocation}
        containerColumn
        deleteMutation={DELETE_ALLOCATION_PRIORITIES_MUTATION}
        hasPermissions={userPermissions.has(
          ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALLOCATION_PRIORITY,
        )}
        inputSize="standard"
        isReset={isReset}
        label="Allocation Priority Tier"
        mutation={UPSERT_ALLOCATION_PRIORITIES_MUTATION}
        mutationVariableName="priorities"
        name="allocationPriorities"
        query={ALLOCATION_PRIORITY_QUERY}
        setIsError={setIsError}
        setIsFormChanged={setIsFormChanged}
        setIsReset={setIsReset}
      />
    </Accordion>
    <WarningModal
      expanded={expanded as string}
      isError={isError}
      isOpen={(accordionClick !== expanded && isFormChanged) || !!blockedLocation}
      onCancel={onCancel}
      onCloseModal={onCloseModal}
    />
  </div>
);

export { UserDefinedAttributeView };
