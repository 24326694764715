import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import { headerButtonNames } from 'pages/DestinationAssortment/constants';
import { mapRowToMutationArgs } from 'pages/DestinationAssortment/mappers';
import {
  destinationAssortmentsDownloadQuery,
  destinationAssortmentsOptionsQuery,
  destinationAssortmentsQuery,
  destinationAssortmentsUploadMutation,
  lastDestinationAssortmentTimeQuery,
  updateAssortmentMutation,
  updateDestinationAssortmentsMutation,
} from 'pages/DestinationAssortment/operations';
import { Row, SetupResult } from 'pages/DestinationAssortment/types';
import {
  useDownload,
  useEditPage,
  useIsSystemLocked,
  useOnGridChange,
  usePagination,
  usePermissions,
  useRole,
  useUploadFile,
} from 'shared/hooks';
import { currenciesQuery, usersQuery } from 'shared/operations/query';
import {
  CurrenciesQuery,
  DestinationAssortmentSortField,
  DestinationAssortmentsOptionsQuery,
  DestinationAssortmentsQuery,
  DestinationAssortmentsQueryVariables,
  LastDestinationAssortmentTimeQuery,
  LastDestinationAssortmentTimeQueryVariables,
  SortOrder,
  UpdateAssortmentMutation,
  UpdateAssortmentMutationVariables,
  UpdateDestinationAssortmentsMutation,
  UpdateDestinationAssortmentsMutationVariables,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import {
  displayNameOptionMapper,
  isFirstLoad,
  mapToCurrenciesOptions,
  mapToSelectOptions,
} from 'shared/utils';
import { PackageTypeName } from 'shared/constants/package';

import { useColumns } from './useColumns';

const useSetup = (): SetupResult => {
  const [activePageIndex, setActiveIndexPage] = useState(0);
  const [filter, setFilter] = useState<DestinationAssortmentsQueryVariables['filter']>({});
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();

  const { refetchSystemStatusQuery, systemLocked } = useIsSystemLocked();
  const isCostingOn = true;
  const userPermissions = usePermissions();
  const role = useRole();
  const {
    onCloseEditPage,
    onEditClick,
    selectedRows,
    setSelectedRows,
    showEditPage,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
  } = useEditPage<Row>();

  const {
    data: { packageTypes, velocityCategories } = {
      velocityCategories: [],
      packageTypes: [],
    },
  } = useQuery<DestinationAssortmentsOptionsQuery>(destinationAssortmentsOptionsQuery);

  const eachPackageTypeId = packageTypes.find(({ name }) => name === PackageTypeName.Each)?.id;

  const { data: { currencies } = { currencies: [] } } = useQuery<CurrenciesQuery>(currenciesQuery);

  const {
    data: { destinationAssortments: { assortments: data, totalCount } } = {
      destinationAssortments: {
        totalCount: 0,
        assortments: [],
      },
    },
    loading,
    networkStatus,
    client,
  } = useQuery<DestinationAssortmentsQuery, DestinationAssortmentsQueryVariables>(
    destinationAssortmentsQuery,
    {
      variables: {
        pagination,
        sorting: {
          field: DestinationAssortmentSortField[headerButtonNames[activePageIndex]],
          order: SortOrder.Desc,
        },
        filter,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    data: { lastDestinationAssortmentTime } = { lastDestinationAssortmentTime: '' },
    loading: lastTimeLoading,
  } = useQuery<LastDestinationAssortmentTimeQuery, LastDestinationAssortmentTimeQueryVariables>(
    lastDestinationAssortmentTimeQuery,
  );

  const onDataGridDownloadButtonClick = useDownload({
    query: destinationAssortmentsDownloadQuery,
    queryResponseDataKey: 'destinationAssortmentsDownload',
    selectedRows,
    triggerUncheckAllCheckboxes,
  });

  const { data: { users } = { users: [] } } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery);

  const uploadFileProps = useUploadFile({
    downloadTemplateQuery: destinationAssortmentsDownloadQuery,
    mutation: destinationAssortmentsUploadMutation,
    client,
    evictCacheForQueryName: 'destinationAssortments',
  });

  const [updateAssortment] = useMutation<
    UpdateAssortmentMutation,
    UpdateAssortmentMutationVariables
  >(updateAssortmentMutation, {
    async onCompleted() {
      await refetchSystemStatusQuery();
    },
  });

  const updateFilter = useCallback((newFilter: DestinationAssortmentsQueryVariables['filter']) => {
    setFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
    resetPageIndex();
  }, []);

  const onUpdateAssortmentClick = useCallback(() => updateAssortment(), []);

  const onTogglePage = useCallback((newPageIndex: number) => {
    setActiveIndexPage(newPageIndex);
    resetPageIndex();
  }, []);

  const reset = useCallback(() => {
    resetPageIndex();
    setFilter({});
  }, []);

  const destinationAssortmentsOptions = useMemo(() => {
    const velocityCategoryOptions = mapToSelectOptions(velocityCategories);
    const currenciesOptions = mapToCurrenciesOptions(currencies);
    const userOptions = mapToSelectOptions(users, displayNameOptionMapper);
    const packagingIdOptions = mapToSelectOptions(packageTypes);

    return {
      velocityCategoryOptions,
      currenciesOptions,
      userOptions,
      packagingIdOptions,
    };
  }, [velocityCategories, currencies, users]);

  const columns = useColumns({
    isFirstLoad: isFirstLoad(networkStatus),
    activePageIndex,
    updateFilter,
    userPermissions,
    role,
    eachPackageTypeId,
    isCostingOn,
    ...destinationAssortmentsOptions,
  });

  const [onChange] = useOnGridChange<
    Row,
    UpdateDestinationAssortmentsMutation,
    UpdateDestinationAssortmentsMutationVariables
  >({
    data,
    mutation: updateDestinationAssortmentsMutation,
    mapRowToMutationArgs,
  });

  const { currenciesOptions, packagingIdOptions, velocityCategoryOptions } =
    destinationAssortmentsOptions;

  return {
    velocityCategoryOptions,
    currenciesOptions,
    packagingIdOptions,
    onCloseEditPage,
    onEditClick,
    packageTypes,
    reset,
    selectedRows,
    showEditPage,
    triggerUncheckAllCheckboxes,
    data,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    loadingMoreData: loading,
    showMainLoader: (isFirstLoad(networkStatus) && !showEditPage) || lastTimeLoading,
    columns,
    activePageIndex,
    onTogglePage,
    lastDestinationAssortmentTime,
    onUpdateAssortmentClick,
    onDataGridDownloadButtonClick,
    uncheckAllCheckboxes,
    setSelectedRows,
    showUpdateAssortmentLoader: systemLocked,
    uploadFileProps,
    userPermissions,
    isCostingOn,
  };
};

export { useSetup };
