import { FC } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { ReactComponent as AddIcon } from 'pages/UserDefinedAttribute/assets/plus-icon.svg';
import { InfoTooltip } from 'shared/components/InfoTooltip';

import styles from './AddButton.module.css';
import { AddButtonProps } from './types';

const AddButton: FC<AddButtonProps> = ({
  buttonPosition = 'flex-start',
  isDisabled,
  onClick,
  title,
  tooltip,
}) => (
  <Grid alignItems="center" container justifyContent={buttonPosition}>
    <Button
      className={styles.addButton}
      disabled={isDisabled}
      disableFocusRipple
      disableRipple
      onClick={onClick}
      startIcon={<AddIcon />}
    >
      {title}
    </Button>
    <InfoTooltip title={tooltip} />
  </Grid>
);

export { AddButton };
