import { mergeDeepRight } from 'ramda';
import { O } from 'ts-toolbelt';

import { PackageType } from 'shared/types';

const makePackageType = (props: O.Partial<PackageType, 'deep'> = {}): PackageType =>
  mergeDeepRight<PackageType, O.Partial<PackageType, 'deep'>>(
    { id: '1', name: 'Each', __typename: 'PackageType' },
    props,
  );

export { makePackageType };
