import { isEmpty, isNil } from 'ramda';

import {
  BaseQueryArgs,
  MapUserAutocompleteValueSelectToFilterParamsInput,
  MapUserAutocompleteValueSelectToFilterParams as MapUserAutocompleteValueSelectToFilterParamsType,
} from '../types';

const defaultValueMapper = <
  QueryArgs extends BaseQueryArgs,
  IsMultiple extends boolean = false,
  Value = NonNullable<MapUserAutocompleteValueSelectToFilterParamsInput<IsMultiple>>,
>(
  value: NonNullable<Value>,
  filterFieldName: keyof QueryArgs['filter'],
): QueryArgs['filter'] => ({
  [filterFieldName]: value,
});

const mapAutocompleteValueToFilterParams = <
  QueryArgs extends BaseQueryArgs,
  IsMultiple extends boolean = false,
  Value = MapUserAutocompleteValueSelectToFilterParamsInput<IsMultiple>,
>(
  value: Value,
  filterFieldName: keyof QueryArgs['filter'],
  mapUserAutocompleteValueSelectToFilterParams?: MapUserAutocompleteValueSelectToFilterParamsType<
    QueryArgs,
    IsMultiple,
    Value
  >,
  defaultMapper: (
    v: NonNullable<Value>,
    filterFieldName: keyof QueryArgs['filter'],
  ) => QueryArgs['filter'] = defaultValueMapper,
): QueryArgs['filter'] => {
  let filterParams: QueryArgs['filter'];

  if (isNil(value) || isEmpty(value)) {
    filterParams = {
      [filterFieldName]: undefined,
    };
  } else if (mapUserAutocompleteValueSelectToFilterParams) {
    filterParams = mapUserAutocompleteValueSelectToFilterParams(value);
  } else {
    filterParams = defaultMapper(value as NonNullable<Value>, filterFieldName);
  }

  return filterParams;
};

export { mapAutocompleteValueToFilterParams };
