import { Row as TableRow } from 'react-table';

import { FormValues } from 'pages/Item/components/MassEditFormRow';
import { Row } from 'pages/Item/types';

const mapMassEditRowToMutationArgs = ({
  formData: {
    acceptableShelfLife,
    alcoholByVolume,
    baggableAtPack,
    binnableAtPack,
    guaranteedShelfLife,
    ignoreBlockOverReceipt,
    markdownItem,
    percentageOverageAllowed,
    requireProduceBag,
    stopBuyingFlag,
    ...restFormData
  },
  selectedRows,
}: {
  selectedRows: TableRow<Row>[];
  formData: FormValues;
}) => ({
  input: {
    items: selectedRows.map(({ original: { id } }) => ({
      ...restFormData,
      id,
      alcoholByVolume: alcoholByVolume ? Number(alcoholByVolume) : null,
      guaranteedShelfLife: guaranteedShelfLife ? Number(guaranteedShelfLife) : null,
      stopBuyingFlag: Boolean(stopBuyingFlag),
      markdownItem: Boolean(markdownItem),
      baggableAtPack: Boolean(baggableAtPack),
      binnableAtPack: Boolean(binnableAtPack),
      requireProduceBag: Boolean(requireProduceBag),
      ignoreBlockOverReceipt: Boolean(ignoreBlockOverReceipt),
      percentageOverageAllowed: percentageOverageAllowed ? Number(percentageOverageAllowed) : null,
      acceptableShelfLife: acceptableShelfLife ? Number(acceptableShelfLife) : null,
    })),
  },
});

export { mapMassEditRowToMutationArgs };
