import { Column } from 'react-table';

import { Row } from '../types';

export const headersForMassEditColumns: Column<Row>[] = [
  {
    Header: 'Location Name',
    accessor: 'name',
  },
  {
    Header: 'Location ID',
    accessor: 'extId',
  },
];
