import { MIXED_IS_ACTIVE_OPTION_VALUE, MIXED_PACKAGE_TYPE_ID } from 'pages/Suppliers/constants';
import { MIXED_SEND_TO_TRAVERSE_OPTION_VALUE } from 'pages/Suppliers/constants/mixedSendToTraverseOptionValue';
import { MappedRow } from 'pages/Suppliers/types';
import { SuppliersQuery } from 'shared/types';

const mapDataToRows = (data: SuppliersQuery['suppliers']['suppliers']) =>
  data.map(({ extID, id, name, opcos }) => {
    let defaultUom: MappedRow['packageTypeId'] = null;
    let combinedIsActive: number = 0;
    let combinedSendToTraverse: number = 0;
    const rows: MappedRow[] = [];

    opcos.forEach(
      (
        {
          address,
          buyer,
          city,
          country,
          id: opcoId,
          isActive: isOpcoActive,
          name: opcoName,
          packageTypeId,
          postalCode: zip,
          sendToTraverse,
          stateProvinceRegion: state,
        },
        index,
      ) => {
        if (packageTypeId !== defaultUom) {
          if (index === 0) {
            defaultUom = packageTypeId;
          } else {
            defaultUom = MIXED_PACKAGE_TYPE_ID;
          }
        }
        if (Number(isOpcoActive) !== combinedIsActive) {
          if (index === 0) {
            combinedIsActive = Number(isOpcoActive);
          } else {
            combinedIsActive = MIXED_IS_ACTIVE_OPTION_VALUE;
          }
        }
        if (Number(sendToTraverse) !== combinedSendToTraverse) {
          if (index === 0) {
            combinedSendToTraverse = Number(sendToTraverse);
          } else {
            combinedSendToTraverse = MIXED_SEND_TO_TRAVERSE_OPTION_VALUE;
          }
        }

        rows.push({
          country,
          id: opcoId,
          name: opcoName,
          packageTypeId,
          isActive: isOpcoActive,
          opcoId,
          address,
          city,
          state,
          zip,
          sendToTraverse,
          buyer,
        });
      },
    );

    return {
      id,
      name,
      packageTypeId: defaultUom,
      isActive: combinedIsActive,
      supplierId: extID,
      sendToTraverse: combinedSendToTraverse,
      rows,
    };
  });

export { mapDataToRows };
