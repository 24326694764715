import { SelectOption } from 'shared/types';

const mapToSelectOption = <T extends SelectOption>({ i18n, id, languageCode, name }: T) => {
  const label =
    languageCode && i18n && i18n.name ? i18n.name[languageCode as keyof typeof i18n.name] : name;
  return {
    label,
    value: id,
  };
};

export { mapToSelectOption };
