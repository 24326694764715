import { CSSProperties, Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import {
  Column,
  ColumnInstance,
  IdType,
  PluginHook,
  UseTableInstanceProps,
  useExpanded,
  useRowSelect,
  useRowState,
  useTable,
} from 'react-table';
import { usePreviousDistinct, useUpdateEffect } from 'react-use';
import { useSticky } from 'react-table-sticky';
import { clone, equals, mapObjIndexed, always as noop } from 'ramda';

import { Loader } from 'shared/components/Loader';

import { PaginationActions } from './components/PaginationActions';
import { Header } from './components/Header';
import styles from './DataGrid.module.css';
import { DataGridProps, DataRow } from './types';
import {
  SelectProps,
  defaultExpandedRows,
  defaultRowsPerPageOptions,
  defaultSelectedRows,
} from './constants';
import { Row } from './components/Row';
import {
  EmptyCell,
  FactoryCellExpander,
  FactoryCellRowSelectCheckbox,
  FactoryHeaderRowExpander,
  FactoryHeaderRowSelectCheckbox,
} from './components/Cells';

const defaultDeriveRowKey = <DataType extends DataRow>({ id }: DataType) => id;

const defaultPreviousData: any[] = [];

const CellExpander = FactoryCellExpander();

const DataGrid = memo(
  <DataType extends DataRow>({
    highlightRow = noop(false),
    highlightRowWithColor = noop(undefined),
    borderAfterIndex,
    columns,
    data,
    onChange = noop,
    onPageChange = noop,
    onRowsPerPageChange = noop,
    pageIndex = 0,
    pageSize = 0,
    totalCount = 0,
    showPagination = true,
    deriveRowKey = defaultDeriveRowKey,
    enableRowSelection = false,
    expandedRowIds = defaultExpandedRows,
    expandAllRows = false,
    shouldExpandByDefault,
    checkIsCheckboxDisabled,
    checkIsCheckboxSelectable,
    setSelectedRows,
    selectedRowIds = defaultSelectedRows,
    uncheckAllCheckboxes,
    toggleAllTableRows,
    getNumberOfEnableFields,
    enableRowState = false,
    enableExpanding = false,
    loadingMoreData = false,
    showLoaderOnLoadingMoreData = true,
    disableOnLoadingMoreData = false,
    usePreviousDataOnLoadingMore = true,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    testId = 'datagrid',
    currentPageLoaded,
  }: DataGridProps<DataType>): JSX.Element => {
    const rowsByIdRef = useRef<UseTableInstanceProps<DataType>['rowsById']>({});
    const selectedFlatRows = useRef<UseTableInstanceProps<DataType>['rowsById']>({});
    const [selectedFlatRowsArray, setSelectedFlatRowsArray] = useState<
      UseTableInstanceProps<DataType>['rows']
    >([]);
    const previousData = usePreviousDistinct(
      data,
      (_, next) => next?.length === undefined || next?.length === 0,
    );
    const previousPageIndex = usePreviousDistinct(
      pageIndex,
      (_, next) => next === undefined || next === 0,
    );
    const previousPageSize = usePreviousDistinct(
      pageSize,
      (_, next) => next === undefined || next === 10,
    );
    const previousTotalCount = usePreviousDistinct(
      totalCount,
      (_, next) => next === undefined || next === 0,
    );

    const [currentData, currentPageIndex, currentPageSize, currentTotalCount] =
      usePreviousDataOnLoadingMore && loadingMoreData
        ? [
            previousData || (defaultPreviousData as DataType[]),
            previousPageIndex || (0 as number),
            previousPageSize || (10 as number),
            previousTotalCount as number,
          ]
        : [data, pageIndex, pageSize, totalCount];

    const currentPageRowIds = useMemo(() => {
      const set = new Set<string>();
      currentData.forEach(item => {
        set.add(deriveRowKey(item));
      });
      return set;
    }, [currentData, deriveRowKey]);

    const previousPageRowIds = usePreviousDistinct(currentPageRowIds, (prev, next) => {
      if (!prev && !next) return true;
      if (!prev || !next) return false;
      let pageChanged = true;
      next.forEach(id => {
        // if there is some id that is in both the previous data and current data
        // then we will assume that the page did not change, that only the data
        // within the same page changed
        if (prev.has(id)) {
          pageChanged = false;
        }
      });
      return !pageChanged;
    });

    const disableCells = disableOnLoadingMoreData && loadingMoreData;

    const {
      getTableProps,
      headerGroups,
      prepareRow,
      rows,
      rowsById,
      state: { selectedRowIds: selectedRowIdsState },
      toggleAllRowsExpanded,
      toggleAllRowsSelected,
    } = useTable<DataType>(
      {
        columns,
        data: currentData,
        updateGridData: onChange,
        initialState: {
          selectedRowIds,
          expanded: expandedRowIds,
        },
        autoResetExpanded: false,
        autoResetRowState: false,
        autoResetSelectedRows: false,
        disableCells,
        getRowId: deriveRowKey,
        stateReducer: (newState, { type, value }) => {
          if (type === 'toggleAllRowsSelected' && value === false) {
            return {
              ...newState,
              selectedRowIds: {} as Record<IdType<DataType>, boolean>,
            };
          }
          return newState;
        },
      },
      ...(
        [
          useSticky,
          enableExpanding && useExpanded,
          enableRowSelection && useRowSelect,
          enableRowState && useRowState,
          hooks => {
            hooks.visibleColumns.push(restColumns => [
              ...([
                enableRowSelection && {
                  id: 'selection',
                  Header: FactoryHeaderRowSelectCheckbox({
                    checkIsCheckboxSelectable,
                    toggleAllTableRows,
                    getNumberOfEnableFields,
                  }),
                  Cell: FactoryCellRowSelectCheckbox({
                    checkIsCheckboxDisabled,
                    checkIsCheckboxSelectable,
                  }),
                  sticky: columns[0]?.sticky,
                  width: 45,
                },
                enableExpanding && {
                  Header: FactoryHeaderRowExpander(),
                  id: 'expander',
                  Cell: CellExpander,
                  SubCell: EmptyCell,
                  sticky: columns[0]?.sticky,
                  width: 45,
                },
              ].filter(Boolean) as Column<DataType>[]),
              ...restColumns,
            ]);
          },
        ] as Array<PluginHook<DataType>>
      ).filter(Boolean),
    );

    const lastStickyIndex = useMemo(() => {
      let maximumIndex = -1;

      for (let i = 0; i < headerGroups?.[0].headers?.length ?? 0; i++) {
        let header: Pick<ColumnInstance, 'sticky' | 'id' | 'width' | 'placeholderOf'> =
          headerGroups[0].headers[i];
        if (header.placeholderOf) header = header.placeholderOf;

        if (header.sticky === 'left') {
          maximumIndex = (maximumIndex ?? 0) + 1;
        } else {
          break;
        }
      }

      return maximumIndex;
    }, [headerGroups]);

    const { style: tableStyleRaw, ...tableProps } = getTableProps();

    const tableStyle = useMemo<CSSProperties>(
      () => ({
        ...tableStyleRaw,
        gridTemplateColumns: `${enableExpanding ? '45px ' : ''}${
          enableRowSelection ? '45px ' : ''
        }${columns.reduce(
          (previous, { columns: c, width }) =>
            c?.length
              ? `${previous} ${c.reduce((p, { width: w }) => `${p} ${w ? `${w}px` : 'auto'}`, '')}`
              : `${previous} ${width ? `${width}px` : 'auto'}`,
          '',
        )}`,
      }),
      [enableExpanding, enableRowSelection, tableStyleRaw, columns],
    );

    useEffect(() => {
      rowsByIdRef.current = rowsById;
    }, [rowsById]);

    useUpdateEffect(() => {
      if (enableRowSelection) {
        const newSelectedFlatRowsArray: UseTableInstanceProps<DataType>['rows'] = [];
        selectedFlatRows.current = mapObjIndexed((_, rowId) => {
          const row = rowsByIdRef.current[rowId] || selectedFlatRows.current[rowId];
          newSelectedFlatRowsArray.push(row);
          return row;
        }, selectedRowIdsState);
        setSelectedFlatRowsArray(newSelectedFlatRowsArray);
        setSelectedRows!(newSelectedFlatRowsArray);
      }
    }, [selectedRowIdsState, enableRowSelection, selectedFlatRows, rowsByIdRef]);

    useEffect(() => {
      if (uncheckAllCheckboxes) {
        toggleAllRowsSelected(false);
      }
    }, [uncheckAllCheckboxes, selectedFlatRowsArray]);

    useEffect(() => {
      if (expandAllRows) toggleAllRowsExpanded(true);
    }, [previousPageRowIds]);

    useEffect(() => {
      if (!shouldExpandByDefault || !currentPageLoaded) {
        return;
      }

      currentData
        .filter(currentDataElement => shouldExpandByDefault(currentDataElement))
        .forEach(currentDataElement => rowsById[currentDataElement.id]?.toggleRowExpanded(true));
    }, [currentPageLoaded]);

    return (
      <div className={styles.wrapper}>
        <div
          data-testid={testId}
          id="test"
          {...tableProps}
          className={styles.table}
          style={tableStyle}
        >
          <Header<DataType>
            borderAfterIndex={borderAfterIndex}
            headerGroups={headerGroups}
            lastStickyIndex={lastStickyIndex}
          />
          {loadingMoreData && showLoaderOnLoadingMoreData && <Loader isLoading />}
          {rows.map(row => {
            prepareRow(row);
            const state = clone(row.state);
            return (
              <Fragment key={deriveRowKey(row.original)}>
                <Row
                  borderAfterIndex={borderAfterIndex}
                  disableCells={disableCells}
                  highlightRow={highlightRow}
                  highlightRowWithColor={highlightRowWithColor}
                  isExpanded={row.isExpanded}
                  isSelected={row.isSelected}
                  lastStickyIndex={lastStickyIndex}
                  original={row.original}
                  row={row}
                  selectedFlatRows={selectedFlatRowsArray}
                  state={state}
                />
                {row.isExpanded &&
                  row.original.rows?.map(subRow => (
                    <Row
                      key={subRow.id}
                      borderAfterIndex={borderAfterIndex}
                      disableCells={disableCells}
                      highlightRow={highlightRow}
                      highlightRowWithColor={highlightRowWithColor}
                      isSelected={row.isSelected}
                      isSubRow
                      lastStickyIndex={lastStickyIndex}
                      original={subRow as DataType}
                      row={row}
                      state={state}
                    />
                  ))}
              </Fragment>
            );
          })}
        </div>
        {showPagination && (
          <TablePagination
            ActionsComponent={PaginationActions}
            className={styles.pagination}
            colSpan={columns.length}
            component="div"
            count={currentTotalCount}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            page={currentPageIndex}
            rowsPerPage={currentPageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            SelectProps={SelectProps}
          />
        )}
      </div>
    );
  },
  equals,
);

export { DataGrid };
