import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { downloadBase64StringAsFile } from 'shared/utils';

import { DataResult, QueryArguments, UseDownloadArguments } from './types';

const useDownload = <Row extends { id: string }>({
  idField = 'id',
  mapDataToDownloadQueryArgs,
  query,
  queryResponseDataKey,
  selectedRows,
  triggerUncheckAllCheckboxes,
  view,
}: UseDownloadArguments<Row>) => {
  const [downloadAssortment] = useLazyQuery<DataResult, QueryArguments>(query, {
    onCompleted(data) {
      triggerUncheckAllCheckboxes();
      downloadBase64StringAsFile(
        data[queryResponseDataKey].file.content,
        data[queryResponseDataKey].file.name,
      );
    },
    // if remove this or change to another fetchPolicy onCompleted did not trigger when downloadTemplate executing more than one time in a row
    fetchPolicy: 'cache-and-network',
  });

  return useCallback(
    () =>
      downloadAssortment({
        ...(mapDataToDownloadQueryArgs?.({ selectedRows, view }) ?? {
          variables: {
            filter: {
              IDs: selectedRows.map(({ original }) => original[idField]),
            },
          },
        }),
      }),
    [selectedRows],
  );
};

export { useDownload };
