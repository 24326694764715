import { Permissions, permissions } from 'shared/constants';
import { useRole } from 'shared/hooks';

const usePermissions = (): Permissions => {
  const role = useRole();

  return permissions[role];
};

export { usePermissions };
