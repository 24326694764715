import { FC } from 'react';

import { Spinner } from 'shared/components/Loader/components/Spinner';

import styles from './UpdateAssortmentLoader.module.css';

const UpdateAssortmentLoader: FC = () => (
  <div className={styles.wrapper}>
    <Spinner active size={100} />
    <div className={styles.container}>
      <h1>Updating Assortment</h1>
      <p>Update in progress, please limit any changes</p>
    </div>
  </div>
);

export { UpdateAssortmentLoader };
