import { mergeDeepRight } from 'ramda';
import { O } from 'ts-toolbelt';

import { FullOpco } from 'shared/types';

const makeFullOpco = (props: O.Partial<FullOpco, 'deep'> = {}): FullOpco =>
  mergeDeepRight<FullOpco, O.Partial<FullOpco, 'deep'>>(
    {
      id: '1',
      ext_id: '1',
      name: 'goPuff Distribution Center',
      address: '20 something st',
      city: 'Commonwealth',
      country: 'USA',
      isActive: true,
      packageTypeId: '1',
      postalCode: '04214',
      stateProvinceRegion: 'Philadelphia',
      sendToTraverse: true,
      buyer: 'buyer@test.com',
      updatedAt: 0,
      updatedBy: 'test',
      __typename: 'FullOpco',
    },
    props,
  ) as FullOpco;

export { makeFullOpco };
