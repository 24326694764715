import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useIsSystemLocked } from 'shared/hooks/useIsSystemLocked';
import { unprocessedRecordsQuery } from 'shared/operations/query';
import { useIsDestinationAssortmentGlobalLoaderVisible } from 'shared/globalState';
import { UnprocessedRecordsResultQuery } from 'shared/types';
import { usePermissions } from 'shared/hooks';

import { SetupResult } from '../types';

const useSetup = (): SetupResult => {
  const userPermissions = usePermissions();

  const { data: { unprocessedRecords: unprocessedRecordsCount } = { unprocessedRecords: null } } =
    useQuery<UnprocessedRecordsResultQuery>(unprocessedRecordsQuery);
  const [
    isDestinationAssortmentGlobalLoaderVisible,
    setIsDestinationAssortmentGlobalLoaderVisible,
  ] = useIsDestinationAssortmentGlobalLoaderVisible();

  const { client, previousSystemLocked, startPolling, stopPolling, systemLocked } =
    useIsSystemLocked();

  useEffect(() => {
    if (systemLocked) {
      if (!isDestinationAssortmentGlobalLoaderVisible) {
        setIsDestinationAssortmentGlobalLoaderVisible(true);
      }
    } else if (isDestinationAssortmentGlobalLoaderVisible) {
      setIsDestinationAssortmentGlobalLoaderVisible(false);
    }
  }, [isDestinationAssortmentGlobalLoaderVisible, systemLocked]);

  useEffect(() => {
    if (!systemLocked && previousSystemLocked) {
      client.cache.evict({ fieldName: 'destinationAssortments', broadcast: true });
      client.cache.evict({ fieldName: 'lastDestinationAssortmentTime', broadcast: true });

      client.cache.gc();
    }
  }, [client, previousSystemLocked, systemLocked]);

  useEffect(() => {
    if (systemLocked) {
      startPolling(10000);
    } else {
      stopPolling();
    }
  }, [systemLocked]);

  return {
    unprocessedRecordsCount,
    userPermissions,
  };
};

export { useSetup };
