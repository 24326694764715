import { FC } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { DealStatusChip, mapDataToRows as mapDataToHeaderRows } from 'pages/DealManagement';
import { LongTextTooltip } from 'shared/components/LongTextTooltip';

import { DealHeaderProps } from './types';

export const DealHeader: FC<DealHeaderProps> = ({ deal }) => {
  if (!deal) {
    return <></>;
  }

  const dealHeaderRow = mapDataToHeaderRows([deal])[0];

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Offer #</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Parent Company</TableCell>
              <TableCell>Deal Description</TableCell>
              <TableCell>Deal Type</TableCell>
              <TableCell>Deal Start Date</TableCell>
              <TableCell>Start Time Zone</TableCell>
              <TableCell>Deal End Date</TableCell>
              <TableCell>End Time Zone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{dealHeaderRow.offerNumber}</TableCell>
              <TableCell>
                <DealStatusChip status={dealHeaderRow.status} />
              </TableCell>
              <TableCell>{dealHeaderRow.parentCompanyName}</TableCell>
              <TableCell>
                <LongTextTooltip value={dealHeaderRow.description} width="300px" />
              </TableCell>
              <TableCell>{dealHeaderRow.type}</TableCell>
              <TableCell>{dealHeaderRow.startDate}</TableCell>
              <TableCell>{deal.startTimeZone}</TableCell>
              <TableCell>{dealHeaderRow.endDate}</TableCell>
              <TableCell>{deal.endTimeZone}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box paddingTop={4} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Submitter Name</TableCell>
              <TableCell>Submitter Email</TableCell>
              <TableCell>Approved By</TableCell>
              <TableCell>Approval Date</TableCell>
              <TableCell>Last Updated By</TableCell>
              <TableCell>Last Updated Date</TableCell>
              <TableCell>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{dealHeaderRow.submitterName}</TableCell>
              <TableCell>
                <LongTextTooltip value={dealHeaderRow.submitterEmail} width="150px" />
              </TableCell>
              <TableCell>{dealHeaderRow.approvedBy}</TableCell>
              <TableCell>{dealHeaderRow.approvedDate}</TableCell>
              <TableCell>{dealHeaderRow.updatedBy}</TableCell>
              <TableCell>{dealHeaderRow.updatedDate}</TableCell>
              <TableCell>
                <LongTextTooltip value={dealHeaderRow.note} width="300px" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
