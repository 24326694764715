import { ActionsType, pagePermissions } from 'shared/constants';

import { HasPermissionsProps } from './types';

const hasPermissions = ({ pagePath, userPermissions }: HasPermissionsProps): boolean => {
  if (!pagePath) {
    throw new Error('Path is required');
  }

  if (typeof pagePath === 'string') {
    const requiredPermission = pagePermissions.get(pagePath) as ActionsType;
    if (!userPermissions.has(requiredPermission)) {
      // eslint-disable-next-line no-console
      console.warn(`user missing required permissions.
userPermissions=${JSON.stringify(userPermissions)} 
requiredPermission=${requiredPermission} 
pagePath=${pagePath}`);
      return false;
    }
  }

  if (Array.isArray(pagePath)) {
    const hasAllRequiredPermissions = pagePath.every(path => {
      const requiredPermission = pagePermissions.get(path) as ActionsType;
      if (!userPermissions.has(requiredPermission)) {
        // eslint-disable-next-line no-console
        console.warn(`user missing required permissions. 
 userPermissions=${JSON.stringify(userPermissions)}
 requiredPermission=${requiredPermission}
 pagePath=${path}`);
        return false;
      }
      return true;
    });
    if (!hasAllRequiredPermissions) {
      return false;
    }
  }
  return true;
};

export { hasPermissions };
