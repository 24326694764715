import { PackageTypeName } from 'shared/constants/package';
import { MappedRow } from 'pages/ItemSource/types';
import { ItemSourceQuery, User } from 'shared/types';
import { SupplierItemNumberColumnName } from 'pages/ItemSource/constants';

import { PackagingMap } from './types';

const mapDataToRows = (data: ItemSourceQuery['itemSources']['items'], users: User[]) =>
  data.map(
    ({
      id,
      item: { extId: itemId, i18n: itemI18n, name },
      opco: {
        id: opcoId,
        name: opco,
        stateProvinceRegion: opcoStateProvinceRegion,
        supplier: { extID },
      },
      packaging,
      ...rest
    }): MappedRow => {
      const packagingMap: PackagingMap = {} as PackagingMap;

      packaging.forEach(p => {
        const packageTypeName = p.packageType.name as PackageTypeName;
        const supplierItemNumber = {
          [SupplierItemNumberColumnName]: p.supplierNumber,
        };
        let mappedPackaging: MappedRow = {
          declaredVolume: packageTypeName === PackageTypeName.Pallet ? undefined : p.declaredVolume,
          measuredVolume: packageTypeName === PackageTypeName.Pallet ? undefined : p.measuredVolume,
          id: `${id}:${p.id}`,
          name: packageTypeName,
          upc: [p.upcCode],
          opco,
          packageTypeName,
          opcoStateProvinceRegion,
          supplierExtId: extID,
          updatedAt: p.updatedAt,
          updatedBy: p.updatedBy
            ? users
                .find(({ id: userId }) => userId.includes(p.updatedBy))
                ?.displayName.toString() || p.updatedBy
            : '',
          ...supplierItemNumber,
        };

        switch (packageTypeName) {
          case PackageTypeName.Pallet: {
            mappedPackaging = {
              declaredHi: p.declaredHi,
              declaredTi: p.declaredTi,
              measuredHi: p.measuredHi,
              measuredTi: p.measuredTi,
              quantity: p.perChildPackageQty,
              roundingThreshold: p.roundingThreshold,
              ...mappedPackaging,
            };
            break;
          }
          case PackageTypeName.Each: {
            mappedPackaging = {
              declaredVolume: p.declaredVolume,
              declaredLength: p.declaredLength,
              declaredLengthUnits: p.declaredLengthUnits,
              declaredWeight: p.declaredWeight,
              declaredWeightUnits: p.declaredWeightUnits,
              declaredWidth: p.declaredWidth,
              declaredHeight: p.declaredHeight,
              measuredHeight: p.measuredHeight,
              measuredLength: p.measuredLength,
              measuredLengthUnits: p.measuredLengthUnits,
              measuredWeight: p.measuredWeight,
              measuredVolume: p.measuredVolume,
              measuredWeightUnits: p.measuredWeightUnits,
              measuredWidth: p.measuredWidth,
              upc: p.eachUPCs,
              supplierExtId: extID,
              maxAllocationQuantity: p.maxAllocationQuantity,
              ...supplierItemNumber,
            } as MappedRow;
            break;
          }
          default: {
            mappedPackaging = {
              declaredVolume: p.declaredVolume,
              measuredVolume: p.measuredVolume,
              declaredLength: p.declaredLength,
              declaredLengthUnits: p.declaredLengthUnits,
              declaredWeight: p.declaredWeight,
              declaredWeightUnits: p.declaredWeightUnits,
              declaredWidth: p.declaredWidth,
              declaredHeight: p.declaredHeight,
              measuredHeight: p.measuredHeight,
              measuredLength: p.measuredLength,
              measuredLengthUnits: p.measuredLengthUnits,
              measuredWeight: p.measuredWeight,
              measuredWeightUnits: p.measuredWeightUnits,
              measuredWidth: p.measuredWidth,
              quantity: p.perChildPackageQty,
              roundingThreshold: p.roundingThreshold,
              ...mappedPackaging,
            };
          }
        }

        packagingMap[packageTypeName] = mappedPackaging;
      });

      return {
        inner: packagingMap[PackageTypeName.Inner]?.quantity,
        case: packagingMap[PackageTypeName.Case]?.quantity,
        masterCase: packagingMap[PackageTypeName.MasterCase]?.quantity,
        pallet: packagingMap[PackageTypeName.Pallet]?.quantity,
        ...packagingMap.Each,
        rows: [
          packagingMap[PackageTypeName.Inner],
          packagingMap[PackageTypeName.Case],
          packagingMap[PackageTypeName.MasterCase],
          packagingMap[PackageTypeName.Pallet],
        ].filter(Boolean),
        id,
        name,
        i18n: itemI18n,
        opco,
        opcoStateProvinceRegion,
        packageTypeName: PackageTypeName.Each,
        itemId,
        opcoId,
        ...rest,
      };
    },
  );

export { mapDataToRows };
