import { useCallback } from 'react';
import addDays from 'date-fns/addDays';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';

import { DatePicker } from 'shared/components/DatePicker';
import { Label } from 'shared/components/Search/shared';
import { BaseQueryArgs } from 'shared/components/Search/Filter/shared';

import { isValidDate } from './utils';
import { FilterSearchDatePickerProps } from './types';

const FilterSearchDatePicker = <QueryArgs extends BaseQueryArgs>({
  filterFieldName,
  label,
  onSubmitFilter,
}: FilterSearchDatePickerProps<QueryArgs>) => {
  const handleSubmit = useCallback(
    (newDate: Date | null) => {
      let submittedDate;

      if (newDate && isValidDate(newDate)) {
        const from = startOfDay(new Date(newDate));
        const to = addDays(from, 1);

        submittedDate = {
          from: getUnixTime(from),
          to: getUnixTime(to),
        };
      }

      onSubmitFilter({
        [filterFieldName]: submittedDate,
      });
    },
    [onSubmitFilter],
  );

  return (
    <>
      <Label label={label} />
      <DatePicker label={null} onSubmit={handleSubmit} />
    </>
  );
};

export { FilterSearchDatePicker };
