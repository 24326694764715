import { FocusEventHandler, KeyboardEventHandler, useCallback } from 'react';

import { Input, Label, SearchInputProps } from 'shared/components/Search/shared';
import { TooltipHeader } from 'shared/components/TooltipHeader';

import { BaseQueryArgs, mapAutocompleteValueToFilterParams } from '../shared';

import { FilterSearchInputProps } from './types';

const FilterSearchInput = <QueryArgs extends BaseQueryArgs>({
  filterFieldName,
  label,
  mapUserInputToFilerParams,
  onSubmitFilter,
  title,
}: FilterSearchInputProps<QueryArgs>) => {
  const onBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    event => {
      onSubmitFilter(
        mapAutocompleteValueToFilterParams<QueryArgs, false, string>(
          (event.target as HTMLInputElement).value,
          filterFieldName,
          mapUserInputToFilerParams,
        ),
      );
    },
    [filterFieldName, mapUserInputToFilerParams, onSubmitFilter],
  );

  const onKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  return (
    <>
      {title ? (
        <TooltipHeader label={label?.toString() || ''} title={title} />
      ) : (
        <Label label={label} />
      )}
      <Input InputProps={SearchInputProps} onBlur={onBlur} onKeyPress={onKeyPress} />
    </>
  );
};

export { FilterSearchInput };
