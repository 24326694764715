import { useSetup } from './hooks';
import { CostManagementView } from './CostManagementView';

const CostManagement = () => {
  const props = useSetup();

  return <CostManagementView {...props} />;
};

export { CostManagement };
