/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC } from 'react';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { Link, Redirect } from 'react-router-dom';

import { Loader } from 'shared/components/Loader';
import { Tabs } from 'shared/components/Tabs';
import { CommentsModal } from 'shared/components/Modal/CommentsModal';
import { ConfirmModal } from 'shared/components/Modal/ConfirmModal';
import { DealApprovalStatus } from 'shared/types';
import { PAGE_DEAL_MANAGEMENT } from 'shared/constants/access';
import { DataGridDownloadButton } from 'shared/components/IconButton/DataGridDownloadButton';

import { DealManagementDetailViewProps } from './types';
import {
  DealHeader,
  DealLineItemsTab,
  DealLocationsTab,
  PricingChannelMultiSelector,
} from './components';
import styles from './DealManagementDetailView.module.css';

const DealManagementDetailView: FC<DealManagementDetailViewProps> = ({
  canApproveAndReject,
  canChangePricingChannels,
  canDelete,
  canRescindDeals,
  commentsRequired,
  deal,
  deleteDealsLoading,
  deleteMessage,
  rescindDealsLoading,
  rescindMessage,
  shouldShowRescindModal,
  isFirstLoadingFinish,
  onApproveOrRejectClick,
  onCloseCommentsModalClick,
  onCloseDeleteModalClick,
  onConfirmDeleteModalClick,
  onDeleteClick,
  onRescindDealClick,
  onConfirmRescindModalClick,
  onCloseRescindModalClick,
  onSubmitCommentsModalClick,
  onSubmitPricingChannelsClick,
  redirectToDealManagement,
  shouldShowCommentsModal,
  shouldShowDeleteModal,
  updateDealsApprovalStatusLoading,
  onDownloadButtonClick,
}) => (
  <>
    {redirectToDealManagement ? (
      <Redirect to={PAGE_DEAL_MANAGEMENT} />
    ) : (
      <>
        {isFirstLoadingFinish ? (
          <>
            {deal ? (
              <Box style={{ padding: '1.5rem' }}>
                <div>
                  <div style={{ display: 'inline-block' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link color="inherit" to={PAGE_DEAL_MANAGEMENT}>
                        Deal Management
                      </Link>
                      <Typography color="text.primary">
                        {deal?.offerNumber ? `Offer #${deal?.offerNumber}` : 'Detail'}
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div style={{ display: 'inline-block', float: 'right' }}>
                    {canChangePricingChannels && deal?.id && (
                      <PricingChannelMultiSelector
                        dealPricingChannels={deal?.pricingChannels}
                        onSubmitPricingChannelsClick={onSubmitPricingChannelsClick}
                      />
                    )}
                    {canApproveAndReject && (
                      <>
                        <Button
                          className={styles.headerGridAction}
                          disabled={updateDealsApprovalStatusLoading}
                          onClick={() => onApproveOrRejectClick(DealApprovalStatus.Approved)}
                          variant="outlined"
                        >
                          Approve
                        </Button>
                        <Button
                          className={styles.headerGridAction}
                          color="error"
                          disabled={updateDealsApprovalStatusLoading}
                          onClick={() => onApproveOrRejectClick(DealApprovalStatus.Rejected)}
                          sx={{ color: 'error.main' }}
                          variant="outlined"
                        >
                          Reject
                        </Button>
                      </>
                    )}
                    {canDelete && (
                      <Button
                        className={styles.headerGridAction}
                        color="error"
                        disabled={deleteDealsLoading}
                        onClick={onDeleteClick}
                        sx={{ color: 'error.main' }}
                        variant="outlined"
                      >
                        Delete
                      </Button>
                    )}
                    {canRescindDeals && (
                      <Button
                        className={styles.headerGridAction}
                        color="error"
                        disabled={rescindDealsLoading}
                        onClick={onRescindDealClick}
                        sx={{ color: 'error.main' }}
                        variant="outlined"
                      >
                        Rescind Deal
                      </Button>
                    )}
                    <DataGridDownloadButton onClick={onDownloadButtonClick} />
                  </div>
                </div>
                <Box>
                  <DealHeader deal={deal} />
                  <Box marginTop={4}>
                    <Tabs
                      tabs={[
                        {
                          key: 0,
                          label: 'Line Items',
                          children: <DealLineItemsTab deal={deal} />,
                        },
                        {
                          key: 1,
                          label: 'Locations',
                          children: <DealLocationsTab deal={deal} />,
                        },
                      ]}
                    />
                  </Box>
                </Box>
                <CommentsModal
                  commentsRequired={commentsRequired}
                  isOpen={shouldShowCommentsModal}
                  onClose={onCloseCommentsModalClick}
                  onSubmit={onSubmitCommentsModalClick}
                />
                <ConfirmModal
                  confirmMessage={deleteMessage}
                  isOpen={shouldShowDeleteModal}
                  onClose={onCloseDeleteModalClick}
                  onConfirm={onConfirmDeleteModalClick}
                />
                <ConfirmModal
                  confirmMessage={rescindMessage}
                  isOpen={shouldShowRescindModal}
                  onClose={onCloseRescindModalClick}
                  onConfirm={onConfirmRescindModalClick}
                />
              </Box>
            ) : (
              <Box>Deal not found</Box>
            )}
          </>
        ) : (
          <Loader isLoading />
        )}
      </>
    )}
  </>
);

export { DealManagementDetailView };
