import { downloadColumnNames } from './mapRowsForDownload';

const downloadColumnNamesTypesMap = new Map<string, string>([
  [downloadColumnNames.itemDepartment, 'Text'],
  [downloadColumnNames.itemClass, 'General'],
  [downloadColumnNames.itemId, 'Text'],
  [downloadColumnNames.itemName, 'General'],
  [downloadColumnNames.caseCount, 'Number'],
  [downloadColumnNames.eachUPC, 'Text'],
  [downloadColumnNames.caseUPC, 'Text'],
  [downloadColumnNames.eachVIN, 'Text'],
  [downloadColumnNames.caseVIN, 'Text'],
  [downloadColumnNames.status, 'Text'],
  [downloadColumnNames.type, 'Text'],
  [downloadColumnNames.eachCostValidation, 'Number'],
  [downloadColumnNames.caseCostValidation, 'Number'],
  [downloadColumnNames.note, 'Text'],
  [downloadColumnNames.isDisabled, 'Text'],
  [downloadColumnNames.effectiveDate, 'Date'],
  [downloadColumnNames.endDate, 'Date'],
  [downloadColumnNames.eachCost, 'Number'],
  [downloadColumnNames.caseCost, 'Number'],
  [downloadColumnNames.currency, 'General'],
  [downloadColumnNames.supplierName, 'General'],
  [downloadColumnNames.supplierId, 'Text'],
  [downloadColumnNames.opcoName, 'General'],
  [downloadColumnNames.opcoId, 'Text'],
  [downloadColumnNames.opcoStateProvinceRegion, 'General'],
  [downloadColumnNames.locationName, 'General'],
  [downloadColumnNames.locationId, 'Text'],
  [downloadColumnNames.locationAddress, 'General'],
  [downloadColumnNames.locationCity, 'General'],
  [downloadColumnNames.locationStateProvinceRegion, 'General'],
  [downloadColumnNames.locationPostalCode, 'Text'],
  [downloadColumnNames.parentCompany, 'General'],
]);

export { downloadColumnNamesTypesMap };
