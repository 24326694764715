import Button from '@mui/material/Button';
import classNames from 'classnames';
import { FC } from 'react';

import styles from './ToggleButton.module.css';
import { ToggleButtonProps } from './types';

const ToggleButton: FC<ToggleButtonProps> = ({ active, name, onClick }) => (
  <Button
    className={classNames({
      [styles.active]: active,
      [styles.nonActive]: !active,
    })}
    onClick={onClick}
  >
    {name}
  </Button>
);

export { ToggleButton };
