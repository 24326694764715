import { ComponentType, useMemo, useRef } from 'react';
import { usePrevious } from 'react-use';
import { UseComboboxReturnValue } from 'downshift';

import { OptionValueType, SelectOption as SelectOptionType } from '../types';

type ComboboxReturnProps = UseComboboxReturnValue<SelectOptionType>;

const useLastRenderedList = <SelectedOptions extends OptionValueType | null | OptionValueType[]>({
  SelectOption,
  filteredOptions,
  getItemProps,
  isOpen,
  options,
  selectedValue,
}: {
  filteredOptions: SelectOptionType[];
  getItemProps: ComboboxReturnProps['getItemProps'];
  isOpen: boolean;
  options: SelectOptionType[];
  selectedValue: SelectedOptions;
  SelectOption: ComponentType<{
    item: SelectOptionType;
    selectedValue: SelectedOptions;
  }>;
}) => {
  const lastRenderedList = useRef<JSX.Element[] | null>(null);
  const previousIsOpen = usePrevious(isOpen);

  return useMemo(() => {
    if (isOpen) {
      if (previousIsOpen === false && lastRenderedList.current) {
        return lastRenderedList.current;
      }
      lastRenderedList.current = filteredOptions.map((item, index) => {
        const { onMouseMove, ...itemProps } = getItemProps({
          item,
          index,
          disabled: !!item.disabled,
        }) as {
          onMouseMove: Function;
        };

        return (
          <SelectOption key={item.value} item={item} selectedValue={selectedValue} {...itemProps} />
        );
      });
      return lastRenderedList.current;
    }
    if (lastRenderedList.current?.length !== options.length) {
      lastRenderedList.current = null;
    }
    return null;
  }, [isOpen, options, filteredOptions, previousIsOpen, selectedValue]);
};

export { useLastRenderedList };
