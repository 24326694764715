import { FC } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { ArrayTooltip } from 'shared/components/ArrayTooltip';

import { DealLocationsTabProps } from './types';

export const DealLocationsTab: FC<DealLocationsTabProps> = ({ deal }) => {
  if (!deal) {
    return <></>;
  }

  const locationRows = deal?.locations?.map(location => ({
    name: location.name,
    id: location.extId,
  }));

  const locationGroupRows = deal?.locationGroups?.map(locationGroup => ({
    name: locationGroup.name,
    locationIds: locationGroup.locations.map(location => location.extId),
  }));

  return (
    <Box>
      {deal.country && <Box>This deal applies to all locations in {deal.country}</Box>}
      <Grid container spacing={2}>
        <Grid item md={6}>
          {!deal.country && (
            <Box>
              <Typography variant="h5">Location List</Typography>
              <Box marginTop={1}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Location Name</TableCell>
                        <TableCell>Location ID</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {locationRows?.map(row => (
                        <TableRow key={row.id}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.id}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item md={6}>
          {!deal.country && (
            <Box>
              <Typography variant="h5">Location Group List</Typography>
              <Box marginTop={1}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Location Group Name</TableCell>
                        <TableCell>Location IDs</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {locationGroupRows?.map(row => (
                        <TableRow key={row.name}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            <ArrayTooltip
                              displayCount={4}
                              elementsPerRow={10}
                              values={row.locationIds}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
