import { Option, OrderFrequencyType } from 'shared/types';

const orderFrequencyOptions: Option<string, string>[] = [
  {
    label: 'Weekly',
    value: OrderFrequencyType.Weekly,
  },
  {
    label: 'Bi-Weekly (1,3)',
    value: OrderFrequencyType.Biweekly_1_3,
  },
  {
    label: 'Bi-Weekly (2,4)',
    value: OrderFrequencyType.Biweekly_2_4,
  },
  {
    label: 'Monthly (1)',
    value: OrderFrequencyType.Monthly_1,
  },
  {
    label: 'Monthly (2)',
    value: OrderFrequencyType.Monthly_2,
  },
  {
    label: 'Monthly (3)',
    value: OrderFrequencyType.Monthly_3,
  },
  {
    label: 'Monthly (4)',
    value: OrderFrequencyType.Monthly_4,
  },
];

export { orderFrequencyOptions };
