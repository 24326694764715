import { useMemo } from 'react';

import { Row, UseColumnsArgs, UseColumnsResult } from 'pages/SupplierAssortment/types';
import {
  FactoryCellDataLink,
  FactoryCellSelect,
  FactoryCellTimeConverter,
  FactoryCellUPC,
} from 'shared/components/DataGrid';
import {
  isReadOnlyRole,
  mapToIdDefaultNull,
  mapToSelectOptions,
  mapValuesSeparatedWithComma,
} from 'shared/utils';
import { isSupplierPage } from 'pages/SupplierAssortment/utils';
import {
  ItemsOptionsQuery,
  ItemsOptionsQueryVariables,
  OpcosQuery,
  OpcosQueryVariables,
  SupplierAssortmentQueryVariables,
  User,
} from 'shared/types';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteQuery,
  FilterSearchDatePicker,
  FilterSearchInput,
} from 'shared/components/Search';
import { itemsOptionsQuery, opcosQuery } from 'shared/operations/query';

const CellItemName = FactoryCellDataLink<Row>();
const CellTimeConverter = FactoryCellTimeConverter();
const CellUPC = FactoryCellUPC();

const useColumns = ({
  activePageIndex,
  languageCode,
  role,
  systemLocked,
  updateFilter,
  userOptions,
}: UseColumnsArgs) => {
  const {
    itemId,
    itemName,
    managedBy,
    opcoId,
    opcoSiteName,
    opcoStateProvinceRegion,
    updatedAt,
    updatedBy,
  } = useMemo(
    () => ({
      opcoSiteName: (
        <FilterSearchAutocompleteQuery<
          OpcosQuery,
          OpcosQueryVariables,
          SupplierAssortmentQueryVariables
        >
          filterFieldName="opcoNames"
          label="OpCo/Site Name"
          mapDataToOptions={result => mapToSelectOptions(result.opcos.opcos)}
          mapUserInputToAutocompleteQueryFilerParams={value => ({
            name: value,
          })}
          onSubmitFilter={updateFilter}
          query={opcosQuery}
        />
      ),
      opcoId: (
        <FilterSearchInput<SupplierAssortmentQueryVariables>
          filterFieldName="opcoIds"
          label="Opco/Site ID"
          mapUserInputToFilerParams={value => ({
            opcoIds: mapValuesSeparatedWithComma(value),
          })}
          onSubmitFilter={updateFilter}
        />
      ),
      opcoStateProvinceRegion: (
        <FilterSearchInput<SupplierAssortmentQueryVariables>
          filterFieldName="opcoStateProvinceRegion"
          label="Opco State/Province/Region"
          mapUserInputToFilerParams={value => ({
            opcoStateProvinceRegion: value,
          })}
          onSubmitFilter={updateFilter}
        />
      ),
      itemName: (
        <FilterSearchAutocompleteQuery<
          ItemsOptionsQuery,
          ItemsOptionsQueryVariables,
          SupplierAssortmentQueryVariables
        >
          filterFieldName="itemNames"
          label="Item Name"
          mapDataToOptions={a =>
            mapToSelectOptions(
              a.items.items.map(item => ({
                ...item,
                languageCode: languageCode.split('-').join(''),
              })),
            )
          }
          mapUserInputToAutocompleteQueryFilerParams={value => ({
            name: value,
            languageCode: languageCode || 'en-US',
          })}
          onSubmitFilter={updateFilter}
          query={itemsOptionsQuery}
        />
      ),
      itemId: (
        <FilterSearchInput<SupplierAssortmentQueryVariables>
          filterFieldName="itemExtIds"
          label="Item ID"
          mapUserInputToFilerParams={value => ({
            itemExtIds: mapValuesSeparatedWithComma(value),
          })}
          onSubmitFilter={updateFilter}
        />
      ),
      managedBy: (
        <FilterSearchAutocomplete<SupplierAssortmentQueryVariables>
          filterFieldName="managedByIds"
          label="Managed by"
          mapUserAutocompleteValueSelectToFilterParams={option => ({
            managedByIds: [option!.value],
          })}
          onSubmitFilter={updateFilter}
          options={userOptions}
        />
      ),
      updatedAt: (
        <FilterSearchDatePicker<SupplierAssortmentQueryVariables>
          filterFieldName="updatedAt"
          label="Updated At"
          onSubmitFilter={updateFilter}
        />
      ),
      updatedBy: (
        <FilterSearchAutocomplete<SupplierAssortmentQueryVariables>
          filterFieldName="updatedBy"
          label="Updated By"
          mapUserAutocompleteValueSelectToFilterParams={option => ({
            updatedBy: option!.value,
          })}
          onSubmitFilter={updateFilter}
          options={userOptions}
        />
      ),
    }),
    [userOptions],
  );
  return useMemo(() => {
    const CellManagedBy = FactoryCellSelect<Row, string, User>({
      isDisabled: ({ item, opco }: Row) =>
        !item?.extId || !opco?.id || systemLocked || isReadOnlyRole(role),
      options: userOptions,
      pickValue: mapToIdDefaultNull,
    });

    const eachUpcsFilter = (
      <FilterSearchInput<SupplierAssortmentQueryVariables>
        filterFieldName="eachUPCs"
        label="EACH UPC"
        mapUserInputToFilerParams={value => ({
          eachUPCs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const columnsItem = [
      {
        Header: () => itemName,
        accessor: 'item.name',
        Cell: CellItemName,
        width: 300,
      },
      {
        Header: () => eachUpcsFilter,
        accessor: 'item.upc',
        Cell: CellUPC,
      },
      {
        Header: () => itemId,
        accessor: 'item.extId',
      },
      {
        Header: () => managedBy,
        accessor: 'managedBy',
        Cell: CellManagedBy,
      },
      {
        Header: () => opcoSiteName,
        accessor: 'opco.name',
      },
      {
        Header: () => opcoId,
        accessor: 'opco.id',
      },
      {
        Header: () => opcoStateProvinceRegion,
        accessor: 'opco.stateProvinceRegion',
        width: 200,
      },
      {
        Header: () => updatedBy,
        accessor: 'opco.updatedBy',
      },
      {
        Header: () => updatedAt,
        accessor: 'opco.updatedAt',
        Cell: CellTimeConverter,
        width: 200,
      },
    ];
    const columnsSupplier = [
      {
        Header: () => opcoSiteName,
        accessor: 'opco.name',
      },
      {
        Header: () => opcoId,
        accessor: 'opco.id',
      },
      {
        Header: () => opcoStateProvinceRegion,
        accessor: 'opco.stateProvinceRegion',
        width: 200,
      },
      {
        Header: () => updatedBy,
        accessor: 'opco.updatedBy',
      },
      {
        Header: () => updatedAt,
        accessor: 'opco.updatedAt',
        Cell: CellTimeConverter,
        width: 200,
      },
      {
        Header: () => itemName,
        accessor: 'item.name',
        Cell: CellItemName,
      },
      {
        Header: () => eachUpcsFilter,
        accessor: 'item.upc',
        Cell: CellUPC,
      },
      {
        Header: () => itemId,
        accessor: 'item.extId',
      },
      {
        Header: () => managedBy,
        accessor: 'managedBy',
        Cell: CellManagedBy,
      },
    ];
    const columns = isSupplierPage(activePageIndex) ? columnsSupplier : columnsItem;

    return columns as UseColumnsResult;
  }, [activePageIndex, userOptions, systemLocked, languageCode]);
};

export { useColumns };
