import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { ManufacturingType } from 'shared/types';

import { replicateN } from '../utils';
import { makeManufacturingType } from '../factories';

const manufacturingTypes = (
  props: O.Partial<ManufacturingType, 'deep'>[] = [],
): ManufacturingType[] =>
  mergeDeepArrays<ManufacturingType>(replicateN(makeManufacturingType(), 2), props);

export { manufacturingTypes };
