import { CellObject } from 'xlsx';
import * as XLSX from 'xlsx';

import { EXCEL_DATE_FORMAT } from 'shared/tools/excel/constants';

import { downloadColumnNamesTypesMap } from '../mappers/mapColumnsForXlsx';

const configureCell = (cell: CellObject, columnHeader?: string) => {
  const result = cell;
  if (!cell || !columnHeader) {
    return result;
  }
  if (downloadColumnNamesTypesMap.get(columnHeader) === 'Text') {
    result.t = 's';
    result.z = '@';
  } else if (downloadColumnNamesTypesMap.get(columnHeader) === 'Date') {
    XLSX.utils.cell_set_number_format(result, EXCEL_DATE_FORMAT);
  } else if (downloadColumnNamesTypesMap.get(columnHeader) === 'Number') {
    result.t = 'n';
  }
  return result;
};

// Deprecated
// TODO: Use exportXlsxFile from shared/tools/excel/exportXlsxFile.ts
export const exportCostManagementXlsxFile = (
  data: unknown[],
  fileName: string,
  sheetName = 'sheet1',
) => {
  const sheet = XLSX.utils.json_to_sheet(data, {
    cellStyles: true,
    cellDates: true,
  });

  const colWidth = Object.keys(data[0] as object).map(key => ({
    wch: Math.max(key.length, 12),
  }));
  sheet['!cols'] = colWidth;

  const rowLength = data.length;
  const colLength = data[0] ? Object.keys(data[0] as object).length : 0;
  // if data is empty then add ROW_STYLE_COUNT to set as example row with styles
  const emptyRows = data?.length === 0 ? 1 : 0;
  const emptyArray = Array.from({ length: emptyRows }, () =>
    Array.from({ length: colLength }, () => ['']),
  );
  if (emptyRows) {
    XLSX.utils.sheet_add_aoa(sheet, emptyArray, {
      origin: { r: rowLength + 1, c: 0 },
      sheetStubs: true,
    });
  }

  const range = {
    start: { r: 1, c: 0 },
    end: { r: rowLength + emptyRows, c: colLength - 1 },
  };
  for (let R = range.start.r; R <= range.end.r; ++R) {
    for (let C = range.start.c; C <= range.end.c; ++C) {
      let cell: CellObject = sheet[XLSX.utils.encode_cell({ r: R, c: C })];
      const column = XLSX.utils.encode_col(C);
      const columnHeader = (sheet[`${column}1`] as CellObject)?.v?.toString() ?? '';
      cell = configureCell(cell, columnHeader);
    }
  }

  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, sheet, sheetName);
  /* generate XLSX file and send to client */
  XLSX.writeFileXLSX(book, `${fileName}.xlsx`, { cellStyles: true });
};
