import { Dispatch, SetStateAction, useEffect, useState } from 'react';

function getSessionStorageOrDefault(key: string, defaultValue: string): string {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return stored;
}

const useSessionStorage = (
  key: string,
  defaultValue: string,
): [string, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = useState<string>(getSessionStorageOrDefault(key, defaultValue));

  useEffect(() => {
    sessionStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
};

export { useSessionStorage };
