import { Row as TableRow } from 'react-table';

import { Row } from 'pages/Location/types';
import { FormValues } from 'pages/Location/components/MassEditFormRow';

const mapMassEditRowToMutationArgs = ({
  formData,
  selectedRows,
}: {
  selectedRows: TableRow<Row>[];
  formData: FormValues;
}) => ({
  input: {
    locations: selectedRows.map(({ original: { id } }) => ({
      ...formData,
      isThirdParty: Boolean(formData.isThirdParty),
      stopBuying: Boolean(formData.stopBuying),
      ageOutThreshold: Number(formData.ageOutThreshold),
      id,
    })),
  },
});

export { mapMassEditRowToMutationArgs };
