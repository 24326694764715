import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { AlcoholType } from 'shared/types';

import { pickOptions, replicateN } from '../utils';
import { makeAlcoholType } from '../factories';

import { alcoholVolumes } from './alcoholVolumes';

const alcoholVolumesOptions = alcoholVolumes();

const alcoholTypes = (props: O.Partial<AlcoholType, 'deep'>[] = []): AlcoholType[] =>
  mergeDeepArrays<AlcoholType>(
    replicateN({
      data: makeAlcoholType(),
      n: 5,
      replacements: {
        name: [, 'Beer', 'Wine', 'Vodka', 'Hard Liquor'],
        volumes: [
          ,
          pickOptions({ options: alcoholVolumesOptions, pick: [1, 2, 15] }),
          pickOptions({ options: alcoholVolumesOptions, pick: [8, 9, 10, 16] }),
          pickOptions({ options: alcoholVolumesOptions, pick: [11, 12, 13, 17] }),
          pickOptions({ options: alcoholVolumesOptions, pick: [14, 18] }),
        ],
      },
    }),
    props,
  );

export { alcoholTypes };
