import { always } from 'ramda';
import Tooltip from '@mui/material/Tooltip';
import { Link, LinkProps } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';

import { mapToLanguage, memoCell } from 'shared/utils';
import { CellProps } from 'shared/types/props';
import { SessionStorageContext } from 'setup/providers/Context/Context';

import styles from './FactoryCellDataLink.module.css';
import { CellValue, DataRow } from './types';

const defaultLinkProps = always('/');

const FactoryCellDataLink = <DataType extends DataRow>(
  cellArguments: {
    isLink?: (rowData: DataType) => boolean;
    linkProps?: (rowData: DataType) => LinkProps['to'];
    maxWidth?: string;
    dependencies?: (keyof DataType)[];
    useI18n?: boolean;
  } = { dependencies: [] },
) =>
  memoCell<CellProps<DataType, CellValue>>(({ row: { original }, value: initialValue }) => {
    const {
      isLink,
      linkProps = defaultLinkProps,
      maxWidth = '224px',
      useI18n = true,
    } = cellArguments;
    const { language } = useContext(SessionStorageContext);
    const i18n = mapToLanguage.get(language)?.split('-').join('') || '';
    const value = useI18n
      ? (original.i18n?.name && original.i18n?.name[i18n]) ||
        (original.item?.i18n?.name && original.item?.i18n?.name[i18n]) ||
        initialValue
      : initialValue;

    return (
      <Tooltip title={value ?? ''}>
        <div>
          {isLink?.(original) ? (
            // TODO: Split this component and refactor to prop accordingly
            <Link className={styles.link} to={linkProps(original)}>
              <Typography className={styles.dots} style={{ maxWidth }}>
                {value}
              </Typography>
            </Link>
          ) : (
            <Typography className={styles.dots} style={{ maxWidth }}>
              {value}
            </Typography>
          )}
        </div>
      </Tooltip>
    );
  }, cellArguments.dependencies);

export { FactoryCellDataLink };
