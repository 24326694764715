import { EditPanel } from 'shared/components/Edit';
import { Loader } from 'shared/components/Loader';

import { DataGridPageProps } from './types';
import styles from './DataGridPage.module.css';

const DataGridPage = <Row extends object, IsMassEditOn extends boolean>({
  children,
  header,
  isMassEditOn = true as IsMassEditOn,
  isLoading,
  onAddTagsClick,
  onEditClick,
  selectedRows = [],
  triggerUncheckAllCheckboxes,
}: DataGridPageProps<Row, IsMassEditOn>) => (
  <div className={styles.dataGridPageContainer}>
    <div className={styles.container}>
      {header}
      <div className={styles.content}>
        <Loader isLoading={!!isLoading} />
        {children}
      </div>
    </div>
    {isMassEditOn && !!selectedRows.length && (
      <EditPanel
        onAddTagsClick={onAddTagsClick}
        onEditClick={onEditClick!}
        selectedRows={selectedRows}
        triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes!}
      />
    )}
  </div>
);

export { DataGridPage };
