import { Row as TableRow } from 'react-table';

import { Row } from 'pages/Location/types';
import { FormValues } from 'pages/Location/components/MassEditFormRow';
import { mapRowToLocationArgs } from 'pages/Location/mappers/mapRowToLocationArgs';
import { makeArrayWithUniqElements } from 'shared/utils';
import { MutationUpdateLocationsArgs } from 'shared/types';

const mapRowToAddTagsMutationArgs = ({
  formData,
  selectedRows,
}: {
  selectedRows: TableRow<Row>[];
  formData: FormValues;
}): MutationUpdateLocationsArgs => ({
  input: {
    locations: selectedRows.map(({ original }) => {
      const {
        alcoholVolumes,
        locationCapabilities,
        locationCustomAssortments,
        locationTobaccoCapabilities,
        ...locationArgs
      } = mapRowToLocationArgs(original);

      return {
        locationCustomAssortments: makeArrayWithUniqElements(
          locationCustomAssortments!,
          formData.locationCustomAssortments,
        ),
        locationTobaccoCapabilities: makeArrayWithUniqElements(
          locationTobaccoCapabilities!,
          formData.locationTobaccoCapabilities,
        ),
        locationCapabilities: makeArrayWithUniqElements(
          locationCapabilities!,
          formData.locationCapabilities,
        ),
        alcoholVolumes: makeArrayWithUniqElements(alcoholVolumes!, formData.alcoholVolumes),
        ...locationArgs,
      };
    }),
  },
});

export { mapRowToAddTagsMutationArgs };
