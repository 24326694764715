import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DeepPartial,
  FormProvider,
  UnpackNestedValue,
  UseFormSetValue,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';

import { Loader } from 'shared/components/Loader';
import { ConditionalWrapper } from 'shared/components/ConditionalWrapper';
import { AddButton } from 'pages/UserDefinedAttribute/components/AddButton';
import { WarningModal } from 'pages/UserDefinedAttribute/components/WarningModal';
import { Search } from 'pages/UserDefinedAttribute/components/Search';
import { FORM_ID } from 'pages/UserDefinedAttribute/constants';
import { preventPressEnter } from 'shared/utils';

import { WarningDeleteTagModal } from './components/WarningDeleteTagModal';
import { BaseFormItem, FormValues, GeneralFormProps, MutationArgs } from './types';
import styles from './GeneralForm.module.css';

const GeneralForm = memo(
  <FormItem extends BaseFormItem = BaseFormItem, AdditionalRowProps extends object = never>({
    Row,
    afterSave,
    blockedLocation,
    canFilter = false,
    className,
    containerColumn,
    deleteAlcoholVolumeMutation,
    deleteMutation,
    deriveAdditionalRowProps,
    filterMapDataToMutationItems,
    hasPermissions,
    isDeletionPossible,
    isReset,
    label,
    loading = false,
    makeEmptyFormItem,
    mutation,
    mutationVariableName,
    name,
    query,
    setIsError,
    setIsFormChanged,
    setIsReset,
    validationSchema: userValidationSchema,
    wrapInFormProvider = false,
  }: GeneralFormProps<FormItem, AdditionalRowProps>): JSX.Element => {
    const methods = useForm<FormValues<FormItem>>({
      mode: 'onBlur',
      resolver: userValidationSchema,
    });
    const {
      control,
      formState: { dirtyFields, errors, isDirty, isValid },
      getValues,
      handleSubmit,
      reset,
      setValue,
      trigger,
    } = methods;
    const { append, fields, remove } = useFieldArray({
      name,
      control,
      keyName: 'fieldId',
    });
    const [filter, setFilter] = useState('');
    const [disabledFields, setDisabledFields] =
      useState<UnpackNestedValue<DeepPartial<FormValues<FormItem>>>>();
    const [deleteIndexInfo, setDeleteIndexInfo] = useState<{
      isAlcoholVolume: boolean;
      id: number;
    } | null>(null);
    const { data, loading: dataLoading } =
      useQuery<UnpackNestedValue<DeepPartial<FormValues<FormItem>>>>(query);
    const mutationOptions = useMemo(
      () => ({
        refetchQueries: [query],
        onCompleted() {
          afterSave();
        },
      }),
      [],
    );
    const [saveFormChanges, { loading: savingLoading }] = useMutation<
      UnpackNestedValue<DeepPartial<FormValues<FormItem>>>,
      MutationArgs<FormItem>
    >(mutation, mutationOptions);
    const [deleteTagMutation, { loading: deleteTagLoading }] = useMutation(deleteMutation, {
      ...mutationOptions,
      update(cache) {
        cache.evict({
          fieldName: 'items',
          broadcast: false,
        });
        cache.evict({
          fieldName: 'locations',
          broadcast: false,
        });
        cache.gc();
      },
    });

    useEffect(() => {
      if (data && !blockedLocation) {
        const filteredData = { ...data };

        if (filter !== '') {
          filteredData[name] = data[name]?.filter(item =>
            item?.name?.toLowerCase()?.includes(filter),
          );
        }

        reset(filteredData);
        setDisabledFields(filteredData);
      }
    }, [blockedLocation, data, filter]);

    useEffect(() => {
      setIsFormChanged(isDirty);
    }, [isDirty]);

    useEffect(() => {
      setIsError(!isValid);
    }, [isValid]);

    useEffect(() => {
      if (isReset) {
        reset(disabledFields);
        setDisabledFields(disabledFields);
        setIsReset(false);
      }
    }, [isReset]);

    const onAddButtonClick = useCallback(() => {
      // seems to be an issue with react-hook-form types
      // @ts-ignore
      append(makeEmptyFormItem());
    }, []);

    const onSubmit = useMemo(
      () =>
        handleSubmit(formData =>
          saveFormChanges({
            variables: {
              input: {
                [mutationVariableName]: filterMapDataToMutationItems(formData, dirtyFields, name),
              },
            },
          }),
        ),
      [dirtyFields, filterMapDataToMutationItems, name],
    );

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        (setValue as UseFormSetValue<Record<string, any>>)(e.target.id, e.target.value, {
          shouldDirty: true,
        });
      },
      [setValue],
    );

    const onBlur = useCallback(() => trigger(), [trigger]);

    const onDelete = useCallback(
      ({
        fieldId,
        fieldIndex,
        isAlcoholVolume = false,
      }: {
        fieldIndex: number;
        fieldId: string | undefined;
        isAlcoholVolume?: boolean;
      }) => {
        if (fieldId) {
          setDeleteIndexInfo({ isAlcoholVolume, id: +fieldId });
        } else {
          remove(fieldIndex);
        }
      },
      [],
    );

    const onCancel = useCallback(() => setIsReset(true), []);

    const onClose = useCallback(() => setDeleteIndexInfo(null), []);

    const onProceed = useCallback(() => {
      const variables = { variables: { ids: [deleteIndexInfo!.id] } };

      if (deleteIndexInfo?.isAlcoholVolume) {
        void deleteAlcoholVolumeMutation!(variables);
      } else {
        void deleteTagMutation(variables);
      }

      setDeleteIndexInfo(null);
    }, [deleteIndexInfo]);

    if (dataLoading) {
      return <Loader isLoading />;
    }

    return (
      <>
        <Loader isLoading={loading || savingLoading || deleteTagLoading} />
        <ConditionalWrapper condition={wrapInFormProvider} Wrapper={FormProvider} {...methods}>
          <div className={styles.header}>
            {canFilter && <Search label={label} onSubmit={setFilter} />}
            <AddButton
              buttonPosition="flex-end"
              isDisabled={!hasPermissions}
              onClick={onAddButtonClick}
              title={`Add ${label}`}
              tooltip="Deleting a value is not possible"
            />
          </div>

          <form
            className={classNames(
              {
                [styles.containerColumn]: containerColumn,
                [styles.container]: !containerColumn,
              },
              className,
            )}
            id={FORM_ID}
            onKeyDown={preventPressEnter}
            onSubmit={onSubmit}
            role="presentation"
          >
            {fields.map((field, index) => (
              <Row
                key={field.fieldId}
                errors={errors}
                getValues={getValues}
                hasPermissions={hasPermissions}
                index={index}
                isDeletionPossible={isDeletionPossible}
                isDisabled={
                  (disabledFields as FormValues<FormItem>)[name].length > index || !hasPermissions
                }
                label={label}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onDelete={onDelete}
                setDeleteIndexInfo={setDeleteIndexInfo}
                {...((deriveAdditionalRowProps?.({
                  fields,
                  dirtyFields,
                  disabledFields,
                  fieldName: name,
                  errors,
                  index,
                }) || {}) as AdditionalRowProps)}
              />
            ))}
          </form>
        </ConditionalWrapper>
        <WarningModal
          isError={!isValid}
          isOpen={!!deleteIndexInfo && !!Object.keys(dirtyFields).length}
          onCancel={onCancel}
          onCloseModal={onClose}
        />
        <WarningDeleteTagModal
          isOpen={!!deleteIndexInfo && !Object.keys(dirtyFields).length}
          onClose={onClose}
          onProceed={onProceed}
        />
      </>
    );
  },
);

export { GeneralForm };
