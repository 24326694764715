import { useAuth0 } from '@auth0/auth0-react';

import { RolesType } from 'shared/constants/access/permissions';

import {
  ROLE_ADMIN,
  ROLE_ANALYST,
  ROLE_BUYER,
  ROLE_MANAGER,
  ROLE_MANUFACTURER,
  ROLE_PRICING,
  ROLE_READ_ONLY,
  ROLE_VENDOR,
} from '../../constants';

import { CustomUserClaim } from './types';

const useHighestPrivilegeRole = (roles: RolesType[]): RolesType => {
  const roleOrder = [
    ROLE_ADMIN,
    ROLE_MANAGER,
    ROLE_ANALYST,
    ROLE_PRICING,
    ROLE_BUYER,
    ROLE_VENDOR,
    ROLE_MANUFACTURER,
    ROLE_READ_ONLY,
  ] as RolesType[];
  const rolePriority = (role: RolesType): number => {
    const index = roleOrder.indexOf(role);
    return index === -1 ? Number.MAX_SAFE_INTEGER : index;
  };
  const sortedRoles = roles.sort((a, b) => rolePriority(a) - rolePriority(b));
  return sortedRoles[0];
};

const useRole = (): RolesType => {
  const { user } = useAuth0<CustomUserClaim>();
  const roles: Set<string | undefined> = new Set(['read-only']);
  (user?.['https://sc-dc.supply.gopuff/roles'] ?? []).forEach(role => roles.add(role));
  return useHighestPrivilegeRole(Array.from(roles) as RolesType[]);
};

export { useRole };
