import { differenceInHours, fromUnixTime } from 'date-fns';

const currentTimeHoursDifference = (targetUnixTime: number, sourceTime?: Date): number => {
  const startTimeFromUnix = fromUnixTime(targetUnixTime);
  let now = new Date();
  if (sourceTime) {
    now = sourceTime;
  }
  const hours = differenceInHours(now, startTimeFromUnix);

  return hours;
};

export { currentTimeHoursDifference };
