import { equals } from 'ramda';
import { memo, useCallback, useMemo } from 'react';

import { pickError } from 'pages/UserDefinedAttribute/utils';
import { DeleteIconButton } from 'shared/components/IconButton';
import { TextField } from 'shared/components/TextField';

import styles from './Row.module.css';
import { BaseFormItem, RowProps } from './types';

const Row = memo(
  <FormItem extends BaseFormItem = BaseFormItem>({
    errors,
    getValues,
    hasPermissions,
    index,
    inputSize = 'compact',
    isDeletionPossible,
    isDisabled,
    label,
    name,
    onBlur,
    onChange,
    onDelete,
  }: RowProps<FormItem>) => {
    const fieldName = `${name}.${index}.name`;
    const fieldValue = getValues(fieldName as `${typeof name}.0.name`);
    const descriptionName = `${name}.${index}.description`;
    const descriptionValue = getValues(descriptionName as `${typeof name}.0.description`);
    // a workaround to put added field at the beginning of the list
    const rowOrder = useMemo(() => ({ order: -index }), [index]);

    const isDeleteButtonEnabled = useMemo(
      () => (isDeletionPossible ? isDeletionPossible(fieldValue) : true),
      [],
    );
    const onDeleteClick = useCallback(() => {
      const fieldId = `${name}.${index}.id`;

      onDelete({
        fieldId: getValues(fieldId as `${typeof name}.0.id`),
        fieldIndex: index,
      });
    }, [index]);

    return (
      <div className={styles.inputsWrapper} style={rowOrder}>
        <TextField
          className={styles.textField}
          defaultValue={fieldValue}
          disabled={isDisabled || !hasPermissions}
          error={pickError(fieldName, errors)}
          label={label}
          name={fieldName}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={label}
          size={inputSize}
        />

        <TextField
          defaultValue={descriptionValue}
          disabled={!hasPermissions}
          error={pickError(descriptionName, errors)}
          label="Description"
          name={descriptionName}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={hasPermissions ? 'Add description' : ''}
          size={inputSize}
        />
        {isDeleteButtonEnabled && hasPermissions && <DeleteIconButton onClick={onDeleteClick} />}
      </div>
    );
  },
  equals,
);

export { Row };
