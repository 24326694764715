import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from 'shared/tools/yup';

declare module 'yup' {
  interface ArraySchema<T> {
    uniqueAlcoholVolumes(propertyPath?: any, message?: any): ArraySchema<T>;
  }
}

const VolumeSchema = yup.object({
  volume: yup
    .number()
    .typeError('Invalid Input')
    .min(0, 'Invalid Number')
    .max(100, 'Invalid Number')
    .optional(),
});

const ItemSchema = yup.object({
  name: yup.string().required('Field is required ').max(35, 'Maximum length up to 35'),
  description: yup.string().nullable(true).optional(),
  volumes: yup.array().of(VolumeSchema).uniqueAlcoholVolumes('volume', 'Must be unique'),
});

const AlcoholFormSchema = yup.object({
  alcoholTypes: yup.array().of(ItemSchema),
});

export const validationSchema = yupResolver(AlcoholFormSchema);
