import { Tld } from './tld';

const {
  location: { host },
} = window;

let tempTLD = host.split('.').reverse()[0] as Tld;
if (host === 'localhost:3000') {
  tempTLD = Tld.local;
} else if (host === 'localhost:8010') {
  tempTLD = Tld.e2e;
}
const TLD = tempTLD;

export { TLD };
