import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import {
  SortOrder,
  TransportationLaneSortField,
  TransportationLanesQuery,
  TransportationLanesQueryVariables,
  UpdateTransportationLanesAccountIdMutation,
  UpdateTransportationLanesAccountIdMutationVariables,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import {
  useDownload,
  useEditPage,
  useIsSystemLocked,
  useOnGridChange,
  usePagination,
  usePermissions,
  useRole,
  useUploadFile,
} from 'shared/hooks';
import { mapRowToMutationArgs } from 'pages/TransportationLane/mappers';
import {
  displayNameOptionMapper,
  isFirstLoad,
  mapToSelectOptions,
  uniqueUnpack,
} from 'shared/utils';
import {
  transportationLaneDownloadQuery,
  transportationLaneUploadMutation,
  transportationLanesQuery,
  updateTransportationLanesAccountId,
} from 'pages/TransportationLane/operations';
import { Row, SetupResult } from 'pages/TransportationLane/types';
import { headerButtonNames } from 'pages/TransportationLane/constants';
import { isDestinationPage, isExistingConnection } from 'pages/TransportationLane/utils';

import { mapDataToDownloadQueryArgs } from '../mappers/mapDataToDownloadQueryArgs';
import { usersQuery } from '../../../shared/operations/query';

import { useColumns } from './useColumns';

const useSetup = (): SetupResult => {
  const editPageProps = useEditPage<Row>(isExistingConnection);
  const { onCloseEditPage, onEditClick, selectedRows, showEditPage, triggerUncheckAllCheckboxes } =
    editPageProps;
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const [activePageIndex, setActiveIndexPage] = useState(0);
  const [filter, setFilter] = useState<TransportationLanesQueryVariables['filter']>({});
  const [uniqueSelectedRows, setUniqueSelectedRows] = useState<Row[]>([]);
  const {
    data: { transportationLanes: { totalCount, transportationLanes: data } } = {
      transportationLanes: { transportationLanes: [], totalCount: 0 },
    },
    loading,
    networkStatus,
    client,
  } = useQuery<TransportationLanesQuery, TransportationLanesQueryVariables>(
    transportationLanesQuery,
    {
      variables: {
        pagination,
        sorting: {
          field: TransportationLaneSortField[headerButtonNames[activePageIndex]],
          order: SortOrder.Asc,
        },
        filter,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const userPermissions = usePermissions();
  const role = useRole();

  const assortmentType = isDestinationPage(activePageIndex)
    ? TransportationLaneSortField.Source
    : TransportationLaneSortField.Destination;
  const { systemLocked } = useIsSystemLocked();

  const {
    data: { users } = { users: [] },
    networkStatus: usersNetworkStatus,
    loading: usersLoading,
  } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery);
  const userOptions = useMemo(() => mapToSelectOptions(users, displayNameOptionMapper), [users]);

  const [onChange] = useOnGridChange<
    Row,
    UpdateTransportationLanesAccountIdMutation,
    UpdateTransportationLanesAccountIdMutationVariables
  >({
    data,
    mutation: updateTransportationLanesAccountId,
    mapRowToMutationArgs,
  });

  const reset = useCallback(() => {
    resetPageIndex();
    setFilter({});
  }, []);

  const onTogglePage = useCallback((newPageIndex: number) => {
    triggerUncheckAllCheckboxes();
    setActiveIndexPage(newPageIndex);
    reset();
  }, []);

  const updateFilter = useCallback((newFilter: TransportationLanesQueryVariables['filter']) => {
    setFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
    resetPageIndex();
  }, []);

  const columns = useColumns({
    activePageIndex,
    updateFilter,
    systemLocked,
    role,
    userOptions,
  });

  const borderAfterIndex: number = 5;

  const onDataGridDownloadButtonClick = useDownload({
    query: transportationLaneDownloadQuery,
    queryResponseDataKey: 'transportationLaneDownload',
    selectedRows,
    triggerUncheckAllCheckboxes,
    mapDataToDownloadQueryArgs,
    view: assortmentType,
  });
  const uploadFileProps = useUploadFile({
    downloadTemplateQuery: transportationLaneDownloadQuery,
    mutation: transportationLaneUploadMutation,
    client,
    evictCacheForQueryName: 'transportationLanes',
    view: assortmentType,
  });

  const handleEditClick = useCallback(() => {
    setUniqueSelectedRows(
      uniqueUnpack(
        selectedRows,
        isDestinationPage(activePageIndex) ? 'destination' : 'source',
        'original',
      ),
    );
    onEditClick();
  }, [activePageIndex, onEditClick, selectedRows]);

  const handleCloseEditPage = useCallback(() => {
    setUniqueSelectedRows([]);
    onCloseEditPage();
  }, [onCloseEditPage]);

  return {
    onDataGridDownloadButtonClick,
    uploadFileProps,
    onTogglePage,
    borderAfterIndex,
    columns,
    totalCount,
    data,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    showMainLoader:
      (isFirstLoad(networkStatus) || isFirstLoad(usersNetworkStatus)) && !showEditPage,
    activePageIndex,
    loadingMoreData: loading || usersLoading,
    systemLocked,
    reset,
    editPageProps: {
      ...editPageProps,
      onEditClick: handleEditClick,
      onCloseEditPage: handleCloseEditPage,
      uniqueSelectedRows,
    },
    userPermissions,
  };
};

export { useSetup };
