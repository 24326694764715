import { addMinutes, fromUnixTime, getUnixTime } from 'date-fns';

import {
  CostPackaging,
  MappedRow,
  PackageTypeIdNameMap,
  Row,
  UnsubmittedRows,
} from 'pages/CostManagement/types';
import { PackageTypeName } from 'shared/constants/package';
import { CostDateStatus, User } from 'shared/types';

const findCostPackagingValue = (
  costPackagings: CostPackaging[],
  packageTypeName: PackageTypeName,
  packageTypeIdNameMap: PackageTypeIdNameMap,
): number | undefined =>
  costPackagings.find(
    ({ packageTypeId }) => packageTypeId === packageTypeIdNameMap[packageTypeName],
  )?.value;

const timezoneAdjustedDate = (value: number): number => {
  // Add a offset to the initial value to avoid the date being set to the previous day
  // as dates are displayed in the user's timezone, but stored in UTC. The effective
  // date should always match the date that was uploaded.
  const unixTime = fromUnixTime(value);
  const tzAdjustedDate = addMinutes(unixTime, unixTime.getTimezoneOffset());
  return getUnixTime(tzAdjustedDate);
};

const getStatusOrder = (status: CostDateStatus): number => {
  switch (status) {
    case CostDateStatus.Current:
      return 1;
    case CostDateStatus.Future:
      return 2;
    case CostDateStatus.Past:
      return 3;
    default:
      return 4;
  }
};

const mapDataToRows = (
  data: Row[],
  packageTypeIdNameMap: PackageTypeIdNameMap,
  users: User[],
  canEdit: boolean,
  unsubmittedRows: UnsubmittedRows,
): MappedRow[] =>
  data.map(
    ({
      costDates: costDatesData,
      id,
      isDisabled,
      item: {
        class: itemClass,
        department: itemDepartment,
        i18n: itemI18n,
        id: itemId,
        internalId: internalItemId,
        name: itemName,
      },
      location,
      opco,
      supplier: { id: supplierId, internalId: internalSupplierId, name: supplierName },
      updatedAt,
      updatedBy,
    }) => {
      const sortedCostDates = [...costDatesData].sort(
        (a, b) => getStatusOrder(a.status) - getStatusOrder(b.status),
      );
      const costDate = sortedCostDates.length > 0 ? sortedCostDates[0] : null;
      const costPackagings = costDate?.costPackagings ?? [];

      const row: MappedRow = {
        id: `${id}${costDate ? `:${costDate.id}` : ''}`,
        costManagementId: id,
        itemId,
        itemDepartment,
        itemClass,
        itemName,
        i18n: itemI18n,
        locationId: location?.id,
        locationName: location?.name,
        opcoId: opco?.id,
        opcoName: opco?.name,
        opcoStateProvinceRegion: opco?.stateProvinceRegion || '',
        supplierId,
        supplierName,
        effectiveDate: costDate?.effectiveDate
          ? timezoneAdjustedDate(costDate?.effectiveDate)
          : undefined,
        internalItemId,
        internalSupplierId,
        internalLocationId: location?.internalId,
        [PackageTypeName.Each]: findCostPackagingValue(
          costPackagings,
          PackageTypeName.Each,
          packageTypeIdNameMap,
        ),
        [PackageTypeName.Inner]: findCostPackagingValue(
          costPackagings,
          PackageTypeName.Inner,
          packageTypeIdNameMap,
        ),
        [PackageTypeName.Case]: findCostPackagingValue(
          costPackagings,
          PackageTypeName.Case,
          packageTypeIdNameMap,
        ),
        [PackageTypeName.MasterCase]: findCostPackagingValue(
          costPackagings,
          PackageTypeName.MasterCase,
          packageTypeIdNameMap,
        ),
        [PackageTypeName.Pallet]: findCostPackagingValue(
          costPackagings,
          PackageTypeName.Pallet,
          packageTypeIdNameMap,
        ),
        currency: costDate?.currency?.id,
        costId: costDate?.id,
        updatedAt: costDate?.updatedAt,
        updatedBy: costDate?.updatedBy
          ? users
              .find(({ id: userId }) => userId === costDate?.updatedBy)
              ?.displayName.toString() || ''
          : '',
        costManagementUpdatedAt: updatedAt,
        costManagementUpdatedBy: updatedBy
          ? users.find(({ id: userId }) => userId === updatedBy)?.displayName.toString() ||
            updatedBy
          : '',
        isDisabled,
        status: costDate?.status ?? CostDateStatus.Undefined,
        isAddLocationCostRow: false,
        isUnsubmitted: false,
      };

      row.rows = sortedCostDates.map(
        ({
          costPackagings: costDateCostPackagings,
          currency,
          effectiveDate,
          id: costId,
          status,
          updatedAt: costDateUpdatedAt,
          updatedBy: costDateUpdatedBy,
        }) => ({
          id: `${id}:${costId}`,
          costId,
          costManagementId: id,
          effectiveDate: timezoneAdjustedDate(effectiveDate),
          [PackageTypeName.Each]: findCostPackagingValue(
            costDateCostPackagings,
            PackageTypeName.Each,
            packageTypeIdNameMap,
          ),
          [PackageTypeName.Inner]: findCostPackagingValue(
            costDateCostPackagings,
            PackageTypeName.Inner,
            packageTypeIdNameMap,
          ),
          [PackageTypeName.Case]: findCostPackagingValue(
            costDateCostPackagings,
            PackageTypeName.Case,
            packageTypeIdNameMap,
          ),
          [PackageTypeName.MasterCase]: findCostPackagingValue(
            costDateCostPackagings,
            PackageTypeName.MasterCase,
            packageTypeIdNameMap,
          ),
          [PackageTypeName.Pallet]: findCostPackagingValue(
            costDateCostPackagings,
            PackageTypeName.Pallet,
            packageTypeIdNameMap,
          ),
          currency: currency?.id,
          updatedAt: costDateUpdatedAt,
          updatedBy: costDateUpdatedBy
            ? users
                .find(({ id: userId }) => userId === costDateUpdatedBy)
                ?.displayName.toString() || costDateUpdatedBy
            : '',
          costManagementUpdatedAt: updatedAt,
          costManagementUpdatedBy: updatedBy
            ? users.find(({ id: userId }) => userId === updatedBy)?.displayName.toString() || ''
            : '',
          parent: row,
          isDisabled,
          isAddLocationCostRow: false,
          status,
          isUnsubmitted: false,
        }),
      );

      if (unsubmittedRows[row.id]) {
        unsubmittedRows[row.id]!.forEach(unsubmittedRow => {
          row.rows?.push({ ...unsubmittedRow, isDisabled, parent: row });
        });
      }

      if (
        canEdit &&
        row.effectiveDate &&
        row.currency &&
        (row[PackageTypeName.Each]! > 0 ||
          row[PackageTypeName.Inner]! > 0 ||
          row[PackageTypeName.Case]! > 0 ||
          row[PackageTypeName.MasterCase]! > 0 ||
          row[PackageTypeName.Pallet]! > 0) &&
        !isDisabled &&
        !unsubmittedRows[row.id]?.length
      ) {
        row.rows?.push({
          isAddLocationCostRow: true,
          id: `${id}-add-location-cost`,
          isDisabled,
          costManagementId: id,
          parent: row,
          isUnsubmitted: false,
        });
      }

      row.rows?.shift();

      return row;
    },
  );

export { mapDataToRows };
