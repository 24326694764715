import { useCallback, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { BaseQueryArgs, mapAutocompleteValueToFilterParams } from '../shared';
import { Label } from '../../shared';
import { Select } from '../../../Select';

import { FilterSearchSelectProps } from './types';
import styles from './FilterSearchSelect.module.css';

const FilterSearchSelect = <QueryArgs extends BaseQueryArgs>({
  defaultOption,
  filterFieldName,
  label,
  mapUserInputToFilerParams,
  onSubmitFilter,
  options,
  title,
  ...props
}: FilterSearchSelectProps<QueryArgs>) => {
  const [value, setValue] = useState<string>(defaultOption ?? '');

  const handleChange = useCallback(
    ({ target: { value: selectedValue } }: SelectChangeEvent<string>) => {
      setValue(selectedValue);

      onSubmitFilter(
        mapAutocompleteValueToFilterParams<QueryArgs, false, string>(
          selectedValue,
          filterFieldName,
          mapUserInputToFilerParams,
        ),
      );
    },
    [setValue],
  );

  return (
    <>
      <Label className={styles.label} label={label} />
      <Select value={value} {...props} onChange={handleChange} options={options} />
    </>
  );
};

export { FilterSearchSelect };
