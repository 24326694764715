import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';

import { beforeDate } from '../constants';

const isValidDate = (date: Date | string = '') => {
  if (date instanceof Date) {
    return true;
  }

  const [month, day, year] = date.split('/');
  const testedDate = new Date(+year, +month, +day);

  return isValid(testedDate) && isAfter(testedDate, beforeDate);
};

export { isValidDate };
