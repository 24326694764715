import { FC } from 'react';
import Popper, { PopperProps } from '@mui/material/Popper';

const style = {
  width: 'fit-content',
};

const PopperFitContent: FC<PopperProps> = props => (
  <Popper {...props} placement="bottom-start" style={style} />
);

export { PopperFitContent };
