import { FC, useMemo } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

import {
  AutocompleteOption,
  SearchAutocompleteQuery,
  SearchAutocompleteQueryProps,
} from 'shared/components/Search';
import { locationAutocompleteOptionsQuery, opcosQuery } from 'shared/operations/query';
import {
  LocationAutocompleteOptionsQuery,
  LocationAutocompleteOptionsQueryVariables,
  OpcosQuery,
  OpcosQueryVariables,
} from 'shared/types';
import { isNonZeroNumberOnlyString, mapToSelectOptions } from 'shared/utils';

import styles from './MassEditFormRow.module.css';
import { FormValues, MassEditFormRowProps } from './types';

const MassEditFormRow: FC<MassEditFormRowProps> = ({ control, isDestinationPage }) => {
  const label = isDestinationPage ? 'Source' : 'Destination';
  const { renderLocation } = useMemo<{
    renderLocation: ControllerProps<FormValues, FormValues['locations']>['render'];
  }>(() => {
    let locationAutocompleteProps: Pick<
      SearchAutocompleteQueryProps<
        LocationAutocompleteOptionsQuery | OpcosQuery,
        LocationAutocompleteOptionsQueryVariables | OpcosQueryVariables,
        true
      >,
      'query' | 'mapDataToOptions' | 'mapUserInputToAutocompleteQueryFilerParams'
    >;

    if (isDestinationPage) {
      locationAutocompleteProps = {
        query: opcosQuery,
        mapDataToOptions: data => mapToSelectOptions((data as OpcosQuery)?.opcos.opcos),
        mapUserInputToAutocompleteQueryFilerParams: value => {
          if (isNonZeroNumberOnlyString(value)) {
            return { id: [value] };
          }

          return { name: value };
        },
      };
    } else {
      locationAutocompleteProps = {
        query: locationAutocompleteOptionsQuery,
        mapDataToOptions: data =>
          mapToSelectOptions((data as LocationAutocompleteOptionsQuery)?.locations.locations),
        mapUserInputToAutocompleteQueryFilerParams: value => {
          if (isNonZeroNumberOnlyString(value)) {
            return { extIds: [value] };
          }

          return { name: value };
        },
      };
    }
    return {
      renderLocation: ({
        field: { onBlur, onChange: handleChange, value: val },
        fieldState: { error },
      }) => (
        <SearchAutocompleteQuery<
          LocationAutocompleteOptionsQuery | OpcosQuery,
          LocationAutocompleteOptionsQueryVariables | OpcosQueryVariables,
          true
        >
          className={styles.locationField}
          clearInputValueOnblur
          defaultValue={val as AutocompleteOption[]}
          error={error?.message}
          label={`Assign ${label}`}
          multiple
          onBlur={onBlur}
          onChange={(_, data) => handleChange(data)}
          placeholder="Search by Name or ID"
          showAutocompleteAfter={1}
          {...locationAutocompleteProps}
        />
      ),
    };
  }, [control, label]);
  return <Controller control={control} name="locations" render={renderLocation} />;
};

export { MassEditFormRow };
