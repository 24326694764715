import { FC } from 'react';
import { Tooltip, Typography } from '@mui/material';

import { LongTextTooltipProps } from './types';

export const LongTextTooltip: FC<LongTextTooltipProps> = ({ value, width }) => (
  <>
    <Tooltip title={value}>
      <Typography noWrap variant="body2" width={width}>
        {value}
      </Typography>
    </Tooltip>
  </>
);
