import { Row as TableRow } from 'react-table';

import { Row } from 'pages/TransportationLane/types';
import { TransportationLaneDownloadFilter } from 'shared/types';

const mapDataToDownloadQueryArgs = ({
  selectedRows,
  view,
}: {
  selectedRows: TableRow<Row>[];
  view?: string;
}) => ({
  variables: {
    view,
    filter: selectedRows.reduce<TransportationLaneDownloadFilter>(
      (acc, { original: { destination, id, source } }) => {
        const sourceIDs = [...acc.sourceIDs!];
        const destinationIDs = [...acc.destinationIDs!];
        const IDs = [...acc.IDs!];

        if (id) {
          IDs.push(id);
        } else if (source?.id) {
          sourceIDs.push(source?.id);
        } else if (destination?.id) {
          destinationIDs.push(destination?.id);
        }

        return {
          sourceIDs,
          destinationIDs,
          IDs,
        };
      },
      { sourceIDs: [], destinationIDs: [], IDs: [] },
    ),
  },
});

export { mapDataToDownloadQueryArgs };
