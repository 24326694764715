import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row as TableRow } from 'react-table';

import { Option } from 'shared/types';

import { UseEditPageResult } from './types';

const useEditPage = <Row extends object>(
  isExistingConnection?: (row: TableRow<Row>) => boolean,
): UseEditPageResult<Row> => {
  const [showEditPage, setShowEditPage] = useState(false);
  const [showAddTagsPage, setShowAddTagsPage] = useState(false);
  const [uncheckAllCheckboxes, setUncheckAllCheckboxes] = useState(false);
  const [showWarningExistingConnections, setShowWarningExistingConnections] = useState(false);
  const [selectedRows, setSelectedRows] = useState<TableRow<Row>[]>([]);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [defaultOptions, setDefaultOptions] = useState<Option<string, string>[] | null>(null);
  const [newRows, setNewRows] = useState<Row[]>([]);

  const onCloseEditPage = useCallback(() => {
    setShowEditPage(false);
    setShowAddTagsPage(false);
    setDefaultOptions(null);
    setSelectedRows([]);
    setIsFirstSave(true);
    setNewRows([]);
  }, []);

  const triggerUncheckAllCheckboxes = useCallback(() => setUncheckAllCheckboxes(true), []);

  const onProceed = useCallback(() => {
    setShowWarningExistingConnections(false);
    setShowEditPage(true);
  }, []);

  const onCloseWarningExistingConnectionsModal = useCallback(
    () => setShowWarningExistingConnections(false),
    [],
  );

  const onEditClick = useCallback(() => {
    const isExisting = isExistingConnection ? selectedRows.some(isExistingConnection) : false;

    if (isExisting) {
      setShowWarningExistingConnections(true);
      return;
    }

    setShowEditPage(true);
  }, [selectedRows]);

  const onAddTagsClick = useCallback(() => setShowAddTagsPage(true), []);

  useEffect(() => {
    if (uncheckAllCheckboxes) {
      setUncheckAllCheckboxes(false);
    }
  }, [uncheckAllCheckboxes]);

  return useMemo(
    () => ({
      showEditPage,
      uncheckAllCheckboxes,
      showWarningExistingConnections,
      selectedRows,
      setSelectedRows,
      onCloseEditPage,
      onProceed,
      onCloseWarningExistingConnectionsModal,
      onEditClick,
      triggerUncheckAllCheckboxes,
      onAddTagsClick,
      showAddTagsPage,
      defaultOptions,
      setDefaultOptions,
      isFirstSave,
      setIsFirstSave,
      newRows,
      setNewRows,
    }),
    [
      showEditPage,
      uncheckAllCheckboxes,
      showWarningExistingConnections,
      selectedRows,
      onEditClick,
      showAddTagsPage,
      isFirstSave,
      defaultOptions,
      newRows,
    ],
  );
};

export { useEditPage };
