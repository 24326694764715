import { FC, useCallback, useMemo } from 'react';

import { Loader } from 'shared/components/Loader';
import { EditPage } from 'shared/components/Edit';
import { FactoryCellDelete } from 'shared/components/DataGrid';
import { ContainerCapacityInput, OrderFrequencyType } from 'shared/types';

import {
  FormValues,
  MassEditFormRow,
  MassEditFormRowAdditionalProps,
} from './components/MassEditFormRow';
import { useSetup } from './hooks';
import { SourceDestinationView } from './SourceDestinationView';
import { MappedRow, UseColumnsResult } from './types';
import { headersForMassEditColumns } from './constants';
import { updateSourceDestinationMutation } from './operations';

const SourceDestinationPage: FC = () => {
  const {
    containerUomOptions,
    onCloseEditPage,
    reset,
    selectedRows,
    setSelectedRows,
    showEditPage,
    showMainLoader,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
    ...props
  } = useSetup();

  const deriveMassEditData = useCallback(() => {
    const data = selectedRows.map(({ original }) => original);
    const defaultValues = {
      deliveryWindowFrom: undefined,
      deliveryWindowTo: undefined,
      transitType: undefined,
      capacities: undefined,
      gopuffTransport: undefined,
      containerMax: undefined,
      containerMin: undefined,
      containerUom: '3',
      isActive: undefined,
      fillKillThreshold: undefined,
      monLeadTime: undefined,
      tueLeadTime: undefined,
      wedLeadTime: undefined,
      thuLeadTime: undefined,
      friLeadTime: undefined,
      satLeadTime: undefined,
      sunLeadTime: undefined,
      autoRelease: undefined,
      orderFrequency: OrderFrequencyType.Weekly,
      minimumOrderValue: undefined,
      movCurrencyId: undefined,
      minimumOrderQuantity: undefined,
      moqOrderingUomId: undefined,
      minimumOrderWeight: undefined,
      mowSystemOfMeasureId: undefined,
    };
    const mapRowToMutationArgs = ({
      capacities,
      containerMax,
      containerMin,
      containerUom,
      gopuffTransport,
      autoRelease,
      isActive,
      ...formData
    }: FormValues) => ({
      input: {
        destinations: selectedRows.map(
          ({ original: { capacities: prevCapacities, id, ...restFromRow } }) => {
            let newCapacities: ContainerCapacityInput[] = [];

            if (containerMax || containerMin) {
              let foundCapacity = false;

              prevCapacities!.forEach(
                ({
                  containerMax: prevContainerMax,
                  containerMin: prevContainerMin,
                  containerUom: { id: prevContainerUomId },
                  id: rowId,
                }) => {
                  let newContainerMin = prevContainerMax;
                  let newContainerMax = prevContainerMin;

                  if (prevContainerUomId === containerUom) {
                    foundCapacity = true;

                    newContainerMin = containerMin || 0;
                    newContainerMax = containerMax || 0;
                  }

                  newCapacities.push({
                    id: rowId,
                    containerMin: newContainerMin,
                    containerMax: newContainerMax,
                    containerUom: prevContainerUomId,
                  });
                },
              );

              if (!foundCapacity) {
                newCapacities.push({
                  containerMin: containerMin || 0,
                  containerMax: containerMax || 0,
                  containerUom,
                });
              }
            } else {
              newCapacities = prevCapacities!.map(
                ({
                  containerMax: updatedContainerMax,
                  containerMin: updatedContainerMin,
                  containerUom: { id: containerUonId },
                  id: newId,
                }) => ({
                  id: newId,
                  containerMin: updatedContainerMin,
                  containerMax: updatedContainerMax,
                  containerUom: containerUonId,
                }),
              );
            }

            return {
              id,
              isActive: Boolean(isActive),
              fillKillThreshold: formData.fillKillThreshold,
              minimumOrderValue: formData.minimumOrderValue,
              movCurrencyId: formData.movCurrencyId,
              minimumOrderQuantity: formData.minimumOrderQuantity,
              moqOrderingUomId: formData.moqOrderingUomId,
              minimumOrderWeight: formData.minimumOrderWeight,
              mowSystemOfMeasureId: formData.mowSystemOfMeasureId,
              gopuffTransport: Boolean(gopuffTransport),
              capacities: newCapacities,
              monLeadTime: formData.monLeadTime ?? restFromRow.monLeadTime,
              tueLeadTime: formData.tueLeadTime ?? restFromRow.tueLeadTime,
              wedLeadTime: formData.wedLeadTime ?? restFromRow.wedLeadTime,
              thuLeadTime: formData.thuLeadTime ?? restFromRow.thuLeadTime,
              friLeadTime: formData.friLeadTime ?? restFromRow.friLeadTime,
              satLeadTime: formData.satLeadTime ?? restFromRow.satLeadTime,
              sunLeadTime: formData.sunLeadTime ?? restFromRow.sunLeadTime,
              autoRelease: Boolean(autoRelease),
              transitType: formData.transitType ?? restFromRow.transitType,
              deliveryWindowFrom: formData.deliveryWindowFrom ?? restFromRow.deliveryWindowFrom,
              deliveryWindowTo: formData.deliveryWindowTo ?? restFromRow.deliveryWindowTo,
              orderFrequency: formData.orderFrequency ?? restFromRow.orderFrequency,
            };
          },
        ),
      },
    });
    const columns = [
      ...headersForMassEditColumns,
      {
        id: 'delete',
        Cell: FactoryCellDelete<MappedRow>({ setData: setSelectedRows }),
      },
    ] as UseColumnsResult;

    return useMemo(
      () => ({
        defaultValues,
        columns,
        data,
        mapRowToMutationArgs,
      }),
      [selectedRows],
    );
  }, [selectedRows]);

  const editFormRowProps = useMemo<MassEditFormRowAdditionalProps>(
    () => ({
      containerUomOptions,
    }),
    [containerUomOptions, selectedRows],
  );

  if (showMainLoader) {
    return <Loader isLoading />;
  }

  return (
    <>
      {!showEditPage && (
        <SourceDestinationView
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
          uncheckAllCheckboxes={uncheckAllCheckboxes}
          {...props}
        />
      )}
      {showEditPage && (
        <EditPage
          afterMutation={reset}
          deriveMassEditData={deriveMassEditData}
          editFormRowProps={editFormRowProps}
          MassEditFormRow={MassEditFormRow}
          mutation={updateSourceDestinationMutation}
          onCloseEditPage={onCloseEditPage}
        />
      )}
    </>
  );
};

export { SourceDestinationPage };
