import { find, isNil, not, path, pathSatisfies, pipe } from 'ramda';
import { O } from 'ts-toolbelt';
import { Split } from 'ts-toolbelt/out/String/Split';

import { DottedPaths } from 'shared/types';

const findFirstNotNil = <T extends object, PropPath extends DottedPaths<T, 3>>(
  propPath: PropPath,
  objects: T[],
) => {
  const propPathList = (propPath as string).split('.');
  return pipe<T[], T | undefined, O.Path<T, Split<PropPath, '.'>> | undefined>(
    find(pathSatisfies(pipe(isNil, not), propPathList)),
    path(propPathList),
  )(objects);
};

export { findFirstNotNil };
