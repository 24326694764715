import { FC } from 'react';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import { HeaderProps } from './types';
import styles from './Header.module.css';

const Header: FC<HeaderProps> = ({ children, condensed, subTitle, title }) => (
  <header className={classNames(styles.container, { [styles.condensed]: !!condensed })}>
    <div>
      <Typography className={classNames({ [styles.titleMargin]: !!subTitle })} variant="h3">
        {title}
      </Typography>
      {!!subTitle && (
        <Typography fontWeight="normal" variant="h5">
          {subTitle}
        </Typography>
      )}
    </div>
    {children}
  </header>
);

export { Header };
