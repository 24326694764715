import { useCallback, useState } from 'react';
import { equals } from 'ramda';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as React from 'react';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { useDeepCompareEffect } from 'react-use';

import { SearchAutocomplete } from '../SearchAutocomplete';
import { AutocompleteOption } from '../shared';

import styles from './SearchAutocompleteMultiple.module.css';
import type { SearchAutocompleteMultipleProps } from './types';
import { CustomChip } from './components/CustomChip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const renderOptionWithCheckboxProp = (
  restProps: React.HTMLAttributes<HTMLLIElement>,
  option: AutocompleteOption,
  { selected }: AutocompleteRenderOptionState,
) => (
  <li {...restProps}>
    <Checkbox checked={selected} checkedIcon={checkedIcon} icon={icon} style={{ marginRight: 8 }} />
    {option.label}
  </li>
);
const renderTags = () => null;

const SearchAutocompleteMultiple = <FreeSolo extends boolean = false>({
  chipsOutSideOfInput,
  renderOptionWithCheckbox,
  value: controlledValue,
  ...props
}: SearchAutocompleteMultipleProps<FreeSolo>) => {
  const [value, setValue] = useState<AutocompleteOption[]>(controlledValue || []);

  const onDelete = useCallback(
    (chip: string) => () => {
      setValue(values => values.filter(v => v.label !== chip));
    },
    [],
  );

  useDeepCompareEffect(() => {
    if (controlledValue && !equals(value, controlledValue)) {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  return (
    <>
      <SearchAutocomplete<true, FreeSolo>
        {...props}
        blurOnEnterKeyPress
        disableCloseOnSelect
        disableMuiEnterKeyDownHandling
        multiple
        renderOption={renderOptionWithCheckbox ? renderOptionWithCheckboxProp : undefined}
        renderTags={chipsOutSideOfInput ? renderTags : undefined}
        value={value}
      />
      {chipsOutSideOfInput && value?.length && (
        <CustomChip className={styles.chip} onDelete={onDelete} value={value} />
      )}
    </>
  );
};

export { SearchAutocompleteMultiple };
