import { FC, useEffect, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { path } from 'ramda';

import styles from './InputField.module.css';
import { InputFieldProps } from './types';

const inputProps = { classes: { input: styles.input, disabled: styles.disabled } };
const formHelperTextProps = { classes: { error: styles.error } };

const InputField: FC<InputFieldProps> = ({ deleteField, isDisabled, name }) => {
  const [emptyValue, setEmptyValue] = useState(false);
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const textFieldProps = {
    ...register(name, {
      onBlur: ({ target: { value } }) => {
        if (value === '') {
          setEmptyValue(true);
        }
      },
    }),
  };
  const error = path<FieldError>(name.split('.'), errors);

  useEffect(() => {
    if (emptyValue) {
      deleteField();

      setEmptyValue(false);
    }
  }, [emptyValue]);

  return (
    <div className={styles.inputWrapper}>
      <TextField
        disabled={isDisabled}
        error={!!error}
        FormHelperTextProps={formHelperTextProps}
        helperText={error ? error.message : ''}
        id={name}
        InputProps={inputProps}
        placeholder="Enter"
        {...textFieldProps}
      />
    </div>
  );
};

export { InputField };
