import { FC } from 'react';
import { Box } from '@mui/material';

import { constants } from 'pages/DealManagementDetail/constants';

import { DealLineItemsScanDeal } from '../DealLineItemsScanDeal';

import { DealLineItemsTabProps } from './types';

export const DealLineItemsTab: FC<DealLineItemsTabProps> = ({ deal }) => {
  if (!deal) {
    return <></>;
  }

  switch (deal.type.name) {
    case constants.dealTypeNames.scan:
      return <DealLineItemsScanDeal deal={deal} />;
    default:
      return <Box>Invalid deal type {deal.type}</Box>;
  }
};
