import { useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { downloadBase64StringAsFile } from 'shared/utils';

import type { UseUploadFileArgs } from './types';

const useOnDownloadTemplate = <
  TemplateQueryResult extends {
    [key: string]: {
      file: {
        name: string;
        content: string;
      };
    };
  },
>({
  customFilter,
  downloadTemplateQuery,
  fileType,
  view,
}: Partial<UseUploadFileArgs>): (() => void) => {
  const [downloadTemplate] = useLazyQuery<TemplateQueryResult>(downloadTemplateQuery!, {
    onCompleted(downloadTemplateData) {
      const {
        file: { content, name },
      } = Object.values(downloadTemplateData)[0];

      downloadBase64StringAsFile(content, name, fileType);
    },
    // if remove this or change to another fetchPolicy onCompleted did not trigger when downloadTemplate executing more than one time in a row
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line no-param-reassign
  const onDownloadTemplate = useCallback(
    () =>
      downloadTemplate({
        variables: {
          filter: customFilter || { IDs: [] },
          ...(view && { view }),
        },
      }),
    [view],
  );
  return useMemo(() => onDownloadTemplate, [onDownloadTemplate]);
};
export { useOnDownloadTemplate };
