import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { Currency } from 'shared/types';

import { replicateN } from '../utils';
import { makeCurrencies } from '../factories';

const currencies = (props: O.Partial<Currency, 'deep'>[] = []): Currency[] =>
  mergeDeepArrays<Currency>(
    replicateN(makeCurrencies(), 3, {
      code: [, 'EUR', 'CAD', 'GBP'],
    }),
    props,
  );

export { currencies };
