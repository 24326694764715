import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row as BaseRow } from 'react-table';

import {
  useDownload,
  useEditPage,
  useOnGridChange,
  usePagination,
  usePermissions,
  useRole,
  useUploadFile,
} from 'shared/hooks';
import { usersQuery } from 'shared/operations/query';
import {
  QueryItemSourcesArgs,
  UpdateItemSourceMutation,
  UpdateItemSourceMutationVariables,
  UsersQuery,
  UsersQueryVariables,
  useItemSourceQuery,
  usePackageTypesQuery,
} from 'shared/types';
import { displayNameOptionMapper, isFirstLoad, mapToSelectOptions } from 'shared/utils';

import { mapDataToRows, mapRowToMutationArgs } from '../mappers';
import {
  itemSourceDownloadQuery,
  itemSourceUploadMutation,
  updateItemSourceMutation,
} from '../operations';
import { MappedRow, Row, SetupResult } from '../types';

import { useColumns } from './useColumns';

const afterMutation = (mutationResponseData: UpdateItemSourceMutation, row: BaseRow<MappedRow>) => {
  if (
    mutationResponseData.updateItemSources.items[0].packaging.length - 1 >
      Number(row.original.rows?.length) &&
    !row.isExpanded
  ) {
    row.toggleRowExpanded();
  }
};

const defaultItems: Row[] = [];

const useSetup = (): SetupResult => {
  const [filter, setFilter] = useState<QueryItemSourcesArgs['filter']>({});
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const userPermissions = usePermissions();
  const role = useRole();

  const updateFilter = useCallback((newFilter: QueryItemSourcesArgs['filter']) => {
    setFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
    resetPageIndex();
  }, []);

  const {
    data: { itemSources: { items: data, totalCount } } = {
      itemSources: {
        items: defaultItems,
        totalCount: 0,
      },
    },
    loading,
    networkStatus,
    client,
  } = useItemSourceQuery({
    variables: {
      pagination,
      filter: filter ?? {},
    },
    notifyOnNetworkStatusChange: true,
  });

  const uploadFileProps = useUploadFile({
    downloadTemplateQuery: itemSourceDownloadQuery,
    mutation: itemSourceUploadMutation,
    client,
    evictCacheForQueryName: 'itemSources',
  });

  const {
    data: { packageTypes } = {
      packageTypes: [],
    },
  } = usePackageTypesQuery();

  const [mappedData, setMappedData] = useState<MappedRow[]>([]);
  const [isFirstLoadingFinish, setIsFirstLoadingFinish] = useState(false);

  const reset = useCallback(() => {
    resetPageIndex();
    setFilter({});
  }, []);

  const {
    data: { users } = { users: [] },
    networkStatus: usersNetworkStatus,
    loading: usersLoading,
  } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery);

  useEffect(() => {
    if (data !== defaultItems) {
      setMappedData(mapDataToRows(data, users));

      setIsFirstLoadingFinish(true);
    }
  }, [data, users]);

  useEffect(() => {
    if (isFirstLoad(networkStatus)) {
      setIsFirstLoadingFinish(false);
    }
  }, [networkStatus]);

  const arbitraryMapperData = useMemo(() => ({ packageTypes }), [packageTypes]);
  const userOptions = useMemo(() => mapToSelectOptions(users, displayNameOptionMapper), [users]);

  const [onChange, mutationLoading] = useOnGridChange<
    Row,
    UpdateItemSourceMutation,
    UpdateItemSourceMutationVariables,
    MappedRow
  >({
    data,
    mutation: updateItemSourceMutation,
    mapRowToMutationArgs,
    arbitraryMapperData,
    after: afterMutation,
  });

  const columns = useColumns({
    updateFilter,
    userOptions,
    packageTypes,
    role,
  });

  const {
    onCloseEditPage,
    onEditClick,
    selectedRows,
    setSelectedRows,
    showEditPage,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
  } = useEditPage<MappedRow>();

  const handleEditClick = useCallback(() => {
    setSelectedRows(previousSelectedRows =>
      previousSelectedRows.map(row => ({ ...row, original: { ...row.original, rows: [] } })),
    );
    onEditClick();
  }, [onEditClick]);

  const onDataGridDownloadButtonClick = useDownload({
    query: itemSourceDownloadQuery,
    queryResponseDataKey: 'itemSourceDownload',
    selectedRows,
    triggerUncheckAllCheckboxes,
  });

  return {
    onCloseEditPage,
    onEditClick: handleEditClick,
    selectedRows,
    setSelectedRows,
    showEditPage,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
    onDataGridDownloadButtonClick,
    columns,
    data: mappedData,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    showMainLoader:
      isFirstLoad(networkStatus) ||
      isFirstLoad(usersNetworkStatus) ||
      (totalCount !== 0 && !mappedData.length) ||
      !isFirstLoadingFinish,
    loadingMoreData: mutationLoading || loading || usersLoading,
    uploadFileProps,
    reset,
    packageTypes,
    userPermissions,
  };
};

export { useSetup };
