import { useMemo } from 'react';
import { equals, not, nthArg, pipe, prop } from 'ramda';

import {
  EmptyCell,
  FactoryCellDataLink,
  FactoryCellEmptyOn,
  FactoryCellInput,
  FactoryCellMutedHeader,
  FactoryCellSelect,
  FactoryCellSelectBinary,
  FactoryCellTimeConverter,
  FactoryCellTooltipHeader,
} from 'shared/components/DataGrid';
import { lengthOptions, weightOptions } from 'shared/constants/units';
import { ItemSourceQuery, ItemSourceQueryVariables } from 'shared/types';
import { PackageTypeName } from 'shared/constants/package';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteQuery,
  FilterSearchDatePicker,
  FilterSearchInput,
} from 'shared/components/Search';
import { isReadOnlyRole, mapToSelectOptions, mapValuesSeparatedWithComma } from 'shared/utils';
import { TooltipHeader } from 'shared/components/TooltipHeader';
import { PACKAGE_TYPE_HIERARCHY } from 'shared/constants/package/packageTypeName';

import { FactoryCellEachUpc } from '../components/FactoryCellEachUpc';
import type { MappedRow, UseColumnsArgs, UseColumnsResult } from '../types';
import { PackageTypeColumnName, SupplierItemNumberColumnName } from '../constants';
import { pickUpcValue } from '../utils';
import { itemSourceQuery } from '../operations';
import { FactoryCellHighlightText } from '../components/FactoryCellHighlightText';
import { mapRowsToPackageTypeOptions } from '../mappers';

const onPalletColumn = pipe<keyof MappedRow, MappedRow, any, MappedRow, string, boolean>(
  nthArg(1),
  prop('packageTypeName'),
  equals(PackageTypeName.Pallet as string),
);
const CellTimeConverter = FactoryCellTimeConverter();
const onNotPalletColumn = pipe(onPalletColumn, not);

const useColumns = ({
  role,
  updateFilter,
  userOptions,
  packageTypes,
}: UseColumnsArgs): UseColumnsResult => {
  const isReadOnly = isReadOnlyRole(role);
  const isReadOnlyFunc = () => isReadOnly;

  const CellInputDeclaredLengthMetric = FactoryCellInput<MappedRow>({
    type: 'float',
    max: 10000,
    isDisabled: isReadOnlyFunc,
    showErrorBorder: ({ declaredHeight, declaredLength, declaredWidth }) =>
      (declaredHeight !== null || declaredWidth !== null) && declaredLength === null && !isReadOnly,
  });

  const CellInputPackage = FactoryCellInput({
    type: 'float',
    max: 10000,
    showSuccessBorder: true,
    isDisabled: isReadOnlyFunc,
  });

  const CellEachUpc = FactoryCellEachUpc();

  const CellIsBreakable = FactoryCellSelectBinary<MappedRow>({
    dependencies: ['breakingThreshold', 'breakingFromPackageTypeID', 'breakingToPackageTypeID'],
    isDisabled: ({ breakingThreshold, breakingFromPackageTypeID, breakingToPackageTypeID }) =>
      !breakingThreshold || !breakingFromPackageTypeID || !breakingToPackageTypeID || isReadOnly,
  });

  const CellBreakingFrom = FactoryCellSelect<MappedRow>({
    dependencies: ['isBreakable', 'rows'],
    deriveOptionsFromRow: ({ rows }) => mapRowsToPackageTypeOptions(rows, packageTypes, false),
    isDisabled: isReadOnlyFunc,
  });

  const CellBreakingTo = FactoryCellSelect<MappedRow>({
    dependencies: ['isBreakable', 'rows', 'breakingFromPackageTypeID'],
    deriveOptionsFromRow: ({ rows, breakingFromPackageTypeID }) => {
      const options = mapRowsToPackageTypeOptions(rows, packageTypes, true);

      if (!breakingFromPackageTypeID) {
        return options;
      }
      const breakingFromPackage = packageTypes.find(
        ({ id }) => id === breakingFromPackageTypeID,
      )?.name;
      if (!breakingFromPackage) {
        return options;
      }
      return options.filter(
        ({ label }) =>
          PACKAGE_TYPE_HIERARCHY.indexOf(label as PackageTypeName) <
          PACKAGE_TYPE_HIERARCHY.indexOf(breakingFromPackage as PackageTypeName),
      );
    },
    isDisabled: isReadOnlyFunc,
  });

  const CellRoundingThreshold = FactoryCellInput<MappedRow>({
    type: 'float',
    max: 1,
    isDisabled: isReadOnlyFunc,
  });

  const CellRoundingThresholdEmptyOnPallet = FactoryCellEmptyOn(
    CellRoundingThreshold,
    onPalletColumn,
  );

  const CellIsLotSize = FactoryCellSelectBinary<MappedRow>({
    isDisabled: isReadOnlyFunc,
  });

  const CellLotSize = FactoryCellInput<MappedRow>({
    dependencies: ['isLotSizeEnabled'],
    type: 'number',
    max: 100000,
    isDisabled: ({ isLotSizeEnabled }) => !isLotSizeEnabled || isReadOnly,
  });

  const CellMaxAllocationQuantity = FactoryCellInput<MappedRow>({
    type: 'number',
    max: 100000,
    isDisabled: isReadOnlyFunc,
    isPopperActive: true,
    popperText: 'Warning, updating this field will update all entries on Destination Assortment.',
  });

  const CellBreakingThreshold = FactoryCellInput<MappedRow>({
    isDisabled: isReadOnlyFunc,
    type: 'float',
    max: 1,
  });

  const CellInputDeclaredLengthEmptyOnPallet = FactoryCellEmptyOn(
    CellInputDeclaredLengthMetric,
    onPalletColumn,
  );

  const CellInputDeclaredLWidthMetric = FactoryCellInput<MappedRow>({
    type: 'float',
    max: 10000,
    showErrorBorder: ({ declaredHeight, declaredLength, declaredWidth }) =>
      (declaredHeight !== null || declaredLength !== null) && declaredWidth === null && !isReadOnly,
    isDisabled: isReadOnlyFunc,
  });

  const CellInputDeclaredWidthEmptyOnPallet = FactoryCellEmptyOn(
    CellInputDeclaredLWidthMetric,
    onPalletColumn,
  );

  const CellInputDeclaredHeightMetric = FactoryCellInput<MappedRow>({
    type: 'float',
    max: 10000,
    showErrorBorder: ({ declaredHeight, declaredLength, declaredWidth }) =>
      (declaredWidth !== null || declaredLength !== null) && declaredHeight === null && !isReadOnly,
    isDisabled: isReadOnlyFunc,
  });

  const CellInputDeclaredLHeightEmptyOnPallet = FactoryCellEmptyOn(
    CellInputDeclaredHeightMetric,
    onPalletColumn,
  );

  const CellInputMeasuredLengthMetric = FactoryCellInput<MappedRow>({
    type: 'float',
    max: 10000,
    showErrorBorder: ({ measuredHeight, measuredLength, measuredWidth }) =>
      (measuredHeight !== null || measuredWidth !== null) && measuredLength === null && !isReadOnly,
    isDisabled: isReadOnlyFunc,
  });

  const CellInputMeasuredLengthMetricEmptyOnPallet = FactoryCellEmptyOn(
    CellInputMeasuredLengthMetric,
    onPalletColumn,
  );

  const CellInputMeasuredWidthMetric = FactoryCellInput<MappedRow>({
    type: 'float',
    max: 10000,
    showErrorBorder: ({ measuredHeight, measuredLength, measuredWidth }) =>
      (measuredHeight !== null || measuredLength !== null) && measuredWidth === null && !isReadOnly,
    isDisabled: isReadOnlyFunc,
  });

  const CellInputMeasuredWidthEmptyOnPallet = FactoryCellEmptyOn(
    CellInputMeasuredWidthMetric,
    onPalletColumn,
  );

  const CellInputMeasuredHeightMetric = FactoryCellInput<MappedRow>({
    type: 'float',
    max: 10000,
    showErrorBorder: ({ measuredHeight, measuredLength, measuredWidth }) =>
      (measuredWidth !== null || measuredLength !== null) && measuredHeight === null && !isReadOnly,
    isDisabled: isReadOnlyFunc,
  });

  const CellInputMeasuredHeightMetricEmptyOnPallet = FactoryCellEmptyOn(
    CellInputMeasuredHeightMetric,
    onPalletColumn,
  );

  const CellInputWeight = FactoryCellInput<MappedRow>({
    type: 'float',
    max: 100000,
    isDisabled: isReadOnlyFunc,
  });

  const CellInputI = FactoryCellInput<MappedRow>({
    type: 'number',
    max: 100,
    isDisabled: isReadOnlyFunc,
  });

  const CellPackagingUpc = FactoryCellInput<MappedRow, string[]>({
    type: 'number',
    pickValue: pickUpcValue,
    isDisabled: isReadOnlyFunc,
  });

  const CellSupplierItemNumber = FactoryCellInput<MappedRow>({ isDisabled: isReadOnlyFunc });

  const CellInputWeightEmptyOnPallet = FactoryCellEmptyOn(CellInputWeight, onPalletColumn);

  const CellInputPresentOnlyForPallet = FactoryCellEmptyOn(CellInputI, onNotPalletColumn);

  const CellWeightUnits = FactoryCellSelect<MappedRow>({
    options: weightOptions,
    isDisabled: isReadOnlyFunc,
  });

  const CellLengthUnits = FactoryCellSelect<MappedRow>({
    options: lengthOptions,
    isDisabled: isReadOnlyFunc,
  });

  const CellWeightUnitsEmptyOnPalletColumn = FactoryCellEmptyOn(CellWeightUnits, onPalletColumn);

  const CellLengthUnitsEmptyOnPalletColumn = FactoryCellEmptyOn(CellLengthUnits, onPalletColumn);

  const CellVolumeMetric = FactoryCellHighlightText();
  const CellItemName = FactoryCellDataLink();

  const itemNameFilter = (
    <FilterSearchAutocompleteQuery<ItemSourceQuery, ItemSourceQueryVariables>
      filterFieldName="itemName"
      label=""
      mapDataToOptions={a => mapToSelectOptions(a.itemSources.items.map(({ item }) => item))}
      mapUserInputToAutocompleteQueryFilerParams={value => ({
        itemName: value,
      })}
      onSubmitFilter={updateFilter}
      query={itemSourceQuery}
    />
  );

  const upcsFilter = (
    <FilterSearchInput<ItemSourceQueryVariables>
      filterFieldName="UPCs"
      label=""
      mapUserInputToFilerParams={value => ({
        UPCs: mapValuesSeparatedWithComma(value),
      })}
      onSubmitFilter={updateFilter}
    />
  );

  const sourceNameFilter = (
    <FilterSearchAutocompleteQuery<ItemSourceQuery, ItemSourceQueryVariables>
      filterFieldName="sourceName"
      label=""
      mapDataToOptions={a => mapToSelectOptions(a.itemSources.items.map(({ opco }) => opco))}
      mapUserInputToAutocompleteQueryFilerParams={value => ({
        sourceName: value,
      })}
      onSubmitFilter={updateFilter}
      query={itemSourceQuery}
    />
  );

  const opcoStateProvinceRegion = (
    <FilterSearchInput<ItemSourceQueryVariables>
      filterFieldName="opcoStateProvinceRegion"
      label=""
      mapUserInputToFilerParams={value => ({
        opcoStateProvinceRegion: value,
      })}
      onSubmitFilter={updateFilter}
    />
  );

  const itemIDsFilter = (
    <FilterSearchInput<ItemSourceQueryVariables>
      filterFieldName="itemExtIds"
      label=""
      mapUserInputToFilerParams={value => ({
        itemExtIds: mapValuesSeparatedWithComma(value),
      })}
      onSubmitFilter={updateFilter}
    />
  );

  const supplierIDsFilter = (
    <FilterSearchInput<ItemSourceQueryVariables>
      filterFieldName="supplierItemNumbers"
      label=""
      mapUserInputToFilerParams={value => ({
        supplierNumbers: mapValuesSeparatedWithComma(value),
      })}
      onSubmitFilter={updateFilter}
    />
  );

  const supplierNumbersFilter = (
    <FilterSearchInput<ItemSourceQueryVariables>
      filterFieldName="supplierNumbers"
      label=""
      mapUserInputToFilerParams={value => ({
        supplierItemNumbers: mapValuesSeparatedWithComma(value),
      })}
      onSubmitFilter={updateFilter}
    />
  );

  const updatedAtFilter = (
    <FilterSearchDatePicker<ItemSourceQueryVariables>
      filterFieldName="updatedAt"
      label="Updated At"
      onSubmitFilter={updateFilter}
    />
  );
  const updatedByFilter = (
    <FilterSearchAutocomplete<ItemSourceQueryVariables>
      filterFieldName="updatedBy"
      label="Updated By"
      mapUserAutocompleteValueSelectToFilterParams={option => ({
        updatedBy: option!.value,
      })}
      onSubmitFilter={updateFilter}
      options={userOptions}
    />
  );

  return useMemo(
    () => [
      {
        id: 'itemName',
        Header: FactoryCellMutedHeader({
          mainText: 'Item Name',
          mutedText: '',
        }),
        width: 240,
        sticky: 'left',
        columns: [
          {
            Header: itemNameFilter,
            accessor: 'name',
            Cell: CellItemName,
          },
        ],
      },
      {
        id: 'itemId',
        Header: FactoryCellMutedHeader({
          mainText: 'Item ID',
          mutedText: '',
        }),
        columns: [
          {
            Header: itemIDsFilter,
            accessor: 'itemId',
            width: 240,
          },
        ],
      },
      {
        id: 'upc',
        Header: FactoryCellMutedHeader({
          mainText: 'UPC',
          mutedText: '',
        }),
        columns: [
          {
            Header: upcsFilter,
            accessor: 'upc',
            Cell: CellEachUpc,
            SubCell: CellPackagingUpc,
          },
        ],
      },
      {
        id: 'supplierItemNumber',
        Header: FactoryCellMutedHeader({
          mainText: 'Supplier Item Number',
          mutedText: '',
        }),
        columns: [
          {
            Header: supplierNumbersFilter,
            accessor: SupplierItemNumberColumnName,
            Cell: CellSupplierItemNumber,
          },
        ],
      },
      {
        id: 'supplierExtIdColumn',
        Header: FactoryCellTooltipHeader({
          label: 'Supplier Number',
          title: 'DC sources will use the SCDC Supplier Id',
          isHeaderCell: true,
        }),
        columns: [
          {
            Header: supplierIDsFilter,
            accessor: 'supplierExtId',
            width: 140,
          },
        ],
      },
      {
        id: 'sourceName',
        Header: FactoryCellMutedHeader({
          mainText: 'Opco/Site Name',
          mutedText: '',
        }),
        columns: [
          {
            Header: sourceNameFilter,
            accessor: 'opco',
            width: 225,
          },
        ],
      },
      {
        id: 'opcoStateProvinceRegion',
        Header: FactoryCellMutedHeader({
          mainText: 'Opco State/Province/Region',
          mutedText: '',
        }),
        columns: [
          {
            Header: opcoStateProvinceRegion,
            accessor: 'opcoStateProvinceRegion',
            width: 250,
          },
        ],
      },
      {
        id: 'mngt',
        Header: FactoryCellMutedHeader({
          mainText: 'Package MNGT',
          mutedText: '(*Add QTY to activate)',
        }),
        columns: [
          {
            Header: 'Inner',
            accessor: PackageTypeColumnName.Inner,
            Cell: CellInputPackage,
            SubCell: EmptyCell,
            width: 96,
          },
          {
            Header: 'Case',
            accessor: PackageTypeColumnName.Case,
            Cell: CellInputPackage,
            SubCell: EmptyCell,
            width: 96,
          },
          {
            Header: 'Master Case',
            accessor: PackageTypeColumnName.Mastercase,
            Cell: CellInputPackage,
            SubCell: EmptyCell,
            width: 96,
          },
          {
            Header: 'Pallet',
            accessor: PackageTypeColumnName.Pallet,
            Cell: CellInputPackage,
            SubCell: EmptyCell,
            width: 96,
          },
        ],
      },
      {
        id: 'lotSizeManagement',
        Header: FactoryCellMutedHeader({
          mainText: 'Lot Size MNGT',
          mutedText: '',
        }),
        columns: [
          {
            Header: 'Lot Size (Yes/No)',
            accessor: 'isLotSizeEnabled',
            Cell: CellIsLotSize,
            SubCell: EmptyCell,
            width: 130,
          },
          {
            Header: 'Lot Size Minimum',
            accessor: 'lotSizeMin',
            Cell: CellLotSize,
            SubCell: EmptyCell,
            width: 130,
          },
          {
            Header: 'Lot Size Increment',
            accessor: 'lotSizeIncrement',
            Cell: CellLotSize,
            SubCell: EmptyCell,
            width: 130,
          },
        ],
      },
      {
        id: 'ordering',
        Header: FactoryCellMutedHeader({
          mainText: 'Ordering',
          mutedText: '',
        }),
        columns: [
          {
            Header: (
              <TooltipHeader label="Rounding Threshold" title="Decimal from 0-1 to represent %" />
            ),
            accessor: 'roundingThreshold',
            Cell: EmptyCell,
            SubCell: CellRoundingThresholdEmptyOnPallet,
          },
          {
            Header: <TooltipHeader label="Max Allocation" title="Represented in terms of EACHES" />,
            Cell: CellMaxAllocationQuantity,
            accessor: 'maxAllocationQuantity',
            SubCell: EmptyCell,
          },
        ],
      },
      {
        id: 'breakable',
        Header: FactoryCellMutedHeader({
          mainText: 'Break Pack',
          mutedText: '',
        }),
        columns: [
          {
            Header: (
              <TooltipHeader
                label="Is Breakable"
                title="Enabled when all 3 of the break pack parameters are set"
              />
            ),
            accessor: 'isBreakable',
            Cell: CellIsBreakable,
            SubCell: EmptyCell,
            width: 96,
          },
          {
            Header: <TooltipHeader label="Breaking From" title="Higher UOM to break from" />,
            accessor: 'breakingFromPackageTypeID',
            Cell: CellBreakingFrom,
            SubCell: EmptyCell,
            width: 120,
          },
          {
            Header: <TooltipHeader label="Breaking To" title="Lower UOM to break to" />,
            accessor: 'breakingToPackageTypeID',
            Cell: CellBreakingTo,
            SubCell: EmptyCell,
            width: 110,
          },
          {
            Header: <TooltipHeader label="Threshold" title="Decimal from 0-1 to represent %" />,
            accessor: 'breakingThreshold',
            Cell: CellBreakingThreshold,
            SubCell: EmptyCell,
            width: 96,
          },
        ],
      },
      {
        id: 'declared',
        Header: FactoryCellTooltipHeader({
          label: 'Declared',
          title: 'Provided by Supplier',
        }),
        columns: [
          {
            Header: 'Length',
            accessor: 'declaredLength',
            Cell: CellInputDeclaredLengthMetric,
            SubCell: CellInputDeclaredLengthEmptyOnPallet,
            width: 96,
          },
          {
            Header: 'Width',
            accessor: 'declaredWidth',
            Cell: CellInputDeclaredLWidthMetric,
            SubCell: CellInputDeclaredWidthEmptyOnPallet,
            width: 96,
          },
          {
            Header: 'Height',
            accessor: 'declaredHeight',
            Cell: CellInputDeclaredHeightMetric,
            SubCell: CellInputDeclaredLHeightEmptyOnPallet,
            width: 96,
          },
          {
            Header: 'UOM',
            accessor: 'declaredLengthUnits',
            Cell: CellLengthUnits,
            SubCell: CellLengthUnitsEmptyOnPalletColumn,
          },
          {
            Header: 'Weight',
            accessor: 'declaredWeight',
            Cell: CellInputWeight,
            SubCell: CellInputWeightEmptyOnPallet,
            width: 96,
          },
          {
            Header: 'SOM',
            accessor: 'declaredWeightUnits',
            Cell: CellWeightUnits,
            SubCell: CellWeightUnitsEmptyOnPalletColumn,
          },
          {
            Header: 'Declared Volume',
            accessor: 'declaredVolume',
            Cell: CellVolumeMetric,
          },
          {
            Header: 'TI',
            accessor: 'declaredTi',
            Cell: EmptyCell,
            SubCell: CellInputPresentOnlyForPallet,
            width: 96,
          },
          {
            Header: 'HI',
            accessor: 'declaredHi',
            Cell: EmptyCell,
            SubCell: CellInputPresentOnlyForPallet,
            width: 96,
          },
        ],
      },
      {
        id: 'measured',
        Header: FactoryCellTooltipHeader({
          label: 'Measured',
          title: 'Measured by Gopuff',
        }),
        columns: [
          {
            Header: 'Length',
            accessor: 'measuredLength',
            Cell: CellInputMeasuredLengthMetric,
            SubCell: CellInputMeasuredLengthMetricEmptyOnPallet,
            width: 96,
          },
          {
            Header: 'Width',
            accessor: 'measuredWidth',
            Cell: CellInputMeasuredWidthMetric,
            SubCell: CellInputMeasuredWidthEmptyOnPallet,
            width: 96,
          },
          {
            Header: 'Height',
            accessor: 'measuredHeight',
            Cell: CellInputMeasuredHeightMetric,
            SubCell: CellInputMeasuredHeightMetricEmptyOnPallet,
            width: 96,
          },
          {
            Header: 'UOM',
            accessor: 'measuredLengthUnits',
            Cell: CellLengthUnits,
            SubCell: CellLengthUnitsEmptyOnPalletColumn,
          },
          {
            Header: 'Weight',
            accessor: 'measuredWeight',
            Cell: CellInputWeight,
            SubCell: CellInputWeightEmptyOnPallet,
            width: 96,
          },
          {
            Header: 'SOM',
            accessor: 'measuredWeightUnits',
            Cell: CellWeightUnits,
            SubCell: CellWeightUnitsEmptyOnPalletColumn,
          },
          {
            Header: 'Measured Volume',
            accessor: 'measuredVolume',
            Cell: CellVolumeMetric,
          },
          {
            Header: 'TI',
            accessor: 'measuredTi',
            Cell: EmptyCell,
            SubCell: CellInputPresentOnlyForPallet,
            width: 96,
          },
          {
            Header: 'HI',
            accessor: 'measuredHi',
            Cell: EmptyCell,
            SubCell: CellInputPresentOnlyForPallet,
            width: 96,
          },
        ],
      },
      {
        Header: () => updatedByFilter,
        accessor: 'updatedBy',
      },
      {
        Header: () => updatedAtFilter,
        accessor: 'updatedAt',
        Cell: CellTimeConverter,
        width: 170,
      },
    ],
    [userOptions, packageTypes],
  );
};

export { useColumns };
