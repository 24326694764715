import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import { deriveColumnName } from 'pages/Location/helpers';
import { mapRowToMutationArgs } from 'pages/Location/mappers';
import {
  locationDownloadQuery,
  locationOptionsQuery,
  locationQuery,
  locationUploadMutation,
  updateLocationMutation,
} from 'pages/Location/operations';
import { Row, SetupResult } from 'pages/Location/types';
import {
  useDownload,
  useEditPage,
  useOnGridChange,
  usePagination,
  usePermissions,
  useRole,
  useUploadFile,
} from 'shared/hooks';
import { usersQuery } from 'shared/operations/query';
import {
  LocationOptionsQuery,
  LocationOptionsQueryVariables,
  LocationQuery,
  LocationQueryVariables,
  QueryItemsArgs,
  UpdateLocationMutation,
  UpdateLocationMutationVariables,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import { displayNameOptionMapper, isFirstLoad, mapToSelectOptions } from 'shared/utils';

import { useColumns } from './useColumns';

const useSetup = (): SetupResult => {
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const [filter, setFilter] = useState<LocationQueryVariables['filter']>({});
  const {
    data: { users } = { users: [] },
    networkStatus: usersNetworkStatus,
    loading: usersLoading,
  } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery);
  const userOptions = useMemo(() => mapToSelectOptions(users, displayNameOptionMapper), [users]);

  const {
    onAddTagsClick,
    onCloseEditPage,
    onEditClick,
    selectedRows,
    setSelectedRows,
    showAddTagsPage,
    showEditPage,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
  } = useEditPage<Row>();

  const userPermissions = usePermissions();
  const role = useRole();

  const {
    data: {
      alcoholTypes,
      capabilities,
      customAssortments,
      nationalAssortments,
      allocationPriorities,
      tobaccoTypes,
    } = {
      customAssortments: [],
      capabilities: [],
      tobaccoTypes: [],
      nationalAssortments: [],
      allocationPriorities: [],
      alcoholTypes: [],
    },
    networkStatus: locationOptionsNetworkStatus,
  } = useQuery<LocationOptionsQuery, LocationOptionsQueryVariables>(locationOptionsQuery);
  const {
    data: { locations: { locations: data, totalCount } } = {
      locations: {
        totalCount: 0,
        locations: [],
      },
    },
    loading,
    networkStatus,
    client,
  } = useQuery<LocationQuery, LocationQueryVariables>(locationQuery, {
    errorPolicy: 'all',
    variables: {
      pagination,
      filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const reset = useCallback(() => {
    resetPageIndex();
    setFilter({});
  }, []);

  const updateFilter = useCallback((newFilter: QueryItemsArgs['filter']) => {
    setFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
    resetPageIndex();
  }, []);

  const [onChange, mutationLoading] = useOnGridChange<
    Row,
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >({
    data,
    mutation: updateLocationMutation,
    mapRowToMutationArgs,
    deriveColumnName,
  });

  const locationOptions = useMemo(() => {
    const capabilitiesOptions = mapToSelectOptions(capabilities);
    const nationalAssortmentsOptions = mapToSelectOptions(nationalAssortments);
    const customAssortmentsOptions = mapToSelectOptions(customAssortments);
    const tobaccoTypesOptions = mapToSelectOptions(tobaccoTypes);
    const allocationPriorityOptions = mapToSelectOptions(allocationPriorities);

    return {
      capabilitiesOptions,
      nationalAssortmentsOptions,
      customAssortmentsOptions,
      alcoholTypes,
      tobaccoTypesOptions,
      allocationPriorityOptions,
    };
  }, [
    capabilities,
    nationalAssortments,
    customAssortments,
    alcoholTypes,
    tobaccoTypes,
    allocationPriorities,
  ]);

  const columns = useColumns({
    ...locationOptions,
    alcoholTypes,
    updateFilter,
    userOptions,
    role,
  });

  const onDataGridDownloadButtonClick = useDownload({
    query: locationDownloadQuery,
    queryResponseDataKey: 'locationDownload',
    selectedRows,
    triggerUncheckAllCheckboxes,
  });

  const uploadFileProps = useUploadFile({
    downloadTemplateQuery: locationDownloadQuery,
    mutation: locationUploadMutation,
    client,
    evictCacheForQueryName: 'locations',
  });

  return {
    onAddTagsClick,
    onCloseEditPage,
    onEditClick,
    selectedRows,
    setSelectedRows,
    showAddTagsPage,
    showEditPage,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
    onDataGridDownloadButtonClick,
    uploadFileProps,
    columns,
    data,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    loadingMoreData: loading || mutationLoading || usersLoading,
    showMainLoader:
      (totalCount !== 0 && !data.length) ||
      isFirstLoad(locationOptionsNetworkStatus) ||
      isFirstLoad(usersNetworkStatus) ||
      isFirstLoad(networkStatus),
    locationOptions,
    reset,
    userPermissions,
  };
};

export { useSetup };
