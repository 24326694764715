import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useState,
} from 'react';

import { Input, SearchInputProps } from 'shared/components/Search/shared';

import { SearchProps } from './types';
import styles from './Search.module.css';

const Search: FC<SearchProps> = ({ label, onSubmit }) => {
  const [value, setValue] = useState('');

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => setValue(event.target.value),
    [],
  );

  const onKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  const onBlur = useCallback<FocusEventHandler<HTMLInputElement>>(event => {
    const correctedValue = event.target.value.trim();

    setValue(correctedValue);
    onSubmit(correctedValue.toLowerCase());
  }, []);

  return (
    <div className={styles.container}>
      <Input
        InputProps={SearchInputProps}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={`Search ${label}`}
        value={value}
      />
    </div>
  );
};

export { Search };
