import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { CustomAssortment } from 'shared/types';

import { replicateN } from '../utils';
import { makeCustomAssortment } from '../factories';

const customAssortments = (props: O.Partial<CustomAssortment, 'deep'>[] = []): CustomAssortment[] =>
  mergeDeepArrays<CustomAssortment>(
    replicateN(makeCustomAssortment(), 5, {
      name: [, 'EURO', 'LESS', 'FED', 'SEV'],
    }),
    props,
  );

export { customAssortments };
