import { Option, PackageType } from 'shared/types';
import { mapToSelectOptions } from 'shared/utils';
import { MIXED_PACKAGE_TYPE_ID } from 'pages/Suppliers/constants';

const makePackageTypeOptions = (packageTypes: PackageType[]): Option<string, string>[] => [
  ...mapToSelectOptions(packageTypes),
  { label: 'Mixed', value: MIXED_PACKAGE_TYPE_ID, disabled: true },
];

export { makePackageTypeOptions };
