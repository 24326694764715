enum Tld {
  local = 'local',
  dev = 'dev',
  tech = 'tech',
  com = 'com',
  corp = 'corp',
  e2e = 'e2e',
}

export { Tld };
