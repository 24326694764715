import { useCallback, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { useUpdateEffect } from 'react-use';

import { memoCell } from 'shared/utils';
import { OptionsLabel, SelectBinary, deriveOptions } from 'shared/components/Select/SelectBinary';
import { MockCellValue } from 'shared/components/DataGrid/components/Cells/types';
import type { CellSelectProps } from 'shared/components/DataGrid/components/Cells/types';
import { renderEmptyPlaceholder } from 'shared/components/Select';
import { UpdateGridData } from 'shared/types/props';

import { shouldDisable } from '../utils';

import type { CellValue } from './types';

const FactoryCellSelectBinary = <
  DataType extends object,
  ObjectValue extends object | CellValue = CellValue,
>(
  cellArguments: {
    optionsLabel?: OptionsLabel;
    dependencies?: (keyof DataType)[];
    isDisabled?: (rowData: DataType) => boolean;
    resetValueAfterUpdate?: boolean;
    update?: UpdateGridData<DataType, CellValue>;
    isHidden?: (rowData: DataType) => boolean;
  } = { dependencies: [], resetValueAfterUpdate: false },
) =>
  memoCell<CellSelectProps<DataType, CellValue, ObjectValue>>(cellProps => {
    const {
      column: { id: columnId },
      row,
      updateGridData,
      value: initialValue,
    } = cellProps;
    const { index, original } = row;
    const { isDisabled, optionsLabel, resetValueAfterUpdate, update, isHidden } = cellArguments;
    if (isHidden && isHidden(original)) return null;

    const { value: derivedInitialValue = initialValue, isOpen } = (initialValue ??
      {}) as MockCellValue<boolean>;
    const [value, setValue] = useState(Number(derivedInitialValue));
    const disabled = shouldDisable(cellProps, isDisabled);

    const handleChange = useCallback(
      ({ target: { value: selectedValue } }: SelectChangeEvent<number | ''>) => {
        setValue(Number(selectedValue));
        (update ?? updateGridData)(index, columnId, Boolean(selectedValue), original, row);
      },
      [updateGridData, index, columnId, setValue, row, update],
    );

    const options = useMemo(() => deriveOptions(optionsLabel), [optionsLabel]);

    const disabledRenderValue = useMemo(() => {
      if (value === null) {
        return renderEmptyPlaceholder;
      }
      const valueText = options.find(({ value: optionValue }) => optionValue === value)?.label;
      return valueText ? () => valueText : renderEmptyPlaceholder;
    }, [disabled, value, options]);

    useUpdateEffect(() => {
      if (resetValueAfterUpdate) {
        setValue(Number(derivedInitialValue));
      }
    }, [resetValueAfterUpdate, derivedInitialValue]);

    if (disabled) {
      return <SelectBinary disabled renderValue={disabledRenderValue} value={value} />;
    }

    return (
      <SelectBinary
        onChange={handleChange}
        open={isOpen}
        optionsLabel={optionsLabel}
        value={value}
      />
    );
  }, cellArguments.dependencies);

export { FactoryCellSelectBinary };
