import { Button, Typography } from '@mui/material';
import { FC } from 'react';

import sharedStyles from 'shared/components/UploadFileModal/UploadFileModal.module.css';

import { downloadStrikethroughTemplateExcel } from './downloadStrikethroughTemplateExcel';

export const UploadModalContent: FC = () => {
  const uploadTypes = [
    {
      name: 'STRIKETHROUGH DEAL TEMPLATE',
      downloadTemplate: downloadStrikethroughTemplateExcel,
    },
  ];
  return (
    <>
      Use <Typography variant="bold1">upload file</Typography> for mass data adding. Download one of
      the following Excel templates for your use case. Fill in the template, and upload it to the
      app.
      <br />
      <Typography variant="bold1">Excel Templates:</Typography>
      {uploadTypes.map(type => (
        <Button
          key={type.name}
          className={sharedStyles.templates}
          component="div"
          onClick={type.downloadTemplate}
        >
          {type.name}
        </Button>
      ))}
    </>
  );
};
