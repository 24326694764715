import { isNil, not, path, pathSatisfies, pipe, reduce } from 'ramda';
import { O } from 'ts-toolbelt';
import { Split } from 'ts-toolbelt/out/String/Split';

import { DottedPaths } from '../types';

const pickAllByPathIfNotNil = <T extends object, PropPath extends DottedPaths<T, 3>>(
  pathDots: PropPath,
  objects: T[],
) => {
  const pathParts = (pathDots as string).split('.');
  return reduce<T, O.Path<T, Split<PropPath, '.'>>[]>(
    (acc, next) => {
      if (pathSatisfies(pipe(isNil, not), pathParts, next)) {
        return [...acc, path(pathParts, next)] as O.Path<T, Split<PropPath, '.'>>[];
      }

      return [...acc];
    },
    [],
    objects,
  );
};

export { pickAllByPathIfNotNil };
