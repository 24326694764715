import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { Capability } from 'shared/types';

import { replicateN } from '../utils';
import { makeCapability } from '../factories';

const capabilities = (props: O.Partial<Capability, 'deep'>[] = []): Capability[] =>
  mergeDeepArrays<Capability>(
    replicateN(makeCapability(), 4, {
      name: [, 'Freezer', 'Ambient', 'Cafe'],
    }),
    props,
  );

export { capabilities };
