import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { Capability } from 'shared/types';

const makeCapability = (props: O.Partial<Capability, 'deep'> = {}): Capability =>
  merge<Capability, O.Partial<Capability, 'deep'>>(
    {
      id: '1',
      name: 'Fridge',
      description: '',
      updatedAt: 0,
      updatedBy: 'test',
      __typename: 'Capability',
    },
    props,
  );

export { makeCapability };
