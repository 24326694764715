import { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useRole } from 'shared/hooks/useRole';
import { ROLE_NAMES, ROLE_READ_ONLY } from 'shared/constants';

import { ReactComponent as LogoutLogo } from './logout.svg';

const ProfileLogout: FC = () => {
  const auth0Auth = useAuth0();
  const [userName, setUserName] = useState('');
  const role = useRole();

  useEffect(() => {
    const fetchUserInfo = () => {
      const name = auth0Auth.user?.name || '';
      setUserName(name);
    };
    void fetchUserInfo();
  }, []);

  const { logout } = auth0Auth;
  const returnTo = window.location.origin;
  return (
    <Grid
      alignItems="center"
      borderTop="1px solid #74b3f9"
      container
      justifyContent="space-between"
      padding="0.5rem 1rem 0.5rem 1.5rem"
    >
      <div>
        <Typography color="#F2F3F4" style={{ wordBreak: 'break-all' }} variant="h5">
          {userName}
        </Typography>
        {role !== ROLE_READ_ONLY && (
          <Typography
            color="#DCECFD"
            display="block"
            letterSpacing="1.8px"
            lineHeight="1.25rem"
            paddingTop="0.375rem"
            variant="overline"
          >
            {ROLE_NAMES[role]}
          </Typography>
        )}
      </div>
      <Button
        onClick={() => {
          logout({
            logoutParams: { returnTo },
          });
        }}
      >
        <LogoutLogo />
      </Button>
    </Grid>
  );
};

export { ProfileLogout };
