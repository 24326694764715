import { memoCell } from 'shared/utils';
import { CellProps } from 'shared/types';
import { CellValue } from 'pages/ItemSource/types';

import styles from './styles.module.css';

const FactoryCellHighlightText = <DataType extends object>() =>
  memoCell<CellProps<DataType, CellValue>>(({ value: initialValue }) => (
    <div>
      {initialValue === 0 ? (
        <span className={styles.highlightText}>{initialValue}</span>
      ) : (
        initialValue
      )}
    </div>
  ));

export { FactoryCellHighlightText };
