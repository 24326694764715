import { CostDateStatus, CostDateType, CostManagementsV2Query } from 'shared/types';

const costManagementResultMock: CostManagementsV2Query['costManagementRecords'] = {
  records: [
    {
      id: '1080342',
      item: {
        id: '323',
        internalId: '10829',
        name: 'Coca-Cola 20oz Btl',
        class: 'Soda',
        department: 'Drink',
        parentCompany: 'Coca-Cola',
        i18n: {
          name: {
            enUS: 'Coca-Cola 20oz Btl',
            enGB: '',
            enCA: '',
            frFR: '',
            frCA: '',
            deDE: '',
            esES: '',
            __typename: 'I18n',
          },
          __typename: 'ItemI18n',
        },
        __typename: 'Item',
      },
      itemSources: [
        {
          id: '1',
          opco: {
            id: '499',
            __typename: 'Opco',
          },
          packaging: [
            {
              id: '666391',
              perChildPackageQty: 1,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '1',
                name: 'Each',
                __typename: 'PackageType',
              },
              eachUPCs: [
                '04904403',
                '490440',
                '49000000443',
                '49000400441',
                '4904403',
                '049000400441',
                '4399009700001',
              ],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696947',
              perChildPackageQty: 2,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '4',
                name: 'Mastercase',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696957',
              perChildPackageQty: 3,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '5',
                name: 'Pallet',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
          ],
          __typename: 'ItemSource',
        },
      ],
      supplier: {
        id: '1001736',
        internalId: '681',
        name: 'Test2',
        __typename: 'Supplier',
      },
      location: {
        id: '346',
        internalId: '167',
        name: 'Kimpossible (Test)',
        address: 'Ben Franklin Bridge',
        city: 'Camden',
        stateProvinceRegion: 'New Jersey',
        postalCode: '08102',
        __typename: 'Location',
      },
      opco: {
        id: '522',
        name: 'Main.GOPUFF (test supplier)',
        stateProvinceRegion: 'Pennsylvania',
        __typename: 'Opco',
      },
      isDisabled: false,
      costDates: [
        {
          id: '1199694',
          costManagementId: '1080342',
          effectiveDate: 1672617600,
          endDate: 1672617600,
          type: CostDateType.Regular,
          costPackagings: [
            {
              costDateId: '1199694',
              packageTypeId: '1',
              value: 1.45,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
            {
              costDateId: '1199694',
              packageTypeId: '3',
              value: 34.79,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
          ],
          currency: {
            id: '3',
            code: 'USD',
            __typename: 'Currency',
          },
          updatedAt: 1672328956,
          updatedByDisplayName: '',
          status: CostDateStatus.Current,
          comments: '',
          commentedByDisplayName: '',
          canAutoApprove: false,
          __typename: 'CostDate',
        },
      ],
      updatedBy: 'cost-mgt-backfill',
      updatedAt: 1662985268,
      __typename: 'CostManagement',
    },
    {
      id: '1045206',
      item: {
        id: '15590',
        internalId: '26718',
        name: 'White Claw Seltzer Flavor No. 2 Variety 12pk 12oz Can 5.0% ABV',
        class: 'Beer',
        department: 'Alcohol',
        parentCompany: 'Mark Anthony Brands',
        i18n: {
          name: {
            enUS: 'White Claw Seltzer Flavor No. 2 Variety 12pk 12oz Can 5.0% ABV',
            enGB: '',
            enCA: '',
            frFR: '',
            frCA: '',
            deDE: '',
            esES: '',
            __typename: 'I18n',
          },
          __typename: 'ItemI18n',
        },
        __typename: 'Item',
      },
      itemSources: [
        {
          id: '1',
          opco: {
            id: '499',
            __typename: 'Opco',
          },
          packaging: [
            {
              id: '666395',
              perChildPackageQty: 1,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '1',
                name: 'Each',
                __typename: 'PackageType',
              },
              eachUPCs: ['635985800064'],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696983',
              perChildPackageQty: 2,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '4',
                name: 'Mastercase',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696986',
              perChildPackageQty: 3,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '5',
                name: 'Pallet',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
          ],
          __typename: 'ItemSource',
        },
      ],
      supplier: {
        id: '1001736',
        internalId: '681',
        name: 'Test2',
        __typename: 'Supplier',
      },
      location: {
        id: '346',
        internalId: '167',
        name: 'Kimpossible (Test)',
        address: 'Ben Franklin Bridge',
        city: 'Camden',
        stateProvinceRegion: 'New Jersey',
        postalCode: '08102',
        __typename: 'Location',
      },
      opco: {
        id: '522',
        name: 'Main.GOPUFF (test supplier)',
        stateProvinceRegion: 'Pennsylvania',
        __typename: 'Opco',
      },
      isDisabled: false,
      costDates: [
        {
          id: '868488',
          costManagementId: '1045206',
          effectiveDate: 1662940800,
          endDate: 1662940800,
          type: CostDateType.Regular,
          costPackagings: [
            {
              costDateId: '868488',
              packageTypeId: '1',
              value: 14.26,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
          ],
          currency: {
            id: '3',
            code: 'USD',
            __typename: 'Currency',
          },
          updatedAt: 1662985249,
          updatedByDisplayName: 'Darryl Mendillo',
          status: CostDateStatus.Current,
          comments: '',
          commentedByDisplayName: '',
          canAutoApprove: false,
          __typename: 'CostDate',
        },
      ],
      updatedBy: 'cost-mgt-backfill',
      updatedAt: 1662985249,
      __typename: 'CostManagement',
    },
    {
      id: '1147100',
      item: {
        id: '9822',
        internalId: '37518',
        name: 'Waterloo Grapefruit Sparkling Water 12oz Can',
        class: 'Water',
        department: 'Drink',
        parentCompany: 'Waterloo',
        i18n: {
          name: {
            enUS: 'Waterloo Grapefruit Sparkling Water 12oz Can',
            enGB: '',
            enCA: '',
            frFR: '',
            frCA: '',
            deDE: '',
            esES: '',
            __typename: 'I18n',
          },
          __typename: 'ItemI18n',
        },
        __typename: 'Item',
      },
      itemSources: [
        {
          id: '1',
          opco: {
            id: '499',
            __typename: 'Opco',
          },
          packaging: [
            {
              id: '666392',
              perChildPackageQty: 1,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '1',
                name: 'Each',
                __typename: 'PackageType',
              },
              eachUPCs: ['819215020020', '819215020136'],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696949',
              perChildPackageQty: 2,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '4',
                name: 'Mastercase',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696954',
              perChildPackageQty: 3,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '5',
                name: 'Pallet',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
          ],
          __typename: 'ItemSource',
        },
      ],
      supplier: {
        id: '1001736',
        internalId: '681',
        name: 'Test2',
        __typename: 'Supplier',
      },
      location: {
        id: '346',
        internalId: '167',
        name: 'Kimpossible (Test)',
        address: 'Ben Franklin Bridge',
        city: 'Camden',
        stateProvinceRegion: 'New Jersey',
        postalCode: '08102',
        __typename: 'Location',
      },
      opco: {
        id: '522',
        name: 'Main.GOPUFF (test supplier)',
        stateProvinceRegion: 'Pennsylvania',
        __typename: 'Opco',
      },
      isDisabled: false,
      costDates: [
        {
          id: '970382',
          costManagementId: '1147100',
          effectiveDate: 1662940800,
          endDate: 1662940800,
          type: CostDateType.Regular,
          costPackagings: [
            {
              costDateId: '970382',
              packageTypeId: '1',
              value: 0.35,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
            {
              costDateId: '970382',
              packageTypeId: '3',
              value: 4.22,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
          ],
          currency: {
            id: '3',
            code: 'USD',
            __typename: 'Currency',
          },
          updatedAt: 1666965100,
          updatedByDisplayName: '',
          status: CostDateStatus.Current,
          comments: '',
          commentedByDisplayName: '',
          canAutoApprove: false,
          __typename: 'CostDate',
        },
        {
          id: '1217364',
          costManagementId: '1147100',
          effectiveDate: 1684195200,
          endDate: 1684195200,
          type: CostDateType.Regular,
          costPackagings: [],
          currency: {
            id: '3',
            code: 'USD',
            __typename: 'Currency',
          },
          updatedAt: 1684265232,
          updatedByDisplayName: '',
          status: CostDateStatus.Pending,
          comments: '',
          commentedByDisplayName: '',
          canAutoApprove: false,
          __typename: 'CostDate',
        },
      ],
      updatedBy: 'cost-mgt-backfill',
      updatedAt: 1662985303,
      __typename: 'CostManagement',
    },
    {
      id: '1146460',
      item: {
        id: '9760',
        internalId: '37597',
        name: 'Little Debbie Nutty Buddy Bar 3oz',
        class: 'Sweet',
        department: 'Snacks',
        parentCompany: 'McKee Foods',
        i18n: {
          name: {
            enUS: 'Little Debbie Nutty Buddy Bar 3oz',
            enGB: '',
            enCA: '',
            frFR: '',
            frCA: '',
            deDE: '',
            esES: '',
            __typename: 'I18n',
          },
          __typename: 'ItemI18n',
        },
        __typename: 'Item',
      },
      itemSources: [
        {
          id: '1',
          opco: {
            id: '499',
            __typename: 'Opco',
          },
          packaging: [
            {
              id: '666393',
              perChildPackageQty: 1,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '1',
                name: 'Each',
                __typename: 'PackageType',
              },
              eachUPCs: [
                '2430086566',
                '024300871665',
                '024300881664',
                '24300865664',
                '24300881664',
                '24300871665',
                '024300865664',
              ],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696951',
              perChildPackageQty: 3,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '5',
                name: 'Pallet',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696989',
              perChildPackageQty: 2,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '4',
                name: 'Mastercase',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
          ],
          __typename: 'ItemSource',
        },
      ],
      supplier: {
        id: '1001736',
        internalId: '681',
        name: 'Test2',
        __typename: 'Supplier',
      },
      location: {
        id: '346',
        internalId: '167',
        name: 'Kimpossible (Test)',
        address: 'Ben Franklin Bridge',
        city: 'Camden',
        stateProvinceRegion: 'New Jersey',
        postalCode: '08102',
        __typename: 'Location',
      },
      opco: {
        id: '522',
        name: 'Main.GOPUFF (test supplier)',
        stateProvinceRegion: 'Pennsylvania',
        __typename: 'Opco',
      },
      isDisabled: false,
      costDates: [
        {
          id: '969742',
          costManagementId: '1146460',
          effectiveDate: 1662940800,
          endDate: 1662940800,
          type: CostDateType.Regular,
          costPackagings: [
            {
              costDateId: '969742',
              packageTypeId: '1',
              value: 0.66,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
          ],
          currency: {
            id: '3',
            code: 'USD',
            __typename: 'Currency',
          },
          updatedAt: 1662985303,
          updatedByDisplayName: 'Darryl Mendillo',
          status: CostDateStatus.Current,
          comments: '',
          commentedByDisplayName: '',
          canAutoApprove: false,
          __typename: 'CostDate',
        },
      ],
      updatedBy: 'cost-mgt-backfill',
      updatedAt: 1662985303,
      __typename: 'CostManagement',
    },
    {
      id: '1083952',
      item: {
        id: '356',
        internalId: '53176',
        name: 'Sprite 20oz Btl',
        class: 'Soda',
        department: 'Drink',
        parentCompany: 'Coca-Cola',
        i18n: {
          name: {
            enUS: 'Sprite 20oz Btl',
            enGB: '',
            enCA: '',
            frFR: '',
            frCA: '',
            deDE: '',
            esES: '',
            __typename: 'I18n',
          },
          __typename: 'ItemI18n',
        },
        __typename: 'Item',
      },
      itemSources: [
        {
          id: '1',
          opco: {
            id: '499',
            __typename: 'Opco',
          },
          packaging: [
            {
              id: '666390',
              perChildPackageQty: 1,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '1',
                name: 'Each',
                __typename: 'PackageType',
              },
              eachUPCs: [
                '04976400',
                '49000407648',
                '049000007640',
                '049000407648',
                '1452',
                '4976400',
                '49000007640',
              ],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696945',
              perChildPackageQty: 2,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '4',
                name: 'Mastercase',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696960',
              perChildPackageQty: 3,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '5',
                name: 'Pallet',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
          ],
          __typename: 'ItemSource',
        },
      ],
      supplier: {
        id: '1001736',
        internalId: '681',
        name: 'Test2',
        __typename: 'Supplier',
      },
      location: {
        id: '346',
        internalId: '167',
        name: 'Kimpossible (Test)',
        address: 'Ben Franklin Bridge',
        city: 'Camden',
        stateProvinceRegion: 'New Jersey',
        postalCode: '08102',
        __typename: 'Location',
      },
      opco: {
        id: '522',
        name: 'Main.GOPUFF (test supplier)',
        stateProvinceRegion: 'Pennsylvania',
        __typename: 'Opco',
      },
      isDisabled: false,
      costDates: [
        {
          id: '1199743',
          costManagementId: '1083952',
          effectiveDate: 1672617600,
          endDate: 1672617600,
          type: CostDateType.Regular,
          costPackagings: [
            {
              costDateId: '1199743',
              packageTypeId: '1',
              value: 1.45,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
            {
              costDateId: '1199743',
              packageTypeId: '3',
              value: 34.79,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
          ],
          currency: {
            id: '3',
            code: 'USD',
            __typename: 'Currency',
          },
          updatedAt: 1672329705,
          updatedByDisplayName: '',
          status: CostDateStatus.Current,
          comments: '',
          commentedByDisplayName: '',
          canAutoApprove: false,
          __typename: 'CostDate',
        },
      ],
      updatedBy: 'cost-mgt-backfill',
      updatedAt: 1662985270,
      __typename: 'CostManagement',
    },
    {
      id: '1135021',
      item: {
        id: '784',
        internalId: '59870',
        name: 'Pepsi 20oz Btl',
        class: 'Soda',
        department: 'Drink',
        parentCompany: 'PepsiCo Beverages',
        i18n: {
          name: {
            enUS: 'Pepsi 20oz Btl',
            enGB: '',
            enCA: '',
            frFR: '',
            frCA: '',
            deDE: '',
            esES: '',
            __typename: 'I18n',
          },
          __typename: 'ItemI18n',
        },
        __typename: 'Item',
      },
      itemSources: [
        {
          id: '1',
          opco: {
            id: '499',
            __typename: 'Opco',
          },
          packaging: [
            {
              id: '666397',
              perChildPackageQty: 1,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '1',
                name: 'Each',
                __typename: 'PackageType',
              },
              eachUPCs: [
                '012000001291',
                '1212901',
                '01253102  ',
                '01253102',
                '01212901',
                '12000001291',
                '1253102',
              ],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696973',
              perChildPackageQty: 2,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '4',
                name: 'Mastercase',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
            {
              id: '696976',
              perChildPackageQty: 3,
              supplierItemPackagingNumber: '',
              packageType: {
                id: '5',
                name: 'Pallet',
                __typename: 'PackageType',
              },
              eachUPCs: [''],
              upcCode: '',
              __typename: 'Packaging',
            },
          ],
          __typename: 'ItemSource',
        },
      ],
      supplier: {
        id: '1001736',
        internalId: '681',
        name: 'Test2',
        __typename: 'Supplier',
      },
      location: {
        id: '346',
        internalId: '167',
        name: 'Kimpossible (Test)',
        address: 'Ben Franklin Bridge',
        city: 'Camden',
        stateProvinceRegion: 'New Jersey',
        postalCode: '08102',
        __typename: 'Location',
      },
      opco: {
        id: '522',
        name: 'Main.GOPUFF (test supplier)',
        stateProvinceRegion: 'Pennsylvania',
        __typename: 'Opco',
      },
      isDisabled: false,
      costDates: [
        {
          id: '1200287',
          costManagementId: '1135021',
          effectiveDate: 1672617600,
          endDate: 1672617600,
          type: CostDateType.Regular,
          costPackagings: [
            {
              costDateId: '1200287',
              packageTypeId: '1',
              value: 1.42,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
            {
              costDateId: '1200287',
              packageTypeId: '3',
              value: 34.11,
              percentageChange: 0.0,
              __typename: 'CostPackaging',
            },
          ],
          currency: {
            id: '3',
            code: 'USD',
            __typename: 'Currency',
          },
          updatedAt: 1672406233,
          updatedByDisplayName: '',
          status: CostDateStatus.Current,
          comments: '',
          commentedByDisplayName: '',
          canAutoApprove: false,
          __typename: 'CostDate',
        },
      ],
      updatedBy: 'cost-mgt-backfill',
      updatedAt: 1662985296,
      __typename: 'CostManagement',
    },
  ],
  totalCount: 6,
  __typename: 'CostManagementResult',
};

export { costManagementResultMock };
