import { Row } from 'react-table';

import { MappedRow } from '../types';

export const mapDataGridRowsToMappedRows = (dataGridRows: Row<MappedRow>[]): MappedRow[] =>
  dataGridRows.reduce((mappedRows, dataGridRow) => {
    const { original } = dataGridRow;
    mappedRows.push(original);
    if (original?.rows?.length) {
      mappedRows.push(...original.rows.filter(row => !row.isAddLocationCostRow));
    }
    return mappedRows;
  }, [] as MappedRow[]);
