import IconButton from '@mui/material/IconButton';
import { ButtonProps } from '@mui/material/Button';

import { ReactComponent as DeleteIcon } from 'shared/assets/svg/close-icon.svg';

import styles from './DeleteIconButton.module.css';

const DeleteIconButton = (props: ButtonProps) => (
  <IconButton className={styles.deleteButton} {...props}>
    <DeleteIcon />
  </IconButton>
);

export { DeleteIconButton };
