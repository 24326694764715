import { DealManagementView } from './DealManagementView';
import { useSetup } from './hooks';

const DealManagement = () => {
  const props = useSetup();

  return <DealManagementView {...props} />;
};

export { DealManagement };
