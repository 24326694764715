import { useSetup } from './hooks/useSetup';
import { ItemExtraSubClassDefaultView } from './ItemExtraSubClassDefaultView';

const ItemExtraSubClassDefault = () => {
  const props = useSetup();

  return <ItemExtraSubClassDefaultView {...props} />;
};

export { ItemExtraSubClassDefault };
