import { useContext, useMemo } from 'react';
import { Row as TableRow } from 'react-table';

import { MappedRow, Row, UseColumnsArgs, UseColumnsResult } from 'pages/CostManagement/types';
import {
  EmptyCell,
  FactoryCellDatePicker,
  FactoryCellEmptyOn,
  FactoryCellInput,
  FactoryCellMultiDateConverter,
  FactoryCellMutedHeader,
  FactoryCellSelect,
  FactoryCellSelectBinary,
  FactoryCellText,
} from 'shared/components/DataGrid';
import { PackageTypeName } from 'shared/constants/package';
import { inputTypes } from 'shared/components/Input/constants';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteQuery,
  FilterSearchDatePicker,
  FilterSearchInput,
  FilterSearchSelectBinary,
} from 'shared/components/Search';
import {
  CostManagementsQueryVariables,
  ItemsOptionsQuery,
  ItemsOptionsQueryVariables,
  LocationAutocompleteOptionsQuery,
  LocationAutocompleteOptionsQueryVariables,
} from 'shared/types';
import { mapToLanguage, mapToSelectOptions, mapValuesSeparatedWithComma } from 'shared/utils';
import { itemsOptionsQuery, locationAutocompleteOptionsQuery } from 'shared/operations/query';
import { SessionStorageContext } from 'setup/providers/Context/Context';
import { FactoryCellStatus } from 'pages/CostManagement/components/FactoryCellStatus';
import { FactoryCellAddLocationCost } from 'pages/CostManagement/components/FactoryCellAddLocationCost';
import { FilterStatus } from 'shared/components/Search/Filter/FilterSearchStatus';

import { CostDateStatusFilter } from '../constants';

const CellStatus = FactoryCellStatus();

const CellMultiDateConverter = FactoryCellMultiDateConverter(
  'MMM/dd/yyyy',
  ['costManagementUpdatedAt'],
  ['Cost Management'],
  'Cost Date',
);

const useColumns = ({
  canEdit,
  currenciesOptions,
  languageCode,
  setUnsubmittedRows,
  updateFilter,
  updateIsDisabled,
  userOptions,
}: UseColumnsArgs): UseColumnsResult =>
  useMemo(() => {
    const itemDepartmentFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="itemDepartment"
        label="Item Department"
        onSubmitFilter={updateFilter}
      />
    );

    const itemClassFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="itemClass"
        label="Item Class"
        onSubmitFilter={updateFilter}
      />
    );

    const itemNameFilter = (
      <FilterSearchAutocompleteQuery<
        ItemsOptionsQuery,
        ItemsOptionsQueryVariables,
        CostManagementsQueryVariables
      >
        filterFieldName="itemName"
        label="Item Name"
        mapDataToOptions={a =>
          mapToSelectOptions(
            a.items.items.map(item => ({
              ...item,
              languageCode: languageCode.split('-').join(''),
            })),
          )
        }
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          name: value,
          languageCode: languageCode || 'en-US',
        })}
        onSubmitFilter={updateFilter}
        query={itemsOptionsQuery}
      />
    );

    const itemIdFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="itemExtIDs"
        label="Item ID"
        mapUserInputToFilerParams={value => ({
          itemExtIDs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const statusFilter = (
      <FilterStatus<CostManagementsQueryVariables>
        filterFieldName="costDateStatuses"
        filterOptions={new Set<string>(CostDateStatusFilter)}
        label="Status"
        onSubmitFilter={updateFilter}
      />
    );

    const supplierNameFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="supplierName"
        label="Supplier Name"
        onSubmitFilter={updateFilter}
      />
    );

    const supplierIdsFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="supplierExtIDs"
        label="Supplier Number"
        mapUserInputToFilerParams={value => ({
          supplierExtIDs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
        title="DC sources will use the SCDC Supplier Id"
      />
    );

    const locationNameFilter = (
      <FilterSearchAutocompleteQuery<
        LocationAutocompleteOptionsQuery,
        LocationAutocompleteOptionsQueryVariables,
        CostManagementsQueryVariables
      >
        filterFieldName="locationName"
        label="Location Name"
        mapDataToOptions={data => mapToSelectOptions(data.locations.locations)}
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          name: value,
        })}
        onSubmitFilter={updateFilter}
        query={locationAutocompleteOptionsQuery}
      />
    );
    const locationIdFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="locationExtIDs"
        label="Location ID"
        mapUserInputToFilerParams={value => ({
          locationExtIDs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const opcoNameFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="opcoName"
        label="OpCo Name"
        onSubmitFilter={updateFilter}
      />
    );

    const opcoIdsFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="opcoIDs"
        label="OpCo ID"
        mapUserInputToFilerParams={value => ({
          opcoIDs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const opcoStateProvinceRegionFilter = (
      <FilterSearchInput<CostManagementsQueryVariables>
        filterFieldName="opcoStateProvinceRegion"
        label="OpCo State/Province/Region"
        mapUserInputToFilerParams={value => ({
          opcoStateProvinceRegion: value,
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const isDisabledFilter = (
      <FilterSearchSelectBinary<CostManagementsQueryVariables>
        filterFieldName="isDisabled"
        label="Is Disabled"
        onSubmitFilter={updateFilter}
      />
    );

    const updatedAtFilter = (
      <FilterSearchDatePicker<CostManagementsQueryVariables>
        filterFieldName="updatedAt"
        label="Updated At"
        onSubmitFilter={updateFilter}
      />
    );

    const updatedByFilter = (
      <FilterSearchAutocomplete<CostManagementsQueryVariables>
        filterFieldName="updatedBy"
        label="Updated By"
        mapUserAutocompleteValueSelectToFilterParams={option => ({
          updatedBy: option!.value,
        })}
        onSubmitFilter={updateFilter}
        options={userOptions}
      />
    );

    const CellInputPackage = FactoryCellEmptyOn<MappedRow>(
      FactoryCellInput({
        type: inputTypes.Money,
        min: 0.01,
        max: 100000,
        dependencies: ['effectiveDate', 'currency', 'isDisabled'],
        isDisabled: ({ currency, effectiveDate, isDisabled }: MappedRow) =>
          !effectiveDate || !canEdit || !currency || isDisabled,
        resetStateOnNewValue: true,
      }),
      (_, { isAddLocationCostRow }) => isAddLocationCostRow,
      null,
      false,
      ['effectiveDate', 'currency', 'isDisabled'],
    );

    const deriveHasDateDependenciesChanged = (previous: MappedRow, next: MappedRow): boolean => {
      const previousRows = (previous.parent ? previous.parent.rows : previous.rows) ?? [];
      const nextRows = (next.parent ? next.parent.rows : next.rows) ?? [];

      return (
        nextRows.length !== previousRows.length ||
        !!previousRows.some(
          (row, index) => !!nextRows[index] && nextRows[index].effectiveDate !== row.effectiveDate,
        ) ||
        previous.parent?.effectiveDate !== next.parent?.effectiveDate ||
        previous.effectiveDate !== next.effectiveDate
      );
    };

    const CellEffectiveDate = FactoryCellDatePicker<MappedRow>({
      disableKeyboardInput: true,
      dependencies: ['isDisabled'],
      isDisabled: ({ isDisabled }) => isDisabled || !canEdit,
      deriveDisabledDates: ({ parent, rows }) => {
        const allRows = parent ? parent.rows : rows;
        const disabledDates =
          allRows!.reduce<number[]>(
            (result, { effectiveDate }) => (effectiveDate ? [...result, effectiveDate] : result),
            [],
          ) ?? [];

        if (parent && parent.effectiveDate) {
          disabledDates.push(parent.effectiveDate);
        }

        return disabledDates;
      },
      deriveHasDependenciesChanged: deriveHasDateDependenciesChanged,
    });

    const CellAddLocationCost = FactoryCellAddLocationCost(
      setUnsubmittedRows,
      [],
      deriveHasDateDependenciesChanged,
    );

    const CellEffectiveDateOrAddLocationCost = FactoryCellEmptyOn<MappedRow>(
      CellAddLocationCost,
      (_, { isAddLocationCostRow }) => isAddLocationCostRow,
      CellEffectiveDate,
      true,
      ['isDisabled'],
      deriveHasDateDependenciesChanged,
    );

    return [
      {
        Header: () => itemDepartmentFilter,
        accessor: 'itemDepartment',
        width: 140,
      },
      {
        Header: () => itemClassFilter,
        accessor: 'itemClass',
        width: 150,
      },
      {
        Header: () => itemNameFilter,
        accessor: 'itemName',
        Cell: ({ row }: { row: TableRow<MappedRow> }) => {
          const { language } = useContext(SessionStorageContext);

          const i18n = (mapToLanguage.get(language)?.split('-').join('') ||
            '') as keyof Row['item']['i18n']['name'];

          const i18nName =
            row.original.i18n?.name && row.original.i18n?.name[i18n]
              ? row.original.i18n?.name[i18n]
              : row.original.itemName;

          return i18nName || '';
        },
      },
      { Header: () => itemIdFilter, accessor: 'itemId' },
      { Header: () => supplierNameFilter, accessor: 'supplierName' },
      { Header: () => supplierIdsFilter, accessor: 'supplierId', width: 140 },
      { Header: () => locationNameFilter, accessor: 'locationName' },
      { Header: () => locationIdFilter, accessor: 'locationId' },
      { Header: () => opcoNameFilter, accessor: 'opcoName' },
      { Header: () => opcoIdsFilter, accessor: 'opcoId' },
      {
        Header: () => opcoStateProvinceRegionFilter,
        accessor: 'opcoStateProvinceRegion',
        width: 200,
      },
      {
        Header: () => isDisabledFilter,
        accessor: 'isDisabled',
        Cell: FactoryCellSelectBinary({
          isDisabled: () => !canEdit,
          update: updateIsDisabled,
        }),
        SubCell: EmptyCell,
      },
      {
        Header: 'Effective Date',
        accessor: 'effectiveDate',
        Cell: CellEffectiveDate,
        SubCell: CellEffectiveDateOrAddLocationCost,
        width: 200,
      },
      { Header: () => statusFilter, accessor: 'status', Cell: CellStatus },
      {
        id: 'cost',
        Header: FactoryCellMutedHeader({
          mainText: 'Cost',
          mutedText: '(*Packaging Type)',
        }),
        columns: [
          {
            Header: 'Each',
            accessor: PackageTypeName.Each,
            Cell: CellInputPackage,
            width: 96,
          },
          {
            Header: 'Inner',
            accessor: PackageTypeName.Inner,
            Cell: CellInputPackage,
            width: 96,
          },
          {
            Header: 'Case',
            accessor: PackageTypeName.Case,
            Cell: CellInputPackage,
            width: 96,
          },
          {
            Header: 'Master Case',
            accessor: PackageTypeName.MasterCase,
            Cell: CellInputPackage,
            width: 96,
          },
          {
            Header: 'Pallet',
            accessor: PackageTypeName.Pallet,
            Cell: CellInputPackage,
            width: 96,
          },
        ],
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        Cell: FactoryCellSelect<MappedRow, string>({
          options: currenciesOptions,
          isDisabled: ({ effectiveDate, isDisabled }) => !effectiveDate || !canEdit || isDisabled,
          showError: ({ currency, rows }) =>
            rows!.some(
              ({ currency: subRowCurrency, isAddLocationCostRow, isUnsubmitted }) =>
                !isAddLocationCostRow && !isUnsubmitted && currency !== subRowCurrency,
            ),
          dependencies: ['isDisabled'],
        }),
        SubCell: FactoryCellText<MappedRow>({
          isRed: ({ currency, parent }) => parent!.currency !== currency,
          pickValue: ({ currency }) =>
            currency ? currenciesOptions.find(({ value }) => value === currency)!.label : '',
          dependencies: ['currency'],
        }),
      },
      {
        Header: () => updatedByFilter,
        accessor: 'updatedBy',
      },
      {
        Header: () => updatedAtFilter,
        accessor: 'updatedAt',
        Cell: CellMultiDateConverter,
        width: 170,
      },
    ].filter(Boolean) as UseColumnsResult;
  }, [currenciesOptions, canEdit, languageCode, userOptions]);

export { useColumns };
