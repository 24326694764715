import { FC } from 'react';

import { DataGridPage } from 'shared/components/DataGridPage';
import { DataGrid } from 'shared/components/DataGrid';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';

import { AddButton } from './components/AddButton';
import { AddModal } from './components/AddModal';
import { CostManagementViewProps } from './types';

const CostManagementView: FC<CostManagementViewProps> = ({
  canEdit,
  downloadButtonDisabled,
  onAddClick,
  onCloseAddModalClick,
  onDataGridDownloadButtonClick,
  onSaveAndCloseAddModalClick,
  selectedRows,
  shouldShowAddModal,
  uploadFileProps,
  ...props
}) => (
  <>
    <DataGridPage
      header={
        <HeaderDataGridActions
          downloadButtonDisabled={downloadButtonDisabled}
          hasAccessToUploadFile={canEdit}
          onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
          title="Cost Management"
          uploadFileProps={uploadFileProps}
        >
          {canEdit && <AddButton onClick={onAddClick} />}
        </HeaderDataGridActions>
      }
      selectedRows={selectedRows}
    >
      <DataGrid
        {...props}
        disableOnLoadingMoreData
        enableExpanding
        enableRowSelection
        showLoaderOnLoadingMoreData={false}
      />
    </DataGridPage>
    <AddModal
      isOpen={shouldShowAddModal}
      onClose={onCloseAddModalClick}
      onSaveAndClose={onSaveAndCloseAddModalClick}
    />
  </>
);

export { CostManagementView };
