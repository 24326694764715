import { TLD } from 'shared/constants/environment';
import type { Config } from 'shared/types';
import { Tld } from 'shared/constants/tld';
import {
  FEATURE_FLAG_SHOW_ITEM_EXTRA_SUB_CLASS_DEFAULT,
  FEATURE_FLAG_SHOW_LEAD_TIME_OVERRIDE,
} from 'shared/constants/featureFlags';

const auth0Dev = {
  domain: 'gopuff-scdc-dev.us.auth0.com',
  clientId: 'WP7fTba5oDHJLng2NOLXPW87rtC5hgIx',
  authorizationParams: {
    audience: 'https://scdc-api.gopuff.com/api',
  },
};

const resourcesUrl =
  'https://docs.google.com/spreadsheets/d/1O6bixzCr1XPMhxo_q20W1NcU3wQzJKU8i0bneBE-qBw/edit?usp=sharing';

const localConfig: Config = {
  auth0: auth0Dev,
  apiUrl: 'http://localhost:8080/query',
  resourcesUrl,
  featureFlags: {
    [FEATURE_FLAG_SHOW_ITEM_EXTRA_SUB_CLASS_DEFAULT]: true,
    [FEATURE_FLAG_SHOW_LEAD_TIME_OVERRIDE]: true,
  },
};

const devConfig: Config = {
  auth0: auth0Dev,
  apiUrl: 'https://sc-dc-api.supply.gopuff.dev/query',
  resourcesUrl,
  featureFlags: {
    [FEATURE_FLAG_SHOW_ITEM_EXTRA_SUB_CLASS_DEFAULT]: true,
    [FEATURE_FLAG_SHOW_LEAD_TIME_OVERRIDE]: true,
  },
};

const testConfig: Config = {
  auth0: auth0Dev,
  apiUrl: 'http://localhost:8080/query',
  resourcesUrl,
  featureFlags: {
    [FEATURE_FLAG_SHOW_ITEM_EXTRA_SUB_CLASS_DEFAULT]: true,
    [FEATURE_FLAG_SHOW_LEAD_TIME_OVERRIDE]: true,
  },
};

const stgConfig: Config = {
  auth0: auth0Dev,
  apiUrl: 'https://sc-dc-api.supply.gopuff.tech/query',
  resourcesUrl,
  featureFlags: {
    [FEATURE_FLAG_SHOW_ITEM_EXTRA_SUB_CLASS_DEFAULT]: true,
    [FEATURE_FLAG_SHOW_LEAD_TIME_OVERRIDE]: true,
  },
};

const prodConfig: Config = {
  auth0: {
    domain: 'gopuff-scdc.us.auth0.com',
    clientId: 'F9lhniNZhK2PBGGRRWJBxCDknAjJdArW',
    authorizationParams: {
      audience: 'https://scdc-api.gopuff.com/api',
    },
  },
  apiUrl: 'https://sc-dc-api.supply.gopuff.com/query',
  resourcesUrl,
  featureFlags: {
    [FEATURE_FLAG_SHOW_ITEM_EXTRA_SUB_CLASS_DEFAULT]: false,
    [FEATURE_FLAG_SHOW_LEAD_TIME_OVERRIDE]: true,
  },
};

const configMap: Record<Tld, Config> = {
  [Tld.local]: localConfig,
  [Tld.dev]: devConfig,
  [Tld.e2e]: testConfig,
  [Tld.tech]: stgConfig,
  [Tld.com]: prodConfig,
  [Tld.corp]: prodConfig,
};

const config: Config = configMap[TLD] ?? localConfig;

export { config };
