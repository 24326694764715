import {
  always,
  cond,
  equals,
  flip,
  identity,
  ifElse,
  is,
  of,
  pipe,
  prop,
  props,
  take,
  takeLast,
} from 'ramda';

import { PickOptionsParameters, PickOptionsReturn } from './types';

const pickOptions = <T extends object = object, Unpack extends boolean = false>({
  options,
  pick,
  pickCount = 1,
  unpack = false as Unpack,
}: PickOptionsParameters<T, Unpack>): PickOptionsReturn<T, Unpack> =>
  cond<any, T | T[]>([
    [is(Array), flip(props)(options) as () => T[]],
    [equals('start'), () => take(pickCount, options)],
    [equals('end'), () => takeLast(pickCount, options)],
    [
      is(Number),
      pipe(flip(prop)(options), ifElse(always(unpack), identity, of)) as () => PickOptionsReturn<
        T,
        Unpack
      >,
    ],
  ])(pick) as PickOptionsReturn<T, Unpack>;

export { pickOptions };
