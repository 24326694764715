import { useQuery } from '@apollo/client';
import { useCallback, useContext, useMemo, useState } from 'react';

import { mapRowToMutationArgs } from 'pages/Item/mappers';
import {
  itemDownloadQuery,
  itemOptionsQuery,
  itemQuery,
  itemUploadMutation,
  updateItemMutation,
} from 'pages/Item/operations';
import { Row, SetupResult } from 'pages/Item/types';
import { SessionStorageContext } from 'setup/providers/Context/Context';
import {
  useDownload,
  useEditPage,
  useOnGridChange,
  usePagination,
  usePermissions,
  useRole,
  useUploadFile,
} from 'shared/hooks';
import { usersQuery } from 'shared/operations/query';
import {
  ItemFilter,
  ItemOptionsQuery,
  ItemQuery,
  QueryItemsArgs,
  UpdateItemMutation,
  UpdateItemMutationVariables,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import {
  displayNameOptionMapper,
  isFirstLoad,
  mapToLanguage,
  mapToSelectOptions,
} from 'shared/utils';

import { useColumns } from './useColumns';

const useSetup = (): SetupResult => {
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const [filter, setFilter] = useState<QueryItemsArgs['filter']>({});
  const userPermissions = usePermissions();
  const role = useRole();
  const { language } = useContext(SessionStorageContext);

  const {
    data: { users } = { users: [] },
    networkStatus: usersNetworkStatus,
    loading: usersLoading,
  } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery);
  const userOptions = useMemo(() => mapToSelectOptions(users, displayNameOptionMapper), [users]);

  const {
    data: {
      alcoholTypes,
      capabilities,
      customAssortments,
      manufacturingTypes,
      nationalAssortments,
      tobaccoTypes,
    } = {
      customAssortments: [],
      capabilities: [],
      tobaccoTypes: [],
      nationalAssortments: [],
      alcoholTypes: [],
      manufacturingTypes: [],
    },
    networkStatus: itemOptionsNetworkStatus,
  } = useQuery<ItemOptionsQuery>(itemOptionsQuery);

  const {
    data: { items: { items: data, totalCount } } = {
      items: {
        totalCount: 0,
        items: [],
      },
    },
    loading,
    networkStatus: itemQueryNetworkStatus,
    client,
  } = useQuery<ItemQuery, QueryItemsArgs>(itemQuery, {
    variables: {
      pagination,
      filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [onChange, mutationLoading] = useOnGridChange<
    Row,
    UpdateItemMutation,
    UpdateItemMutationVariables
  >({
    data,
    mutation: updateItemMutation,
    mapRowToMutationArgs,
  });

  const {
    onAddTagsClick,
    onCloseEditPage,
    onEditClick,
    selectedRows,
    setSelectedRows,
    showAddTagsPage,
    showEditPage,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
  } = useEditPage<Row>();
  const onDataGridDownloadButtonClick = useDownload({
    query: itemDownloadQuery,
    queryResponseDataKey: 'itemDownload',
    selectedRows,
    triggerUncheckAllCheckboxes,
  });
  const uploadFileProps = useUploadFile({
    downloadTemplateQuery: itemDownloadQuery,
    mutation: itemUploadMutation,
    client,
    evictCacheForQueryName: 'items',
  });

  const reset = useCallback(() => {
    resetPageIndex();
    setFilter({});
  }, []);

  const updateFilter = useCallback((newFilter: QueryItemsArgs['filter']) => {
    setFilter(prevFilter => {
      let { isAlcohol, isBom, isTobacco } = prevFilter as ItemFilter;

      if (newFilter!.isBom) {
        isAlcohol = undefined;
        isTobacco = undefined;
      }

      if (
        Object.hasOwnProperty.call(newFilter, 'isAlcohol') ||
        Object.hasOwnProperty.call(newFilter, 'isTobacco')
      ) {
        isBom = undefined;
      }

      return {
        ...prevFilter,
        isAlcohol,
        isTobacco,
        isBom,
        ...newFilter,
      };
    });
    resetPageIndex();
  }, []);

  const itemOptions = useMemo(() => {
    const capabilitiesOptions = mapToSelectOptions(capabilities);
    const nationalAssortmentsOptions = mapToSelectOptions(nationalAssortments);
    const customAssortmentsOptions = mapToSelectOptions(customAssortments);
    const alcoholTypesOptions = mapToSelectOptions(alcoholTypes);
    const manufacturingTypesOptions = mapToSelectOptions(manufacturingTypes);
    const tobaccoTypesOptions = mapToSelectOptions(tobaccoTypes);

    return {
      capabilitiesOptions,
      nationalAssortmentsOptions,
      customAssortmentsOptions,
      alcoholTypesOptions,
      manufacturingTypesOptions,
      tobaccoTypesOptions,
    };
  }, [
    capabilities,
    nationalAssortments,
    customAssortments,
    alcoholTypes,
    manufacturingTypes,
    tobaccoTypes,
  ]);

  const columns = useColumns({
    ...itemOptions,
    updateFilter,
    userOptions,
    role,
    languageCode: mapToLanguage.get(language) || 'en-US',
  });

  return {
    onAddTagsClick,
    onCloseEditPage,
    onEditClick,
    selectedRows,
    setSelectedRows,
    showAddTagsPage,
    showEditPage,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
    onDataGridDownloadButtonClick,
    uploadFileProps,
    itemOptions,
    columns,
    data,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    loadingMoreData: loading || usersLoading || mutationLoading,
    showMainLoader:
      (totalCount !== 0 && !data.length) ||
      isFirstLoad(itemOptionsNetworkStatus) ||
      isFirstLoad(itemQueryNetworkStatus) ||
      isFirstLoad(usersNetworkStatus),
    reset,
    userPermissions,
  };
};

export { useSetup };
