import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { TransportationLane } from 'shared/types';

import { makeTransportationLane } from '../factories';
import { replicateN } from '../utils';

import { locations } from './locations';

const transportationLaneLocations = locations();

const transportationLanes = (
  props: O.Partial<TransportationLane, 'deep'>[] = [],
): TransportationLane[] =>
  mergeDeepArrays<TransportationLane>(
    replicateN({
      data: makeTransportationLane(),
      n: 5,
      replacements: {
        destination: transportationLaneLocations,
        source: transportationLaneLocations.reverse(),
      },
      skipIncrementForFields: ['destination'],
    }),
    props,
  );

export { transportationLanes };
