import { FC } from 'react';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'center' };

const Toasts: FC = ({ children }) => (
  <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={6000}>
    {children}
  </SnackbarProvider>
);

export { Toasts };
