import { TableCommonProps, UseExpandedInstanceProps } from 'react-table';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from './FactoryHeaderRowExpander.module.css';

const classes = { root: styles.container };

interface FactoryHeaderRowExpanderProps<DataType extends object>
  extends UseExpandedInstanceProps<DataType> {
  getToggleAllRowsExpandedProps: (props?: Partial<TableCommonProps>) => TableCommonProps;
}
const FactoryHeaderRowExpander =
  <DataType extends object>() =>
  ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: FactoryHeaderRowExpanderProps<DataType>) =>
    (
      <div className={styles.container} {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <ExpandLessIcon classes={classes} color="disabled" />
        ) : (
          <ExpandMoreIcon classes={classes} color="disabled" />
        )}
      </div>
    );

export { FactoryHeaderRowExpander };
