import { FC } from 'react';

import styles from './Label.module.css';
import { LabelProps } from './types';

const Label: FC<LabelProps> = ({ className, label }) => (
  <>
    {label &&
      (typeof label === 'string' ? (
        <label className={className ?? styles.label} htmlFor="inputSearch">
          {label}
        </label>
      ) : (
        label
      ))}
  </>
);

export { Label };
