import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { User } from 'shared/types';

const makeUsers = (props: O.Partial<User, 'deep'> = {}): User =>
  merge<User, O.Partial<User, 'deep'>>(
    {
      id: '1',
      displayName: 'John Smit',
      email: '',
      __typename: 'User',
    },
    props,
  );

export { makeUsers };
