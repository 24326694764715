import { FC } from 'react';

import { DataGrid } from 'shared/components/DataGrid';
import { DataGridPage } from 'shared/components/DataGridPage';
import { ACTION_MASS_DESTINATION_ASSORTMENT } from 'shared/constants';

import { UpdateAssortmentLoader } from './components/UpdateAssortmentLoader';
import { DestinationAssortmentsViewComponentProps, Row } from './types';
import { Header } from './components/Header';

const DestinationAssortmentView: FC<DestinationAssortmentsViewComponentProps> = ({
  activePageIndex,
  lastDestinationAssortmentTime,
  onDataGridDownloadButtonClick,
  onEditClick,
  onTogglePage,
  onUpdateAssortmentClick,
  selectedRows,
  showUpdateAssortmentLoader,
  triggerUncheckAllCheckboxes,
  uploadFileProps,
  userPermissions,
  ...props
}) => (
  <>
    {showUpdateAssortmentLoader ? (
      <UpdateAssortmentLoader />
    ) : (
      <DataGridPage<Row, true>
        header={
          <Header
            activePageIndex={activePageIndex}
            lastDestinationAssortmentTime={lastDestinationAssortmentTime}
            onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
            onTogglePage={onTogglePage}
            onUpdateAssortmentClick={onUpdateAssortmentClick}
            uploadFileProps={uploadFileProps}
            userPermissions={userPermissions}
          />
        }
        isMassEditOn={userPermissions.has(ACTION_MASS_DESTINATION_ASSORTMENT)}
        onEditClick={onEditClick}
        selectedRows={selectedRows}
        triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
      >
        <DataGrid {...props} enableRowSelection />
      </DataGridPage>
    )}
  </>
);
export { DestinationAssortmentView };
