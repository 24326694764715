import { Column } from 'react-table';

import { Row } from '../types';

export const headersForMassEditColumns: Column<Row>[] = [
  {
    Header: 'Item Name',
    accessor: ({ item: { name } }) => name,
  },
  {
    Header: 'Item ID',
    accessor: ({ item: { extId } }) => extId,
  },
  {
    Header: 'Location ID',
    accessor: ({ location: { extId } }) => extId,
  },
  {
    Header: 'Source Name',
    accessor: ({ source: { name } }) => name,
  },
  {
    Header: 'Source ID',
    accessor: ({ source: { extID } }) => extID,
  },
  {
    Header: 'Opco/Site Name',
    accessor: ({ opco: { name } }) => name,
  },
  {
    Header: 'Opco/Site ID',
    accessor: ({ opco: { id } }) => id,
  },
];
