import { FC } from 'react';
import classNames from 'classnames';

import { InfoTooltip } from 'shared/components/InfoTooltip';

import { TooltipHeaderProps } from './types';
import styles from './TooltipHeader.module.css';

const convertLargeText = (text: string) =>
  text.split('\n').map(item => (
    <p key={item.toString()} className={styles.tooltip}>
      {item}
    </p>
  ));

const TooltipHeader: FC<TooltipHeaderProps> = ({ isSubHeaderCell = false, label, title }) => (
  <div className={classNames(styles.default, { [styles.width]: isSubHeaderCell })}>
    {label}
    <InfoTooltip title={convertLargeText(title)} />
  </div>
);

export { TooltipHeader };
