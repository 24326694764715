import { FC } from 'react';

import { DataGrid } from 'shared/components/DataGrid';
import { Header } from 'shared/components/Header';
import { DataGridPage } from 'shared/components/DataGridPage';

import { deriveRowKey } from './utils';
import { BOMViewProps } from './types';

const BOMView: FC<BOMViewProps> = ({ extId, name, ...props }) => (
  <DataGridPage
    header={<Header condensed subTitle={`Item ID: ${extId}`} title={name} />}
    isMassEditOn={false}
  >
    <DataGrid {...props} deriveRowKey={deriveRowKey} />
  </DataGridPage>
);

export { BOMView };
