import { StoreObject, defaultDataIdFromObject } from '@apollo/client';

import { Row } from '../types';

const deriveZeroIdRowKey = (row: Row) => `0:${row.itemSources[0].id}`;

const deriveRowKey = (object: Readonly<StoreObject>) => {
  const row = object as Row;
  return row.id === '0'
    ? `CostManagement:${deriveZeroIdRowKey(row)}`
    : defaultDataIdFromObject(object);
};

export { deriveRowKey, deriveZeroIdRowKey };
