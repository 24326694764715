import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

const style = { cursor: 'pointer' };

const SearchInputProps = {
  size: 'small' as const,
  endAdornment: (
    <InputAdornment position="end">
      <Search color="action" fontSize="small" style={style} />
    </InputAdornment>
  ),
};

export { SearchInputProps };
