import { forwardRef } from 'react';
import classNames from 'classnames';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import styles from './SelectButton.module.css';
import { SelectButtonProps } from './types';

const SelectButton = forwardRef<HTMLDivElement, SelectButtonProps>(
  ({ ariaDescribedby, children, disabled, open, toggleIconDataAttribute, ...buttonProps }, ref) => (
    <div
      ref={ref}
      aria-describedby={ariaDescribedby}
      aria-disabled={disabled}
      className={classNames(styles.container, { [styles.disabled]: disabled })}
      role="button"
      tabIndex={0}
      {...buttonProps}
    >
      <div className={styles.content}>{children}</div>
      <div className={styles.icon}>
        {open ? (
          <ArrowDropUpIcon color="action" {...{ [toggleIconDataAttribute]: true }} />
        ) : (
          <ArrowDropDownIcon
            color={disabled ? 'disabled' : 'action'}
            {...{ [toggleIconDataAttribute]: true }}
          />
        )}
      </div>
    </div>
  ),
);

export { SelectButton };
