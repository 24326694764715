import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorIcon from '@mui/icons-material/CancelRounded';
import InfoIcon from '@mui/icons-material/Info';

import { oneMb } from 'shared/components/UploadFileModal/constants';
import { bytesToReadableSize } from 'shared/components/UploadFileModal/utils';
import { DeleteIconButton } from 'shared/components/IconButton';

import { SelectFileProps } from './types';
import styles from './SelectFile.module.css';

const DEFAULT_ACCEPTED_FILE_TYPES = ['.csv'];

const SelectFile: FC<SelectFileProps> = ({
  loading,
  onChangeFile,
  rowLimit,
  acceptedFileTypes = DEFAULT_ACCEPTED_FILE_TYPES,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newFile = event.target.files![0];

      if (newFile) {
        setFile(newFile);
        onChangeFile(newFile);
      }

      // onChange method not called when select the same file
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      event.target.value = null;
    },
    [onChangeFile],
  );

  const onDelete = useCallback(() => {
    setFile(null);
    onChangeFile(null);
  }, [onChangeFile]);

  const selectedFileName = useMemo(
    () =>
      file ? (
        <div className={styles.selectedFile}>
          {file.name} ({bytesToReadableSize(file.size)})
          <DeleteIconButton className={styles.deleteIcon} onClick={onDelete} />
        </div>
      ) : (
        <div className={styles.noFile}>Please, select a file to upload</div>
      ),
    [file],
  );

  const infoText = useMemo(
    () =>
      file ? null : (
        <div>
          <div className={styles.infoContainer}>
            <InfoIcon className={styles.icon} /> File Max size up to 10 MB and {rowLimit || 1000}{' '}
            rows
          </div>
          <div className={styles.infoContainer}>
            <InfoIcon className={styles.icon} /> If Excel is automatically changing what you typed,
            try setting the cell format to <b>Text</b>
          </div>
        </div>
      ),
    [file],
  );

  const error = useMemo(
    () =>
      file && file.size > oneMb * 10 ? (
        <div className={styles.errorContainer}>
          <ErrorIcon className={styles.icon} /> The file is too big. The file has to be no more than
          10 MB
        </div>
      ) : null,
    [file],
  );

  return (
    <>
      <div className={styles.inputContainer}>
        {selectedFileName}
        <LoadingButton component="label" htmlFor="inputFile" loading={loading} variant="contained">
          <div>Select File</div>
          <input
            accept={acceptedFileTypes.join(',')}
            hidden
            id="inputFile"
            onChange={onChange}
            type="file"
          />
        </LoadingButton>
      </div>
      {infoText}
      {error}
    </>
  );
};

export { SelectFile };
