import { isNil } from 'ramda';
import { FC } from 'react';

import { CellProps } from 'shared/types/props';
import { memoCell } from 'shared/utils';

const FactoryCellEmptyOn = <
  DataType extends object,
  CellValue = any,
  T extends CellProps<DataType, CellValue> = CellProps<DataType, CellValue>,
>(
  Cell: FC<T>,
  on: (...args: [keyof DataType, DataType, any]) => boolean = isNil,
  EmptyResult: FC<T> | null = null,
  presentOnMode = false,
  dependencies: (keyof DataType)[] = [],
  deriveHasDependenciesChanged?: (previousRowData: DataType, nextRowData: DataType) => boolean,
) =>
  memoCell<T>(
    props => {
      const {
        column: { id: columnId },
        row,
        value,
      } = props;
      let shouldBeEmpty: boolean = on(columnId, row.original, value);

      if (presentOnMode) {
        shouldBeEmpty = !shouldBeEmpty;
      }

      if (shouldBeEmpty) {
        return EmptyResult !== null ? <EmptyResult {...props} /> : EmptyResult;
      }

      return <Cell {...props} />;
    },
    dependencies,
    false,
    deriveHasDependenciesChanged,
  );

export { FactoryCellEmptyOn };
