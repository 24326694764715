import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { ContainerCapacity } from 'shared/types';

import { replicateN } from '../utils';
import { makeCapacity } from '../factories';

const data = makeCapacity();

const capacities = (props: O.Partial<ContainerCapacity, 'deep'>[] = []): ContainerCapacity[] =>
  mergeDeepArrays<ContainerCapacity>(
    replicateN({ data, n: 5, skipIncrementForFields: ['containerUom'] }),
    props,
  );

export { capacities };
