import Chip from '@mui/material/Chip';
import { yellow } from '@mui/material/colors';

import { memoCell } from 'shared/utils';
import { CostDateType, Option } from 'shared/types';
import { FactoryCellSelect, MockCellValue } from 'shared/components/DataGrid';
import { MappedRow } from 'pages/CostManagementV2/types';

import { FactoryCellTypeProps } from './types';

const costDateTypeOptions: Option<CostDateType, string>[] = [
  { value: CostDateType.Regular, label: CostDateType.Regular },
  { value: CostDateType.Promo, label: CostDateType.Promo },
];

const FactoryCellType = () =>
  memoCell<FactoryCellTypeProps>(props => {
    const { row, value } = props;
    if (row.original.isUnsubmitted) {
      const NewCellType = FactoryCellSelect<
        MappedRow,
        CostDateType,
        CostDateType | MockCellValue<CostDateType>
      >({
        options: costDateTypeOptions,
        pickValue: pickValue => pickValue as CostDateType | null,
        dependencies: ['isDisabled'],
      });
      return <NewCellType {...props} />;
    }
    switch (value) {
      case CostDateType.Regular:
        return <Chip color="info" label="REGULAR" size="small" />;
      case CostDateType.Promo:
        return <Chip label="PROMO" size="small" sx={{ backgroundColor: yellow[600] }} />;
      default:
        return <></>;
    }
  });

export { FactoryCellType };
