import { useQuery } from '@apollo/client';
import { useCallback, useContext, useMemo, useState } from 'react';

import { mapDataToDownloadQueryArgs, mapRowToMutationArgs } from 'pages/SupplierAssortment/mappers';
import {
  supplierAssortmentDownloadQuery,
  supplierAssortmentUploadMutation,
  supplierAssortmentsQuery,
  updateSupplierAssortmentsMutation,
} from 'pages/SupplierAssortment/operations';
import { Row, SetupResult } from 'pages/SupplierAssortment/types';
import { isExistingConnection, isSupplierPage } from 'pages/SupplierAssortment/utils';
import { SessionStorageContext } from 'setup/providers/Context/Context';
import {
  useDownload,
  useEditPage,
  useIsSystemLocked,
  useOnGridChange,
  usePagination,
  usePermissions,
  useRole,
  useUploadFile,
} from 'shared/hooks';
import { usersQuery } from 'shared/operations/query';
import {
  SortOrder,
  SupplierAssortmentQuery,
  SupplierAssortmentQueryVariables,
  SupplierAssortmentSortField,
  UpdateSupplierAssortmentMutation,
  UpdateSupplierAssortmentMutationVariables,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import {
  displayNameOptionMapper,
  isFirstLoad,
  mapToLanguage,
  mapToSelectOptions,
  uniqueUnpack,
} from 'shared/utils';

import { useColumns } from './useColumns';

const useSetup = (): SetupResult => {
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const { language } = useContext(SessionStorageContext);

  const userPermissions = usePermissions();
  const role = useRole();

  const editPageProps = useEditPage<Row>(isExistingConnection);
  const { onCloseEditPage, onEditClick, selectedRows, showEditPage, triggerUncheckAllCheckboxes } =
    editPageProps;

  const [defaultManagedBy, setDefaultManagedBy] = useState<null | string>(null);
  const [filter, setFilter] = useState<SupplierAssortmentQueryVariables['filter']>({});
  const [uniqueSelectedRows, setUniqueSelectedRows] = useState<Row[]>([]);
  const [activePageIndex, setActiveIndexPage] = useState(0);
  const assortmentType = isSupplierPage(activePageIndex)
    ? SupplierAssortmentSortField.Opco
    : SupplierAssortmentSortField.Item;

  const onDataGridDownloadButtonClick = useDownload({
    query: supplierAssortmentDownloadQuery,
    queryResponseDataKey: 'supplierAssortmentDownload',
    selectedRows,
    triggerUncheckAllCheckboxes,
    mapDataToDownloadQueryArgs,
    view: assortmentType,
  });

  const { data: { users } = { users: [] }, networkStatus: usersNetworkStatus } = useQuery<
    UsersQuery,
    UsersQueryVariables
  >(usersQuery);
  const {
    data: { supplierAssortments: { assortments: data, totalCount } } = {
      supplierAssortments: { assortments: [], totalCount: 0 },
    },
    loading,
    networkStatus,
    client,
  } = useQuery<SupplierAssortmentQuery, SupplierAssortmentQueryVariables>(
    supplierAssortmentsQuery,
    {
      variables: {
        pagination,
        sorting: {
          field: assortmentType,
          order: SortOrder.Asc,
        },
        filter,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const uploadFileProps = useUploadFile({
    downloadTemplateQuery: supplierAssortmentDownloadQuery,
    mutation: supplierAssortmentUploadMutation,
    client,
    evictCacheForQueryName: 'supplierAssortments',
    view: assortmentType,
  });

  const { systemLocked } = useIsSystemLocked();

  const [onChange] = useOnGridChange<
    Row,
    UpdateSupplierAssortmentMutation,
    UpdateSupplierAssortmentMutationVariables
  >({
    data,
    mutation: updateSupplierAssortmentsMutation,
    mapRowToMutationArgs,
  });

  const handleCloseEditPage = useCallback(() => {
    setDefaultManagedBy(null);
    onCloseEditPage();
  }, [onCloseEditPage]);

  const reset = useCallback(() => {
    resetPageIndex();
    setFilter({});
  }, []);

  const onTogglePage = useCallback((newPageIndex: number) => {
    triggerUncheckAllCheckboxes();
    setActiveIndexPage(newPageIndex);
    reset();
  }, []);

  const updateFilter = useCallback((newFilter: SupplierAssortmentQueryVariables['filter']) => {
    setFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
    resetPageIndex();
  }, []);

  const userOptions = useMemo(() => mapToSelectOptions(users, displayNameOptionMapper), [users]);

  const columns = useColumns({
    activePageIndex,
    systemLocked,
    userOptions,
    updateFilter,
    role,
    languageCode: mapToLanguage.get(language) || 'en-US',
  });
  const borderAfterIndex: number = isSupplierPage(activePageIndex) ? 5 : 4;

  const handleEditClick = useCallback(() => {
    setUniqueSelectedRows(
      uniqueUnpack(selectedRows, isSupplierPage(activePageIndex) ? 'opco' : 'item', 'original'),
    );
    onEditClick();
  }, [activePageIndex, onEditClick, selectedRows]);

  return {
    onTogglePage,
    borderAfterIndex,
    columns,
    totalCount,
    data,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    activePageIndex,
    showMainLoader:
      (isFirstLoad(networkStatus) && !showEditPage) || isFirstLoad(usersNetworkStatus),
    loadingMoreData: loading,
    userOptions,
    systemLocked,
    reset,
    uploadFileProps,
    editPageProps: {
      ...editPageProps,
      onEditClick: handleEditClick,
      onCloseEditPage: handleCloseEditPage,
      defaultManagedBy,
      setDefaultManagedBy,
      uniqueSelectedRows,
    },
    onDataGridDownloadButtonClick,
    userPermissions,
  };
};

export { useSetup, supplierAssortmentsQuery };
