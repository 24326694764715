import { memo, useCallback } from 'react';

import { AlcoholVolume } from 'pages/UserDefinedAttribute/components/AlcoholForm/components/AlcoholVolume';
import { pickError } from 'pages/UserDefinedAttribute/utils';
import { DeleteIconButton } from 'shared/components/IconButton';
import { TextField } from 'shared/components/TextField';

import styles from './Row.module.css';
import { RowProps } from './types';

const Row = memo<RowProps>(
  ({
    areDisabledVolumes,
    errors,
    getValues,
    hasPermissions = false,
    index,
    isDisabled,
    label,
    name,
    onBlur,
    onChange,
    onDelete,
  }) => {
    const fieldName = `${name}.${index}.name`;
    const fieldValue = getValues(fieldName as `${typeof name}.0.name`);
    const descriptionName = `${name}.${index}.description`;
    const descriptionValue = getValues(descriptionName as `${typeof name}.0.description`);

    const onDeleteClick = useCallback(() => {
      const fieldId = getValues(`${name}.${index}.id`);

      onDelete({ fieldId, fieldIndex: index });
    }, [index]);

    return (
      <>
        <TextField
          defaultValue={fieldValue}
          disabled={isDisabled || !hasPermissions}
          error={pickError(fieldName, errors)}
          label={label}
          name={fieldName}
          onBlur={onBlur}
          onChange={onChange}
          placeholder="Alcohol Type"
          size="small"
        />
        <AlcoholVolume
          getValues={getValues}
          hasPermissions={hasPermissions}
          initialLength={areDisabledVolumes}
          onDelete={onDelete}
          typeIndex={index}
        />
        <div className={styles.wrapper}>
          <TextField
            defaultValue={descriptionValue}
            disabled={!hasPermissions}
            error={pickError(descriptionName, errors)}
            label="Description"
            name={descriptionName}
            onBlur={onBlur}
            onChange={onChange}
            placeholder="Add Description"
          />
          {hasPermissions && (
            <DeleteIconButton className={styles.deleteButton} onClick={onDeleteClick} />
          )}
        </div>
      </>
    );
  },
);

export { Row };
