import { Column } from 'react-table';

import { Row } from '../types';

export const headersForMassEditColumns: [Column<Row>[], Column<Row>[]] = [
  [
    {
      Header: 'Source Name',
      accessor: ({ source: { name } }) => name,
    },
    {
      Header: 'Source ID',
      accessor: ({ source: { id } }) => id,
    },
  ],
  [
    {
      Header: 'Destination Name',
      accessor: ({ destination: { name } }) => name,
    },
    {
      Header: 'Destination ID',
      accessor: ({ destination: { extId } }) => extId,
    },
  ],
];
