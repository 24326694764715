import { forwardRef } from 'react';

import { ListItemButtonProps } from './types';
import styles from './ListItemButton.module.css';

const ListItemButton = forwardRef<HTMLDivElement, ListItemButtonProps>(
  ({ children, ...divProps }, ref) => (
    <div
      aria-disabled={divProps.disabled}
      className={styles.listItemButton}
      {...divProps}
      ref={ref}
    >
      {children}
    </div>
  ),
);

export { ListItemButton };
