import { useMemo } from 'react';

import { FactoryCellDataLink } from 'shared/components/DataGrid';

import { Row, UseColumnsResult } from '../types';

const CellComponentName = FactoryCellDataLink<Row>();

const columns: UseColumnsResult = [
  {
    Header: 'Component Name',
    accessor: ({ item }) => item.name,
    width: 240,
    Cell: CellComponentName,
    sticky: 'left',
  },
  {
    Header: 'Item ID',
    accessor: ({ item }) => item.extId,
    width: 120,
  },
  {
    Header: 'Category',
    accessor: ({ item }) => item.category,
    width: 120,
  },
  {
    Header: 'Class',
    accessor: ({ item }) => item.class,
    width: 120,
  },
  {
    Header: 'Brand',
    accessor: ({ item }) => item.brand,
    width: 120,
  },
];

const useColumns = (): UseColumnsResult => useMemo(() => columns, []);

export { useColumns };
