import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/UploadFileOutlined';

import { UploadIconButtonProps } from './types';
import styles from './UploadIconButton.module.css';

const UploadIconButton = ({ onClick }: UploadIconButtonProps) => (
  <IconButton className={styles.uploadIconButton} onClick={onClick}>
    <UploadIcon className={styles.icon} />
  </IconButton>
);

export { UploadIconButton };
