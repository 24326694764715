import classNames from 'classnames';

import { FieldError } from 'shared/components/FieldError';
import { FieldLabel } from 'shared/components/FieldLabel';
import { SelectAlcoholType } from 'pages/Location/components/SelectAlcoholType';

import styles from './SelectAlcoholTypeField.module.css';
import { SelectAlcoholTypeFieldProps } from './types';

const SelectAlcoholTypeField = ({
  className,
  error,
  options,
  ...props
}: SelectAlcoholTypeFieldProps) => (
  <div className={classNames(styles.container, className)}>
    <FieldLabel name="alcoholVolumes">Alcohol Type</FieldLabel>
    <SelectAlcoholType {...props} name="alcoholVolumes" options={options} />
    {error && <FieldError block message={error} />}
  </div>
);

export { SelectAlcoholTypeField };
