import { forwardRef } from 'react';

import { itemToString } from 'shared/components/NewestSelect/shared/utils';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from 'shared/components/List';
import { CheckboxIcon } from 'shared/components/CheckboxIcon';

import { SelectOptionProps } from './types';

const SelectOption = forwardRef<HTMLLIElement, SelectOptionProps>(
  ({ item, selectedValue, ...itemProps }, ref) => {
    const selected = selectedValue.includes(item.value);
    return (
      <ListItem {...itemProps} ref={ref} aria-selected={selected}>
        <ListItemButton>
          <ListItemIcon>
            <CheckboxIcon checked={selected} />
          </ListItemIcon>
          <ListItemText>{itemToString(item)}</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  },
);

export { SelectOption };
