import { Row as TableRow } from 'react-table';

import { Row } from 'pages/SupplierAssortment/types';
import { SupplierAssortmentDownloadFilter } from 'shared/types';

const mapDataToDownloadQueryArgs = ({
  selectedRows,
  view,
}: {
  selectedRows: TableRow<Row>[];
  view?: string;
}) => ({
  variables: {
    view,
    filter: selectedRows.reduce<SupplierAssortmentDownloadFilter>(
      (acc, { original: { id, item, opco } }) => {
        const itemIDs = [...acc.itemIDs!];
        const opcoIDs = [...acc.opcoIDs!];
        const IDs = [...acc.IDs!];

        if (id) {
          IDs.push(id);
        } else if (item?.id) {
          itemIDs.push(item?.id);
        } else if (opco?.id) {
          opcoIDs.push(opco?.id);
        }

        return {
          itemIDs,
          opcoIDs,
          IDs,
        };
      },
      { itemIDs: [], opcoIDs: [], IDs: [] },
    ),
  },
});

export { mapDataToDownloadQueryArgs };
