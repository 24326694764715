import { FC, useMemo } from 'react';
import { Column } from 'react-table';

import {
  queryDealDetails,
  updateDealLineScanDealMutation,
} from 'pages/DealManagementDetail/operations';
import {
  DataGrid,
  FactoryCellInput,
  FactoryCellMutedHeader,
  FactoryCellUPC,
} from 'shared/components/DataGrid';
import { Row } from 'pages/DealManagementDetail/types';
import { inputTypes } from 'shared/components/Input/constants';
import { useOnGridChange, useRole } from 'shared/hooks';
import {
  DealStatus,
  UpdateDealLineScanDealMutation,
  UpdateDealLineScanDealMutationVariables,
} from 'shared/types';
import { mapRowToMutationArgs } from 'pages/DealManagementDetail/mappers/mapRowToMutationArgs/mapRowToMutationArgs';
import { ROLE_EXTERNAL_USER } from 'shared/constants';

import { DealLineItemsScanDealProps } from './types';

const CellUPC = FactoryCellUPC();

export const DealLineItemsScanDeal: FC<DealLineItemsScanDealProps> = ({ deal }) => {
  if (!deal) {
    return <></>;
  }

  const itemRows = deal.lines?.map(line => ({
    status: deal.status,
    id: line.scanDeal?.id,
    eachUpc: line.item.upc,
    extId: line.item.extId,
    name: line.item.name,
    vendorFundingPerUnit: line.scanDeal?.vendorFundingPerUnit,
    gopuffDollarFundingPerUnit: line.scanDeal?.gopuffDollarFundingPerUnit,
    gopuffPercentageFundingPerUnit: line.scanDeal?.gopuffPercentageFundingPerUnit,
  }));

  const role = useRole();

  const CellInputGopuffDollarFunding = FactoryCellInput({
    type: inputTypes.Money,
    max: 100000,
    dependencies: ['gopuffDollarFundingPerUnit', 'gopuffPercentageFundingPerUnit', 'status'],
    isDisabled: ({ gopuffPercentageFundingPerUnit }: Row) =>
      !!gopuffPercentageFundingPerUnit ||
      deal.status !== DealStatus.Pending ||
      ROLE_EXTERNAL_USER.has(role),
    resetStateOnNewValue: true,
  });

  const CellInputGopuffPercentageFunding = FactoryCellInput({
    type: inputTypes.Money,
    max: 99,
    dependencies: ['gopuffDollarFundingPerUnit', 'gopuffPercentageFundingPerUnit', 'status'],
    isDisabled: ({ gopuffDollarFundingPerUnit }: Row) =>
      !!gopuffDollarFundingPerUnit ||
      deal.status !== DealStatus.Pending ||
      ROLE_EXTERNAL_USER.has(role),
    resetStateOnNewValue: true,
  });

  const hideGopuffFundingColumn = ROLE_EXTERNAL_USER.has(role);

  const columns: Column<Row>[] = useMemo(
    () =>
      [
        {
          Header: 'Each UPC',
          accessor: 'eachUpc',
          Cell: CellUPC,
        },
        {
          Header: 'Item Gopuff ID',
          accessor: 'extId',
        },
        {
          Header: 'Item Name',
          accessor: 'name',
        },
        {
          Header: `Vendor Funding Per Unit (${deal.currency.code})`,
          accessor: 'vendorFundingPerUnit',
        },
        hideGopuffFundingColumn
          ? null
          : {
              id: 'gopuffFunding',
              Header: FactoryCellMutedHeader({
                mainText: 'Strikethrough Amount - dollar off OR percentage off',
                mutedText: `(Delta between Strikethrough Amount and Vendor Funding is funded by Gopuff)`,
              }),
              columns: [
                {
                  Header: `Dollar Off Strikethrough (${deal.currency.code})`,
                  accessor: 'gopuffDollarFundingPerUnit',
                  Cell: CellInputGopuffDollarFunding,
                },
                {
                  Header: `Percentage Off Strikethrough (%)`,
                  accessor: 'gopuffPercentageFundingPerUnit',
                  Cell: CellInputGopuffPercentageFunding,
                },
              ],
            },
      ].filter(Boolean) as Column<Row>[],
    [],
  );

  const [onChange, mutationLoading] = useOnGridChange<
    Row,
    UpdateDealLineScanDealMutation,
    UpdateDealLineScanDealMutationVariables
  >({
    data: itemRows,
    mutation: updateDealLineScanDealMutation,
    mapRowToMutationArgs,
    refetchQueries: [queryDealDetails],
  });

  return (
    <DataGrid<Row>
      columns={columns}
      data={itemRows}
      loadingMoreData={mutationLoading}
      onChange={onChange}
      showPagination={false}
    />
  );
};
