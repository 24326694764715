enum PackageTypeName {
  Inner = 'Inner',
  Case = 'Case',
  MasterCase = 'Mastercase',
  Pallet = 'Pallet',
  Each = 'Each',
}

// Ordered from smallest to largest package types
const PACKAGE_TYPE_HIERARCHY = [
  PackageTypeName.Each,
  PackageTypeName.Inner,
  PackageTypeName.Case,
  PackageTypeName.MasterCase,
  PackageTypeName.Pallet,
];

export { PackageTypeName, PACKAGE_TYPE_HIERARCHY };
