import './setup/datadog';
import './setup/wdyr';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from 'pages/App';
import { Providers } from 'setup/providers';

import 'shared/css/global.css';

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);
