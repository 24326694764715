import { CostDateInput, UpdateCostDatesMutationVariables } from 'shared/types';
import { MappedRow, PackageTypeIdNameMap, Row } from 'pages/CostManagementV2/types';
import { PackageTypeName } from 'shared/constants/package';

const mapRowToMutationArgs = (
  row: Row,
  updateChunk: Record<string, any>,
  mappedRow: MappedRow,
  { packageTypeIdNameMap }: { packageTypeIdNameMap: PackageTypeIdNameMap },
): UpdateCostDatesMutationVariables => {
  const updateColumnName = Object.keys(updateChunk)[0];

  if (mappedRow.isUnsubmitted) {
    return {
      input: [
        {
          costManagementId: mappedRow.costManagementId,
          currencyId: mappedRow.currency,
          costPackagings: [
            {
              packageTypeId: Number(packageTypeIdNameMap[updateColumnName as PackageTypeName]),
              value: Number(updateChunk[updateColumnName]),
            },
          ],
          effectiveDate: mappedRow.effectiveDate!,
          type: mappedRow.type,
          endDate: mappedRow.endDate,
        },
      ],
    };
  }
  const currentCostDate = row.costDates.find(({ id }) => id === mappedRow.costId)!;
  let mappedUpdatedChuck: Partial<CostDateInput>;

  if (updateColumnName === 'effectiveDate' || updateColumnName === 'endDate') {
    mappedUpdatedChuck = updateChunk;
  } else if (updateColumnName !== 'currency') {
    let found = false;

    mappedUpdatedChuck = {
      costPackagings: currentCostDate.costPackagings.map(({ packageTypeId, value }) => {
        let updatedValue = value;

        if (packageTypeId === packageTypeIdNameMap[updateColumnName as PackageTypeName]) {
          updatedValue = Number(updateChunk[updateColumnName]);
          found = true;
        }

        return {
          costDateId: currentCostDate.id,
          packageTypeId: Number(packageTypeId),
          value: updatedValue,
        };
      }),
    };

    if (!found) {
      mappedUpdatedChuck.costPackagings = [
        ...mappedUpdatedChuck.costPackagings!,
        {
          costDateId: currentCostDate.id,
          packageTypeId: Number(packageTypeIdNameMap[updateColumnName as PackageTypeName]),
          value: Number(updateChunk[updateColumnName]),
        },
      ];
    }
  }

  return {
    input:
      updateColumnName === 'currency'
        ? row.costDates.map(({ costManagementId, costPackagings, effectiveDate, id }) => ({
            id,
            costManagementId,
            effectiveDate,
            costPackagings: costPackagings.map(({ __typename, packageTypeId, ...props }) => ({
              ...props,
              packageTypeId: Number(packageTypeId),
            })),
            currencyId: updateChunk[updateColumnName] as string,
          }))
        : [
            {
              id: mappedRow.costId,
              costManagementId: mappedRow.costManagementId,
              effectiveDate: mappedRow.effectiveDate!,
              endDate: mappedRow.endDate,
              type: mappedRow.type,
              costPackagings: currentCostDate?.costPackagings.map(
                ({ costDateId, packageTypeId, value }) => ({
                  costDateId,
                  packageTypeId: Number(packageTypeId),
                  value,
                }),
              ),
              currencyId: currentCostDate?.currency?.id,
              ...mappedUpdatedChuck!,
            },
          ],
  };
};

export { mapRowToMutationArgs };
