import { FC } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import styles from './PackagingTypeError.module.css';

const PackagingTypeError: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.iconWrapper}>
      <WarningAmberIcon className={styles.icon} />
    </div>
    <div>
      <div className={styles.line}>Packaging type not available for all selections.</div>
      <div>Only selections with packaging type available will be updated.</div>
    </div>
  </div>
);

export { PackagingTypeError };
