import { difference } from 'ramda';
import { Row as TableRow } from 'react-table';

import { Option, SupplierAssortmentInput } from 'shared/types';
import { Row } from 'pages/SupplierAssortment/types';

type Options = Option<string, string>[] | null;

const mapRowAndOptionToMutationInput = (
  row: Row,
  option: Option<string, string>,
  managedById: string | null | undefined,
  updateLocationFieldName: string,
): SupplierAssortmentInput => ({
  itemID: row.item?.id ?? '',
  opcoID: row.opco?.id ?? '',
  managerID: managedById ?? '',
  ...{
    [updateLocationFieldName]: option.value,
  },
});

const mapDataToMassEditMutationArgs = ({
  data,
  defaultManagedBy,
  defaultOptions,
  isFirstSave,
  managedById,
  newOptions,
  newRows,
  selectedRows,
  updateLocationFieldName,
}: {
  newOptions: Options;
  defaultOptions: Options;
  data: Row[];
  newRows: Row[];
  selectedRows: TableRow<Row>[];
  managedById: string | null | undefined;
  defaultManagedBy: string | null;
  updateLocationFieldName: 'itemID' | 'opcoID';
  isFirstSave: boolean;
}) => {
  const upsertAssortments: SupplierAssortmentInput[] = [];
  const removeAssortments: SupplierAssortmentInput[] = [];
  const deletedOptions = difference(defaultOptions || [], newOptions || []);
  const addedOptions = difference(newOptions || [], defaultOptions || []);
  const [field, oppositeField]: [keyof Row, keyof Row] =
    updateLocationFieldName === 'itemID' ? ['item', 'opco'] : ['opco', 'item'];

  [...selectedRows, ...newRows].forEach(row => {
    const originalRow: Row = (row as TableRow<Row>).original || row;
    deletedOptions.forEach(deletedOption => {
      if (originalRow[field].id === deletedOption.value) {
        removeAssortments.push(
          mapRowAndOptionToMutationInput(
            originalRow,
            deletedOption,
            managedById,
            updateLocationFieldName,
          ),
        );
      }
    });
  });

  data.forEach(row => {
    if (defaultManagedBy !== managedById) {
      (newOptions || []).forEach(addedOption => {
        upsertAssortments.push(
          mapRowAndOptionToMutationInput(row, addedOption, managedById, updateLocationFieldName),
        );
      });
    } else if (isFirstSave) {
      (newOptions || []).forEach(addedOption => {
        if (
          !selectedRows.find(
            selectedRow =>
              selectedRow.original[oppositeField]?.id === row[oppositeField]?.id &&
              selectedRow.original[field]?.id === addedOption.value,
          )
        ) {
          upsertAssortments.push(
            mapRowAndOptionToMutationInput(row, addedOption, managedById, updateLocationFieldName),
          );
        }
      });
    } else {
      addedOptions.forEach(addedOption => {
        upsertAssortments.push(
          mapRowAndOptionToMutationInput(row, addedOption, managedById, updateLocationFieldName),
        );
      });
    }
  });

  return {
    input: {
      upsertAssortments,
      removeAssortments,
    },
  };
};

export { mapDataToMassEditMutationArgs };
