import { Dispatch, SetStateAction, useCallback } from 'react';
import { Row as TableRow } from 'react-table';

import { memoCell } from 'shared/utils';
import { CellProps } from 'shared/types';
import { DeleteIconButton } from 'shared/components/IconButton';

import styles from './FactoryCellDelete.module.css';

const FactoryCellDelete = <DataType extends object>({
  setData,
}: {
  setData: Dispatch<SetStateAction<TableRow<DataType>[]>>;
}) =>
  memoCell<CellProps<DataType, string[]>>(props => {
    const {
      row: { index },
    } = props;

    const onClick = useCallback(
      () =>
        setData(previousData => {
          const previousDataCopy = [...previousData];
          previousDataCopy.splice(index, 1);

          return previousDataCopy;
        }),
      [setData],
    );
    // @ts-ignore
    if (props.row?.isSubRow) return null;

    return <DeleteIconButton className={styles.delete} onClick={onClick} />;
  });

export { FactoryCellDelete };
