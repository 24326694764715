import { Row, UpDatedChuck } from 'pages/SupplierAssortment/types';
import { UpdateSupplierAssortmentMutationVariables } from 'shared/types';

const mapRowToMutationArgs = (
  row: Row,
  updatedChuck: UpDatedChuck,
): UpdateSupplierAssortmentMutationVariables => {
  const { managedBy } = updatedChuck;

  return {
    input: [
      {
        id: row.id,
        managerID: managedBy,
      },
    ],
  };
};

export { mapRowToMutationArgs };
