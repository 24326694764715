const downloadBase64StringAsFile = (content: string, filename: string, fileType = 'csv') => {
  const link = document.createElement('a');
  if (fileType === 'csv') {
    const byteCharacters = Buffer.from(content, 'base64').toString('utf-8');
    const blob = new Blob([byteCharacters], { type: 'text/csv;charset=UTF-8' });
    link.href = window.URL.createObjectURL(blob);
  } else if (fileType === 'excel') {
    const pre = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    link.href = pre + content;
  }
  link.download = filename;
  link.click();
};

export { downloadBase64StringAsFile };
