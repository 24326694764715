import { useCallback, useState } from 'react';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';

import { memoCell } from 'shared/utils';
import { CellProps } from 'shared/types/props';
import { shouldDisable } from 'shared/components/DataGrid/components/Cells/utils';
import { DatePicker } from 'shared/components/DatePicker';

const FactoryCellDatePicker = <
  DataType extends object,
  CellValue = number | null,
  ObjectValue extends object | CellValue = CellValue,
>(
  cellArguments: {
    dependencies?: (keyof DataType)[];
    pickValue?: (value: ObjectValue) => CellValue;
    isDisabled?: boolean | ((rowData: DataType) => boolean);
    disableKeyboardInput?: boolean;
    deriveDisabledDates?: (rowData: DataType) => number[];
    deriveHasDependenciesChanged?: (previousRowData: DataType, nextRowData: DataType) => boolean;
    deriveDateRange?: (rowData: DataType) => {
      minDate: Date | undefined;
      maxDate: Date | undefined;
    };
    isBlankCell?: (rowData: DataType) => boolean;
    shouldDisableDate?: (day: Date) => boolean;
    formatDateToCellValue?: (value: Date | null) => CellValue;
    formatCellValueToDate?: (value: CellValue) => Date | null;
  } = { dependencies: [] },
) =>
  memoCell<CellProps<DataType, CellValue, ObjectValue>>(
    cellProps => {
      const {
        column: { id: columnId },
        row,
        updateGridData,
        value: initialValue,
      } = cellProps;
      const {
        deriveDateRange,
        deriveDisabledDates,
        disableKeyboardInput = false,
        isBlankCell,
        isDisabled,
        pickValue,
        shouldDisableDate,
        formatDateToCellValue,
        formatCellValueToDate,
      } = cellArguments;
      const { index, original, state } = row;
      const disabled =
        typeof isDisabled === 'boolean' ? isDisabled : shouldDisable(cellProps, isDisabled);
      const blankCell = isBlankCell?.(row.original);
      const mapInitialValue = pickValue ? pickValue(initialValue) : (initialValue as CellValue);

      // The CellValue might be number (unix timestamp) or string (ISO date YYYY-MM-DD)
      // But the DatePicker component expects Date object internally
      const [value, setValue] = useState<Date | null>(() => {
        if (!mapInitialValue) {
          return null;
        }
        if (formatCellValueToDate) {
          return formatCellValueToDate(mapInitialValue);
        }

        // we know for sure it is a number because that's CellValue default type
        return fromUnixTime(mapInitialValue as unknown as number);
      });
      const [valueBeforeChange, setValueBeforeChange] = useState(value);
      const disabledDates = deriveDisabledDates?.(row.original);
      const { maxDate, minDate } = deriveDateRange?.(row.original) ?? {};

      const onSubmit = useCallback(
        (newDate: Date | null) => {
          setValue(newDate);

          if (valueBeforeChange !== newDate) {
            // Convert from Date type (internal to the date picker) to CellValue type (the type used by the grid)
            const newDateForUpdate = newDate && (formatDateToCellValue ?? getUnixTime)(newDate);
            updateGridData(index, columnId, newDateForUpdate as CellValue, original, row, state);
          }

          setValueBeforeChange(newDate);
        },
        [
          updateGridData,
          index,
          columnId,
          value,
          original,
          row,
          state,
          setValueBeforeChange,
          valueBeforeChange,
        ],
      );

      return blankCell ? (
        <></>
      ) : (
        <DatePicker
          disabled={disabled}
          disabledDates={disabledDates}
          disableKeyboardInput={disableKeyboardInput}
          label={null}
          maxDate={maxDate}
          minDate={minDate}
          onSubmit={onSubmit}
          shouldDisableDate={shouldDisableDate}
          value={value}
        />
      );
    },
    cellArguments.dependencies,
    false,
    cellArguments.deriveHasDependenciesChanged,
  );

export { FactoryCellDatePicker };
