import { ChangeEvent, FocusEventHandler, forwardRef, useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { numberContainsOnlyPointZeros } from 'shared/utils';

import { InputBase } from '../InputBase';

import { inputTypes, validators } from './constants';
import { InputProps } from './types';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      defaultValue,
      max,
      min,
      onBlur,
      onChange,
      placeholder = 'Enter',
      type,
      value: outerValue,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState(() => String(outerValue ?? defaultValue ?? ''));
    const [lastValidValue, setLastValidValue] = useState(value);

    const handleBlur = useMemo<FocusEventHandler<HTMLInputElement> | undefined>(
      () =>
        min
          ? event => {
              setValue(newValue => {
                if (numberContainsOnlyPointZeros(newValue)) {
                  return lastValidValue;
                }
                setLastValidValue(newValue);

                return newValue;
              });
              onBlur?.(event);
            }
          : onBlur,
      [min, onBlur, lastValidValue],
    );

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        let correctedValue: string | number = event.currentTarget.value;

        if (type && validators[type]) {
          correctedValue = correctedValue.replace(validators[type]!, '');
        } else if (type === inputTypes.Money) {
          const valueParts = correctedValue.replace(validators[inputTypes.Float]!, '').split('.');
          correctedValue = `${valueParts.shift()}${
            valueParts.length ? `.${valueParts.join('')}` : ''
          }`;
          correctedValue =
            correctedValue.indexOf('.') >= 0
              ? correctedValue.substr(0, correctedValue.indexOf('.')) +
                correctedValue.substr(correctedValue.indexOf('.'), 3)
              : correctedValue;
        }

        if (min && correctedValue < min && !numberContainsOnlyPointZeros(correctedValue)) {
          correctedValue = min;
        }

        if (max && correctedValue > max) {
          correctedValue = max;
        }

        correctedValue = String(correctedValue);

        // eslint-disable-next-line no-param-reassign
        event.target.value = correctedValue;

        setValue(correctedValue);

        if (!(min && numberContainsOnlyPointZeros(correctedValue))) {
          onChange?.({
            ...event,
            currentTarget: { ...event.currentTarget, value: correctedValue },
          });
        }
      },
      [onChange, min, max, type],
    );

    useUpdateEffect(() => {
      const newValue = String(outerValue ?? '');
      setValue(newValue);
      setLastValidValue(newValue);
    }, [outerValue]);

    return (
      <InputBase
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        {...props}
        ref={ref}
        value={value}
      />
    );
  },
);

export { Input };
