import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { NationalAssortment } from 'shared/types';

const makeNationalAssortment = (
  props: O.Partial<NationalAssortment, 'deep'> = {},
): NationalAssortment =>
  merge<NationalAssortment, O.Partial<NationalAssortment, 'deep'>>(
    {
      id: '1',
      name: 'Custom',
      description: 'Description...',
      __typename: 'NationalAssortment',
    },
    props,
  );

export { makeNationalAssortment };
