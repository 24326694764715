import { UseComboboxProps } from 'downshift';
import { useCallback, useState } from 'react';

import { SelectOption } from '../types';

const useSearch = ({ options }: { options: SelectOption[] }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchInputValue, setSearchInputValue] = useState('');

  const onInputValueChange = useCallback<
    NonNullable<UseComboboxProps<SelectOption>['onInputValueChange']>
  >(
    ({ inputValue = '' }) => {
      setSearchInputValue(inputValue);
      setFilteredOptions(
        options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase())),
      );
    },
    [options],
  );

  const clearSearch = useCallback(() => {
    setSearchInputValue('');
    setFilteredOptions(options);
  }, [options]);

  return {
    clearSearch,
    filteredOptions,
    searchInputValue,
    onInputValueChange,
  };
};

export { useSearch };
