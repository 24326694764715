import { format, fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { MappedRow, Row } from '../types';

const formatNote = (note: Row['note'], notedBy: Row['notedBy']) => {
  if (!note) {
    return '';
  }

  const notedByDisplayName = notedBy?.displayName ?? '';

  return `${notedByDisplayName}${notedByDisplayName ? ': ' : ''} ${note}`;
};

const mapDataToRows = (data: Row[]): MappedRow[] =>
  data.map(row => {
    const dealRow = {
      id: row.id,
      offerNumber: row.offerNumber,
      status: row.status,
      parentCompanyName: row.parentCompany.name,
      submitterName: row.createdBy.displayName,
      submitterEmail: row.createdBy.email,
      description: row.description,
      type: row.type.name,
      startDate: formatInTimeZone(fromUnixTime(row.startTime), row.startTimeZone, 'MM/dd/yyyy'),
      endDate: formatInTimeZone(fromUnixTime(row.endTime), row.endTimeZone, 'MM/dd/yyyy'),
      approvedBy: row.approvedBy?.displayName ?? '',
      approvedDate: row.approvedAt ? format(fromUnixTime(row.approvedAt), 'MM/dd/yyyy') : '',
      updatedDate: format(fromUnixTime(row.updatedAt), 'MM/dd/yyyy'),
      updatedBy: row.updatedBy.displayName,
      note: formatNote(row.note, row.notedBy),

      // used for deal details download only
      country: row.country,
      startTimeZone: row.startTimeZone,
      endTimeZone: row.endTimeZone,
      currency: row.currency?.code ?? '',
    };

    return dealRow;
  });

export { mapDataToRows };
