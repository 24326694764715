import { gql } from '@apollo/client';

export const NATIONAL_ASSORTMENTS_MUTATION = gql`
  mutation NationalAssortmentMutation($input: NationalAssortmentsInput!) {
    upsertNationalAssortments(input: $input) {
      assortments {
        id
        name
        description
      }
    }
  }
`;

export const DELETE_NATIONAL_ASSORTMENTS_MUTATION = gql`
  mutation DeleteNationalAssortmentsMutation($ids: [ID!]!) {
    deleteNationalAssortments(ids: $ids)
  }
`;

export const UPSERT_ALLOCATION_PRIORITIES_MUTATION = gql`
  mutation UpsertAllocationPrioritiesMutation($input: AllocationPrioritiesInput!) {
    upsertAllocationPriorities(input: $input) {
      priorities {
        id
        name
        description
      }
    }
  }
`;

export const DELETE_ALLOCATION_PRIORITIES_MUTATION = gql`
  mutation DeleteAllocationPrioritiesMutation($ids: [ID!]!) {
    deleteAllocationPriorities(ids: $ids)
  }
`;

export const CUSTOM_ASSORTMENTS_MUTATION = gql`
  mutation CustomAssortmentsMutation($input: CustomAssortmentsInput!) {
    upsertCustomAssortments(input: $input) {
      assortments {
        id
        name
        description
      }
    }
  }
`;

export const DELETE_CUSTOM_ASSORTMENTS_MUTATION = gql`
  mutation DeleteCustomAssortmentsMutation($ids: [ID!]!) {
    deleteCustomAssortments(ids: $ids)
  }
`;

export const CAPABILITIES_MUTATION = gql`
  mutation CapabilitiesMutation($input: CapabilitiesInput!) {
    upsertCapabilities(input: $input) {
      capabilities {
        id
        name
        description
      }
    }
  }
`;

export const DELETE_CAPABILITIES_MUTATION = gql`
  mutation DeleteCapabilitiesMutation($ids: [ID!]!) {
    deleteCapabilities(ids: $ids)
  }
`;

export const TOBACCO_TYPES_MUTATION = gql`
  mutation TobaccoTypesMutation($input: TobaccoTypesInput!) {
    upsertTobaccoTypes(input: $input) {
      types {
        id
        name
        description
      }
    }
  }
`;

export const DELETE_TOBACCO_TYPES_MUTATION = gql`
  mutation DeleteTobaccoTypesMutation($ids: [ID!]!) {
    deleteTobaccoTypes(ids: $ids)
  }
`;

export const ALCOHOL_TYPES_MUTATION = gql`
  mutation AlcoholTypesMutation($input: AlcoholTypesInput!) {
    upsertAlcoholTypes(input: $input) {
      types {
        id
        name
        description
        volumes {
          id
          volume
        }
      }
    }
  }
`;

export const DELETE_ALCOHOL_TYPES_MUTATION = gql`
  mutation DeleteAlcoholTypesMutation($ids: [ID!]!) {
    deleteAlcoholTypes(ids: $ids)
  }
`;

export const DELETE_ALCOHOL_ABV_MUTATION = gql`
  mutation DeleteAlcoholAbvMutation($ids: [ID!]!) {
    deleteAlcoholABV(ids: $ids)
  }
`;
