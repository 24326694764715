import React, { FC } from 'react';
import {
  StyledEngineProvider,
  ThemeProvider as ThemeProviderBase,
  createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import styles from './ThemeProvider.module.css';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bold1: true;
  }
}

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          fontSize: '0.875rem',
          fontWeight: 700,
          height: '32px',
          padding: '8px 20px',
        },
        outlined: {
          color: '#51A0F7',
        },
        contained: {
          backgroundColor: '#51A0F7',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        classes: { tooltip: styles.tooltip, arrow: styles.tooltipArrow },
        placement: 'top',
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'bold1' },
          style: { fontWeight: 700 },
        },
      ],
    },
  },
  typography: {
    fontSize: 16,
    h3: {
      color: '#434E6B',
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 700,
      letterSpacing: '0.25px',
      color: '#434E6B',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '0.625rem',
      color: '#434e6b',
    },
    overline: {
      fontSize: '0.625rem',
      fontWeight: 700,
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
  },
});

const ThemeProvider: FC = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProviderBase theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
    </ThemeProviderBase>
  </StyledEngineProvider>
);

export { ThemeProvider };
