/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useMutation, useQuery } from '@apollo/client';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import {
  constants as dealManagementConstants,
  mapDataToRows,
  mapRowsForDownload,
} from 'pages/DealManagement';
import {
  deleteDealsMutationGraphql,
  rescindDealsMutationGraphql,
} from 'shared/operations/mutation';
import {
  DealApprovalStatus,
  DealDetailsQuery,
  DealDetailsQueryVariables,
  DealRescindStatus,
  DealStatus,
  DeleteDealsMutation,
  DeleteDealsMutationVariables,
  RescindDealsMutation,
  RescindDealsMutationVariables,
  UpdateDealInput,
  UpdateDealsApprovalStatusMutation,
  UpdateDealsApprovalStatusMutationVariables,
  UpdateDealsMutation,
  UpdateDealsMutationVariables,
} from 'shared/types';
import { useRole } from 'shared/hooks';
import {
  ROLE_ASSIGNED_INTERNAL_USER,
  ROLE_EXTERNAL_USER,
} from 'shared/constants/access/permissions';
import { currentTimeHoursDifference } from 'shared/utils/currentTimeHoursDifference';
import {
  ExportXlsxFileInput,
  XlsxFileSheet,
  exportXlsxFile,
} from 'shared/tools/excel/exportXlsxFile';

import {
  queryDealDetails,
  updateDealApprovalStatusMutation,
  updateDealsMutation,
} from '../operations';
import { RouteParams, UseSetupResult } from '../types';

export const useSetup = (): UseSetupResult => {
  const role = useRole();

  const [isFirstLoadingFinish, setIsFirstLoadingFinish] = useState(false);
  const [shouldShowCommentsModal, setShouldShowCommentsModal] = useState<boolean>(false);
  const [commentsRequired, setCommentsRequired] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<ReactNode>();
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState<boolean>(false);
  const [rescindMessage, setRescindMessage] = useState<ReactNode>();
  const [shouldShowRescindModal, setShouldShowRescindModal] = useState<boolean>(false);
  const [redirectToDealManagement, setRedirectToDealManagement] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [canApproveAndReject, setCanApproveAndReject] = useState<boolean>(false);
  const [canRescindDeals, setCanRescindDeals] = useState<boolean>(false);
  const [targetStatus, setTargetStatus] = useState<DealApprovalStatus>(DealApprovalStatus.Rejected);
  const [canChangePricingChannels, setCanChangePricingChannels] = useState<boolean>(false);

  const { id } = useParams<RouteParams>();
  const { data, refetch } = useQuery<DealDetailsQuery, DealDetailsQueryVariables>(
    queryDealDetails,
    {
      variables: {
        filter: {
          ids: [id],
        },
      },
      onCompleted: () => {
        setIsFirstLoadingFinish(true);
      },
    },
  );

  const deal = data?.deals?.records[0];

  useEffect(() => {
    if (
      ROLE_EXTERNAL_USER.has(role) &&
      (deal?.status === DealStatus.Pending || deal?.status === DealStatus.Rejected)
    ) {
      setCanDelete(true);
    } else if (ROLE_ASSIGNED_INTERNAL_USER.has(role) && deal?.status === DealStatus.Rejected) {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }

    if (
      ROLE_EXTERNAL_USER.has(role) &&
      (deal?.status === DealStatus.Current || deal?.status === DealStatus.Future) &&
      deal?.rescindStatus === null
    ) {
      setCanRescindDeals(true);
    } else {
      setCanRescindDeals(false);
    }

    if (
      ROLE_ASSIGNED_INTERNAL_USER.has(role) &&
      (deal?.status === DealStatus.Pending || deal?.rescindStatus === DealRescindStatus.Pending)
    ) {
      setCanApproveAndReject(true);
    } else {
      setCanApproveAndReject(false);
    }

    if (
      ROLE_ASSIGNED_INTERNAL_USER.has(role) &&
      deal?.status !== DealStatus.Rescinded &&
      deal?.status !== DealStatus.Past
    ) {
      setCanChangePricingChannels(true);
    } else {
      setCanChangePricingChannels(false);
    }
  }, [deal]);

  const [deleteDealsMutation, { loading: deleteDealsLoading }] = useMutation<
    DeleteDealsMutation,
    DeleteDealsMutationVariables
  >(deleteDealsMutationGraphql, {});

  const [rescindDealsMutation, { loading: rescindDealsLoading }] = useMutation<
    RescindDealsMutation,
    RescindDealsMutationVariables
  >(rescindDealsMutationGraphql, {});

  const [updateDealsApprovalStatus, { loading: updateDealsApprovalStatusLoading }] = useMutation<
    UpdateDealsApprovalStatusMutation,
    UpdateDealsApprovalStatusMutationVariables
  >(updateDealApprovalStatusMutation, {});

  const [updateDeals, { loading: updateDealsLoading }] = useMutation<
    UpdateDealsMutation,
    UpdateDealsMutationVariables
  >(updateDealsMutation, {});

  const updateDealsCallback = (deals: UpdateDealInput[]) =>
    updateDeals({
      variables: {
        input: deals,
      },
      onCompleted: () => {
        void refetch();
      },
    });

  const updateDealApprovalStatusCallback = (
    dealIds: string[],
    status: DealApprovalStatus,
    comment?: string,
  ) =>
    updateDealsApprovalStatus({
      variables: {
        input: {
          ids: dealIds,
          approvalStatus: status,
          note: comment,
        },
      },
      onCompleted: () => {
        void refetch();
      },
    });

  const onSubmitPricingChannelsClick = useCallback(
    ({ pricingChannelIDs }: { pricingChannelIDs: UpdateDealInput['pricingChannelIDs'] }) => {
      const currentPricingChannels = deal?.pricingChannels ?? [];
      const currentPricingChannelIDs =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
        currentPricingChannels.map(pricingChannel => pricingChannel?.id) ?? [];
      if (
        deal?.id &&
        pricingChannelIDs &&
        pricingChannelIDs.length > 0 &&
        _.isEqual(_.sortBy(currentPricingChannelIDs), _.sortBy(pricingChannelIDs)) === false
      ) {
        void updateDealsCallback([
          {
            id: deal?.id,
            pricingChannelIDs,
          },
        ]);
      }
    },
    [deal, updateDealApprovalStatusCallback],
  );

  const onApproveOrRejectClick = useCallback(
    (status: DealApprovalStatus.Approved | DealApprovalStatus.Rejected) => {
      setShouldShowCommentsModal(true);
      setTargetStatus(status);
      if (status === DealApprovalStatus.Rejected) {
        setCommentsRequired(true);
      } else {
        setCommentsRequired(false);
      }
    },
    [setShouldShowCommentsModal],
  );

  const onCloseCommentsModalClick = useCallback(() => {
    setShouldShowCommentsModal(false);
  }, [setShouldShowCommentsModal]);

  const onSubmitCommentsModalClick = useCallback(
    ({ comments }: { comments: string }) => {
      if (deal?.id) {
        void updateDealApprovalStatusCallback([deal?.id], targetStatus, comments);
      }
      onCloseCommentsModalClick();
    },
    [deal, updateDealApprovalStatusCallback, onCloseCommentsModalClick],
  );

  const onRescindDealClick = useCallback(() => {
    const hours = currentTimeHoursDifference(deal?.startTime ?? 0);
    let message = <div>Are you sure to rescind the deal offer #{deal?.offerNumber}? </div>;

    // if now is less than 24 hours before the deal start time, then hours >=-24 and hours < 0
    // if now is greater than the deal start time, then hours >= 0
    if (hours >= -24) {
      message = (
        <div>
          The deal identified by offer #{deal?.offerNumber}{' '}
          {hours < 0 ? 'is scheduled to begin in less than 24 hours' : 'is currently active'}.
          Rescinding this deal requires a review by a Gopuff employee. Are you sure you want to
          proceed with rescinding the deal?
        </div>
      );
    }
    setShouldShowRescindModal(true);
    setRescindMessage(message);
  }, [deal]);

  const onCloseRescindModalClick = useCallback(() => {
    setShouldShowRescindModal(false);
    setRescindMessage(undefined);
  }, [setShouldShowRescindModal, setRescindMessage]);

  const rescindDealsCallback = (dealIds: string[]) =>
    rescindDealsMutation({
      variables: {
        input: dealIds,
      },
      onCompleted: () => {
        void refetch();
      },
    });

  const onConfirmRescindModalClick = useCallback(() => {
    if (deal?.id) {
      void rescindDealsCallback([deal.id]);
    }
    setShouldShowRescindModal(false);
  }, [deal]);

  const onDeleteClick = useCallback(() => {
    const message = <div>Are you sure to delete the deal offer #{deal?.offerNumber}?</div>;

    setShouldShowDeleteModal(true);
    setDeleteMessage(message);
  }, [deal]);

  const onCloseDeleteModalClick = useCallback(() => {
    setShouldShowDeleteModal(false);
    setDeleteMessage(undefined);
  }, [setShouldShowDeleteModal, setDeleteMessage]);

  const deleteDealsCallback = (dealIds: string[]) =>
    deleteDealsMutation({
      variables: {
        input: dealIds,
      },
      onCompleted: () => {
        setRedirectToDealManagement(true);
      },
    });

  const onConfirmDeleteModalClick = useCallback(() => {
    if (deal?.id) {
      void deleteDealsCallback([deal.id]);
    }
  }, [deal]);

  const onDownloadButtonClick = useCallback(() => {
    const itemTableHeaders = [
      dealManagementConstants.download.columns.lines.eachUPC,
      dealManagementConstants.download.columns.lines.itemGopuffId,
      dealManagementConstants.download.columns.lines.itemName,
      dealManagementConstants.download.columns.lines.scanDeal.vendorFundingPerUnit,
    ];
    if (ROLE_ASSIGNED_INTERNAL_USER.has(role)) {
      itemTableHeaders.push(
        dealManagementConstants.download.columns.lines.scanDeal.dollarOffStrikethrough,
        dealManagementConstants.download.columns.lines.scanDeal.percentageOffStrikethrough,
      );
    }

    const exportInput: ExportXlsxFileInput = {
      fileName: `Deal ${deal?.offerNumber} (${new Date().toLocaleString()})`,
      sheets: [
        {
          sheetName: 'deal',
          tables: [
            {
              headers: [
                dealManagementConstants.download.columns.header.offerNumber,
                dealManagementConstants.download.columns.header.status,
                dealManagementConstants.download.columns.header.parentCompany,
                dealManagementConstants.download.columns.header.description,
                dealManagementConstants.download.columns.header.type,
                dealManagementConstants.download.columns.header.startDate,
                dealManagementConstants.download.columns.header.startTimeZone,
                dealManagementConstants.download.columns.header.endDate,
                dealManagementConstants.download.columns.header.endTimeZone,
                dealManagementConstants.download.columns.header.locations,
                dealManagementConstants.download.columns.header.currency,
                dealManagementConstants.download.columns.header.submitterName,
                dealManagementConstants.download.columns.header.submitterEmail,
                dealManagementConstants.download.columns.header.approvedBy,
                dealManagementConstants.download.columns.header.approvedDate,
                dealManagementConstants.download.columns.header.updatedBy,
                dealManagementConstants.download.columns.header.updatedDate,
                dealManagementConstants.download.columns.header.note,
              ],
              rows: mapRowsForDownload(mapDataToRows([deal!])),
            },
            {
              headers: itemTableHeaders,
              rows:
                deal?.lines.map(line => {
                  const row: Record<string, unknown> = {};
                  row[dealManagementConstants.download.columns.lines.eachUPC.displayName] =
                    line.item.upc.join(', ');
                  row[dealManagementConstants.download.columns.lines.itemGopuffId.displayName] =
                    line.item.extId;
                  row[dealManagementConstants.download.columns.lines.itemName.displayName] =
                    line.item.name;
                  row[
                    dealManagementConstants.download.columns.lines.scanDeal.vendorFundingPerUnit.displayName
                  ] = line.scanDeal.vendorFundingPerUnit;
                  row[
                    dealManagementConstants.download.columns.lines.scanDeal.dollarOffStrikethrough.displayName
                  ] = line.scanDeal.gopuffDollarFundingPerUnit;
                  row[
                    dealManagementConstants.download.columns.lines.scanDeal.percentageOffStrikethrough.displayName
                  ] =
                    line.scanDeal.gopuffPercentageFundingPerUnit &&
                    line.scanDeal.gopuffPercentageFundingPerUnit / 100;
                  return row;
                }) ?? [],
            },
          ],
        },
      ],
    };

    if (!deal?.country) {
      const locationsSheet: XlsxFileSheet = {
        sheetName: 'locations',
        arrangeTablesHorizontally: true,
        tables: [],
      };
      if (deal?.locations) {
        locationsSheet.tables.push({
          headers: [
            dealManagementConstants.download.columns.locations.name,
            dealManagementConstants.download.columns.locations.id,
          ],
          rows: deal.locations.map(location => ({
            [dealManagementConstants.download.columns.locations.name.displayName]: location.name,
            [dealManagementConstants.download.columns.locations.id.displayName]: location.extId,
          })),
        });
      }
      if (deal?.locationGroups) {
        locationsSheet.tables.push({
          headers: [
            dealManagementConstants.download.columns.locationGroups.name,
            dealManagementConstants.download.columns.locationGroups.locationIds,
          ],
          rows: deal.locationGroups.map(locationGroup => ({
            [dealManagementConstants.download.columns.locationGroups.name.displayName]:
              locationGroup.name,
            [dealManagementConstants.download.columns.locationGroups.locationIds.displayName]:
              locationGroup.locations.map(location => location.extId).join(', '),
          })),
        });
      }
      exportInput.sheets.push(locationsSheet);
    }

    exportXlsxFile(exportInput);
  }, [
    deal,
    dealManagementConstants,
    mapRowsForDownload,
    exportXlsxFile,
    ROLE_ASSIGNED_INTERNAL_USER,
    role,
  ]);

  return {
    isFirstLoadingFinish,
    deal,
    updateDealsApprovalStatusLoading,
    updateDealsLoading,
    shouldShowCommentsModal,
    commentsRequired,
    canApproveAndReject,
    canDelete,
    canChangePricingChannels,
    canRescindDeals,
    deleteDealsLoading,
    rescindDealsLoading,
    deleteMessage,
    shouldShowDeleteModal,
    rescindMessage,
    shouldShowRescindModal,
    redirectToDealManagement,
    onApproveOrRejectClick,
    onSubmitCommentsModalClick,
    onSubmitPricingChannelsClick,
    onCloseCommentsModalClick,
    onRescindDealClick,
    onCloseRescindModalClick,
    onConfirmRescindModalClick,
    onConfirmDeleteModalClick,
    onDeleteClick,
    onCloseDeleteModalClick,
    onDownloadButtonClick,
  };
};
