import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { TobaccoType } from 'shared/types';

import { replicateN } from '../utils';
import { makeTobaccoType } from '../factories';

const tobaccoTypes = (props: O.Partial<TobaccoType, 'deep'>[] = []): TobaccoType[] =>
  mergeDeepArrays<TobaccoType>(
    replicateN(makeTobaccoType(), 5, {
      name: [, 'NVP', 'TFV', 'MFV', 'FT'],
    }),
    props,
  );

export { tobaccoTypes };
