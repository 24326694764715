import { addMinutes, fromUnixTime, getUnixTime } from 'date-fns';

import {
  CostPackaging,
  ItemSource,
  ItemSourcePacking,
  MappedRow,
  PackageTypeIdNameMap,
  Row,
  UnsubmittedRows,
} from 'pages/CostManagementV2/types';
import { PackageTypeName } from 'shared/constants/package';
import { CostDateStatus, CostDateType } from 'shared/types';

import { deriveZeroIdRowKey } from '../../utils';

const findCostPackaging = (
  costPackagings: CostPackaging[],
  packageTypeName: PackageTypeName,
  packageTypeIdNameMap: PackageTypeIdNameMap,
): CostPackaging | undefined =>
  costPackagings.find(
    ({ packageTypeId }) => packageTypeId === packageTypeIdNameMap[packageTypeName],
  );

const findItemSourcePacking = (
  itemSourcePackings: ItemSourcePacking[],
  packageTypeName: PackageTypeName,
  packageTypeIdNameMap: PackageTypeIdNameMap,
): ItemSourcePacking | undefined =>
  itemSourcePackings.find(
    itemSourcePacking =>
      itemSourcePacking?.packageType?.id === packageTypeIdNameMap[packageTypeName],
  );

const findItemSourcesPacking = (
  itemSources: ItemSource[],
  packageTypeName: PackageTypeName,
  packageTypeIdNameMap: PackageTypeIdNameMap,
): ItemSourcePacking[] | undefined => {
  const itemSourcePackings: ItemSourcePacking[] = [];
  for (let i = 0; i < itemSources.length; i++) {
    const itemSourcePacking = findItemSourcePacking(
      itemSources[i] ? itemSources[i].packaging : [],
      packageTypeName,
      packageTypeIdNameMap,
    );
    if (itemSourcePacking) {
      itemSourcePackings.push(itemSourcePacking);
    }
  }

  return itemSourcePackings;
};

const timezoneAdjustedDate = (value: number): number => {
  // Add a offset to the initial value to avoid the date being set to the previous day
  // as dates are displayed in the user's timezone, but stored in UTC. The effective
  // date should always match the date that was uploaded.
  const unixTime = fromUnixTime(value);
  const tzAdjustedDate = addMinutes(unixTime, unixTime.getTimezoneOffset());
  return getUnixTime(tzAdjustedDate);
};

const getStatusOrder = (status: CostDateStatus): number => {
  switch (status) {
    case CostDateStatus.Current:
      return 1;
    case CostDateStatus.Future:
      return 2;
    case CostDateStatus.Past:
      return 3;
    default:
      return 4;
  }
};

const getTypeOrder = (status: CostDateType): number => {
  switch (status) {
    case CostDateType.Promo:
      return 1;
    case CostDateType.Regular:
      return 2;
    default:
      return 3;
  }
};

const formatComments = (comments?: string, commentedByDisplayName?: string) => {
  if (!comments) {
    return '';
  }
  return `${commentedByDisplayName}${commentedByDisplayName ? ': ' : ''} ${comments}`;
};

const formatValidation = (validation?: number, status?: CostDateStatus) => {
  if (
    status === CostDateStatus.Current ||
    typeof validation === 'undefined' ||
    validation === null
  ) {
    return '';
  }
  return `${validation.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
  })}`;
};

const mapDataToRows = (
  data: Row[],
  packageTypeIdNameMap: PackageTypeIdNameMap,
  canEdit: boolean,
  canEditIsDisabled?: boolean,
  unsubmittedRows?: UnsubmittedRows,
): MappedRow[] =>
  data.map(
    ({
      costDates: costDatesData,
      id,
      isDisabled,
      item: {
        class: itemClass,
        department: itemDepartment,
        i18n: itemI18n,
        id: itemId,
        internalId: internalItemId,
        name: itemName,
        parentCompany,
      },
      itemSources,
      location,
      opco,
      supplier: { id: supplierId, internalId: internalSupplierId, name: supplierName },
      updatedAt,
    }) => {
      const sortedCostDates = [...costDatesData].sort((a, b) =>
        a.status === CostDateStatus.Current && b.status === CostDateStatus.Current
          ? getTypeOrder(a.type) - getTypeOrder(b.type)
          : getStatusOrder(a.status) - getStatusOrder(b.status),
      );
      const costDates = sortedCostDates.slice(0);
      const costDate = costDates.length > 0 ? sortedCostDates[0] : null;
      const costPackagings = costDate?.costPackagings ?? [];

      const rowId =
        id === '0'
          ? deriveZeroIdRowKey({ itemSources } as Row)
          : `${id}${costDate ? `:${costDate.id}` : ''}`;
      const row: MappedRow = {
        id: rowId,
        costManagementId: id,
        itemId,
        itemName,
        itemDepartment,
        itemClass,
        i18n: itemI18n,
        locationId: location?.id,
        locationName: location?.name,
        locationAddress: location?.address,
        locationCity: location?.city,
        locationStateProvinceRegion: location?.stateProvinceRegion,
        locationPostalCode: location?.postalCode,
        opcoId: opco?.id,
        opcoName: opco?.name,
        opcoStateProvinceRegion: opco?.stateProvinceRegion || '',
        supplierId,
        supplierName,
        parentCompany,
        autoValidationEachCost: formatValidation(
          findCostPackaging(costPackagings, PackageTypeName.Each, packageTypeIdNameMap)
            ?.percentageChange,
          costDate?.status,
        ),
        autoValidationCaseCost: formatValidation(
          findCostPackaging(costPackagings, PackageTypeName.Case, packageTypeIdNameMap)
            ?.percentageChange,
          costDate?.status,
        ),
        effectiveDate: costDate?.effectiveDate
          ? timezoneAdjustedDate(costDate?.effectiveDate)
          : undefined,
        endDate: costDate?.endDate && timezoneAdjustedDate(costDate?.endDate),
        comments: formatComments(costDate?.comments, costDate?.commentedByDisplayName),
        internalItemId,
        internalSupplierId,
        internalLocationId: location?.internalId,
        eachUPC: findItemSourcesPacking(
          itemSources ?? [],
          PackageTypeName.Each,
          packageTypeIdNameMap,
        )?.flatMap(({ eachUPCs }) => eachUPCs)?.[0],
        caseUPC: findItemSourcesPacking(
          itemSources ?? [],
          PackageTypeName.Case,
          packageTypeIdNameMap,
        )?.map(({ upcCode }) => upcCode)?.[0],
        eachVIN: findItemSourcesPacking(
          itemSources ?? [],
          PackageTypeName.Each,
          packageTypeIdNameMap,
        )
          ?.map(({ supplierItemPackagingNumber }) => supplierItemPackagingNumber)
          ?.filter(vin => !!vin)?.[0],
        caseVIN: findItemSourcesPacking(
          itemSources ?? [],
          PackageTypeName.Case,
          packageTypeIdNameMap,
        )
          ?.map(({ supplierItemPackagingNumber }) => supplierItemPackagingNumber)
          ?.filter(vin => !!vin)?.[0],
        [PackageTypeName.Each]: findCostPackaging(
          costPackagings,
          PackageTypeName.Each,
          packageTypeIdNameMap,
        )?.value,
        [PackageTypeName.Inner]: findCostPackaging(
          costPackagings,
          PackageTypeName.Inner,
          packageTypeIdNameMap,
        )?.value,
        [PackageTypeName.Case]: findCostPackaging(
          costPackagings,
          PackageTypeName.Case,
          packageTypeIdNameMap,
        )?.value,
        [PackageTypeName.MasterCase]: findCostPackaging(
          costPackagings,
          PackageTypeName.MasterCase,
          packageTypeIdNameMap,
        )?.value,
        [PackageTypeName.Pallet]: findCostPackaging(
          costPackagings,
          PackageTypeName.Pallet,
          packageTypeIdNameMap,
        )?.value,
        currency: costDate?.currency?.id,
        costId: costDate?.id,
        updatedAt: costDate?.updatedAt,
        updatedBy: costDate?.updatedByDisplayName,
        costManagementUpdatedAt: updatedAt,
        isDisabled,
        status: costDate?.status ?? CostDateStatus.Undefined,
        type: costDate?.type,
        isAddLocationCostRow: false,
        isUnsubmitted: false,
        itemSourceIds: itemSources?.map(({ id: itemSourceIds }) => itemSourceIds),
        canAutoApprove: costDate?.canAutoApprove ? 'Yes' : 'No',
      };

      const innerQuantity = findItemSourcesPacking(
        itemSources ?? [],
        PackageTypeName.Inner,
        packageTypeIdNameMap,
      )?.[0]?.perChildPackageQty;

      const caseQuantity = findItemSourcesPacking(
        itemSources ?? [],
        PackageTypeName.Case,
        packageTypeIdNameMap,
      )?.[0]?.perChildPackageQty;

      if (typeof innerQuantity === 'number' && typeof caseQuantity === 'number') {
        row.caseCount = Math.max(innerQuantity, caseQuantity);
      } else if (typeof innerQuantity === 'number') {
        row.caseCount = innerQuantity;
      } else if (typeof caseQuantity === 'number') {
        row.caseCount = caseQuantity;
      }

      row.rows = costDates.map(
        ({
          canAutoApprove,
          commentedByDisplayName,
          comments,
          costPackagings: costDateCostPackagings,
          currency,
          effectiveDate,
          endDate,
          id: costId,
          status,
          type,
          updatedAt: costDateUpdatedAt,
          updatedByDisplayName: costDateUpdatedByDisplayName,
        }) => ({
          id: `${id}:${costId}`,
          costId,
          costManagementId: id,
          comments: formatComments(comments, commentedByDisplayName),
          autoValidationEachCost: formatValidation(
            findCostPackaging(costDateCostPackagings, PackageTypeName.Each, packageTypeIdNameMap)
              ?.percentageChange,
          ),
          autoValidationCaseCost: formatValidation(
            findCostPackaging(costDateCostPackagings, PackageTypeName.Case, packageTypeIdNameMap)
              ?.percentageChange,
          ),
          effectiveDate: timezoneAdjustedDate(effectiveDate),
          endDate: endDate ? timezoneAdjustedDate(endDate) : undefined,
          [PackageTypeName.Each]: findCostPackaging(
            costDateCostPackagings,
            PackageTypeName.Each,
            packageTypeIdNameMap,
          )?.value,
          [PackageTypeName.Inner]: findCostPackaging(
            costDateCostPackagings,
            PackageTypeName.Inner,
            packageTypeIdNameMap,
          )?.value,
          [PackageTypeName.Case]: findCostPackaging(
            costDateCostPackagings,
            PackageTypeName.Case,
            packageTypeIdNameMap,
          )?.value,
          [PackageTypeName.MasterCase]: findCostPackaging(
            costDateCostPackagings,
            PackageTypeName.MasterCase,
            packageTypeIdNameMap,
          )?.value,
          [PackageTypeName.Pallet]: findCostPackaging(
            costDateCostPackagings,
            PackageTypeName.Pallet,
            packageTypeIdNameMap,
          )?.value,
          currency: currency?.id,
          updatedAt: costDateUpdatedAt,
          updatedBy: costDateUpdatedByDisplayName,
          costManagementUpdatedAt: updatedAt,
          parent: row,
          isDisabled,
          isAddLocationCostRow: false,
          status,
          type,
          isUnsubmitted: false,
          canAutoApprove: canAutoApprove ? 'Yes' : 'No',
        }),
      );

      if (unsubmittedRows?.[row.id]) {
        unsubmittedRows[row.id]!.forEach(unsubmittedRow => {
          row.rows?.push({ ...unsubmittedRow, isDisabled, parent: row });
        });
      }

      if (
        canEdit &&
        row.rows.some(
          anyRow =>
            anyRow.effectiveDate &&
            anyRow.currency &&
            (anyRow[PackageTypeName.Each]! > 0 ||
              anyRow[PackageTypeName.Inner]! > 0 ||
              anyRow[PackageTypeName.Case]! > 0 ||
              anyRow[PackageTypeName.MasterCase]! > 0 ||
              anyRow[PackageTypeName.Pallet]! > 0),
        ) &&
        (canEditIsDisabled || !isDisabled) &&
        !unsubmittedRows?.[row.id]?.length
      ) {
        row.rows?.push({
          isAddLocationCostRow: true,
          id: `${id}-add-location-cost`,
          isDisabled,
          costManagementId: id,
          parent: row,
          isUnsubmitted: false,
          status: CostDateStatus.Undefined,
        });
      }

      row.rows.shift();

      return row;
    },
  );

export { mapDataToRows };
