import classNames from 'classnames';

import { memoCell } from 'shared/utils';
import type { CellSelectProps } from 'shared/components/DataGrid/components/Cells/types';

import styles from './FactoryCellText.module.css';

const FactoryCellText = <DataType extends object>(
  {
    dependencies,
    isRed,
    pickValue,
  }: {
    isRed?: (rowData: DataType) => boolean;
    pickValue?: (rowData: DataType) => string;
    dependencies?: (keyof DataType)[];
  } = { dependencies: [] },
) =>
  memoCell<CellSelectProps<DataType, string>>(cellProps => {
    const { row, value } = cellProps;
    return (
      <span className={classNames({ [styles.red]: isRed?.(row.original) })}>
        {pickValue?.(row.original) ?? value}
      </span>
    );
  }, dependencies);

export { FactoryCellText };
