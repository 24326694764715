import { FC, useMemo, useState } from 'react';
import { useCombobox } from 'downshift';
import { useUpdateEffect } from 'react-use';
import { Button } from '@mui/material';

import { SelectView } from 'shared/components/NewestSelect/shared/components/SelectView';
import { itemToString } from 'shared/components/NewestSelect/shared/utils';
import {
  useLastRenderedList,
  useOnIsOpenChange,
  useSearch,
} from 'shared/components/NewestSelect/shared/hooks';
import { OptionValueType } from 'shared/components/NewestSelect/shared/types';
import { stateReducer } from 'shared/components/NewestSelect/shared/props';

import { ButtonContent, SelectOption } from './components';
import { SelectProps } from './types';

const Select: FC<SelectProps> = ({
  allowEntryInSearch = false,
  disabled = false,
  initialSelectedOption = null,
  onChange,
  onClose,
  onEntryAdded,
  onOpen,
  open,
  options,
  placeholder,
  searchPlaceholder,
  // @TODO select without search must be a separate component
  selectedOption: controlledSelectedOption,
  showSearch = true,
  validateSearchEntry,
}) => {
  const { clearSearch, filteredOptions, onInputValueChange, searchInputValue } = useSearch({
    options,
  });

  const [selectedOption, setSelectedOption] = useState<OptionValueType | null>(
    controlledSelectedOption !== undefined ? controlledSelectedOption : initialSelectedOption,
  );

  const onIsOpenChange = useOnIsOpenChange({ onOpen, onClose, clearSearch });

  const {
    closeMenu,
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    isOpen,
  } = useCombobox({
    isOpen: open,
    inputValue: searchInputValue,
    selectedItem: null,
    items: filteredOptions,
    itemToString,
    stateReducer,
    onStateChange: action => {
      switch (action.type) {
        case useCombobox.stateChangeTypes.InputChange:
          onInputValueChange(action);
          break;
        default:
          break;
      }
    },
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      const newValue = newSelectedItem?.value ?? null;
      setSelectedOption(newSelectedItem?.value ?? null);
      onChange?.(newValue);
      closeMenu();
    },
    onIsOpenChange,
  });

  const buttonContent = useMemo(
    () => (
      <ButtonContent
        disabled={disabled}
        options={options}
        placeholder={placeholder}
        selectedOption={selectedOption}
      />
    ),
    [selectedOption, options, placeholder, disabled],
  );

  let menuContent = useLastRenderedList({
    SelectOption,
    filteredOptions,
    getItemProps,
    isOpen,
    options,
    selectedValue: selectedOption,
  });
  if (showSearch && allowEntryInSearch && menuContent && !menuContent.length) {
    menuContent = [
      <Button
        key="Add"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        disabled={validateSearchEntry ? !validateSearchEntry(searchInputValue) : false}
        fullWidth
        onClick={() => {
          if (onEntryAdded) {
            onEntryAdded(searchInputValue);
            setSelectedOption(searchInputValue);
            onChange?.(searchInputValue);
            closeMenu();
          } else {
            closeMenu();
          }
        }}
      >
        Save
      </Button>,
    ];
  }

  useUpdateEffect(() => {
    if (controlledSelectedOption !== undefined) {
      setSelectedOption(controlledSelectedOption);
    }
  }, [controlledSelectedOption]);

  return (
    <SelectView
      buttonContent={buttonContent}
      closeMenu={closeMenu}
      disabled={disabled}
      getComboboxProps={getComboboxProps}
      getInputProps={showSearch ? getInputProps : undefined}
      getMenuProps={getMenuProps}
      getToggleButtonProps={getToggleButtonProps}
      open={isOpen}
      searchInputValue={searchInputValue}
      searchPlaceholder={searchPlaceholder}
    >
      {menuContent}
    </SelectView>
  );
};

export { Select };
