import { useCallback, useMemo } from 'react';

import { BaseQueryArgs, mapAutocompleteValueToFilterParams } from '../shared';
import { FilterSearchAutocomplete } from '../FilterSearchAutocomplete';

import { kitBundleOptionsLabel } from './constants';
import { FilterSearchSelectBinaryBomProps } from './types';
import { KIT_BUNDLE_OPTION_VALUE } from './constants/defaultValues';

const FilterSearchSelectBinaryBom = <QueryArgs extends BaseQueryArgs>({
  filterFieldName,
  mapUserAutocompleteValueSelectToFilterParams,
  optionsLabel: { kitBundle, noText, yesText } = kitBundleOptionsLabel,
  ...props
}: FilterSearchSelectBinaryBomProps<QueryArgs>) => {
  const options = useMemo(
    () => [
      { value: '1', label: yesText },
      { value: '0', label: noText },
      { value: KIT_BUNDLE_OPTION_VALUE, label: kitBundle },
    ],
    [yesText, noText, kitBundle],
  );

  const mapper = useCallback<
    NonNullable<
      FilterSearchSelectBinaryBomProps<QueryArgs>['mapUserAutocompleteValueSelectToFilterParams']
    >
  >(
    newValue =>
      mapAutocompleteValueToFilterParams(
        newValue,
        filterFieldName,
        mapUserAutocompleteValueSelectToFilterParams,
        v => {
          const filterValue = { [filterFieldName]: Boolean(Number(v.value)) };
          return v.value === KIT_BUNDLE_OPTION_VALUE
            ? { ...filterValue, isBom: true }
            : filterValue;
        },
      ),
    [filterFieldName, mapUserAutocompleteValueSelectToFilterParams],
  );

  return (
    <>
      <FilterSearchAutocomplete
        filterFieldName={filterFieldName}
        mapUserAutocompleteValueSelectToFilterParams={mapper}
        options={options}
        {...props}
      />
    </>
  );
};

export { FilterSearchSelectBinaryBom };
