import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDeepCompareEffect, useUpdateEffect } from 'react-use';
import debounce from 'lodash.debounce';
import { equals, uniqBy } from 'ramda';
import { AutocompleteValue } from '@mui/core/AutocompleteUnstyled/useAutocomplete';

import {
  SearchAutocomplete,
  SearchAutocompleteProps,
} from 'shared/components/Search/SearchAutocomplete';
import { SearchAutocompleteMultiple } from 'shared/components/Search/SearchAutocompleteMultiple';

import { AutocompleteOption, BaseQueryArgs } from '../shared';

import { SHOW_AUTOCOMPLETE_AFTER } from './constants';
import { SearchAutocompleteQueryProps } from './types';

const filterOptions = (x: AutocompleteOption[]) => x;

const SearchAutocompleteQuery = <
  QueryResult,
  QueryArgs extends BaseQueryArgs,
  IsMultiple extends boolean = false,
>({
  controlled = false,
  mapDataToOptions,
  mapUserInputToAutocompleteQueryFilerParams,
  multiple = false as IsMultiple,
  query,
  onChange,
  onBlur,
  defaultValue,
  clearInputValueOnblur = false,
  showAutocompleteAfter = SHOW_AUTOCOMPLETE_AFTER,
  ...props
}: SearchAutocompleteQueryProps<QueryResult, QueryArgs, IsMultiple>) => {
  const [autocompleteQueryFilter, setAutocompleteQueryFilter] = useState<
    QueryArgs['filter'] | null
  >(null);

  const [derivedOptions, setDerivedOptions] = useState<AutocompleteOption[]>([]);
  const [open, setOpen] = useState<boolean | undefined>(false);
  const [value, setValue] = useState(defaultValue || (multiple ? [] : null));
  const [inputValue, setInputValue] = useState<
    SearchAutocompleteProps<IsMultiple, true>['inputValue']
  >((defaultValue as AutocompleteOption)?.label || '');

  const { data, loading } = useQuery<QueryResult>(query, {
    variables: {
      filter: autocompleteQueryFilter,
      pagination: { offset: 0, limit: 25 },
    } as QueryArgs,
    skip: !autocompleteQueryFilter,
  });

  const debouncedSetAutocompleteQueryFilter = useMemo(
    () =>
      debounce(
        (filter: QueryArgs['filter']) => {
          setAutocompleteQueryFilter(prevState => {
            if (equals(filter, prevState)) {
              return prevState;
            }
            return filter;
          });
        },
        800,
        { leading: true },
      ),
    [],
  );

  useDeepCompareEffect(() => {
    if (data) {
      const options = uniqBy(
        ({ label, value: optionValue }) => `${label}${optionValue}`,
        mapDataToOptions(data),
      );
      setDerivedOptions(options);
    }
  }, [data, mapDataToOptions]);

  useEffect(() => {
    if (derivedOptions.length && (multiple || derivedOptions.length > 1)) {
      setOpen(true);
    }
  }, [derivedOptions, multiple]);

  useEffect(() => {
    if (
      derivedOptions.length &&
      derivedOptions.length === 1 &&
      derivedOptions[0].value !== (value as AutocompleteOption | null)?.value
    ) {
      setOpen(true);
    }
  }, [derivedOptions, value]);

  useEffect(
    () => () => {
      debouncedSetAutocompleteQueryFilter.cancel();
    },
    [],
  );

  useEffect(() => {
    if (open !== undefined) {
      setOpen(undefined);
    }
  }, [open]);

  useUpdateEffect(() => {
    if (controlled) {
      setValue(defaultValue!);
    }
  }, [controlled, defaultValue]);

  const onInputChange = useCallback<
    NonNullable<SearchAutocompleteProps<IsMultiple, true>['onInputChange']>
  >(
    (_, newValue: string, reason, { isOpen, setIsOpen }) => {
      if (reason === 'reset') {
        return;
      }

      setInputValue(newValue);

      if (!loading && newValue.length > showAutocompleteAfter) {
        debouncedSetAutocompleteQueryFilter(mapUserInputToAutocompleteQueryFilerParams(newValue));
      }

      if (newValue.length <= showAutocompleteAfter && isOpen) {
        setDerivedOptions([]);
        setAutocompleteQueryFilter(null);
        setIsOpen(false);
      }
    },
    [
      setDerivedOptions,
      loading,
      mapUserInputToAutocompleteQueryFilerParams,
      setAutocompleteQueryFilter,
    ],
  );

  const handleChange = useCallback<
    NonNullable<SearchAutocompleteProps<IsMultiple, true>['onChange']>
  >(
    async (_, newValue, reason, state) => {
      await new Promise(resolve => {
        resolve(null);
      }).then(() => {
        setValue(newValue);
      });

      if (reason === 'selectOption') {
        if (!multiple) {
          setInputValue((newValue as AutocompleteOption).label);
        } else {
          setInputValue('');
        }
      }

      if (!multiple) {
        setDerivedOptions([]);
        setAutocompleteQueryFilter(null);
        state.setIsOpen(false);
      }

      onChange?.(_, newValue, reason, state);
    },
    [multiple, onChange, setDerivedOptions, setAutocompleteQueryFilter],
  );

  const onFocus = useCallback<NonNullable<SearchAutocompleteProps<IsMultiple, true>['onFocus']>>(
    (event, { setIsOpen }) => {
      const currentValue = (event.target as HTMLInputElement).value;
      if (currentValue.length > showAutocompleteAfter && (multiple || derivedOptions.length > 1)) {
        setIsOpen(true);
      }
    },
    [derivedOptions, multiple],
  );

  const handleBlur = useCallback<NonNullable<SearchAutocompleteProps<IsMultiple, true>['onBlur']>>(
    (e, state) => {
      if (clearInputValueOnblur) {
        setInputValue('');
      }
      debouncedSetAutocompleteQueryFilter.cancel();
      state.setIsOpen(false);
      onBlur?.(e, state);
    },
    [clearInputValueOnblur, onBlur],
  );

  return multiple ? (
    <SearchAutocompleteMultiple<true>
      {...props}
      filterOptions={filterOptions}
      forcePopupIcon
      freeSolo
      inputValue={inputValue}
      loading={loading}
      onBlur={handleBlur as SearchAutocompleteProps<true, true>['onBlur']}
      onChange={handleChange as SearchAutocompleteProps<true, true>['onChange']}
      onFocus={onFocus as SearchAutocompleteProps<true, true>['onFocus']}
      onInputChange={onInputChange as SearchAutocompleteProps<true, true>['onInputChange']}
      open={open}
      options={derivedOptions}
      renderOptionWithCheckbox
      value={value as AutocompleteValue<AutocompleteOption, true, false, false>}
    />
  ) : (
    <SearchAutocomplete<IsMultiple, true>
      {...props}
      blurOnEnterKeyPress
      blurOnSelect
      disableMuiEnterKeyDownHandling
      filterOptions={filterOptions}
      forcePopupIcon
      freeSolo
      inputValue={inputValue}
      loading={loading}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      onInputChange={onInputChange}
      open={open}
      options={derivedOptions}
      selectOnFocus
      value={value as AutocompleteValue<AutocompleteOption, IsMultiple, false, true>}
    />
  );
};

export { SearchAutocompleteQuery };
