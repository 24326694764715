import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';

import { DeriveAdditionalRowProps } from 'pages/UserDefinedAttribute/components/GeneralForm';

import { AdditionalRowProps, FormItem, FormValues } from '../types';

const makeEmptyFormItem = () =>
  ({
    name: '',
    description: '',
    volumes: [],
  } as Partial<FormItem>);

const filterMapDataToMutationItems = (
  formData: UnpackNestedValue<FormValues>,
  dirtyFields: FieldNamesMarkedBoolean<FormValues>,
  fieldName: string,
): FormItem[] =>
  dirtyFields[fieldName]
    .map((item, index) => {
      if (item.name || item.description || item.volumes) {
        const changedField = formData[fieldName][index];

        if (changedField.volumes.find(({ volume }) => volume === 100)) {
          return changedField;
        }

        return {
          ...changedField,
          volumes: [...changedField.volumes, { volume: 100 }],
        };
      }

      return null;
    })
    .filter(existedField => existedField !== null) as FormItem[];

const deriveAdditionalRowProps = ({
  disabledFields,
  fieldName,
  index,
}: Parameters<DeriveAdditionalRowProps<FormItem, AdditionalRowProps>>[0]) => ({
  areDisabledVolumes: (disabledFields as FormValues)[fieldName][index]?.volumes?.length,
});

export { deriveAdditionalRowProps, makeEmptyFormItem, filterMapDataToMutationItems };
