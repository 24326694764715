import { memo } from 'react';

import { DefaultForm } from '../DefaultForm';

import { FormItem, NationalAssortmentFormProps } from './types';
import { isDeletionPossible } from './constants';

const NationalAssortmentForm = memo((props: NationalAssortmentFormProps) => (
  <DefaultForm<FormItem> isDeletionPossible={isDeletionPossible} {...props} />
));

export { NationalAssortmentForm };
