import { FC } from 'react';
import classNames from 'classnames';

import styles from './Placeholder.module.css';
import { PlaceholderProps } from './types';

const Placeholder: FC<PlaceholderProps> = ({ disabled, placeholder = 'Select' }) => (
  <span className={classNames(styles.placeholder, { [styles.disabled]: disabled })}>
    {placeholder}
  </span>
);

export { Placeholder };
