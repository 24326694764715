import { FC, useMemo } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

import { TextField } from 'shared/components/TextField';
import { inputTypes } from 'shared/components/Input/constants';
import { SelectBinaryField, SelectField } from 'shared/components/SelectField';
import {
  availableHoursOptions,
  orderFrequencyOptions,
  transitionTypeOptions,
} from 'pages/SourceDestination/constants';
import { activeInactiveBinaryOptions } from 'shared/constants/options';
import { OrderFrequencyType } from 'shared/types';

import { FormValues, MassEditFormRowProps } from './types';
import styles from './massEditFormRow.module.css';

const MassEditFormRow: FC<MassEditFormRowProps> = ({
  control,
  containerUomOptions = [],
  setValue,
}) => {
  const {
    renderContainerMax,
    renderContainerMin,
    renderContainerUom,
    renderDeliveryWindowFrom,
    renderDeliveryWindowTo,
    renderFillKillThreshold,
    renderFriday,
    renderGoPuffTransp,
    renderIsActive,
    renderMinimumOrderQuantity,
    renderMinimumOrderValue,
    renderMinimumOrderWeight,
    renderMonday,
    renderMoqOrderingUomId,
    renderMovCurrencyId,
    renderMowSystemOfMeasureId,
    renderOrderFrequency,
    renderSaturday,
    renderSunday,
    renderAutoRelease,
    renderThursday,
    renderTransitType,
    renderTuesday,
    renderWednesday,
  } = useMemo<{
    renderIsActive: ControllerProps<FormValues, FormValues['isActive']>['render'];
    renderFillKillThreshold: ControllerProps<FormValues, FormValues['fillKillThreshold']>['render'];
    renderMinimumOrderValue: ControllerProps<FormValues, FormValues['minimumOrderValue']>['render'];
    renderMovCurrencyId: ControllerProps<FormValues, FormValues['movCurrencyId']>['render'];
    renderMinimumOrderQuantity: ControllerProps<
      FormValues,
      FormValues['minimumOrderQuantity']
    >['render'];
    renderMoqOrderingUomId: ControllerProps<FormValues, FormValues['moqOrderingUomId']>['render'];
    renderMinimumOrderWeight: ControllerProps<
      FormValues,
      FormValues['minimumOrderWeight']
    >['render'];
    renderMowSystemOfMeasureId: ControllerProps<
      FormValues,
      FormValues['mowSystemOfMeasureId']
    >['render'];
    renderMonday: ControllerProps<FormValues, FormValues['monLeadTime']>['render'];
    renderTuesday: ControllerProps<FormValues, FormValues['tueLeadTime']>['render'];
    renderWednesday: ControllerProps<FormValues, FormValues['wedLeadTime']>['render'];
    renderThursday: ControllerProps<FormValues, FormValues['thuLeadTime']>['render'];
    renderFriday: ControllerProps<FormValues, FormValues['friLeadTime']>['render'];
    renderSaturday: ControllerProps<FormValues, FormValues['satLeadTime']>['render'];
    renderSunday: ControllerProps<FormValues, FormValues['sunLeadTime']>['render'];
    renderAutoRelease: ControllerProps<FormValues, FormValues['autoRelease']>['render'];
    renderContainerMin: ControllerProps<FormValues, FormValues['containerMin']>['render'];
    renderContainerMax: ControllerProps<FormValues, FormValues['containerMax']>['render'];
    renderContainerUom: ControllerProps<FormValues, FormValues['containerUom']>['render'];
    renderGoPuffTransp: ControllerProps<FormValues, FormValues['gopuffTransport']>['render'];
    renderTransitType: ControllerProps<FormValues, FormValues['transitType']>['render'];
    renderDeliveryWindowFrom: ControllerProps<
      FormValues,
      FormValues['deliveryWindowFrom']
    >['render'];
    renderDeliveryWindowTo: ControllerProps<FormValues, FormValues['deliveryWindowTo']>['render'];
    renderOrderFrequency: ControllerProps<FormValues, FormValues['orderFrequency']>['render'];
  }>(
    () => ({
      renderIsActive: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectBinaryField
          label="is Active"
          name="isActive"
          onBlur={onBlur}
          onChange={handleChange}
          optionsLabel={activeInactiveBinaryOptions}
          placeholder="Select"
          value={value !== '' ? Number(value) : ''}
        />
      ),
      renderFillKillThreshold: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Fill/Kill Threshold"
          name="fillKillThreshold"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMinimumOrderValue: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Minimum Order Value"
          name="minimumOrderValue"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMovCurrencyId: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="MOV Currency ID"
          name="movCurrencyId"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMinimumOrderQuantity: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Minimum Order Quantity"
          name="minimumOrderQuantity"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMoqOrderingUomId: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="MOQ Ordering UOM ID"
          name="moqOrderingUomId"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMinimumOrderWeight: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Minimum Order Weight"
          name="minimumOrderWeight"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMowSystemOfMeasureId: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="MOW System of Measure ID"
          name="mowSystemOfMeasureId"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderMonday: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Monday"
          name="monLeadTime"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderTuesday: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Tuesday"
          name="tueLeadTime"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderWednesday: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Wednesday"
          name="wedLeadTime"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderThursday: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Thursday"
          name="thuLeadTime"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderFriday: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Friday"
          name="friLeadTime"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderSaturday: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Saturday"
          name="satLeadTime"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderSunday: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Sunday"
          name="sunLeadTime"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderAutoRelease: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectBinaryField
          label="Auto Release"
          name="autoRelease"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Select"
          value={Number(value)}
        />
      ),
      renderContainerMin: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Container Min"
          name="containerMin"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderContainerMax: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Container Max"
          name="containerMax"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderContainerUom: ({
        field: { onBlur, onChange: handleChange, value: newValue = '3' },
      }) => (
        <SelectField
          label="Container UOM"
          name="containerUom"
          onBlur={onBlur}
          onChange={event => {
            handleChange(event);
            setValue('containerUom', event.target.value);
          }}
          options={containerUomOptions}
          value={newValue}
        />
      ),
      renderGoPuffTransp: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectBinaryField
          label="Go Puff Transp."
          name="gopuffTransport"
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Select"
          value={Number(value)}
        />
      ),
      renderTransitType: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          label="Transit Type"
          name="transitType"
          onBlur={onBlur}
          onChange={handleChange}
          options={transitionTypeOptions}
          placeholder="Select"
          value={value}
        />
      ),
      renderDeliveryWindowFrom: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          label="Deliv. Win. From"
          name="deliveryWindowFrom"
          onBlur={onBlur}
          onChange={handleChange}
          options={availableHoursOptions}
          placeholder="Select"
          value={value}
        />
      ),
      renderDeliveryWindowTo: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          label="Deliv. Win. To"
          name="deliveryWindowTo"
          onBlur={onBlur}
          onChange={handleChange}
          options={availableHoursOptions}
          placeholder="Select"
          value={value}
        />
      ),
      renderOrderFrequency: ({
        field: { onBlur, onChange: handleChange, value = OrderFrequencyType.Weekly },
      }) => (
        <SelectField
          label="Order Frequency"
          name="orderFrequency"
          onBlur={onBlur}
          onChange={handleChange}
          options={orderFrequencyOptions}
          placeholder="Select"
          value={value}
        />
      ),
    }),
    [control],
  );

  return (
    <div>
      <Controller
        control={control}
        name="isActive"
        render={renderIsActive}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        name="fillKillThreshold"
        render={renderFillKillThreshold}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        name="minimumOrderValue"
        render={renderMinimumOrderValue}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        name="movCurrencyId"
        render={renderMovCurrencyId}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        name="minimumOrderQuantity"
        render={renderMinimumOrderQuantity}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        name="moqOrderingUomId"
        render={renderMoqOrderingUomId}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        name="minimumOrderWeight"
        render={renderMinimumOrderWeight}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        name="mowSystemOfMeasureId"
        render={renderMowSystemOfMeasureId}
        rules={{ required: true }}
      />
      <div className={styles.wrapperFields}>
        <div className={styles.field}>
          <Controller control={control} name="monLeadTime" render={renderMonday} />
        </div>
        <div className={styles.field}>
          <Controller control={control} name="tueLeadTime" render={renderTuesday} />
        </div>
        <div className={styles.field}>
          <Controller control={control} name="wedLeadTime" render={renderWednesday} />
        </div>
        <div className={styles.field}>
          <Controller control={control} name="thuLeadTime" render={renderThursday} />
        </div>
        <div className={styles.field}>
          <Controller control={control} name="friLeadTime" render={renderFriday} />
        </div>
        <div className={styles.field}>
          <Controller control={control} name="satLeadTime" render={renderSaturday} />
        </div>
        <div className={styles.field}>
          <Controller control={control} name="sunLeadTime" render={renderSunday} />
        </div>
      </div>
      <Controller control={control} name="autoRelease" render={renderAutoRelease} />
      <Controller control={control} name="orderFrequency" render={renderOrderFrequency} />
      <Controller control={control} name="containerMin" render={renderContainerMin} />
      <Controller control={control} name="containerMax" render={renderContainerMax} />
      <Controller control={control} name="containerUom" render={renderContainerUom} />
      <Controller control={control} name="gopuffTransport" render={renderGoPuffTransp} />
      <Controller control={control} name="transitType" render={renderTransitType} />
      <Controller control={control} name="deliveryWindowFrom" render={renderDeliveryWindowFrom} />
      <Controller control={control} name="deliveryWindowTo" render={renderDeliveryWindowTo} />
    </div>
  );
};

export { MassEditFormRow };
