/* eslint-disable react/jsx-key */
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import dataGridStyles from '../../DataGrid.module.css';

import { HeaderComponent } from './types';
import styles from './Header.module.css';

const Header: HeaderComponent = ({ borderAfterIndex, headerGroups, lastStickyIndex }) => {
  const multiline = headerGroups.length > 1;
  return (
    <>
      {headerGroups.map(({ headers }, headerGroupIndex) => {
        let gridColumnNumber = 0;

        return headers.map(
          ({ columns, getHeaderProps, id: columnId, placeholderOf, render }, index) => {
            const { style, ...props } = placeholderOf?.sticky
              ? placeholderOf.getHeaderProps()
              : getHeaderProps();
            const computedStyle = {
              ...style,
              zIndex: index <= lastStickyIndex ? 5 : 3,
              top: headerGroupIndex * 48,
            };

            if (columns?.length) {
              gridColumnNumber = gridColumnNumber === 0 ? index + 1 : gridColumnNumber;
              computedStyle.gridColumn = `${gridColumnNumber} / span ${columns.length}`;
              gridColumnNumber += columns.length;
            } else if (gridColumnNumber !== 0) {
              gridColumnNumber++;
            }

            if (multiline) {
              if (headerGroupIndex + 1 === headerGroups.length) {
                computedStyle.borderTop = 0;
              }

              if (headerGroupIndex + 1 < headerGroups.length) {
                computedStyle.paddingBottom = 0;
                computedStyle.borderBottom = 0;
              }

              if (headerGroupIndex !== 0) {
                computedStyle.paddingTop = 0;
              }
            }

            return (
              <div
                className={classNames(styles.tableHeader, {
                  [styles.cellBorderRight]: index === borderAfterIndex,
                  [styles.cellBorderLeft]: index === borderAfterIndex! + 1,
                  [dataGridStyles.shadowCell]: index === lastStickyIndex,
                })}
                {...props}
                data-columnid={columnId}
                style={computedStyle}
              >
                <Typography className={styles.title} component="div" variant="h5">
                  {render('Header')}
                </Typography>
              </div>
            );
          },
        );
      })}
    </>
  );
};

export { Header };
