import { useMemo } from 'react';

import { FactoryCellBinaryOrKitBundle } from 'pages/Item/components/FactoryCellBinaryOrKitBundle';
import type { Row, UseColumnsArgs, UseColumnsResult } from 'pages/Item/types';
import {
  FactoryCellDataLink,
  FactoryCellInput,
  FactoryCellMultiDateConverter,
  FactoryCellNewestSelect,
  FactoryCellNewestSelectMulti,
  FactoryCellSelect,
  FactoryCellSelectBinary,
  FactoryCellUPC,
} from 'shared/components/DataGrid';
import { inputTypes } from 'shared/components/Input/constants';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteMultiple,
  FilterSearchAutocompleteQuery,
  FilterSearchInput,
  FilterSearchSelectBinary,
} from 'shared/components/Search';
import {
  FilterSearchDatePicker,
  FilterSearchSelectBinaryBom,
} from 'shared/components/Search/Filter';
import { TooltipHeader } from 'shared/components/TooltipHeader';
import { itemsOptionsQuery } from 'shared/operations/query';
import {
  AlcoholVolume,
  Capability,
  CaptureExpiryMethod,
  CustomAssortment,
  ItemsOptionsQuery,
  ItemsOptionsQueryVariables,
  ManufacturingType,
  NationalAssortment,
  PackingConstraintType,
  TemperatureType,
  TobaccoType,
} from 'shared/types';
import {
  isReadOnlyRole,
  mapToIdDefaultNull,
  mapToIdsArray,
  mapToSelectOptions,
  mapValuesSeparatedWithComma,
} from 'shared/utils';
import {
  captureExpiryMethodOptions,
  packingConstraintOptions,
  temperatureOptions,
} from 'pages/Item/constants';

const CellItemName = FactoryCellDataLink<Row>({
  isLink: ({ isBom }) => isBom,
  linkProps: ({ extId, id, name }) => ({ pathname: `/bom/${id}/${extId}`, state: { name } }),
  maxWidth: '224px',
  dependencies: ['isBom'],
});
const CellUPC = FactoryCellUPC();
const CellBinaryOrKitBundle = FactoryCellBinaryOrKitBundle();
const CellMultiDateConverter = FactoryCellMultiDateConverter(
  'MMM/dd/yyyy',
  ['itemCustomAssortmentUpdatedAt', 'itemCapabilityUpdatedAt'],
  ['Custom Assortment', 'Capability'],
  'Item',
);

const useColumns = ({
  alcoholTypesOptions,
  capabilitiesOptions,
  customAssortmentsOptions,
  languageCode,
  manufacturingTypesOptions,
  nationalAssortmentsOptions,
  role,
  tobaccoTypesOptions,
  updateFilter,
  userOptions,
}: UseColumnsArgs): UseColumnsResult =>
  useMemo(() => {
    const isReadOnly = isReadOnlyRole(role);
    const isReadOnlyFunc = () => isReadOnly;

    const nameFilter = (
      <FilterSearchAutocompleteQuery<ItemsOptionsQuery, ItemsOptionsQueryVariables>
        filterFieldName="name"
        label="Item Name"
        mapDataToOptions={a =>
          mapToSelectOptions(
            a.items.items.map(item => ({
              ...item,
              languageCode: languageCode.split('-').join(''),
            })),
          )
        }
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          name: value,
          languageCode: languageCode || 'en-US',
        })}
        onSubmitFilter={updateFilter}
        query={itemsOptionsQuery}
      />
    );

    const eachUpcsFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="eachUPCs"
        label="EACH UPC"
        mapUserInputToFilerParams={value => ({
          eachUPCs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const itemIdFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="extIds"
        label="Item ID"
        mapUserInputToFilerParams={value => ({
          extIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );
    const categoryFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="category"
        label="Category"
        onSubmitFilter={updateFilter}
      />
    );
    const classFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="class"
        label="Class"
        onSubmitFilter={updateFilter}
      />
    );
    const brandFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="brand"
        label="Brand"
        onSubmitFilter={updateFilter}
      />
    );
    const nationalAssortmentFilter = (
      <FilterSearchAutocompleteMultiple<ItemsOptionsQueryVariables>
        filterFieldName="nationalAssortmentIds"
        label="National Assortment"
        onSubmitFilter={updateFilter}
        options={nationalAssortmentsOptions}
      />
    );
    const customAssortmentFilter = (
      <FilterSearchAutocompleteMultiple<ItemsOptionsQueryVariables>
        filterFieldName="customAssortmentIds"
        label="Custom Assortment"
        onSubmitFilter={updateFilter}
        options={customAssortmentsOptions}
      />
    );
    const alcoholFilter = (
      <FilterSearchSelectBinaryBom<ItemsOptionsQueryVariables>
        filterFieldName="isAlcohol"
        label="Alcohol"
        onSubmitFilter={updateFilter}
      />
    );
    const alcoholTypeFilter = (
      <FilterSearchAutocompleteMultiple<ItemsOptionsQueryVariables>
        filterFieldName="alcoholTypeIds"
        label="Alcohol Type"
        onSubmitFilter={updateFilter}
        options={alcoholTypesOptions}
      />
    );
    const alcoholAbvFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="alcoholABV"
        label="Alcohol ABV %"
        onSubmitFilter={updateFilter}
      />
    );
    const tobaccoFilter = (
      <FilterSearchSelectBinaryBom<ItemsOptionsQueryVariables>
        filterFieldName="isTobacco"
        label="Tobacco"
        onSubmitFilter={updateFilter}
      />
    );
    const manufacturingTypeFilter = (
      <FilterSearchAutocomplete<ItemsOptionsQueryVariables>
        filterFieldName="manufacturingTypeId"
        label={
          <TooltipHeader
            label="Manufacturing Type"
            title={`Made-To-Order (Bundle) - products assembled on order\n
            Made-To-stock (KIT) - products assembled to stock`}
          />
        }
        onSubmitFilter={updateFilter}
        options={manufacturingTypesOptions}
      />
    );
    const tobaccoTypeFilter = (
      <FilterSearchAutocompleteMultiple<ItemsOptionsQueryVariables>
        filterFieldName="tobaccoTypeIds"
        label="Tobacco Type"
        onSubmitFilter={updateFilter}
        options={tobaccoTypesOptions}
      />
    );
    const stopBuyingFilter = (
      <FilterSearchSelectBinary<ItemsOptionsQueryVariables>
        filterFieldName="stopBuyingFlag"
        label="Stop Buying"
        onSubmitFilter={updateFilter}
      />
    );
    const itemCapabilityFilter = (
      <FilterSearchAutocompleteMultiple<ItemsOptionsQueryVariables>
        filterFieldName="capabilityIds"
        label="Item Capability"
        onSubmitFilter={updateFilter}
        options={capabilitiesOptions}
      />
    );
    const pullByDateFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="pullByDate"
        label="Pull By Date (days)"
        onSubmitFilter={updateFilter}
      />
    );
    const markdownDaysFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="markdownDays"
        label="Bevmo Markdown (days)"
        onSubmitFilter={updateFilter}
      />
    );
    const guaranteedShellLifeFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="guaranteedShelfLife"
        label="Guar. Shelf Life (Days)"
        onSubmitFilter={updateFilter}
      />
    );
    const itemDeptFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="department"
        label="Department"
        onSubmitFilter={updateFilter}
      />
    );
    const supplierFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="parentCompany"
        label="Manufacturer"
        onSubmitFilter={updateFilter}
      />
    );
    const subClassFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="subClass"
        label="Sub class"
        onSubmitFilter={updateFilter}
      />
    );
    const productTypeFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="productType"
        label="Product type"
        onSubmitFilter={updateFilter}
      />
    );
    const captureExpiryMethodFilter = (
      <FilterSearchAutocomplete<ItemsOptionsQueryVariables>
        filterFieldName="captureExpiryMethod"
        label="Capture Expiry Method"
        onSubmitFilter={updateFilter}
        options={captureExpiryMethodOptions}
      />
    );
    const acceptableShelfLifeFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="acceptableShelfLife"
        label="Acceptable Shelf Life (ASL)"
        onSubmitFilter={updateFilter}
      />
    );
    const markdownItemFilter = (
      <FilterSearchSelectBinary<ItemsOptionsQueryVariables>
        filterFieldName="markdownItem"
        label="UK Markdown Item"
        onSubmitFilter={updateFilter}
      />
    );
    const baggableAtPackFilter = (
      <FilterSearchSelectBinary<ItemsOptionsQueryVariables>
        filterFieldName="baggableAtPack"
        label="Baggable at Pack"
        onSubmitFilter={updateFilter}
      />
    );
    const binnableAtPackFilter = (
      <FilterSearchSelectBinary<ItemsOptionsQueryVariables>
        filterFieldName="binnableAtPack"
        label="Binnable at Pack"
        onSubmitFilter={updateFilter}
      />
    );
    const requireProduceBagFilter = (
      <FilterSearchSelectBinary<ItemsOptionsQueryVariables>
        filterFieldName="requireProduceBag"
        label="Require Produce Bag"
        onSubmitFilter={updateFilter}
      />
    );
    const ignoreBlockOverReceiptFilter = (
      <FilterSearchSelectBinary<ItemsOptionsQueryVariables>
        filterFieldName="ignoreBlockOverReceipt"
        label="Ignore Block Over Receipt"
        onSubmitFilter={updateFilter}
      />
    );
    const percentageOverageAllowedFilter = (
      <FilterSearchInput<ItemsOptionsQueryVariables>
        filterFieldName="percentageOverageAllowed"
        label={
          <TooltipHeader
            label="Percentage Overage Allowed"
            title={`Minimum percentage 0% (input: 0)\n
            Maximum percentage 10,000% (input: 10000)`}
          />
        }
        onSubmitFilter={updateFilter}
      />
    );
    const updatedAtFilter = (
      <FilterSearchDatePicker<ItemsOptionsQueryVariables>
        filterFieldName="updatedAt"
        label="Updated At"
        onSubmitFilter={updateFilter}
      />
    );
    const updatedByFilter = (
      <FilterSearchAutocomplete<ItemsOptionsQueryVariables>
        filterFieldName="updatedBy"
        label="Updated By"
        mapUserAutocompleteValueSelectToFilterParams={option => ({
          updatedBy: option!.value,
        })}
        onSubmitFilter={updateFilter}
        options={userOptions}
      />
    );
    const thawToSellEnabledFilter = (
      <FilterSearchSelectBinary<ItemsOptionsQueryVariables>
        filterFieldName="thawToSellEnabled"
        label="Thaw To Sell Enabled"
        onSubmitFilter={updateFilter}
      />
    );
    const packingConstraintFilter = (
      <FilterSearchAutocomplete<ItemsOptionsQueryVariables>
        filterFieldName="packingConstraint"
        label="Packing Constraint"
        onSubmitFilter={updateFilter}
        options={packingConstraintOptions}
      />
    );

    return [
      {
        Header: () => nameFilter,
        accessor: 'name',
        width: 240,
        Cell: CellItemName,
        sticky: 'left',
      },
      {
        Header: () => itemIdFilter,
        accessor: 'extId',
        sticky: 'left',
      },
      {
        Header: () => eachUpcsFilter,
        accessor: 'upc',
        Cell: CellUPC,
        width: 200,
      },
      {
        Header: () => categoryFilter,
        accessor: 'category',
      },
      {
        Header: () => itemDeptFilter,
        accessor: 'department',
      },
      {
        Header: () => classFilter,
        accessor: 'class',
      },
      {
        Header: () => subClassFilter,
        accessor: 'subClass',
      },
      {
        Header: () => productTypeFilter,
        accessor: 'productType',
      },
      {
        Header: () => supplierFilter,
        accessor: 'parentCompany',
      },
      {
        Header: () => brandFilter,
        accessor: 'brand',
      },
      {
        Header: () => nationalAssortmentFilter,
        accessor: 'nationalAssortment',
        Cell: FactoryCellSelect<Row, string, NationalAssortment>({
          options: nationalAssortmentsOptions,
          pickValue: mapToIdDefaultNull,
          isDisabled: isReadOnlyFunc,
        }),
      },
      {
        Header: () => customAssortmentFilter,
        accessor: 'customAssortments',
        Cell: FactoryCellNewestSelectMulti<Row, string, CustomAssortment[]>({
          options: customAssortmentsOptions,
          isDisabled: () => isReadOnly,
          pickValue: mapToIdsArray,
          dependencies: ['nationalAssortment'],
        }),
      },
      {
        Header: () => alcoholFilter,
        accessor: 'isAlcohol',
        Cell: CellBinaryOrKitBundle,
      },
      {
        Header: () => alcoholTypeFilter,
        accessor: 'alcoholType',
        Cell: FactoryCellSelect<Row, string, ManufacturingType>({
          options: alcoholTypesOptions,
          isDisabled: ({ isAlcohol }: Row) => !isAlcohol || isReadOnly,
          pickValue: mapToIdDefaultNull,
          dependencies: ['isAlcohol'],
        }),
      },
      {
        Header: () => alcoholAbvFilter,
        accessor: 'alcoholByVolume',
        Cell: FactoryCellInput<Row, AlcoholVolume>({
          isDisabled: ({ isAlcohol }: Row) => !isAlcohol || isReadOnly,
          dependencies: ['isAlcohol'],
          type: inputTypes.Float,
        }),
      },
      {
        Header: () => tobaccoFilter,
        accessor: 'isTobacco',
        Cell: CellBinaryOrKitBundle,
      },
      {
        Header: () => tobaccoTypeFilter,
        accessor: 'tobaccoType',
        Cell: FactoryCellNewestSelect<Row, string, TobaccoType>({
          options: tobaccoTypesOptions,
          isDisabled: ({ isTobacco }: Row) => !isTobacco || isReadOnly,
          pickValue: mapToIdDefaultNull,
          dependencies: ['isTobacco'],
        }),
      },
      {
        Header: () => stopBuyingFilter,
        accessor: 'stopBuyingFlag',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => manufacturingTypeFilter,
        accessor: 'manufacturingType',
        width: 150,
        Cell: FactoryCellSelect<Row, string, ManufacturingType>({
          options: manufacturingTypesOptions,
          isDisabled: ({ isBom }: Row) => !isBom || isReadOnly,
          pickValue: mapToIdDefaultNull,
          dependencies: ['isBom'],
        }),
      },
      {
        Header: () => guaranteedShellLifeFilter,
        accessor: 'guaranteedShelfLife',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => pullByDateFilter,
        accessor: 'pullByDate',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => markdownDaysFilter,
        accessor: 'markdownDays',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => itemCapabilityFilter,
        accessor: 'capabilities',
        Cell: FactoryCellNewestSelectMulti<Row, string, Capability[]>({
          options: capabilitiesOptions,
          pickValue: mapToIdsArray,
          isDisabled: isReadOnlyFunc,
        }),
      },
      {
        Header: () => captureExpiryMethodFilter,
        accessor: 'captureExpiryMethod',
        Cell: FactoryCellSelect<Row, string, CaptureExpiryMethod>({
          options: captureExpiryMethodOptions,
          isDisabled: isReadOnlyFunc,
        }),
      },
      {
        Header: () => acceptableShelfLifeFilter,
        accessor: 'acceptableShelfLife',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => markdownItemFilter,
        accessor: 'markdownItem',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => baggableAtPackFilter,
        accessor: 'baggableAtPack',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => binnableAtPackFilter,
        accessor: 'binnableAtPack',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => packingConstraintFilter,
        accessor: 'packingConstraint',
        Cell: FactoryCellSelect<Row, string, PackingConstraintType>({
          options: packingConstraintOptions,
          isDisabled: isReadOnlyFunc,
        }),
      },
      {
        Header: () => requireProduceBagFilter,
        accessor: 'requireProduceBag',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => ignoreBlockOverReceiptFilter,
        accessor: 'ignoreBlockOverReceipt',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
      {
        Header: () => percentageOverageAllowedFilter,
        accessor: 'percentageOverageAllowed',
        width: 200,
        Cell: FactoryCellInput({
          type: inputTypes.Number,
          isDisabled: isReadOnlyFunc,
          min: 0,
          max: 10_000,
        }),
      },
      {
        Header: () => thawToSellEnabledFilter,
        accessor: 'thawToSellEnabled',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
      {
        Header: 'Thaw Replenishment Min Qty',
        accessor: 'thawReplenishmentMinQty',
        Cell: FactoryCellInput({
          type: inputTypes.Number,
          isDisabled: isReadOnlyFunc,
          min: 0,
          max: 10_000,
        }),
      },
      {
        Header: 'Thaw Replenishment Max Qty',
        accessor: 'thawReplenishmentMaxQty',
        Cell: FactoryCellInput({
          type: inputTypes.Number,
          isDisabled: isReadOnlyFunc,
          min: 0,
          max: 10_000,
        }),
      },
      {
        Header: 'Thaw Time Hours',
        accessor: 'thawTimeHours',
        Cell: FactoryCellInput({
          type: inputTypes.Number,
          isDisabled: isReadOnlyFunc,
          min: 0,
          max: 10_000,
        }),
      },
      {
        Header: 'Thaw Expiry Days',
        accessor: 'thawExpiryDays',
        Cell: FactoryCellInput({
          type: inputTypes.Number,
          isDisabled: isReadOnlyFunc,
          min: 0,
          max: 10_000,
        }),
      },
      {
        Header: 'Sellable Temperature',
        accessor: 'sellableTemperature',
        Cell: FactoryCellSelect<Row, string, TemperatureType>({
          options: temperatureOptions,
          isDisabled: isReadOnlyFunc,
        }),
      },
      {
        Header: 'Storage Temperature',
        accessor: 'storageTemperature',
        Cell: FactoryCellSelect<Row, string, TemperatureType>({
          options: temperatureOptions,
          isDisabled: isReadOnlyFunc,
        }),
      },
      {
        Header: () => updatedByFilter,
        id: 'updatedBy',
        accessor: (row: Row) => {
          const user = userOptions ? userOptions.filter(u => u.value.includes(row.updatedBy)) : [];
          return row.updatedBy && user.length ? user[0].label : row.updatedBy || '-';
        },
      },
      {
        Header: () => updatedAtFilter,
        accessor: 'updatedAt',
        Cell: CellMultiDateConverter,
        width: 170,
      },
    ].filter(Boolean) as UseColumnsResult;
  }, [
    alcoholTypesOptions,
    capabilitiesOptions,
    customAssortmentsOptions,
    manufacturingTypesOptions,
    nationalAssortmentsOptions,
    tobaccoTypesOptions,
    languageCode,
    userOptions,
  ]);

export { useColumns };
