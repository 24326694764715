import { memoCell } from 'shared/utils';
import { Row } from 'pages/Item/types';
import { CellProps } from 'shared/types';

const FactoryCellBinaryOrKitBundle = () =>
  memoCell<CellProps<Row, boolean>>(
    ({
      row: {
        original: { isBom },
      },
      value,
    }) => {
      if (isBom) {
        return <>KIT/Bundle</>;
      }
      return <>{value ? 'Yes' : 'No'}</>;
    },
  );

export { FactoryCellBinaryOrKitBundle };
