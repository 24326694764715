import { FC, useEffect, useMemo } from 'react';
import { Controller, ControllerProps, useFieldArray } from 'react-hook-form';

import { TextField } from 'shared/components/TextField';
import { inputTypes } from 'shared/components/Input/constants';
import { SelectField } from 'shared/components/SelectField';
import { lengthOptions, weightOptions } from 'shared/constants/units';
import { PackageTypeName } from 'shared/constants/package';
import { PackageTypeNameLabels } from 'pages/ItemSource/constants';
import { noop } from 'shared/utils';

import styles from './massEditFormRow.module.css';
import { FormValues, MassEditFormRowProps } from './types';

const MassEditFormRow: FC<MassEditFormRowProps> = ({ control, updateSubRows, watch }) => {
  const packageInnerValue = watch(PackageTypeName.Inner);
  const packageCaseValue = watch(PackageTypeName.Case);
  const packageMasterCaseValue = watch(PackageTypeName.MasterCase);
  const packagePalletValue = watch(PackageTypeName.Pallet);

  const { append, fields, remove } = useFieldArray({ name: 'packaging', control });

  const checkHasPackageType = (packageTypeName: PackageTypeName) =>
    fields.some(field => field.packageTypeName === packageTypeName);

  const removePackagingRow = (packageTypeName: PackageTypeName) => {
    const packagingIndex = fields.findIndex(el => el.packageTypeName === packageTypeName);
    if (packagingIndex !== -1) {
      remove(packagingIndex);
    }
  };

  const updatePackaging = (
    packageTypeName: PackageTypeName,
    newValue: string,
    appendValue: Omit<FormValues['packaging'][number], 'packageTypeName'> = {
      declaredHeight: undefined,
      declaredLength: undefined,
      declaredWidth: undefined,
      declaredWeight: undefined,
      declaredTi: undefined,
      declaredHi: undefined,
      declaredWeightUnits: '',
      declaredLengthUnits: '',
    },
  ) => {
    const hasPackageType = checkHasPackageType(packageTypeName);

    if (newValue && !hasPackageType) {
      append({
        ...appendValue,
        packageTypeName,
      });
      updateSubRows(packageTypeName, newValue, true);
    }
    if (!newValue && hasPackageType) {
      removePackagingRow(packageTypeName);
      updateSubRows(packageTypeName, newValue, false);
    }
  };

  useEffect(() => {
    updatePackaging(PackageTypeName.Inner, packageInnerValue);
  }, [packageInnerValue]);

  useEffect(() => {
    updatePackaging(PackageTypeName.Case, packageCaseValue);
  }, [packageCaseValue]);

  useEffect(() => {
    updatePackaging(PackageTypeName.MasterCase, packageMasterCaseValue);
  }, [packageMasterCaseValue]);

  useEffect(() => {
    updatePackaging(PackageTypeName.Pallet, packagePalletValue, {
      declaredTi: undefined,
      declaredHi: undefined,
    });
  }, [packagePalletValue]);

  const {
    renderCase,
    renderDeclaredHeight,
    renderDeclaredHi,
    renderDeclaredLength,
    renderDeclaredLengthUnits,
    renderDeclaredTi,
    renderDeclaredWeight,
    renderDeclaredWeightUnits,
    renderDeclaredWidth,
    renderInner,
    renderMasterCase,
    renderPallet,
  } = useMemo<{
    renderInner: ControllerProps<FormValues, FormValues[PackageTypeName.Inner]>['render'];
    renderCase: ControllerProps<FormValues, FormValues[PackageTypeName.Case]>['render'];
    renderMasterCase: ControllerProps<FormValues, FormValues[PackageTypeName.MasterCase]>['render'];
    renderPallet: ControllerProps<FormValues, FormValues[PackageTypeName.Pallet]>['render'];
    renderDeclaredLength: ControllerProps<
      FormValues,
      FormValues['packaging'][number]['declaredLength']
    >['render'];
    renderDeclaredWidth: ControllerProps<
      FormValues,
      FormValues['packaging'][number]['declaredWidth']
    >['render'];
    renderDeclaredHeight: ControllerProps<
      FormValues,
      FormValues['packaging'][number]['declaredHeight']
    >['render'];
    renderDeclaredWeight: ControllerProps<
      FormValues,
      FormValues['packaging'][number]['declaredWeight']
    >['render'];
    renderDeclaredTi: ControllerProps<
      FormValues,
      FormValues['packaging'][number]['declaredTi']
    >['render'];
    renderDeclaredHi: ControllerProps<
      FormValues,
      FormValues['packaging'][number]['declaredHi']
    >['render'];
    renderDeclaredLengthUnits: ControllerProps<
      FormValues,
      FormValues['packaging'][number]['declaredLengthUnits']
    >['render'];
    renderDeclaredWeightUnits: ControllerProps<
      FormValues,
      FormValues['packaging'][number]['declaredWeightUnits']
    >['render'];
  }>(
    () => ({
      renderInner: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label={PackageTypeNameLabels[PackageTypeName.Inner]}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderCase: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label={PackageTypeNameLabels[PackageTypeName.Case]}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderMasterCase: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label={PackageTypeNameLabels[PackageTypeName.MasterCase]}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),
      renderPallet: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label={PackageTypeNameLabels[PackageTypeName.Pallet]}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          showSuccessBorder
          size="full"
          type={inputTypes.Float}
          value={value}
        />
      ),

      renderDeclaredLength: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Length"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderDeclaredWidth: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Width"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderDeclaredHeight: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Height"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderDeclaredWeight: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="Weight"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderDeclaredTi: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="TI"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderDeclaredHi: ({ field: { name, onBlur, onChange: handleChange, value = '' } }) => (
        <TextField
          label="HI"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder="Enter"
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
      renderDeclaredLengthUnits: ({
        field: { name = '', onBlur, onChange: handleChange, value = '' },
      }) => (
        <SelectField
          label="UOM"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          options={lengthOptions}
          placeholder="Container"
          value={value}
        />
      ),
      renderDeclaredWeightUnits: ({
        field: { name, onBlur, onChange: handleChange, value = '' },
      }) => (
        <SelectField
          label="SOM"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          options={weightOptions}
          placeholder="Container"
          value={value}
        />
      ),
    }),
    [control],
  );

  return (
    <div>
      <p className={styles.title}>
        Package MNGT <span className={styles.addition}>(*Add QTY to activate)</span>
      </p>
      <div className={styles.wrapperFields}>
        <div className={styles.field}>
          <Controller control={control} name={PackageTypeName.Inner} render={renderInner} />
        </div>
        <div className={styles.field}>
          <Controller control={control} name={PackageTypeName.Case} render={renderCase} />
        </div>
        <div className={styles.field}>
          <Controller
            control={control}
            name={PackageTypeName.MasterCase}
            render={renderMasterCase}
          />
        </div>
        <div className={styles.field}>
          <Controller control={control} name={PackageTypeName.Pallet} render={renderPallet} />
        </div>
      </div>
      <ul className={styles.listItems}>
        {fields.map((item, index) => {
          let declaredVolumeValue: string = '';
          const declaredHeightValue = watch(
            `packaging[${index}].declaredHeight` as 'packaging.0.declaredHeight',
          );
          const declaredLengthValue = watch(
            `packaging[${index}].declaredLength` as 'packaging.0.declaredLength',
          );
          const declaredWidthValue = watch(
            `packaging[${index}].declaredWidth` as 'packaging.0.declaredWidth',
          );

          if (declaredHeightValue || declaredLengthValue || declaredWidthValue) {
            const countVolume =
              Number(declaredHeightValue) *
              Number(declaredLengthValue) *
              Number(declaredWidthValue);

            declaredVolumeValue = Number.isNaN(countVolume) ? '0' : countVolume.toString();
          }
          return item.packageTypeName !== PackageTypeName.Pallet ? (
            <li key={index.toString()}>
              <p className={styles.title}>For {PackageTypeNameLabels[item.packageTypeName]}</p>
              <div className={styles.wrapperFields}>
                <div className={styles.field}>
                  <Controller
                    control={control}
                    name={`packaging[${index}].declaredLength` as 'packaging.0.declaredLength'}
                    render={renderDeclaredLength}
                  />
                </div>
                <div className={styles.field}>
                  <Controller
                    control={control}
                    name={`packaging[${index}].declaredWidth` as 'packaging.0.declaredWidth'}
                    render={renderDeclaredWidth}
                  />
                </div>
                <div className={styles.field}>
                  <Controller
                    control={control}
                    name={`packaging[${index}].declaredHeight` as 'packaging.0.declaredHeight'}
                    render={renderDeclaredHeight}
                  />
                </div>
                <div className={styles.field}>
                  <Controller
                    control={control}
                    name={
                      `packaging[${index}].declaredLengthUnits` as 'packaging.0.declaredLengthUnits'
                    }
                    render={renderDeclaredLengthUnits}
                  />
                </div>
                <div className={styles.field}>
                  <Controller
                    control={control}
                    name={`packaging[${index}].declaredWeight` as 'packaging.0.declaredWeight'}
                    render={renderDeclaredWeight}
                  />
                </div>
                <div className={styles.field}>
                  <Controller
                    control={control}
                    name={
                      `packaging[${index}].declaredWeightUnits` as 'packaging.0.declaredWeightUnits'
                    }
                    render={renderDeclaredWeightUnits}
                  />
                </div>
                <div className={styles.field}>
                  <TextField
                    disabled
                    label="Declared Volume"
                    name={`packaging[${index}].declaredVolume`}
                    onChange={noop}
                    size="full"
                    value={declaredVolumeValue}
                  />
                </div>
              </div>
            </li>
          ) : (
            <li key={index.toString()}>
              <p className={styles.title}>For {PackageTypeNameLabels[item.packageTypeName]}</p>
              <div className={styles.wrapperFields}>
                <div className={styles.field}>
                  <Controller
                    control={control}
                    name={`packaging[${index}].declaredTi` as 'packaging.0.declaredTi'}
                    render={renderDeclaredTi}
                  />
                </div>
                <div className={styles.field}>
                  <Controller
                    control={control}
                    name={`packaging[${index}].declaredHi` as 'packaging.0.declaredHi'}
                    render={renderDeclaredHi}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { MassEditFormRow };
