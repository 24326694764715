import { FC } from 'react';

import styles from './ListItemIcon.module.css';
import { ListItemIconProps } from './types';

const ListItemIcon: FC<ListItemIconProps> = ({ children, ...divProps }) => (
  <div {...divProps} className={styles.listItemIcon}>
    {children}
  </div>
);

export { ListItemIcon };
