import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from 'shared/tools/yup';

declare module 'yup' {
  interface ArraySchema<T> {
    uniqueAlcoholVolumes(propertyPath?: any, message?: any): ArraySchema<T>;
  }
}

const AllocationPrioritySchema = yup.object({
  name: yup
    .number()
    .typeError('Input must be a number')
    .min(0, 'Input must be positive')
    .max(100, 'Too large')
    .required('Field is required'),
  description: yup.string().nullable(true).optional(),
});

const AllocationPriorityFormSchema = yup.object({
  allocationPriorities: yup.array().of(AllocationPrioritySchema),
});

export const validationSchema = yupResolver(AllocationPriorityFormSchema);
