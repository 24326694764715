import classNames from 'classnames';

import { OptionValueType, Select } from 'shared/components/Select';
import { FieldError } from 'shared/components/FieldError';
import { FieldLabel } from 'shared/components/FieldLabel';

import styles from './SelectField.module.css';
import { SelectFieldProps } from './types';

const SelectField = <OptionValue extends OptionValueType>({
  className,
  displayBlockError = true,
  error,
  label,
  name,
  ...props
}: SelectFieldProps<OptionValue>) => (
  <div className={classNames(styles.container, className)}>
    <FieldLabel name={name}>{label}</FieldLabel>
    <Select {...props} aria-label={label as string} />
    {error && <FieldError block={displayBlockError} message={error} />}
  </div>
);

export { SelectField };
