import { Column } from 'react-table';

export const headersForMassEditColumns: Column[] = [
  {
    Header: 'Source Name',
    accessor: 'source.name',
  },
  {
    Header: 'OpCo/Site ID',
    accessor: 'source.id',
  },
  {
    Header: 'Location Name',
    accessor: 'location.name',
  },
  {
    Header: 'Location ID',
    accessor: 'location.extId',
  },
];
