import { isBefore, isEqual, parseISO } from 'date-fns';

import { GraphJinLeadTimeOverrideQuery } from 'shared/types';
import { getCurrentMonday } from 'shared/tools/date/getCurrentMonday';

import { LeadTimeOverrideRow, MappedRow, SourceDestinationRow, UnsubmittedRows } from '../types';

export const mapDataToRows = (
  data: SourceDestinationRow[],
  leadTimeOverrides: LeadTimeOverrideRow[] | undefined,
  unsubmittedRows: UnsubmittedRows,
  deletedLeadTimeOverrideIds: string[],
) => {
  const leadTimeOverridesByTransportationLaneId: Record<
    string,
    GraphJinLeadTimeOverrideQuery['graphJinLeadTimeOverride']
  > = {};

  leadTimeOverrides?.forEach(override => {
    if (!leadTimeOverridesByTransportationLaneId[override.transportationLaneId]) {
      leadTimeOverridesByTransportationLaneId[override.transportationLaneId] = [];
    }

    leadTimeOverridesByTransportationLaneId[override.transportationLaneId].push(override);
  });

  return data.map(row => {
    const parent: MappedRow = {
      ...row,
    };

    let leadTimeOverridesForId = leadTimeOverridesByTransportationLaneId[row.id] ?? [];

    leadTimeOverridesForId = leadTimeOverridesForId.filter(
      override =>
        !deletedLeadTimeOverrideIds.includes(override.id) &&
        !isBefore(parseISO(override.weekStartDate), getCurrentMonday()),
    );

    // sort by weekStartDate asc
    leadTimeOverridesForId.sort(
      (a, b) => parseISO(a.weekStartDate).getTime() - parseISO(b.weekStartDate).getTime(),
    );

    const leadTimeOverridesRows = leadTimeOverridesForId.map(
      override =>
        ({
          ...override,
          parent,
          id: `${override.transportationLaneId}:${override.id}`,
          isLeadTimeOverrideRow: true,
        } as MappedRow),
    );

    const earliestLeadTimeOverride = leadTimeOverridesRows[0];
    if (
      earliestLeadTimeOverride?.weekStartDate &&
      isEqual(parseISO(earliestLeadTimeOverride.weekStartDate), getCurrentMonday())
    ) {
      parent.isOverridden = true;
      earliestLeadTimeOverride.isCurrentWeek = true;
    }

    if (unsubmittedRows[row.id]) {
      const unsubmittedRowsForId = unsubmittedRows[row.id].map(unsubmittedRow => ({
        ...unsubmittedRow,
        parent,
      }));
      leadTimeOverridesRows.push(...unsubmittedRowsForId);
    }

    if (!unsubmittedRows[row.id]?.length) {
      leadTimeOverridesRows.push({
        isAddLeadTimeOverrideRow: true,
        isLeadTimeOverrideRow: true,
        id: `${row.id}-add-lead-time-override`,
        parent,
      });
    }

    parent.rows = leadTimeOverridesRows;

    return parent;
  });
};
