import { memoCell } from 'shared/utils';

import { DealStatusChip } from '../DealStatusChip';

import { FactoryCellStatusProps } from './types';

const FactoryCellStatus = () =>
  memoCell<FactoryCellStatusProps>(({ value }) => <DealStatusChip status={value} />);

export { FactoryCellStatus };
