import { FC } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ActionModal } from 'shared/components/Modal';

import { WarningExistingConnectionsModalProps } from './types';

const WarningExistingConnectionsModal: FC<WarningExistingConnectionsModalProps> = ({
  children,
  isOpen,
  onClose,
  onProceed,
}) => (
  <ActionModal
    actions={
      <>
        <Button data-testid="cancel-warning-modal-button" onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button data-testid="proceed-warning-modal-button" onClick={onProceed} variant="contained">
          Proceed
        </Button>
      </>
    }
    isOpen={isOpen}
    onClose={onClose}
    size="small"
    title="Warning"
  >
    {children ?? (
      <>
        Please be aware - you are trying to update already existing
        <Typography variant="bold1">{' Connections. '}</Typography>You will update and overwrite the
        <Typography variant="bold1">{' summary '}</Typography>of all Connections for all selections.
      </>
    )}
  </ActionModal>
);

export { WarningExistingConnectionsModal };
