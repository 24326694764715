import { Option } from 'shared/types';

import { defaultOptionsLabel } from '../constants';
import { OptionsLabel } from '../types';

const deriveOptions = ({ noText, yesText }: OptionsLabel = defaultOptionsLabel): Option<
  number,
  string
>[] => [
  { value: 1, label: yesText },
  { value: 0, label: noText },
];

export { deriveOptions };
