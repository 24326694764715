import { useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import Typography from '@mui/material/Typography';

import { DataGrid } from 'shared/components/DataGrid';
import { DataRow } from 'shared/components//DataGrid/types';

import { MassEditForm } from './components/MassEditForm';
import { WarningModal } from './components/WarningModal';
import { Header } from './components/Header';
import styles from './EditPage.module.css';
import { EditPageProps } from './types';

const EditPage = <
  Row extends DataRow,
  FormFieldValues extends Record<string, any>,
  MutationVariables,
  Mutation extends TypedDocumentNode<any, MutationVariables>,
  RowProps extends object,
>({
  MassEditFormRow,
  afterMutation,
  deriveMassEditData,
  editFormRowProps,
  enableExpanding = false,
  evictCacheForQueryName,
  isEditPage,
  mutation,
  onCloseEditPage,
  refetchQueries,
}: EditPageProps<Row, RowProps, FormFieldValues, MutationVariables, Mutation>) => {
  const history = useHistory();
  const [blockedLocation, setBlockedLocation] = useState<string | null>(null);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [needToClosePage, setNeedToClosePage] = useState(false);
  const { defaultValues, mapRowToMutationArgs, onCompleted, onSubmit, ...props } =
    deriveMassEditData();
  const { data } = props;

  useEffect(() => {
    if (!data.length) {
      onCloseEditPage();
    }
  }, [data]);

  useEffect(() => {
    if (needToClosePage && !isFormChanged) {
      afterMutation?.();
      onCloseEditPage();
    }
  }, [needToClosePage, isFormChanged]);

  useEffect(() => {
    if (!isFormChanged && blockedLocation) {
      history.push(blockedLocation);
    }
  }, [isFormChanged]);

  const onChangeUnsavedPage = useCallback(({ pathname }) => {
    setBlockedLocation(pathname);
    setIsWarningModal(true);

    return false;
  }, []);

  const closeEditPageHandler = useCallback(() => {
    if (!isFormChanged) {
      onCloseEditPage();
      afterMutation?.();
    } else {
      setIsWarningModal(true);
    }
  }, [afterMutation, isFormChanged, onCloseEditPage]);

  const onCloseModal = useCallback(() => {
    setIsWarningModal(false);

    if (blockedLocation) {
      setBlockedLocation(null);
    }
  }, [blockedLocation]);

  const onNeedToClosePage = useCallback(() => setNeedToClosePage(true), []);

  const onClickLeave = useCallback(() => {
    if (blockedLocation) {
      setIsFormChanged(false);
    } else {
      onCloseEditPage();
      afterMutation?.();
    }
  }, [onCloseEditPage, blockedLocation]);

  return (
    <div className={styles.wrapper}>
      <Header
        isFormChanged={isFormChanged}
        onCancel={closeEditPageHandler}
        onSaveAndClose={onNeedToClosePage}
      />
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <DataGrid showPagination={false} {...props} enableExpanding={enableExpanding} />
        </div>
        <div className={styles.editWrapper}>
          <Typography className={styles.editTitle} variant="h3">
            Assign selected ({data.length})
          </Typography>
          <MassEditForm<FormFieldValues, RowProps, MutationVariables, Mutation>
            defaultValues={defaultValues}
            evictCacheForQueryName={evictCacheForQueryName}
            isEditPage={isEditPage}
            mapRowToMutationArgs={mapRowToMutationArgs}
            MassEditFormRow={MassEditFormRow}
            mutation={mutation}
            onCompleted={onCompleted}
            onSubmit={onSubmit}
            refetchQueries={refetchQueries}
            rowProps={editFormRowProps as RowProps}
            setIsFormChanged={setIsFormChanged}
          />
        </div>
      </div>
      <Prompt message={onChangeUnsavedPage} when={isFormChanged} />
      <WarningModal
        isOpen={isWarningModal}
        onClickLeave={onClickLeave}
        onCloseModal={onCloseModal}
        onSaveAndCloseModal={onNeedToClosePage}
      />
    </div>
  );
};

export { EditPage };
