import { Column } from 'react-table';

export const headersForMassEditColumns: Column[] = [
  {
    Header: 'Item Name',
    accessor: 'name',
  },
  {
    Header: 'Item ID',
    accessor: 'extId',
  },
  {
    Header: 'EACH UPC',
    accessor: 'upc',
  },
];
