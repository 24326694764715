import { FocusEvent, useCallback, useState } from 'react';

import { SearchAutocompleteProps, State } from 'shared/components/Search/SearchAutocomplete';
import { SearchAutocompleteQuery } from 'shared/components/Search/SearchAutocompleteQuery';

import {
  AutocompleteOption,
  BaseQueryArgs,
  MapUserAutocompleteValueSelectToFilterParamsInput,
  mapAutocompleteValueToFilterParams,
} from '../shared';

import { APPLY_FILTER_AFTER } from './constants';
import { FilterSearchAutocompleteQueryProps } from './types';

const FilterSearchAutocompleteQuery = <
  OptionsQueryResult,
  OptionsQueryArgs extends BaseQueryArgs,
  QueryArgs extends BaseQueryArgs = OptionsQueryArgs,
  IsMultiple extends boolean = boolean,
>({
  filterFieldName,
  mapDataToOptions,
  mapUserAutocompleteValueSelectToFilterParams,
  mapUserInputToDataQueryFilerParams,
  multiple = false as IsMultiple,
  onSubmitFilter,
  query,
  ...props
}: FilterSearchAutocompleteQueryProps<
  OptionsQueryResult,
  OptionsQueryArgs,
  QueryArgs,
  IsMultiple
>) => {
  const [wereFiltersApplied, setWereFiltersApplied] = useState(false);

  const defaultMapper = useCallback(
    v => {
      let filterParams;

      if (multiple) {
        filterParams = {
          [filterFieldName]: (v as AutocompleteOption[]).map(
            ({ label: optionLabel }) => optionLabel,
          ),
        };
      } else {
        filterParams = { [filterFieldName]: (v as AutocompleteOption).label };
      }
      return filterParams;
    },
    [multiple, filterFieldName],
  );

  const onChange = useCallback<NonNullable<SearchAutocompleteProps<IsMultiple, true>['onChange']>>(
    (_, newValue, reason, { inputValue }) => {
      const filter = mapAutocompleteValueToFilterParams<QueryArgs, IsMultiple>(
        newValue as MapUserAutocompleteValueSelectToFilterParamsInput<IsMultiple>,
        filterFieldName,
        mapUserAutocompleteValueSelectToFilterParams,
        defaultMapper,
      );
      if (
        multiple &&
        (reason === 'removeOption' || reason === 'clear') &&
        inputValue &&
        mapUserInputToDataQueryFilerParams
      ) {
        filter[filterFieldName] = [
          ...(filter[filterFieldName] || []),
          ...mapUserInputToDataQueryFilerParams(inputValue)[filterFieldName],
        ] as QueryArgs['filter'];
      }
      setWereFiltersApplied(true);
      onSubmitFilter(filter, 'select');
    },
    [
      mapUserAutocompleteValueSelectToFilterParams,
      multiple,
      filterFieldName,
      mapUserInputToDataQueryFilerParams,
    ],
  );

  const onBlur = useCallback(
    (event: FocusEvent, { value }: State<IsMultiple, true>) => {
      const currentValue = (event.target as HTMLInputElement).value;
      if (currentValue.length > APPLY_FILTER_AFTER) {
        const filter =
          mapUserInputToDataQueryFilerParams?.(currentValue) ||
          ({
            [filterFieldName]: currentValue,
          } as QueryArgs['filter']);
        if (multiple && (value as AutocompleteOption[]).length) {
          filter[filterFieldName] = [
            ...filter[filterFieldName],
            ...mapAutocompleteValueToFilterParams<QueryArgs, IsMultiple>(
              value as MapUserAutocompleteValueSelectToFilterParamsInput<IsMultiple>,
              filterFieldName,
              mapUserAutocompleteValueSelectToFilterParams,
              defaultMapper,
            )[filterFieldName],
          ] as QueryArgs['filter'];
        }
        onSubmitFilter(filter, 'blur');
        setWereFiltersApplied(true);
      } else if (wereFiltersApplied) {
        const filter = {
          [filterFieldName]: undefined,
        } as QueryArgs['filter'];
        if (multiple && (value as AutocompleteOption[]).length) {
          filter[filterFieldName] = mapAutocompleteValueToFilterParams<QueryArgs, IsMultiple>(
            value as MapUserAutocompleteValueSelectToFilterParamsInput<IsMultiple>,
            filterFieldName,
            mapUserAutocompleteValueSelectToFilterParams,
            defaultMapper,
          )[filterFieldName];
        }
        onSubmitFilter(filter, 'blur');
        setWereFiltersApplied(false);
      }
    },
    [
      filterFieldName,
      wereFiltersApplied,
      mapUserInputToDataQueryFilerParams,
      multiple,
      mapUserAutocompleteValueSelectToFilterParams,
    ],
  );

  return (
    <SearchAutocompleteQuery<OptionsQueryResult, OptionsQueryArgs, IsMultiple>
      {...props}
      mapDataToOptions={mapDataToOptions}
      multiple={multiple}
      onBlur={onBlur}
      onChange={onChange}
      query={query}
    />
  );
};

export { FilterSearchAutocompleteQuery };
