import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { EditPanelProps } from './types';
import styles from './EditPanel.module.css';

const EditPanel: FC<EditPanelProps> = ({
  onAddTagsClick,
  onEditClick,
  selectedRows,
  triggerUncheckAllCheckboxes,
}) => (
  <div className={styles.wrapper}>
    <Typography className={styles.selected} variant="h6">
      Selected ({selectedRows.length})
    </Typography>
    <div className={styles.buttons}>
      {triggerUncheckAllCheckboxes && (
        <Button
          className={styles.cancelButton}
          onClick={triggerUncheckAllCheckboxes}
          variant="outlined"
        >
          Cancel
        </Button>
      )}
      {onAddTagsClick && (
        <Button className={styles.addTagsButton} onClick={onAddTagsClick} variant="contained">
          Add Tags
        </Button>
      )}
      {onEditClick && (
        <Button onClick={onEditClick} variant="contained">
          Edit
        </Button>
      )}
    </div>
  </div>
);

export { EditPanel };
