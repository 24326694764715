import React, { memo } from 'react';
import { equals } from 'ramda';
import classNames from 'classnames';

import { DataRow, RowHighlightColor } from 'shared/components/DataGrid/types';

import dataGridStyles from '../../DataGrid.module.css';

import { RowProps } from './types';
import styles from './Row.module.css';

const Row = memo(
  <DataType extends DataRow>({
    borderAfterIndex,
    highlightRow,
    highlightRowWithColor,
    isSelected,
    isSubRow = false,
    lastStickyIndex,
    original,
    row,
  }: RowProps<DataType>) => (
    <>
      {row.cells.map(
        ({ column: { SubCell, accessor, id: columnId }, getCellProps, render }, cellIndex) => {
          const classes = {
            [styles.highlightSelectedRow]: isSelected,
            [styles.cellBorderRight]: cellIndex === borderAfterIndex,
            [styles.cellBorderLeft]: cellIndex === borderAfterIndex! + 1,
            [dataGridStyles.shadowCell]: cellIndex === lastStickyIndex,
          };

          const color = highlightRowWithColor?.(original);
          switch (color) {
            case RowHighlightColor.Green:
              classes[styles.highlightRowGreen] = true;
              break;
            case RowHighlightColor.Yellow:
              classes[styles.highlightRowYellow] = true;
              break;
            default:
              if (highlightRow(original)) {
                classes[styles.highlightRow] = true;
                break;
              }
              classes[styles.tableRow] = true;
              break;
          }
          const { key, ...props } = getCellProps();

          return (
            <div
              key={`${row.original.id}${original.id}${columnId}`}
              {...{
                ...props,
                'data-first-cell': cellIndex === 0 || null,
                'data-testid': `${columnId}-${original.id}${isSubRow ? '-sub' : ''}`,
                'data-columnid': columnId,
              }}
              className={classNames(styles.tableCell, classes)}
            >
              {render(isSubRow && SubCell ? 'SubCell' : 'Cell', {
                value: accessor?.(original, cellIndex) as unknown,
                row: { ...row, original, isSubRow },
              })}
            </div>
          );
        },
      )}
    </>
  ),
  (
    {
      disableCells: previousDisableCells,
      isExpanded: previousIsExpanded,
      isSelected: previousIsSelected,
      original: previousRow,
      row: previousTableRow,
      selectedFlatRows: previousSelectedFlatRows,
      state: previousState,
    },
    {
      disableCells: nextDisableCells,
      isExpanded: nextIsExpanded,
      isSelected: nextIsSelected,
      original: currentRow,
      row: currentTableRow,
      selectedFlatRows: nextSelectedFlatRows,
      state: nextState,
    },
  ) =>
    equals(previousRow, currentRow) &&
    previousDisableCells === nextDisableCells &&
    previousIsSelected === nextIsSelected &&
    previousIsExpanded === nextIsExpanded &&
    previousSelectedFlatRows === nextSelectedFlatRows &&
    previousTableRow.index === currentTableRow.index &&
    equals(previousState, nextState),
);

export { Row };
