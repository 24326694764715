import { useCallback, useState } from 'react';

import { memoCell } from 'shared/utils';
import { OptionValueType } from 'shared/components/Select';
import { SelectMulti } from 'shared/components/NewestSelect';
import { Option } from 'shared/types/props';
import { MockCellValue } from 'shared/components/DataGrid/components/Cells/types';
import type { CellSelectProps } from 'shared/components/DataGrid/components/Cells/types';

const FactoryCellNewestSelectMulti = <
  DataType extends object,
  CellValue extends OptionValueType,
  ObjectValue extends object | CellValue[] = CellValue[],
>(
  cellArguments: {
    options: Option<CellValue, string>[];
    isDisabled?: (rowData: DataType) => boolean;
    pickValue?: (value: ObjectValue) => CellValue[];
    dependencies?: (keyof DataType)[];
  } = { dependencies: [], options: [] },
) =>
  memoCell<CellSelectProps<DataType, CellValue[], ObjectValue>>(
    ({ column: { id: columnId }, row, updateGridData, value: initialValue }) => {
      const { isDisabled, options, pickValue } = cellArguments;
      const { index, original } = row;

      const disabled = isDisabled ? isDisabled(original) : false;

      const { isOpen } = (initialValue ?? { isOpen: false }) as MockCellValue<ObjectValue>;
      const derivedInitialValue =
        ((initialValue ?? { value: [] }) as MockCellValue<ObjectValue>).value ?? initialValue;

      const mapInitialValue = pickValue
        ? pickValue(derivedInitialValue)
        : (derivedInitialValue as CellValue[]);

      const [value, setValue] = useState(mapInitialValue);
      const [valueBeforeOpen, setValueBeforeOpen] = useState<CellValue[]>([]);

      const handleOpen = useCallback(() => {
        setValueBeforeOpen(value);
      }, [setValueBeforeOpen, value]);

      const handleChange = useCallback(
        selectedValue => {
          setValue(selectedValue as CellValue[]);
        },
        [setValue],
      );

      const handleClose = useCallback(() => {
        if (
          !valueBeforeOpen.every(
            optionValueBeforeOpen =>
              !!value.find(optionValueAfterOpen => optionValueBeforeOpen === optionValueAfterOpen),
          ) ||
          valueBeforeOpen.length !== value.length
        ) {
          updateGridData(index, columnId, value, original, row);
        }
      }, [updateGridData, index, columnId, value, valueBeforeOpen, original, row]);

      return (
        <SelectMulti
          disabled={disabled}
          initialSelectedOptions={value}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          open={isOpen}
          options={options}
        />
      );
    },
    cellArguments.dependencies,
  );

export { FactoryCellNewestSelectMulti };
