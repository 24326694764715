import { RowsPerPageOptions } from 'shared/components/DataGrid/types';

export enum TransportationLanePages {
  Source = 'Source',
  Destination = 'Destination',
}

export const headerButtonNames = Object.values(TransportationLanePages);

export const rowsPerPageExtraOptions: RowsPerPageOptions = [5, 10, 25, 50, 100, 300];
