const prepareValue = (value: string, asNumber: boolean) => {
  const v = value.trim();
  if (asNumber) {
    return Number(v);
  }
  return v;
};

const mapValuesSeparatedWithComma = <AsNumbers extends boolean = false>(
  value: string,
  asNumbers: AsNumbers = false as AsNumbers,
): AsNumbers extends true ? number[] : string[] =>
  (value.indexOf(',') !== -1
    ? value.split(',').map(v => prepareValue(v, asNumbers))
    : [prepareValue(value, asNumbers)]) as AsNumbers extends true ? number[] : string[];

export { mapValuesSeparatedWithComma };
