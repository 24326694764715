import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from 'shared/tools/yup';

const ItemSchema = yup.object({
  name: yup.string().required('Field is required').max(80, 'Maximum length up to 80 characters'),
  description: yup.string().nullable(true).optional(),
});

const generalFormSchema = (name: string) =>
  yup.object({
    [name]: yup.array().of(ItemSchema),
  });

export const validationSchema = (name: string) => yupResolver(generalFormSchema(name));
