const transitionType: { name: string; id: string }[] = [
  {
    id: 'LTL',
    name: 'LTL',
  },
  {
    id: 'FTL',
    name: 'FTL',
  },
  {
    id: 'Parcel',
    name: 'Parcel',
  },
];

export { transitionType };
