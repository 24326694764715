import { useCallback } from 'react';

const useOnIsOpenChange = ({
  clearSearch,
  onClose,
  onOpen,
}: {
  onClose?: () => void;
  onOpen?: () => void;
  clearSearch?: () => void;
}) =>
  useCallback(
    ({ isOpen: newIsOpen }) => {
      if (newIsOpen) {
        onOpen?.();
      } else {
        clearSearch?.();
        onClose?.();
      }
    },
    [onOpen, onClose, clearSearch],
  );

export { useOnIsOpenChange };
