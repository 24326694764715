/* eslint-disable no-param-reassign */
import { Modifier, ModifierArguments, detectOverflow } from '@popperjs/core';

const modifiers: Partial<Modifier<any, any>>[] = [
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      mainAxis: false,
      altAxis: true,
      altBoundary: true,
      tether: false,
      rootBoundary: 'document',
    },
  },
  {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn({ state }) {
      state.styles.popper.minWidth = `${state.rects.reference.width}px`;
    },
    effect({ state }) {
      state.elements.popper.style.minWidth = `${
        (state.elements.reference as HTMLElement).offsetWidth
      }px`;
    },
  },
  {
    name: 'offset',
    options: {
      offset: [0, 3],
    },
  },
  {
    name: 'maxSize',
    enabled: true,
    phase: 'main',
    requiresIfExists: ['offset', 'preventOverflow', 'flip'],
    fn({ name, options, state }: ModifierArguments<{}>) {
      const overflow = detectOverflow(state, options);
      const { x, y } = state.modifiersData.preventOverflow || { x: 0, y: 0 };
      const { height, width } = state.rects.popper;
      const [basePlacement] = state.placement.split('-');

      const widthProp = basePlacement === 'left' ? 'left' : 'right';
      const heightProp = basePlacement === 'top' ? 'top' : 'bottom';

      state.modifiersData[name] = {
        width: width - overflow[widthProp] - x,
        height: height - overflow[heightProp] - y,
      };
    },
  },
  {
    name: 'applyMaxSize',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn({ state }: ModifierArguments<{}>) {
      const { height } = state.modifiersData.maxSize as { height: string };
      state.styles.popper = {
        ...state.styles.popper,
        maxHeight: `${Number(height) - 10}px`,
      };
    },
  },
];

const styles = {
  zIndex: 100,
};

export { modifiers, styles };
