import { memo } from 'react';
import { Resolver } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { DELETE_ALCOHOL_ABV_MUTATION } from 'pages/UserDefinedAttribute/operations';

import { GeneralForm } from '../GeneralForm';

import { validationSchema } from './validationSchema';
import { AdditionalRowProps, AlcoholFormProps, FormItem, FormValues } from './types';
import { Row } from './components/Row';
import {
  deriveAdditionalRowProps,
  filterMapDataToMutationItems,
  makeEmptyFormItem,
} from './constants';
import styles from './AlcoholForm.module.css';

const AlcoholForm = memo(({ name, ...props }: AlcoholFormProps) => {
  const { afterSave } = props;
  const [deleteAlcoholVolumeMutation, { loading }] = useMutation(DELETE_ALCOHOL_ABV_MUTATION, {
    update(cache) {
      cache.evict({
        fieldName: 'alcoholTypes',
        broadcast: false,
      });
      cache.evict({
        fieldName: 'locations',
        broadcast: false,
      });
      cache.gc();
    },
    onCompleted() {
      afterSave();
    },
  });

  return (
    <GeneralForm<FormItem, AdditionalRowProps>
      className={styles.container}
      deleteAlcoholVolumeMutation={deleteAlcoholVolumeMutation}
      deriveAdditionalRowProps={deriveAdditionalRowProps}
      filterMapDataToMutationItems={filterMapDataToMutationItems}
      loading={loading}
      makeEmptyFormItem={makeEmptyFormItem}
      name={name}
      Row={Row}
      validationSchema={validationSchema as Resolver<FormValues>}
      wrapInFormProvider
      {...props}
    />
  );
});

export { AlcoholForm };
