import { memo, useCallback } from 'react';
import { Resolver } from 'react-hook-form';

import { GeneralForm } from '../GeneralForm';

import { validationSchema } from './validationSchema';
import { AdditionalRowProps, DefaultFormProps, FormItem, FormValues } from './types';
import {
  filterMapDataToMutationItems as defaultFilterMapDataToMutationItems,
  makeEmptyFormItem,
} from './constants';
import { Row as RowConstructor } from './components/Row';

const DefaultForm = memo(
  <FormItemType extends FormItem = FormItem>({
    filterMapDataToMutationItems = defaultFilterMapDataToMutationItems,
    inputSize,
    name,
    ...props
  }: DefaultFormProps<FormItemType>) => {
    const deriveAdditionalRowProps = useCallback(() => ({ inputSize }), [inputSize]);

    return (
      <GeneralForm<FormItemType, AdditionalRowProps>
        deriveAdditionalRowProps={deriveAdditionalRowProps}
        filterMapDataToMutationItems={filterMapDataToMutationItems}
        makeEmptyFormItem={makeEmptyFormItem}
        name={name}
        Row={RowConstructor}
        validationSchema={validationSchema(name) as Resolver<FormValues<FormItemType>>}
        {...props}
      />
    );
  },
);

export { DefaultForm };
