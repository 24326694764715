import { FC } from 'react';

import { Spinner } from '../components/Spinner';

import styles from './UpdateAssortmentGlobalLoader.module.css';

const UpdateAssortmentGlobalLoader: FC = () => (
  <div className={styles.container}>
    <h1 className={styles.heading}>Updating Assortment </h1>
    <div className={styles.innerContainer}>
      <p>Update in progress, please limit any changes.</p>
      <Spinner active={false} size={30} />
    </div>
  </div>
);

export { UpdateAssortmentGlobalLoader };
