import { FC } from 'react';
import Button from '@mui/material/Button';

import { Header as HeaderBase } from 'shared/components/Header';
import { FORM_ID } from 'pages/UserDefinedAttribute/constants';

import { HeaderProps } from './types';
import styles from './Header.module.css';

const Header: FC<HeaderProps> = ({ isFormChanged, onCancel }) => (
  <HeaderBase title="User-Defined Attribute">
    <div className={styles.buttons}>
      <Button disabled={!isFormChanged} onClick={onCancel} variant="outlined">
        Cancel
      </Button>
      <Button disabled={!isFormChanged} form={FORM_ID} type="submit" variant="contained">
        Save
      </Button>
    </div>
  </HeaderBase>
);

export { Header };
