import { FC, useMemo } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

import { SelectMultiField } from 'shared/components/NewestSelectField';
import { SelectBinaryField, SelectField } from 'shared/components/SelectField';
import { SelectAlcoholTypeField } from 'pages/Location/components/SelectAlcoholTypeField';
import { TextField } from 'shared/components/TextField';
import { AlcoholType } from 'shared/types';
import { inputTypes } from 'shared/components/Input/constants';

import { FormValues, MassEditFormRowProps } from './types';

const MassEditFormRow: FC<MassEditFormRowProps> = ({
  alcoholTypes,
  capabilitiesOptions,
  control,
  customAssortmentsOptions,
  isEditPage,
  nationalAssortmentsOptions,
  allocationPriorityOptions,
  tobaccoTypesOptions,
}) => {
  const {
    renderAgeOutThreshold,
    renderAlcoholType,
    renderCustomAssortments,
    renderIsThirdParty,
    renderLocationCapabilities,
    renderNationalAssortment,
    renderAllocationPriority,
    renderStopBuying,
    renderTobaccoType,
  } = useMemo<{
    renderIsThirdParty: ControllerProps<FormValues, FormValues['isThirdParty']>['render'];
    renderNationalAssortment: ControllerProps<
      FormValues,
      FormValues['nationalAssortment']
    >['render'];
    renderAllocationPriority: ControllerProps<
      FormValues,
      FormValues['allocationPriority']
    >['render'];
    renderCustomAssortments: ControllerProps<
      FormValues,
      FormValues['locationCustomAssortments']
    >['render'];
    renderAlcoholType: ControllerProps<FormValues, FormValues['alcoholVolumes']>['render'];
    renderTobaccoType: ControllerProps<
      FormValues,
      FormValues['locationTobaccoCapabilities']
    >['render'];
    renderLocationCapabilities: ControllerProps<
      FormValues,
      FormValues['locationCapabilities']
    >['render'];
    renderStopBuying: ControllerProps<FormValues, FormValues['stopBuying']>['render'];
    renderAgeOutThreshold: ControllerProps<FormValues, FormValues['ageOutThreshold']>['render'];
  }>(
    () => ({
      renderIsThirdParty: ({ field: { onBlur, onChange: handleChange, value } }) => (
        <SelectBinaryField
          label="Is 3rd party"
          name="isThirdParty"
          onBlur={onBlur}
          onChange={handleChange}
          value={value === undefined ? '' : Number(value)}
        />
      ),
      renderNationalAssortment: ({ field: { onBlur, onChange: handleChange, value = '' } }) => (
        <SelectField
          label="National Assortment"
          name="nationalAssortment"
          onBlur={onBlur}
          onChange={handleChange}
          options={nationalAssortmentsOptions}
          value={value}
        />
      ),
      renderAllocationPriority: ({ field: { onChange: handleChange, value = '' } }) => (
        <SelectField
          label="Allocation Priority"
          name="allocationPriority"
          onChange={handleChange}
          options={allocationPriorityOptions}
          value={value}
        />
      ),
      renderCustomAssortments: ({ field: { onChange: handleChange, value = [] } }) => (
        <SelectMultiField
          label="Custom Assortment"
          name="locationCustomAssortments"
          onChange={handleChange}
          options={customAssortmentsOptions}
          value={value}
        />
      ),
      renderAlcoholType: ({ field: { onBlur, onChange: handleChange, value = [] } }) => (
        <SelectAlcoholTypeField
          onBlur={onBlur}
          onChange={handleChange}
          options={alcoholTypes as AlcoholType[]}
          value={value}
        />
      ),
      renderTobaccoType: ({ field: { onChange: handleChange, value = [] } }) => (
        <SelectMultiField
          label="Tobacco Type"
          name="locationTobaccoCapabilities"
          onChange={handleChange}
          options={tobaccoTypesOptions}
          value={value}
        />
      ),
      renderLocationCapabilities: ({ field: { onChange: handleChange, value = [] } }) => (
        <SelectMultiField
          label="Location Capabilities"
          name="locationCapabilities"
          onChange={handleChange}
          options={capabilitiesOptions}
          value={value}
        />
      ),
      renderStopBuying: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <SelectBinaryField
          label="Stop Buying"
          name="stopBuying"
          onBlur={onBlur}
          onChange={handleChange}
          value={Number(value)}
        />
      ),
      renderAgeOutThreshold: ({ field: { onBlur, onChange: handleChange, value = 0 } }) => (
        <TextField
          label="Age Out Threshold"
          name="ageOutThreshold"
          onBlur={onBlur}
          onChange={handleChange}
          size="full"
          type={inputTypes.Number}
          value={value}
        />
      ),
    }),
    [control],
  );

  return (
    <>
      {isEditPage && (
        <Controller control={control} name="isThirdParty" render={renderIsThirdParty} />
      )}
      {isEditPage && <Controller control={control} name="stopBuying" render={renderStopBuying} />}
      {isEditPage && (
        <Controller control={control} name="allocationPriority" render={renderAllocationPriority} />
      )}
      {isEditPage && (
        <Controller control={control} name="nationalAssortment" render={renderNationalAssortment} />
      )}
      {isEditPage && (
        <Controller control={control} name="ageOutThreshold" render={renderAgeOutThreshold} />
      )}
      <Controller
        control={control}
        name="locationCustomAssortments"
        render={renderCustomAssortments}
      />
      <Controller control={control} name="alcoholVolumes" render={renderAlcoholType} />
      <Controller control={control} name="locationTobaccoCapabilities" render={renderTobaccoType} />
      <Controller
        control={control}
        name="locationCapabilities"
        render={renderLocationCapabilities}
      />
    </>
  );
};

export { MassEditFormRow };
