import { datadogRum } from '@datadog/browser-rum';

import { Tld } from 'shared/constants';
import { TLD } from 'shared/constants/environment';

if (TLD === Tld.com) {
  datadogRum.init({
    applicationId: '22a77bb0-692f-4a6a-8bb0-20ca09e57f80',
    clientToken: 'pub5ca84b8fe412ae7409c81ab9b30157c4',
    site: 'datadoghq.com',
    service: 'sc-dc-ui',
    env: 'production',
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}
