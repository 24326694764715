import { FC } from 'react';
import Button from '@mui/material/Button';

import { ActionModal } from 'shared/components/Modal';

import { ConfirmModalProps } from './types';

const ConfirmModal: FC<ConfirmModalProps> = ({ confirmMessage, isOpen, onClose, onConfirm }) => (
  <ActionModal
    actions={
      <>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained">
          Confirm
        </Button>
      </>
    }
    isOpen={isOpen}
    onClose={onClose}
    size="small"
    title="Warning"
  >
    {confirmMessage}
  </ActionModal>
);

export { ConfirmModal };
