import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';

import { ActionModal } from 'shared/components/Modal';
import { preventPressEnter } from 'shared/utils';

import { CommentsModalFormValues, CommentsModalProps } from './types';
import styles from './CommentsModal.module.css';

const CommentsModal: FC<CommentsModalProps> = ({
  commentsRequired,
  info,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<CommentsModalFormValues>({
    defaultValues: {},
    mode: 'onChange',
  });

  const formId = 'comments-modal-form';

  const onSubmitHandler = useMemo(() => handleSubmit(onSubmit), [onSubmit, handleSubmit]);
  const [disableSubmit, setDisableSubmit] = useState(!!commentsRequired);
  const [comments, setComments] = useState('');

  const onCommentsChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setComments(value);
    if (!commentsRequired) {
      setDisableSubmit(false);
      return;
    }
    if (!value || !value.length) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  };

  useEffect(() => {
    if (commentsRequired && !comments.length) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [commentsRequired]);

  return (
    <ActionModal
      actions={
        <>
          <Button data-testid="comments-modal-cancel-button" onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            data-testid="comments-modal-submit-button"
            disabled={disableSubmit}
            form={formId}
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      title="Add Note"
    >
      {info}
      <form
        id={formId}
        onKeyDown={preventPressEnter}
        onSubmit={onSubmitHandler}
        role="presentation"
      >
        <textarea
          placeholder={`Please provide note${commentsRequired ? ' (required if rejecting)' : ''}`}
          {...register('comments', {
            required: commentsRequired,
            onChange: onCommentsChanged,
          })}
          className={styles.commentsModalInput}
        />
        {errors.comments && (
          <span className={styles.commentsModalError}>Rejection comment is required</span>
        )}
      </form>
    </ActionModal>
  );
};

export { CommentsModal };
