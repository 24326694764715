import classNames from 'classnames';

import { SelectMulti } from 'shared/components/NewestSelect';
import { FieldError } from 'shared/components/FieldError';
import { FieldLabel } from 'shared/components/FieldLabel';

import styles from './SelectMultiField.module.css';
import { SelectMultiFieldProps } from './types';

const SelectMultiField = ({
  className,
  displayBlockError = true,
  error,
  label,
  name,
  options,
  ...props
}: SelectMultiFieldProps) => (
  <div className={classNames(styles.container, className)}>
    <FieldLabel name={name}>{label}</FieldLabel>

    <SelectMulti {...props} options={options} />
    {error && <FieldError block={displayBlockError} message={error} />}
  </div>
);

export { SelectMultiField };
