import { FC, Fragment, useMemo } from 'react';
import { Tooltip } from '@mui/material';

import { ArrayTooltipProps } from './types';
import styles from './ArrayTooltip.module.css';

export const ArrayTooltip: FC<ArrayTooltipProps> = ({
  displayCount = 1,
  values,
  elementsPerRow = 1,
}) => {
  const displayValues = values.slice(0, displayCount).join(', ');
  const tooltipValues = useMemo(() => {
    const result = [];
    for (let i = 0; i < values.length; i += elementsPerRow) {
      result.push(values.slice(i, i + elementsPerRow).join(', '));
    }
    return result;
  }, [values, elementsPerRow]);
  return (
    <>
      {values.length <= displayCount ? (
        <div>{displayValues}</div>
      ) : (
        <Tooltip
          title={
            <div>
              {tooltipValues.map(item => (
                <Fragment key={item}>
                  <p>{item}</p>
                </Fragment>
              ))}
            </div>
          }
        >
          <div className={styles.wrapper}>
            <span className={styles.text}>{displayValues}</span>
            <div className={styles.amount}> +{values.length - displayCount}</div>
          </div>
        </Tooltip>
      )}
    </>
  );
};
