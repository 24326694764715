import { FC } from 'react';
import { isNil } from 'ramda';

import { valueToLabel } from 'shared/components/NewestSelect/shared/utils';
import { Placeholder } from 'shared/components/NewestSelect/shared/components/Placeholder';

import { ButtonContentProps } from './types';
import styles from './ButtonContent.module.css';

const ButtonContent: FC<ButtonContentProps> = ({
  disabled,
  options,
  placeholder,
  selectedOption,
}) => (
  <>
    {isNil(selectedOption) || selectedOption === '' ? (
      <Placeholder disabled={disabled} placeholder={placeholder} />
    ) : (
      <span className={styles.selectedOptionText}>{valueToLabel(selectedOption, options)}</span>
    )}
  </>
);

export { ButtonContent };
