import { FC } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ActionModal } from 'shared/components/Modal';

import { ValidationModalProps } from './types';

const ValidationModal: FC<ValidationModalProps> = ({
  isOpen,
  onClose,
  onUpdateAssortmentClick,
}) => (
  <ActionModal
    actions={
      <>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onUpdateAssortmentClick} variant="contained">
          Proceed
        </Button>
      </>
    }
    isOpen={isOpen}
    onClose={onClose}
    size="small"
    title="Warning"
  >
    Please, confirm your selection. After confirmation the validation process will start{' '}
    immediately,{' '}
    <Typography variant="bold1"> it will take some time and block the part of system</Typography>{' '}
    update functionality for other users.
  </ActionModal>
);

export { ValidationModal };
