import { ApolloErrorHandler } from 'setup/providers/ApolloClient';

import { errorCodes } from '../../../../shared/constants';

import { getErrorCode } from './getErrorCode';

const defaultOnError: ApolloErrorHandler = (
  enqueueSnackbar,
  { graphQLErrors, networkError, operation },
  closeSnackbarAction,
) => {
  if (networkError) {
    enqueueSnackbar(getErrorCode(networkError), { variant: 'error' });
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
    return;
  }

  if (!graphQLErrors) return;

  const firstError = graphQLErrors?.[0];
  const errorCode = firstError.extensions?.code as string | undefined;

  const { ignoreForbiddenError } = (operation.getContext() || {}) as {
    ignoreForbiddenError?: boolean;
  };

  if (errorCode === errorCodes.forbidden && ignoreForbiddenError) {
    return;
  }

  if (errorCode === errorCodes.userInputError) {
    const { locations, message, path } = firstError;
    const alertBody: string[] = [];
    alertBody.push('Invalid Input');
    if (message) {
      alertBody.push(`Message: ${firstError.message}`);
    }
    const validationMessages = firstError.extensions?.validationMessages as string[] | undefined;
    if (validationMessages?.length) {
      alertBody.push('Details: ', ...validationMessages.map(v => `- ${v}`));
    }
    enqueueSnackbar(alertBody.join('\n'), {
      variant: 'error',
      style: { whiteSpace: 'pre-line' },
      persist: !!closeSnackbarAction,
      action: closeSnackbarAction,
    });
    // eslint-disable-next-line no-console
    console.error(
      `[User input error]: Message: ${message}, Location: ${locations}, Path: ${path}, Alert: ${alertBody}`,
    );
    return;
  }

  graphQLErrors.forEach(error => {
    const { locations, message, path } = error;
    enqueueSnackbar(getErrorCode(error), { variant: 'error' });
    // eslint-disable-next-line no-console
    console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
  });
};

export { defaultOnError };
