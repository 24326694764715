import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { AllocationPriority } from 'shared/types';

const makeAllocationPriority = (
  props: O.Partial<AllocationPriority, 'deep'> = {},
): AllocationPriority =>
  merge<AllocationPriority, O.Partial<AllocationPriority, 'deep'>>(
    {
      id: '1',
      name: 'Custom',
      description: 'Description...',
      __typename: 'AllocationPriority',
    },
    props,
  );

export { makeAllocationPriority };
