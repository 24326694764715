import { mapToSelectOptions } from 'shared/utils';
import { transitionType } from 'shared/constants';

export const availableHours: { name: string; id: string }[] = [
  {
    id: '12:00:00 AM',
    name: '12:00 am',
  },
  {
    id: '1:00:00 AM',
    name: '1 am',
  },
  {
    id: '1:30:00 AM',
    name: '1:30 am',
  },
  {
    id: '2:00:00 AM',
    name: '2 am',
  },
  {
    id: '2:30:00 AM',
    name: '2:30 am',
  },
  {
    id: '3:00:00 AM',
    name: '3 am',
  },
  {
    id: '3:30:00 AM',
    name: '3:30 am',
  },
  {
    id: '4:00:00 AM',
    name: '4 am',
  },
  {
    id: '4:30:00 AM',
    name: '4:30 am',
  },
  {
    id: '5:00:00 AM',
    name: '5 am',
  },
  {
    id: '5:30:00 AM',
    name: '5:30 am',
  },
  {
    id: '6:00:00 AM',
    name: '6 am',
  },
  {
    id: '6:30:00 AM',
    name: '6:30 am',
  },
  {
    id: '7:00:00 AM',
    name: '7 am',
  },
  {
    id: '7:30:00 AM',
    name: '7:30 am',
  },
  {
    id: '8:00:00 AM',
    name: '8am',
  },
  {
    id: '8:30:00 AM',
    name: '8:30 am',
  },
  {
    id: '9:00:00 AM',
    name: '9 am',
  },
  {
    id: '9:30:00 AM',
    name: '9:30 am',
  },
  {
    id: '10:00:00 AM',
    name: '10 am',
  },
  {
    id: '10:30:00 AM',
    name: '10:30 am',
  },
  {
    id: '11:00:00 AM',
    name: '11 am',
  },
  {
    id: '11:30:00 AM',
    name: '11:30 am',
  },
  {
    id: '12:00:00 PM',
    name: '12 pm',
  },
  {
    id: '12:30:00 PM',
    name: '12:30 pm',
  },
  {
    id: '1:00:00 PM',
    name: '1 pm',
  },
  {
    id: '1:30:00 PM',
    name: '1:30 pm',
  },
  {
    id: '2:00:00 PM',
    name: '2 pm',
  },
  {
    id: '2:30:00 PM',
    name: '2:30 pm',
  },
  {
    id: '3:00:00 PM',
    name: '3 pm',
  },
  {
    id: '3:30:00 PM',
    name: '3:30 pm',
  },
  {
    id: '4:00:00 PM',
    name: '4 pm',
  },
  {
    id: '4:30:00 PM',
    name: '4:30 pm',
  },
  {
    id: '5:00:00 PM',
    name: '5 pm',
  },
  {
    id: '5:30:00 PM',
    name: '5:30 pm',
  },
  {
    id: '6:00:00 PM',
    name: '6 pm',
  },
  {
    id: '6:30:00 PM',
    name: '6:30 pm',
  },
  {
    id: '7:00:00 PM',
    name: '7 pm',
  },
  {
    id: '7:30:00 PM',
    name: '7:30 pm',
  },
  {
    id: '8:00:00 PM',
    name: '8 pm',
  },
  {
    id: '8:30:00 PM',
    name: '8:30 pm',
  },
  {
    id: '9:00:00 PM',
    name: '9 pm',
  },
  {
    id: '9:30:00 PM',
    name: '9:30 pm',
  },
  {
    id: '10:00:00 PM',
    name: '10 pm',
  },
  {
    id: '10:30:00 PM',
    name: '10:30 pm',
  },
  {
    id: '11:00:00 PM',
    name: '11 pm',
  },
  {
    id: '11:30:00 PM',
    name: '11:30 pm',
  },
  {
    id: '11:59:00 PM',
    name: '11:59 pm',
  },
];

export const transitionTypeOptions = mapToSelectOptions(transitionType);
export const availableHoursOptions = mapToSelectOptions(availableHours);
