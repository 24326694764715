import { Option } from 'shared/types/props';

enum Length {
  Cm = '1',
  M = '2',
  In = '3',
  Ft = '4',
}

const lengthOptions: Option<Length>[] = [
  {
    label: 'cm',
    value: Length.Cm,
  },
  {
    label: 'm',
    value: Length.M,
  },
  {
    label: 'in',
    value: Length.In,
  },
  {
    label: 'ft',
    value: Length.Ft,
  },
];

export { lengthOptions, Length };
