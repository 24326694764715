import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';

import {
  BomQuery,
  BomQueryVariables,
  ParentItemQuery,
  ParentItemQueryVariables,
} from 'shared/types';
import { LocationState, RouterParams, SetupResult } from 'pages/BOM/types';

import { bomItemsQuery, parentItemQuery } from '../operations';

import { useColumns } from './useColumns';

const useSetup = (): SetupResult => {
  const { extId, id } = useParams<RouterParams>();
  const { state: { name } = { name: null } } = useLocation<LocationState>();
  const {
    data: {
      items: {
        items: [{ name: itemNameQuery }],
      },
    } = {
      items: {
        items: [{ name: null }],
      },
    },
    loading: isParentItemLoading,
  } = useQuery<ParentItemQuery, ParentItemQueryVariables>(parentItemQuery, {
    variables: {
      pagination: {
        limit: 1,
        offset: 0,
      },
      filter: {
        ids: [id],
      },
    },
    skip: !!name,
  });
  const {
    data: { bomItems } = {
      bomItems: [],
    },
    loading,
  } = useQuery<BomQuery, BomQueryVariables>(bomItemsQuery, {
    variables: {
      bom: id,
    },
  });

  const columns = useColumns();

  return {
    columns,
    data: bomItems,
    isLoading: loading || isParentItemLoading,
    id,
    name: name || itemNameQuery,
    extId,
  };
};

export { useSetup };
