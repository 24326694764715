import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { memoCell } from 'shared/utils';
import { MappedRow, UnsubmittedRows } from 'pages/CostManagement/types';

import { FactoryCellAddLocationCostProps } from './types';

const FactoryCellAddLocationCost = (
  setUnsubmittedRows: Dispatch<SetStateAction<UnsubmittedRows>>,
  dependencies?: (keyof MappedRow)[],
  deriveHasDependenciesChanged?: (previousRowData: MappedRow, nextRowData: MappedRow) => boolean,
) =>
  memoCell<FactoryCellAddLocationCostProps>(
    ({
      row: {
        original: { costManagementId, parent },
      },
    }) => {
      const { id } = parent!;

      const onAddLocationCostClick = useCallback(() => {
        setUnsubmittedRows(oldValue => ({
          ...oldValue,
          [id]: [
            ...(oldValue[id] ?? []),
            {
              id: oldValue[id]?.length
                ? `${id}-unsubmitted-${oldValue[id].length}`
                : `${id}-unsubmitted-0`,
              isUnsubmitted: true,
              costManagementId,
              isDisabled: false,
              isAddLocationCostRow: false,
              parent,
              currency: parent!.currency,
            },
          ],
        }));
      }, [setUnsubmittedRows, parent]);
      return (
        <Button onClick={onAddLocationCostClick} startIcon={<AddIcon />} variant="outlined">
          Add cost
        </Button>
      );
    },
    dependencies,
    false,
    deriveHasDependenciesChanged,
  );

export { FactoryCellAddLocationCost };
