import { ApolloErrorHandler, getErrorCode } from 'setup/providers/ApolloClient';

const onUpdateError: ApolloErrorHandler = (enqueueSnackbar, { graphQLErrors, networkError }) => {
  if (networkError) {
    enqueueSnackbar(getErrorCode(networkError), { variant: 'error' });
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors && !networkError)
    graphQLErrors.forEach(error => {
      const { locations, message, path } = error;
      enqueueSnackbar(
        message.includes('cost_date_management_id_effective_date_unq')
          ? 'Not possible to save the same dates for same hierarchy - please correct your choice.'
          : getErrorCode(error),
        { variant: 'error' },
      );
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
};

export { onUpdateError };
