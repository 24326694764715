import { forwardRef } from 'react';

import { itemToString } from 'shared/components/NewestSelect/shared/utils';
import { ListItem, ListItemButton, ListItemText } from 'shared/components/List';

import { SelectOptionProps } from './types';

const SelectOption = forwardRef<HTMLLIElement, SelectOptionProps>(
  ({ item, selectedValue, ...itemProps }, ref) => {
    const selected = selectedValue === item.value;
    return (
      <ListItem {...itemProps} ref={ref} aria-selected={selected} highlight={selected}>
        <ListItemButton disabled={item.disabled}>
          <ListItemText>{itemToString(item)}</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  },
);

export { SelectOption };
