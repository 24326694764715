import { FC, useCallback, useMemo } from 'react';

import { FactoryCellDelete } from 'shared/components/DataGrid';
import { EditPage } from 'shared/components/Edit';
import { Loader } from 'shared/components/Loader';

import {
  FormValues,
  MassEditFormRow,
  MassEditFormRowAdditionalProps,
} from './components/MassEditFormRow';
import { headersForMassEditColumns } from './constants';
import { useSetup } from './hooks';
import { ItemView } from './ItemView';
import { mapMassEditRowToMutationArgs, mapRowToAddTagsMutationArgs } from './mappers';
import { updateItemMutation } from './operations';
import { Row, UseColumnsResult } from './types';

const Item: FC = () => {
  const {
    itemOptions,
    onCloseEditPage,
    reset,
    selectedRows,
    setSelectedRows,
    showAddTagsPage,
    showEditPage,
    showMainLoader,
    ...props
  } = useSetup();

  const deriveMassEditData = useCallback(() => {
    const data = selectedRows.map(({ original }) => original);
    const defaultValues = {
      capabilities: undefined,
      guaranteedShelfLife: undefined,
      manufacturingType: undefined,
      stopBuyingFlag: undefined,
      tobaccoType: undefined,
      alcoholByVolume: undefined,
      alcoholType: undefined,
      customAssortments: undefined,
      nationalAssortment: undefined,
      pullByDate: undefined,
      markdownDays: undefined,
      thawToSellEnabled: undefined,
      thawReplenishmentMinQty: undefined,
      thawReplenishmentMaxQty: undefined,
      thawTimeHours: undefined,
      thawExpiryDays: undefined,
      baggableAtPack: true,
      binnableAtPack: true,
    };

    const mapRowToMutationArgs = (formData: FormValues) => {
      if (showAddTagsPage) {
        return mapRowToAddTagsMutationArgs({ formData, selectedRows });
      }

      return mapMassEditRowToMutationArgs({ formData, selectedRows });
    };

    const columns = [
      ...headersForMassEditColumns,
      {
        id: 'delete',
        Cell: FactoryCellDelete<Row>({ setData: setSelectedRows! }),
      },
    ] as UseColumnsResult;

    return useMemo(
      () => ({
        defaultValues,
        columns,
        data,
        mapRowToMutationArgs,
      }),
      [selectedRows, showAddTagsPage],
    );
  }, [selectedRows, showAddTagsPage]);

  const editFormRowProps = useMemo<MassEditFormRowAdditionalProps>(() => {
    const { isAlcoholDisabled, isManufacturingTypeDisabled, isTobaccoDisabled } =
      selectedRows.reduce(
        (accumulator, currentValue) => ({
          isManufacturingTypeDisabled:
            accumulator.isManufacturingTypeDisabled && currentValue.original.isBom,
          isTobaccoDisabled: accumulator.isTobaccoDisabled && currentValue.original.isTobacco,
          isAlcoholDisabled: accumulator.isAlcoholDisabled && currentValue.original.isAlcohol,
        }),
        {
          isManufacturingTypeDisabled: true,
          isTobaccoDisabled: true,
          isAlcoholDisabled: true,
        },
      );

    return {
      ...itemOptions,
      isAlcoholDisabled: !isAlcoholDisabled,
      isManufacturingTypeDisabled: !isManufacturingTypeDisabled,
      isTobaccoDisabled: !isTobaccoDisabled,
    };
  }, [selectedRows, itemOptions]);

  if (showMainLoader) {
    return <Loader isLoading />;
  }

  return (
    <>
      {!showEditPage && !showAddTagsPage && (
        <ItemView {...props} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
      )}
      {(showEditPage || showAddTagsPage) && (
        <EditPage
          afterMutation={reset}
          deriveMassEditData={deriveMassEditData}
          editFormRowProps={editFormRowProps}
          isEditPage={showEditPage}
          MassEditFormRow={MassEditFormRow}
          mutation={updateItemMutation}
          onCloseEditPage={onCloseEditPage}
        />
      )}
    </>
  );
};
export { Item };
