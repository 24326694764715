import { merge } from 'ramda';
import { O } from 'ts-toolbelt';

import { CustomAssortment } from 'shared/types';

const makeCustomAssortment = (props: O.Partial<CustomAssortment, 'deep'> = {}): CustomAssortment =>
  merge<CustomAssortment, O.Partial<CustomAssortment, 'deep'>>(
    {
      description: 'desc',
      id: '1',
      name: 'RAS',
      updatedAt: 0,
      updatedBy: 'test',
      __typename: 'CustomAssortment',
    },
    props,
  );

export { makeCustomAssortment };
