import { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { Row as TableRow } from 'react-table';

import { FactoryHeaderRowSelectCheckboxProps } from './types';
import styles from './FactoryHeaderRowSelectCheckbox.module.css';

const FactoryHeaderRowSelectCheckbox =
  <DataType extends object>({
    checkIsCheckboxSelectable,
    getNumberOfEnableFields,
    toggleAllTableRows,
  }: {
    checkIsCheckboxSelectable?: (arg: {
      selectedFlatRows: TableRow<DataType>[];
      row: TableRow<DataType>;
      allRows: TableRow<DataType>[];
      enabledFields?: number;
    }) => boolean;
    toggleAllTableRows?: (
      rows: TableRow<DataType>[],
      toggleRowSelected: (rowId: string, checked: boolean) => void,
      event: ChangeEvent<HTMLInputElement>,
    ) => void;
    getNumberOfEnableFields?: (rows: TableRow<DataType>[]) => number;
  }) =>
  ({
    flatRows,
    getToggleAllRowsSelectedProps,
    row,
    rows,
    selectedFlatRows,
    toggleRowSelected,
  }: FactoryHeaderRowSelectCheckboxProps<DataType>) => {
    const { indeterminate, onChange, ref, ...rest } = getToggleAllRowsSelectedProps();
    const enabledFields = useMemo(
      () => (getNumberOfEnableFields ? getNumberOfEnableFields(rows) : rows.length),
      [rows],
    );

    const correctedOnChange = toggleAllTableRows
      ? (event: ChangeEvent<HTMLInputElement>) => {
          toggleAllTableRows?.(rows, toggleRowSelected, event);
        }
      : onChange;

    const defaultRef = useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate =
        !!selectedFlatRows.length && selectedFlatRows.length < enabledFields;
    }, [resolvedRef, indeterminate, selectedFlatRows]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        checkIsCheckboxSelectable?.({
          selectedFlatRows,
          row,
          allRows: flatRows,
          enabledFields,
        });
      }

      correctedOnChange!(event);
    };

    const checked = !!selectedFlatRows.length && selectedFlatRows.length - enabledFields === 0;

    return (
      <div className={styles.checkboxWrapper}>
        <input
          ref={resolvedRef}
          onChange={handleChange}
          type="checkbox"
          {...rest}
          checked={checked}
        />
      </div>
    );
  };

export { FactoryHeaderRowSelectCheckbox };
