import { FC, useCallback, useMemo } from 'react';

import { Loader } from 'shared/components/Loader';
import { FactoryCellDelete } from 'shared/components/DataGrid';
import { EditPage } from 'shared/components/Edit';

import { mapMassEditRowToMutationArgs, mapRowToAddTagsMutationArgs } from './mappers';
import { updateLocationMutation } from './operations';
import { FormValues, MassEditFormRow } from './components/MassEditFormRow';
import { headersForMassEditColumns } from './constants';
import { Row, UseColumnsResult } from './types';
import { useSetup } from './hooks';
import { LocationView } from './LocationView';

const LocationPage: FC = () => {
  const {
    locationOptions,
    onCloseEditPage,
    reset,
    selectedRows,
    setSelectedRows,
    showAddTagsPage,
    showEditPage,
    showMainLoader,
    ...props
  } = useSetup();

  const deriveMassEditData = useCallback(() => {
    const data = selectedRows.map(({ original }) => original);
    const defaultValues = {
      isThirdParty: undefined,
      nationalAssortment: undefined,
      allocationPriority: undefined,
      locationCustomAssortments: undefined,
      locationTobaccoCapabilities: undefined,
      locationCapabilities: undefined,
      alcoholVolumes: undefined,
      stopBuying: undefined,
    };

    const mapRowToMutationArgs = (formData: FormValues) => {
      if (showAddTagsPage) {
        return mapRowToAddTagsMutationArgs({ formData, selectedRows });
      }

      return mapMassEditRowToMutationArgs({ formData, selectedRows });
    };

    const columns = [
      ...headersForMassEditColumns,
      {
        id: 'delete',
        Cell: FactoryCellDelete<Row>({ setData: setSelectedRows }),
      },
    ] as UseColumnsResult;

    return useMemo(
      () => ({
        defaultValues,
        columns,
        data,
        mapRowToMutationArgs,
      }),
      [selectedRows, showAddTagsPage],
    );
  }, [selectedRows, showAddTagsPage]);

  if (showMainLoader) {
    return <Loader isLoading />;
  }

  return (
    <>
      {!showEditPage && !showAddTagsPage && (
        <LocationView {...props} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
      )}
      {(showEditPage || showAddTagsPage) && (
        <EditPage
          afterMutation={reset}
          deriveMassEditData={deriveMassEditData}
          editFormRowProps={locationOptions}
          isEditPage={showEditPage}
          MassEditFormRow={MassEditFormRow}
          mutation={updateLocationMutation}
          onCloseEditPage={onCloseEditPage}
        />
      )}
    </>
  );
};

export { LocationPage };
