import { mergeDeepRight } from 'ramda';
import { O } from 'ts-toolbelt';

import { Packaging } from 'shared/types';
import { Length, Weight } from 'shared/constants/units';

import { makePackageType } from './makePackageType';

const makePackaging = (props: O.Partial<Packaging, 'deep'> = {}): Packaging =>
  mergeDeepRight<Packaging, O.Partial<Packaging, 'deep'>>(
    {
      id: '298705',
      declaredHeight: 2000,
      declaredLength: 10000,
      declaredWidth: 10000,
      declaredLengthUnits: Length.Ft,
      declaredWeight: 100000,
      declaredWeightUnits: Weight.Mg,
      declaredTi: 1,
      declaredHi: 1,
      measuredHeight: 10000,
      measuredLength: 10000,
      measuredWidth: 10000,
      measuredLengthUnits: Length.M,
      measuredWeight: 99999,
      measuredWeightUnits: Weight.G,
      measuredTi: 1,
      measuredVolume: 8,
      declaredVolume: 9,
      measuredHi: 1,
      perChildPackageQty: 3,
      packageType: makePackageType(),
      eachUPCs: ['123', '456'],
      upcCode: '789',
      supplierItemPackagingNumber: '765',
      supplierNumber: '765',
      itemID: '1',
      maxAllocationQuantity: 100,
      roundingThreshold: 0.5,
      upc: [],
      updatedAt: 0,
      updatedBy: 'test',
      totalEachQuantity: 0,
      __typename: 'Packaging',
    },
    props,
  ) as Packaging;

export { makePackaging };
