import { ReactNode, memo, useCallback } from 'react';
import { useCustomCompareMemo } from 'use-custom-compare';
import { equals } from 'ramda';
import Chip from '@mui/material/Chip';

import { OptionValueType, Select, renderEmptyPlaceholder } from 'shared/components/Select';

import { Option } from './components/Option';
import type { AdditionalOptionProps, SelectMultiProps } from './types';
import styles from './SelectMulti.module.css';

const SelectMulti = memo(
  <OptionValue extends OptionValueType>({
    options,
    value,
    ...props
  }: SelectMultiProps<OptionValue>): JSX.Element => {
    const renderValue = useCallback(
      (selected: OptionValueType[]): ReactNode => {
        if (!selected.length) {
          return renderEmptyPlaceholder();
        }

        return (
          <div className={styles.renderValueContainer}>
            <Chip
              className={styles.chip}
              label={options.find(({ value: v }) => v === selected[0])?.label}
            />
            {selected.length > 1 && (
              <Chip className={styles.chip} label={`+${selected.length - 1}`} />
            )}
          </div>
        );
      },
      [options],
    );
    const optionProps = useCustomCompareMemo(
      () => ({
        selected: value,
      }),
      [value],
      equals,
    );
    return (
      <Select<OptionValue, OptionValue[], AdditionalOptionProps<OptionValue>>
        {...props}
        multiple
        Option={Option}
        optionProps={optionProps}
        options={options}
        renderValue={renderValue}
        size="small"
        value={value}
      />
    );
  },
  equals,
);

export { SelectMulti };
