import { FC, useMemo } from 'react';
import Button from '@mui/material/Button';
import { Controller, ControllerProps, useForm } from 'react-hook-form';
import { isEmpty, isNil } from 'ramda';
import { useUpdateEffect } from 'react-use';

import { ActionModal } from 'shared/components/Modal';
import { defaultAddModalFormValues } from 'pages/CostManagementV2/constants';
import { AddModalFormValues } from 'pages/CostManagementV2/types';
import { FORM_ID } from 'shared/components/Edit';
import { isNonZeroNumberOnlyString, mapToSelectOptions, preventPressEnter } from 'shared/utils';
import { AutocompleteOption, SearchAutocompleteQuery } from 'shared/components/Search';
import {
  ItemsOptionsQuery,
  ItemsOptionsQueryVariables,
  LocationAutocompleteOptionsQuery,
  LocationAutocompleteOptionsQueryVariables,
  OpcosQuery,
  OpcosQueryVariables,
  SupplierAutocompleteOptionsQuery,
  SupplierAutocompleteOptionsQueryVariables,
} from 'shared/types';
import {
  itemsOptionsQuery,
  locationAutocompleteOptionsQuery,
  opcosQuery,
  supplierAutocompleteOptionsQuery,
} from 'shared/operations/query';

import styles from './AddModal.module.css';
import { AddModalProps } from './types';

const itemMapDataToOptions = (data: ItemsOptionsQuery) => mapToSelectOptions(data?.items.items);

const supplierMapDataToOptions = (data: SupplierAutocompleteOptionsQuery) =>
  mapToSelectOptions(data?.suppliers.suppliers);

const locationMapDataToOptions = (data: LocationAutocompleteOptionsQuery) =>
  mapToSelectOptions(data?.locations.locations);

const opcoMapDataToOptions = (data: OpcosQuery) => mapToSelectOptions(data?.opcos.opcos);

const mapUserInputToAutocompleteQueryFilerParams = (
  value: string,
):
  | ItemsOptionsQueryVariables['filter']
  | LocationAutocompleteOptionsQueryVariables['filter']
  | SupplierAutocompleteOptionsQueryVariables['filter'] => {
  if (isNonZeroNumberOnlyString(value)) {
    return { extIds: [value] };
  }

  return { name: value };
};
const mapUserInputToAutocompleteQueryFilerParamsOpco = (
  value: string,
): OpcosQueryVariables['filter'] => {
  if (isNonZeroNumberOnlyString(value)) {
    return { id: [value] };
  }

  return { name: value };
};

const AddModal: FC<AddModalProps> = ({ isOpen, onClose, onSaveAndClose }) => {
  const { control, handleSubmit, setValue, watch } = useForm<AddModalFormValues>({
    defaultValues: defaultAddModalFormValues,
    mode: 'onChange',
  });

  const itemValue = watch('item');
  const supplierValue = watch('supplier');

  const disableLocation =
    isEmpty(itemValue) || isNil(itemValue) || isEmpty(supplierValue) || isNil(supplierValue);

  const disableOpco = disableLocation;

  useUpdateEffect(() => {
    if (disableLocation) {
      setValue('location', []);
    }
  }, [disableLocation, setValue]);

  useUpdateEffect(() => {
    if (disableOpco) {
      setValue('opco', []);
    }
  }, [disableOpco, setValue]);

  const renderLocation = useMemo<
    ControllerProps<AddModalFormValues, AddModalFormValues['location']>['render']
  >(
    () =>
      ({ field: { onBlur, onChange: handleChange, value: val }, fieldState: { error } }) =>
        (
          <SearchAutocompleteQuery<
            LocationAutocompleteOptionsQuery,
            LocationAutocompleteOptionsQueryVariables,
            true
          >
            className={styles.field}
            clearInputValueOnblur
            controlled
            defaultValue={val as AutocompleteOption[]}
            disabled={disableLocation}
            error={error?.message}
            label="Location"
            mapDataToOptions={locationMapDataToOptions}
            mapUserInputToAutocompleteQueryFilerParams={mapUserInputToAutocompleteQueryFilerParams}
            multiple
            onBlur={onBlur}
            onChange={(_, data) => handleChange(data)}
            placeholder="Search Location or ID"
            query={locationAutocompleteOptionsQuery}
            showAutocompleteAfter={1}
          />
        ),
    [control, disableLocation],
  );

  const renderOpco = useMemo<
    ControllerProps<AddModalFormValues, AddModalFormValues['opco']>['render']
  >(
    () =>
      ({ field: { onBlur, onChange: handleChange, value: val }, fieldState: { error } }) =>
        (
          <SearchAutocompleteQuery<OpcosQuery, OpcosQueryVariables, true>
            className={styles.field}
            clearInputValueOnblur
            controlled
            defaultValue={val as AutocompleteOption[]}
            disabled={disableOpco}
            error={error?.message}
            label="OpCo"
            mapDataToOptions={opcoMapDataToOptions}
            mapUserInputToAutocompleteQueryFilerParams={
              mapUserInputToAutocompleteQueryFilerParamsOpco
            }
            multiple
            onBlur={onBlur}
            onChange={(_, data) => handleChange(data)}
            placeholder="Search OpCo or ID"
            query={opcosQuery}
            showAutocompleteAfter={1}
          />
        ),
    [control, disableOpco],
  );

  const { renderItem, renderSupplier } = useMemo<{
    renderSupplier: ControllerProps<AddModalFormValues, AddModalFormValues['supplier']>['render'];
    renderItem: ControllerProps<AddModalFormValues, AddModalFormValues['item']>['render'];
  }>(
    () => ({
      renderSupplier: ({
        field: { onBlur, onChange: handleChange, value: val },
        fieldState: { error },
      }) => (
        <SearchAutocompleteQuery<
          SupplierAutocompleteOptionsQuery,
          SupplierAutocompleteOptionsQueryVariables,
          true
        >
          className={styles.field}
          clearInputValueOnblur
          defaultValue={val as AutocompleteOption[]}
          error={error?.message}
          label="Supplier"
          mapDataToOptions={supplierMapDataToOptions}
          mapUserInputToAutocompleteQueryFilerParams={mapUserInputToAutocompleteQueryFilerParams}
          multiple
          onBlur={onBlur}
          onChange={(_, data) => handleChange(data)}
          placeholder="Search Supplier or ID"
          query={supplierAutocompleteOptionsQuery}
          showAutocompleteAfter={1}
        />
      ),
      renderItem: ({
        field: { onBlur, onChange: handleChange, value: val },
        fieldState: { error },
      }) => (
        <SearchAutocompleteQuery<ItemsOptionsQuery, ItemsOptionsQueryVariables, true>
          className={styles.field}
          clearInputValueOnblur
          defaultValue={val as AutocompleteOption[]}
          error={error?.message}
          label="Item"
          mapDataToOptions={itemMapDataToOptions}
          mapUserInputToAutocompleteQueryFilerParams={mapUserInputToAutocompleteQueryFilerParams}
          multiple
          onBlur={onBlur}
          onChange={(_, data) => handleChange(data)}
          placeholder="Search Item or ID"
          query={itemsOptionsQuery}
          showAutocompleteAfter={1}
        />
      ),
    }),
    [control],
  );

  const onSubmitHandler = useMemo(
    () => handleSubmit(onSaveAndClose),
    [onSaveAndClose, handleSubmit],
  );

  return (
    <ActionModal
      actions={
        <>
          <Button
            data-testid="cancel-save-and-close-modal-button"
            onClick={onClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            data-testid="save-and-close-warning-modal-button"
            disabled={disableLocation}
            form={FORM_ID}
            type="submit"
            variant="contained"
          >
            Save And Close
          </Button>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      title="Costing granularity"
    >
      <form
        id={FORM_ID}
        onKeyDown={preventPressEnter}
        onSubmit={onSubmitHandler}
        role="presentation"
      >
        <Controller control={control} name="item" render={renderItem} />
        <Controller control={control} name="supplier" render={renderSupplier} />
        <Controller control={control} name="location" render={renderLocation} />
        <Controller control={control} name="opco" render={renderOpco} />
      </form>
    </ActionModal>
  );
};

export { AddModal };
