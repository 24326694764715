import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { usePrevious } from 'react-use';

import { isSystemLocked } from 'shared/utils/isSystemLocked';
import { SystemStatus, SystemStatusQuery, SystemStatusQueryVariables } from 'shared/types';
import { systemStatusQuery } from 'shared/operations/query';

const useIsSystemLocked = () => {
  const {
    client,
    data: { getSystemStatus } = { getSystemStatus: SystemStatus.Unlocked },
    networkStatus,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery<SystemStatusQuery, SystemStatusQueryVariables>(systemStatusQuery);

  const systemLocked = isSystemLocked(getSystemStatus);
  const previousSystemLocked = usePrevious(systemLocked);

  return useMemo(
    () => ({
      client,
      previousSystemLocked,
      systemLocked,
      refetchSystemStatusQuery: refetch,
      startPolling,
      stopPolling,
    }),
    [client, systemLocked, networkStatus, startPolling, stopPolling],
  );
};

export { useIsSystemLocked };
