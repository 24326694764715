import { PackageTypeName } from 'shared/constants/package';

import { MappedRow } from '../types';

const PackageTypeColumnName: {
  [K in Exclude<PackageTypeName, PackageTypeName.Each>]: keyof Pick<
    MappedRow,
    'inner' | 'case' | 'masterCase' | 'pallet'
  >;
} = {
  [PackageTypeName.Inner]: 'inner',
  [PackageTypeName.Case]: 'case',
  [PackageTypeName.MasterCase]: 'masterCase',
  [PackageTypeName.Pallet]: 'pallet',
} as const;

export { PackageTypeColumnName };
