import * as yup from 'yup';

import { AlcoholVolume } from 'shared/types';

// eslint-disable-next-line func-names
yup.addMethod(yup.array, 'uniqueAlcoholVolumes', function (propertyPath: string, message: string) {
  // eslint-disable-next-line func-names
  return this.test('unique', '', function (list = []) {
    const errors: yup.ValidationError[] = [];
    const volumes = list.map((item: AlcoholVolume) => item.volume);

    list.forEach((item: AlcoholVolume, index) => {
      const duplicatedVolumes = volumes.filter((e, i, a) => a.indexOf(e) !== i);
      if (duplicatedVolumes.includes(item.volume)) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message,
          }),
        );
      }
    });

    if (errors.length) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});

export { yup };
