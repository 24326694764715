import Button from '@mui/material/Button';

import { memoCell } from 'shared/utils';

import { UseColumnsArgs } from '../../types';

import { FactoryCellActionsProps } from './types';

const FactoryCellActions = ({
  onOpenDeleteModalClick,
}: {
  onOpenDeleteModalClick: UseColumnsArgs['onOpenDeleteModalClick'];
}) =>
  memoCell<FactoryCellActionsProps>(args => {
    const { row, value } = args;
    if (
      !row.original.isLeadTimeOverrideRow ||
      row.original.isUnsubmitted ||
      row.original.isAddLeadTimeOverrideRow
    )
      return null;

    const deleteButton = (
      <Button
        color="error"
        onClick={() => {
          onOpenDeleteModalClick(
            value.split(':')[1],
            <div>
              <div>Are you sure you want to delete this override?</div>
              <p>
                <div>Override Week Start: {row.original.weekStartDate}</div>
              </p>
            </div>,
          );
        }}
        size="small"
      >
        Delete
      </Button>
    );

    return deleteButton;
  });

export { FactoryCellActions };
