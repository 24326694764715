import {
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Search from '@mui/icons-material/Search';
import * as React from 'react';

import { FieldError } from 'shared/components/FieldError';

import { AutocompleteOption, Input, Label, PopperFitContent } from '../shared';

import { SearchAutocompleteProps, State } from './types';
import styles from './SearchAutocomplete.module.css';

const isOptionEqualToValue = (option: AutocompleteOption, checkedValue: AutocompleteOption) =>
  option.value === checkedValue.value;

const classes = {
  paper: styles.paper,
  popupIndicatorOpen: styles.popupIndicatorOpen,
};

const getOptionLabel = (option: AutocompleteOption | null) => option?.label || '';

const popupIcon = <Search color="action" fontSize="small" />;

const SearchAutocomplete = <
  IsMultiple extends boolean,
  FreeSolo extends boolean = false,
  DisableClearable extends boolean = false,
>({
  className,
  error,
  label,
  multiple = false as IsMultiple,
  placeholder = 'Search',
  open,
  onBlur,
  onChange,
  disableCloseOnSelect,
  disableMuiEnterKeyDownHandling = false,
  blurOnEnterKeyPress = false,
  onFocus,
  onInputChange,
  inputValue,
  value: selectedValue,
  options,
  hideInputWhenNoOptions = false,
  ...props
}: SearchAutocompleteProps<IsMultiple, FreeSolo, DisableClearable>) => {
  const [isOpen, setIsOpen] = useState(open || false);
  const stateRef = useRef<null | State<IsMultiple, FreeSolo, DisableClearable>>();

  const handleOpen = useCallback(() => {
    if (open === undefined) {
      setIsOpen(true);
    }
  }, [open]);

  const handleClose = useCallback(() => {
    if (open === undefined) {
      setIsOpen(false);
    }
  }, [open]);

  const renderInput = useCallback(
    inputProps => <Input {...inputProps} placeholder={placeholder} />,
    [placeholder],
  );

  const handleChange = useCallback(
    (e, newValue, reason) => {
      if (reason === 'clear' && isOpen && !disableCloseOnSelect && open === undefined) {
        setIsOpen(false);
      }
      onChange?.(e, newValue, reason, stateRef.current!);
    },
    [onChange, disableCloseOnSelect, open],
  );

  const handleInputChange = useCallback(
    (e, value, reason) => {
      onInputChange?.(e, value, reason, stateRef.current!);
    },
    [onInputChange],
  );

  const handleFocus = useCallback(
    e => {
      onFocus?.(e, stateRef.current!);
    },
    [onFocus],
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (...args) => {
      if (open === undefined) {
        setIsOpen(false);
      }

      onBlur?.(...args, stateRef.current!);
    },
    [onBlur, open],
  );

  const onKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
    if (event.key === 'Enter') {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  }, []);

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    stateRef.current = {
      isOpen: open ?? isOpen,
      setIsOpen,
      inputValue,
      value: selectedValue,
    };
  }, [open, isOpen, setIsOpen, inputValue, selectedValue]);

  if (hideInputWhenNoOptions && options.length === 0) {
    return <div className={className}>{label}</div>;
  }

  return (
    <div className={className}>
      <Label label={label} />
      <Autocomplete<AutocompleteOption, IsMultiple, DisableClearable, FreeSolo>
        aria-label={label as string}
        classes={classes}
        className={styles.container}
        clearOnBlur={false}
        disableCloseOnSelect={disableCloseOnSelect}
        fullWidth
        getOptionLabel={getOptionLabel}
        id={`inputSearch${label}`}
        inputValue={inputValue}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple={multiple}
        noOptionsText="no option"
        onBlur={handleBlur}
        onChange={handleChange}
        onClose={handleClose}
        onFocus={handleFocus}
        onInputChange={handleInputChange}
        onKeyDown={disableMuiEnterKeyDownHandling ? onKeyDown : undefined}
        onKeyPress={blurOnEnterKeyPress ? onKeyPress : undefined}
        onOpen={handleOpen}
        open={isOpen}
        options={options}
        PopperComponent={PopperFitContent}
        popupIcon={popupIcon}
        renderInput={renderInput}
        value={selectedValue}
        {...props}
      />
      {error && <FieldError block message={error} />}
    </div>
  );
};

export { SearchAutocomplete };
