import { defaultTo, nth, pipe } from 'ramda';

import { MappedRow } from '../types';

const pickUpcValue = pipe<
  MappedRow['upc'] | undefined,
  MappedRow['upc'],
  MappedRow['upc'][number] | undefined,
  string
>(defaultTo([]), nth(0) as () => string, defaultTo(''));

export { pickUpcValue };
