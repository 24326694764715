/* eslint-disable no-console */
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import _ from 'lodash';

import { config } from './constants';

const Security: FC = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState?: AppState | undefined) => {
    history?.push(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = _.merge(config, {
    onRedirectCallback,
  });
  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

export { Security };
