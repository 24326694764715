import { Column } from 'react-table';

export const headersForMassEditColumns: [Column[], Column[]] = [
  [
    {
      Header: 'Item Name',
      accessor: 'item.name',
    },
    {
      Header: 'Item ID',
      accessor: 'item.extId',
    },
    {
      Header: 'EACH UPC',
      accessor: 'item.upc',
    },
  ],
  [
    {
      Header: 'OpCo/Site Name',
      accessor: 'opco.name',
    },
    {
      Header: 'OpCo ID',
      accessor: 'opco.id',
    },
  ],
];
